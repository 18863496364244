/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as echarts from 'echarts';
import geoCoordMap from './geo';
import 'echarts/extension/bmap/bmap';

import './adsMap.less';

class ADSMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
        }
    }

    convertData (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
                res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                });
            }
        }
        return res;
    };

    initChartMap() {
        let chartMap = echarts.init(document.getElementById('mainMap'));
        let option = {
            title: {
                // text: 'XXX融合通信调度系统',
                left: 'center'
            },
            tooltip : {
                trigger: 'item'
            },
            bmap: {
                center: [113.95, 22.57],
                zoom: 14,
                roam: true,
                mapStyle: {
                    styleJson: [{
                        'featureType': 'water',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#d1d1d1'
                        }
                    }, {
                        'featureType': 'land',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#f3f3f3'
                        }
                    }, {
                        'featureType': 'railway',
                        'elementType': 'all',
                        'stylers': {
                            'visibility': 'off'
                        }
                    }, {
                        'featureType': 'highway',
                        'elementType': 'all',
                        'stylers': {
                            'color': '#fdfdfd'
                        }
                    }, {
                        'featureType': 'highway',
                        'elementType': 'labels',
                        'stylers': {
                            'visibility': 'off'
                        }
                    }, {
                        'featureType': 'arterial',
                        'elementType': 'geometry',
                        'stylers': {
                            'color': '#fefefe'
                        }
                    }, {
                        'featureType': 'arterial',
                        'elementType': 'geometry.fill',
                        'stylers': {
                            'color': '#fefefe'
                        }
                    }, {
                        'featureType': 'poi',
                        'elementType': 'all',
                        'stylers': {
                            // 'visibility': 'off'
                        }
                    }, {
                        'featureType': 'green', // 植被
                        'elementType': 'all',
                        'stylers': {
                            // 'visibility': 'off'
                        }
                    }, {
                        'featureType': 'subway', // 地铁线
                        'elementType': 'all',
                        'stylers': {
                            'visibility': 'off'
                        }
                    }, {
                        'featureType': 'manmade',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#d1d1d1'
                        }
                    }, {
                        'featureType': 'local',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#d1d1d1'
                        }
                    }, {
                        'featureType': 'arterial',
                        'elementType': 'labels',
                        'stylers': {
                            'visibility': 'off'
                        }
                    }, {
                        'featureType': 'boundary',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#fefefe'
                        }
                    }, {
                        'featureType': 'building',
                        'elementType': 'all',
                        'stylers': {
                            // 'color': '#d1d1d1'
                        }
                    }, {
                        'featureType': 'label',
                        'elementType': 'labels.text.fill',
                        'stylers': {
                            // 'color': '#999999'
                        }
                    }]
                }
            },
            series : [
                {
                    name: 'pm2.5',
                    type: 'scatter',
                    coordinateSystem: 'bmap',
                    data: [],
                    symbolSize: function (val) {
                        return val[2] / 10;
                    },
                    encode: {
                        value: 2
                    },
                    label: {
                        formatter: '{b}',
                        position: 'right',
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true
                        }
                    }
                },
            ]
        };
        
        chartMap.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartMap.resize();
            })
        });

        this.setState({
            chartMap: chartMap,
        })

        let bmap = chartMap.getModel()?.getComponent('bmap')?.getBMap();
        console.log(bmap);

        return chartMap;
    }

    updateData(data) {
        
    }

    componentDidMount() {
        this.initChartMap();
    }

    render() {
        let { className, style } = this.props;
        return (
            <div className={(className || "") + " map"} style={style}>
                <div id="mainMap" className="china-map" style={{height: style.height, width: '100%'}}/>
            </div>
        );
    }
}

export default ADSMap;