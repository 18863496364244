/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import QueueAnim from 'rc-queue-anim';
import { Menu, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, Popconfirm,
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer
} from 'antd';
import Highlighter from 'react-highlight-words';
import { HoDivider, HoDateRangePicker, HoLinkButton } from './hoComponent';
import { formatValidTime } from './logic';

// ***************************************** 本地分页相关组件 ***************************************** 

export const hoTableExtendColumnSortFunStringZH = (key) => {
    return (recordA, recordB) => recordA[key].localeCompare(recordB[key], 'zh-CN');
}

export const hoTableExtendColumnSortFunNumber = (key) => {
    return (recordA, recordB) => recordA[key] - recordB[key];
}

export const hoTableExtendColumnFilter = (filters) => {
    return filters.map((item) => ({text: item, value: item}));
}

export const hoTableExtendColumn = (title, key, sorter=false, filters=false, searchable=false, width=undefined, extra={}) =>{
    
    // console.log("antdTableColumn", key, sorter, filters, searchable, width);

    let ret = {
        title: title,
        dataIndex: key,
        type: 'str',
        key: key,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    // 排序
    if (typeof(sorter) === typeof(true)){
        // 默认的排序算法是按照字符串长度
        if (sorter === true){
            ret['sorter'] = (recordA, recordB) => recordA[key].length - recordB[key].length;
        }
    }else if (typeof(sorter) === typeof(function(){})){
        // 可以自己定义排序算法
        ret['sorter'] = sorter;
    }

    // 过滤或者搜索
    if (typeof(filters) === typeof(true) && filters === true){
        ret['onFilter'] = (value, item) => item[key] === value;
    }else if (typeof(filters) === typeof([]) && filters.length !== 0){
        // 可以自己定义过滤器的列表，例如：[{text:'苏苏', value:'苏苏'}]，此种过滤适用于只有有限的几个枚举类型字段，表单的状态，类型等
        ret['filters'] = filters;
        // 默认的过滤算法是包含
        ret['onFilter'] = (value, item) => {
            // console.log("onFilter", item, key, value);
            return item[key] === value;
        };
        ret['render'] = (value, record) => {
            // console.log("render", value, record);
            for (let x of filters){
                if (x.value === value){
                    return x.text;
                }
            }
            return value;
        }
    }else if (searchable === true){
        // 是否需要搜索框
        ret['searchable'] = true;
    }

    Object.assign(ret, extra);

    return ret;
}

export const hoTableExtendColumnActionButton = (actions, record) => {
    return actions.map((item, index) => {
        if (index === 0){
            return (<HoLinkButton 
                key={item.title} 
                onClick={ item.onClick ? (e) => {item.onClick(e, record)} : undefined}>
                {item.title}
            </HoLinkButton>);
        }else{
            return (<span key={item.title}>
                <HoDivider type="vertical" style={{marginLeft: 0}}/>
                <HoLinkButton onClick={ item.onClick ? (e) => {item.onClick(e, record)} : undefined}>
                    {item.title}
                </HoLinkButton>
            </span>);
        }
    });
}

export const hoTableExtendColumnTagButton = (tags, record) => {
    return tags.map((item, index) => (
        <Tag color={item.color} key={item.tag}>
            {item.tag}
        </Tag>)
    );
}

export const hoTableExtendColumnTag = (title, key, tags=undefined, width=undefined, extra={}) =>{
    
    let ret = {
        title: title,
        dataIndex: key,
        key: key,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    // 注册的render函数(每行的操作不一样，使用此方法)
    if (typeof(tags) === typeof(function(){})){
        ret['render'] = tags
    // 注册的操作(每行的操作一样，使用此方法)
    }else if (typeof(tags) === typeof([])){
        ret['render'] = (text, record) => {
            return hoTableExtendColumnTagButton(tags, record);
        }
    }
    
    return ret;
}

export const hoTableExtendColumnAction = (title, key, actions=undefined, width=undefined, extra={}) =>{
    
    let ret = {
        title: title,
        key: key,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    // 注册的render函数(每行的操作不一样，使用此方法)
    if (typeof(actions) === typeof(function(){})){
        ret['render'] = actions
    // 注册的操作(每行的操作一样，使用此方法)
    }else if (typeof(actions) === typeof([])){
        ret['render'] = (text, record) => {
            return hoTableExtendColumnActionButton(actions, record);
        }
    }
    
    return ret;
}

export class HoTableExtend extends Component {
    constructor(props){
        super(props);

        this.confPagination = this.confPagination.bind(this);
        this.confColumns = this.confColumns.bind(this);

        this.searchOkOnClick = this.searchOkOnClick.bind(this);
        this.searchResetOnClick = this.searchResetOnClick.bind(this);
        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);

        this.onColumnChange = this.onColumnChange.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.onPaginationShowSizeChange = this.onPaginationShowSizeChange.bind(this);

        const {colSorterKey, colSorterOrder, colFilter} = this.props;
        const sortedInfo = {columnKey: colSorterKey, order: colSorterOrder};

        this.state = {
            // 过滤与排序
            filteredInfo: colFilter ? colFilter : null,
            filteredDataLen: undefined,
            sortedInfo: sortedInfo,
            
            // 分页
            currentPage: 1,
            pageSize: 10,

            // 搜索
            searchText: '',
        }
    }

    searchOkOnClick(selectedKeys, confirm){
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };
    
    searchResetOnClick(clearFilters){
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps(dataIndex, title, nolight){
        let props = {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                // console.log("filterDropdown", selectedKeys);
                return (<div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`搜索${title}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.searchOkOnClick(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        key="search"
                        type="primary"
                        onClick={() => this.searchOkOnClick(selectedKeys, confirm)}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}>搜索</Button>
                    <Button 
                        key="reset"
                        onClick={() => this.searchResetOnClick(clearFilters)} 
                        size="small" 
                        style={{ width: 90 }}>清除</Button>
                </div>);
            },
            onFilter: (value, record) => {
                // console.log("onFilter");
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            },
            onFilterDropdownVisibleChange: visible => {
                // console.log("onFilterDropdownVisibleChange");
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            filterIcon: filtered => {
                // console.log("filterIcon");
                return(<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />);
            },
        }

        if (!nolight){
            props['render'] = text => {
                // console.log("render");
                return (<Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />);
            }
        }
        return props;
    }

    onColumnChange(pagination, filters, sorter, extra) {
        // console.log('Various parameters', pagination, filters, sorter, extra);
        const {currentDataSource} = extra;
        const {onColumnChange} = this.props;
        let dataLen = undefined;
        for (let filter in filters){
            if (filter !== undefined && filter !== null && filter.length !== 0){
                dataLen = currentDataSource.length;
            }
        }

        this.setState({
            filteredInfo: filters,
            filteredDataLen: dataLen,
            sortedInfo: sorter,
        });

        if (onColumnChange) {
            onColumnChange(pagination, filters, sorter)
        }
    };

    onPaginationChange(currentPage, pageSize) {
        // console.log('Page: ',currentPage, pageSize);
        this.setState({
            currentPage: currentPage,
        });
    }

    onPaginationShowSizeChange(currentPage, pageSize) {
        // console.log('Page: ',currentPage, pageSize);
        this.setState({
            currentPage: currentPage,
            pageSize: pageSize,
        });
    }

    // 列头参数
    confColumns(columns){
        const {filteredInfo, sortedInfo} = this.state;
        for (let col of columns){
            // console.log("confColumns", col);
            if (col.filters !== undefined && filteredInfo !== null){
                col['filteredValue'] = filteredInfo[col.key] || null;
            }

            if (col.sorter !== undefined && sortedInfo !== null){
                col['sortOrder'] = sortedInfo.columnKey === col.key && sortedInfo.order;
            }

            if (col.searchable === true){
                Object.assign(col, this.getColumnSearchProps(col.key, col.title, col.nolight))
            }
        } 

        return columns;
    }

    // 分页参数
    confPagination(){
        let { filteredDataLen } = this.state;
        let { data, dataLen, defaultPageSize, pageSizeOptions } = this.props;   

        if (filteredDataLen !== undefined){
            dataLen = filteredDataLen;
        }else{
            dataLen = dataLen || data.length;
        }
        pageSizeOptions = pageSizeOptions || ['10', '20', '50', '100'];
        defaultPageSize = defaultPageSize || 10;

        // console.log("confPagination", currentPage, pageSize, data.length);

        return {
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: pageSizeOptions,
            onChange: this.onPaginationChange,
            onShowSizeChange: this.onPaginationShowSizeChange,
            defaultCurrent: 1,
            defaultPageSize: defaultPageSize,
            showTotal: (total, range) => `显示总共 ${total} 条记录中的 ${range[0]} 至 ${range[1]} 条`,
            total: dataLen,
        };
    }

    render(){
        let {columns, data, rowKey, loading, extra, bordered, size, rowSelection, style, className, pagination, showHeader,
            onRowClick, onRowDoubleClick, onRowContextMenu, onRowMouseEnter, onRowMouseLeave // 单行的回调事件
        } = this.props;

        if (!data){
            return undefined;
        }

        const columnsConfig = this.confColumns(columns);

        if (pagination){
            pagination = this.confPagination();
        }

        return (<QueueAnim type={['bottom', 'top']}>
            <Table 
                key="ho-table-extend"
                style={style}
                className={className}
                columns={columnsConfig} 
                dataSource={data} 
                rowKey={rowKey}
                bordered={bordered}
                size={size}
                showHeader={showHeader}
                loading={loading}
                pagination={pagination}
                onChange={this.onColumnChange} 
                onRow={record => {
                    return {
                        onClick: onRowClick ? (e) => onRowClick(e, record) : undefined, // 点击行
                        onDoubleClick: onRowDoubleClick ? (e) => onRowDoubleClick(e, record) : undefined, // 双击行
                        onContextMenu: onRowContextMenu ? (e) => onRowContextMenu(e, record) : undefined, // 内容菜单
                        onMouseEnter: onRowMouseEnter ? (e) => onRowMouseEnter(e, record) : undefined, // 鼠标移入行
                        onMouseLeave: onRowMouseLeave ? (e) => onRowMouseLeave(e, record) : undefined, // 鼠标移出行
                    };
                }}
                rowClassName={(record, index) => {
                    return (index % 2) ? 'ant-table-row-background-dark' : 'ant-table-row-background-light';
                }}
                rowSelection={rowSelection}
                {...extra}
                />
        </QueueAnim>);
    }
}

HoTableExtend.defaultProps = {
    columns: [],
    data: [],
    title: '',
    dataLen: undefined,
    pageSizeOptions: undefined,
    defaultPageSize: undefined,
    rowKey: 'key',
    loading: false,
    bordered: true,
    pagination: true,
    showHeader: true,
    colSorterKey: undefined,
    colSorterOrder: 'descend', // or ascend
    onRowClick: undefined, 
    onRowDoubleClick: undefined, 
    onRowContextMenu: undefined, 
    onRowMouseEnter: undefined, 
    onRowMouseLeave: undefined,
    extra: undefined,
    size: 'default',
};


// ***************************************** 服务器分页相关组件 ***************************************** 

// 表格普通列(字段排序，搜索)
export const hoTableColumn = (title, dataIndex, type, sortable=false, searchable=false, width='20%', extra={}) => {
    return {
        title: title,
        dataIndex: dataIndex,
        type: type,
        searchable: searchable,
        sorter: sortable,
        width: width,
        ...extra,
    };
}

// 表格TAG标签列
export const hoTableColumnTagButton = (tags) => {
    return tags.map((item, index) => (
        <Tag color={item.color} key={item.tag || index} className={item.className} style={{marginTop: '2px'}}>
            {item.tag}
        </Tag>)
    );
}

export const hoTableColumnTag = (title, key, tags=undefined, width=undefined, extra={}) =>{
    
    let ret = {
        title: title,
        dataIndex: key,
        key: key,
        type: 'str',
        ...extra,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    // 注册的render函数(每行的操作不一样，使用此方法)
    if (typeof(tags) === typeof(function(){})){
        ret['render'] = tags
    // 注册的操作(每行的操作一样，使用此方法)
    }else if (typeof(tags) === typeof([])){
        ret['render'] = (text, record) => {
            return hoTableColumnTagButton(tags, record);
        }
    }
    
    return ret;
}

// 表格操作列
// actions=[{title: '删除', onClick:(e, record) => {console.log("onclick", e, record)} }]
export const hoTableColumnActionButton = (actions, record) => {

    const button = (item, index) => {
        if (item.type === 'dropdown') {
            return <Dropdown overlay={item.menu}>
                <HoLinkButton className="ant-dropdown-link" style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} onClick={e => e.preventDefault()}>{item.title} <Icon type="down" /></HoLinkButton>
            </Dropdown>
        } else {
            if (item.popconfirm) {
                return <Popconfirm
                    key={index}
                    onConfirm={(e) => {
                        if (item.onClick) {
                            item.onClick(e, record)
                        }
                    }} 
                    {...item.popconfirm}>
                    <HoLinkButton key={index} style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}}>{item.title}</HoLinkButton>
                </Popconfirm>
            } else {
                return <HoLinkButton key={index} style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} onClick={item.onClick ? (e) => {item.onClick(e, record)} : undefined}>{item.title}</HoLinkButton>;
            }
            
        }
    }

    return actions.map((item, index) => {
        if (index === 0){
            return button(item, index);
        } else {
            return (<span key={index}>
                <HoDivider type="vertical" style={{marginLeft: 0}}/>
                {button(item, index)}
            </span>);
        }
    });
}

// 表格操作列
export const hoTableColumnAction = (title, key, actions=undefined, width=undefined) =>{
    
    let ret = {
        title: title,
        dataIndex: key,
        key: key,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    // 注册的render函数(每行的操作不一样，使用此方法)
    if (typeof(actions) === typeof(function(){})){
        ret['render'] = actions
    // 注册的操作(每行的操作一样，使用此方法)
    }else if (typeof(actions) === typeof([])){
        ret['render'] = (text, record) => {
            return hoTableColumnActionButton(actions, record);
        }
    }
    
    return ret;
}

// 表格日期列
export const hoTableColumnDate = (title, key, searchable=true, width=undefined, extra={}) =>{
    let ret = {
        title: title,
        dataIndex: key,
        type: 'date',
        key: key,
        timeSearchable: searchable,
    }

    if (width !== undefined){
        // 宽度可以是'20%'，也可以是70，150等
        ret['width'] = width;
    }

    ret['render'] = (text, record) => {
        return formatValidTime(text);
    }

    return {...ret, ...extra};
}

// 初始化分页信息
export const hoTableInitPaginationInfo = (req={}, total=undefined, extra={}) => {
    return Object.assign({
        pagination:{
            page: {
                number: req?.pagination?.page?.number || 1,
                size: req?.pagination?.page?.size || 10,
            },
            sort: {
                field: req?.pagination?.sort?.field || undefined,
                order: req?.pagination?.sort?.order || 0,
            },
            filters: req?.pagination?.filters || [],
            total: total,
            ...extra,
        }
    })
}


export const hoTableTransPaginationInfo = (paginationInfo) => {
//      PageSize	每页返回数据条数	是	[int]		查看
//      PageNum	页码数，从1开始	是	[int]		
//      Filters	二维数组，行之间为或（or）关系，列之间为与(and)关系		[array]	[ [{"Key": "NickName", "Type": "str", "Op": "like", "Value": "性能"}] ]	查看
//      OrderKey	以该字段排序， 支持排序字段同Filters中支持过滤的字段列表		[string]		
//      Desc
    let newPag = {};
    newPag['PageSize'] = paginationInfo.pagination.page.size;
    newPag['PageNum'] = paginationInfo.pagination.page.number;
    newPag['Filters'] = paginationInfo.pagination.filters ? paginationInfo.pagination.filters.map(orItem => {
        return orItem.map(andItem => {
            return {
                Key: andItem[0],
                Type: (andItem[1] === "like" || andItem[1] === "equal" || andItem[1] === "not equal") ? "str" : "int",
                Op: andItem[1] === "equal" ? "=" : (andItem[1] === "not equal" ? "!=" : andItem[1]),
                Value: andItem[2],
            }
        })
    }) : [[]];
    if (paginationInfo.pagination.sort && paginationInfo.pagination.sort.field !== undefined) {
        newPag['OrderKey'] = paginationInfo.pagination.sort.field;
        newPag['Desc'] = paginationInfo.pagination.sort.order;
    }
    return newPag;
}


// 服务器分页的表格
export class HoTable extends Component {
    constructor(props){
        super(props);
       
        this.handleMenuClick = this.handleMenuClick.bind(this);

        this.confPagination = this.confPagination.bind(this);
        this.confColumns = this.confColumns.bind(this);

        this.onColumnChange = this.onColumnChange.bind(this);
        this.searchOkOnClick = this.searchOkOnClick.bind(this);
        this.searchResetOnClick = this.searchResetOnClick.bind(this);
        
        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
        this.getColumnDateSearchProps = this.getColumnDateSearchProps.bind(this);

        this.state = {
            paginationPosition: 'bottom',
            searchInfo: {}, // 搜索信息
            filters: {},
            sorter: {},
        }; 
        this.timeoutId = undefined;
    }

    handleMenuClick(e) {
        switch(e.key) {
            case 'pagination.top':{
                this.setState({
                    paginationPosition: 'top',
                })
                break;
            }
            case 'pagination.bottom':{
                this.setState({
                    paginationPosition: 'bottom',
                })
                break;
            }
            default:
                break;
        }
    }
  
    // 分页参数
    confPagination(){
        const {paginationPosition, pagination} = this.state;
        let { total, } = this.props;   

        // console.log("confPagination", pagination, total);
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.SubMenu title="分页器">
                    <Menu.Item key="pagination.top">置于顶部</Menu.Item>
                    <Menu.Item key="pagination.bottom">置于底部</Menu.Item>
                </Menu.SubMenu>
            </Menu>
        );

        return {
            current: pagination ? pagination.current : 1,
            pageSize: pagination ? pagination.pageSize : 10,
            position: paginationPosition,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            defaultCurrent: 1,
            defaultPageSize: 10,
            showTotal: (total, range) => {
                return <div>
                    <Dropdown overlay={menu}>
                        <Icon type="setting" style={{marginRight: '0.5rem'}} />
                    </Dropdown>
                    <span>显示总共 {total} 条记录中的 {range[0]} 至 {range[1]} 条</span>
              </div>
            },
            total: total ? total : 0,
        };
    }

    // 列头参数
    confColumns(){
        let { filters, sorter } = this.state;
        let { columns } = this.props;
        for (let col of columns){
            col.filterMultiple = false;
            if (col.searchable === true){
                Object.assign(col, this.getColumnSearchProps(col.dataIndex, col.title, col.nolight))
            }

            if (col.timeSearchable === true){
                Object.assign(col, this.getColumnDateSearchProps(col.dataIndex, col.title))
                Object.assign(col, {filtered: true});
            }

            if (filters) {
                let filteredValue = filters[col.dataIndex] || [];
                if (filteredValue.length > 0 && filteredValue[0] !== null && filteredValue[0] !== undefined) {
                    Object.assign(col, {filtered: true, filteredValue: filteredValue});
                } else {
                    Object.assign(col, {filtered: false, filteredValue: null});
                }
            }
        } 

        return columns;
    }

    // 过滤、排序、分页发生变化回调
    onColumnChange(pagination, filters, sorter) {
        
        //  pageNum:    请求第几页数据
        //  pageSize:   每页数据条数
        //  sortField:  指定排序字段， 不填写该字段的话，则不指定排序字段
        //  sortOrder:  排序顺序，0为升序，1为降序
        //  filters: 为过滤器设置  --> {'key': '', 'value': '', 'type': '', 'op': ''} 
        //  key-->查询条件中表的定义字段，value-->key字段对应的值 type-->暂时2种类型，字符串类型的为 'str', 数值类型的为 'int',   op 暂时只支持 "=", "!=", ">", ">=", "<", "<="
        //  举例： [{'key': 'category', 'value': '商品', 'type': 'str', 'op': '='}, {'key': 'shopId', 'value': '20', type': 'int', 'op': '='}]
        //  对应数据库的查询语句就是： select * from tbl_product where category = "商品" and shopId = 20;
        const {onColumnChange, columns} = this.props;

        let tmpFilters = [];
        for (let key in filters){
            if (!filters[key] || filters[key].length === 0){
                continue;
            }

            const ret = columns.find(item => item.dataIndex === key);
            if (ret === undefined){
                continue;
            }

            switch(ret.type){
                case 'str':
                    for (let item in filters[key]){
                        if (filters[key][item]) {
                            tmpFilters.push([key, 'like', filters[key][item]])
                        }
                    }
                    break;

                case 'date':
                    if (filters[key].length === 2){
                        if (filters[key][0]){
                            const startTime = filters[key][0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
                            tmpFilters.push([key, '>=', `'${startTime}'`]);
                        }

                        if (filters[key][1]){
                            const endTime = filters[key][1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
                            tmpFilters.push([key, '<=', `'${endTime}'`]);
                        }
                    }
                    break;
                default:
                    for(let item in filters[key]){
                        tmpFilters.push([key, '=', parseInt(filters[key][item])]);
                    }
                    break;
            }
        }

        const changed = {
            pagination: {
                page: {
                    number: pagination.current,
                    size: pagination.pageSize,
                },
                sort: {
                    field: sorter.field,
                    order: sorter.order === "ascend" ? 0 : 1
                },
                filters: [tmpFilters],
            }
        }

        Object.assign(pagination, {
            pageSize: pagination.pageSize,
            pageNum: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order === "ascend" ? 0 : 1,
            filters: tmpFilters,
        })

        if (onColumnChange !== undefined){
            onColumnChange(changed)
        }

        this.setState({
            pagination: pagination,
            filters: filters,
            sorter: sorter,
            timeoutId: null,
        })
    };

    // 搜索框'搜索'按钮被点击
    searchOkOnClick(dataIndex, selectedKeys, confirm){
        confirm();
        let {searchInfo, pagination, filters, sorter} = this.state;
        let {columns} = this.props;
        let newObj = {};
        let ret = columns?.find(item => item.dataIndex === dataIndex)
        if (ret?.type === 'date'){
            newObj[dataIndex] = selectedKeys;
        } else {
            newObj[dataIndex] = selectedKeys[0];
        }
        Object.assign(searchInfo, newObj);

        for (let key in searchInfo) {
            let ret = columns?.find(item => item.dataIndex === key)
            if (ret?.type === 'date'){
                filters[key] = searchInfo[key] || {}
            } else {
                filters[key] = [searchInfo[key]] || {}
            }
        }

        this.setState({ 
            searchInfo: searchInfo,
            filters: filters,
        });

        this.onColumnChange(pagination || this.confPagination(), filters, sorter)
    };
    
    // 搜索框'重置'按钮被点击
    searchResetOnClick(dataIndex, clearFilters){
        clearFilters();
        let {searchInfo, pagination, filters, sorter} = this.state;
        let {columns} = this.props;

        searchInfo[dataIndex] = undefined;
        for (let key in searchInfo) {
            let ret = columns?.find(item => item.dataIndex === key);
            if (ret?.type === 'date'){
                filters[key] = searchInfo[key] || {}
            } else {
                filters[key] = [searchInfo[key]] || {}
            }
        }
        this.setState({ 
            searchInfo: searchInfo,
            filters: filters,
        });

        this.onColumnChange(pagination || this.confPagination(), filters, sorter)

    };

    // 获取搜索属性
    getColumnSearchProps(dataIndex, title, nolight){
        let props = {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                // console.log("filterDropdown", selectedKeys);
                return (<div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`搜索${title}`}
                        value={selectedKeys ? selectedKeys[0] : null}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.searchOkOnClick(dataIndex, selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        key="search"
                        type="primary"
                        onClick={() => this.searchOkOnClick(dataIndex, selectedKeys, confirm)}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}>搜索</Button>
                    <Button 
                        key="reset"
                        onClick={() => this.searchResetOnClick(dataIndex, clearFilters)} 
                        size="small" 
                        style={{ width: 90 }}>清除</Button>
                </div>);
            },
            onFilterDropdownVisibleChange: visible => {
                // console.log("onFilterDropdownVisibleChange");
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            filterIcon: filtered => {
                // console.log("filterIcon");
                return(<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />);
            },
        }

        if (!nolight){
            props['render'] = (text, record, index) => {
                // 高亮搜索文本
                const {searchInfo} = this.state;
                let searchText = searchInfo[dataIndex] || '';
                return (<Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />);
            }
        }
        return props;
    }

    // 获取搜索属性
    getColumnDateSearchProps(dataIndex, title) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                // console.log("filterDropdown", selectedKeys);
                return (<div style={{ padding: 8, display: 'grid' }}>
                    <HoDateRangePicker
                        value={selectedKeys}
                        onChange={(dates, dateStrings) => setSelectedKeys(dates ? dates : [])}
                    />
                    <div style={{ marginTop: 8, textAlign: 'right' }}>
                        <Button
                            key="search"
                            type="primary"
                            onClick={() => this.searchOkOnClick(dataIndex, selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90, marginRight: 8 }}>搜索</Button>
                        <Button
                            key="reset"
                            onClick={() => this.searchResetOnClick(dataIndex, clearFilters)}
                            size="small"
                            style={{ width: 90 }}>清除</Button>
                    </div>
                </div>);
            },
            filterIcon: filtered => {
                // console.log("filterIcon", filtered);
                return (<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />);
            },
        }
    };
  
    render() {
        let {data, rowKey, loading, rowClassName, extra, bordered, size, rowSelection, className, 
            showHeader, showPagination,
            emptyBehavior, animDelay, anim,
            onRowClick, onRowDoubleClick, onRowContextMenu, onRowMouseEnter, onRowMouseLeave // 单行的回调事件
        } = this.props;
        
        // console.log("render HoTable: ", data)

        // 解决删除此页所有数据时，无法刷新到前一页数据的问题
        if ((!data || data.length === 0) && this.state.pagination && this.state.pagination.pageNum !== 1) {
            if (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    let {pagination, filters, sorter} = this.state;
                    pagination.current = emptyBehavior === 'decrease' ? pagination.pageNum - 1 : 1;
                    if (pagination.current >= 1) {
                        this.onColumnChange(pagination, filters, sorter)
                        this.timeoutId = undefined;
                    } 
                }, 500);
            }
        }

        let tblComponent = <Table
            key={"ho-table-" + rowKey + Math.floor(Math.random() * 1000)}
            className={className}
            columns={this.confColumns()} 
            dataSource={data} 
            rowKey={rowKey}
            bordered={bordered}
            size={size}
            showHeader={showHeader}
            loading={loading}
            pagination={showPagination ? this.confPagination() : false}
            onChange={this.onColumnChange} 
            onRow={record => {
                return {
                    onClick: onRowClick ? (e) => onRowClick(e, record) : undefined, // 点击行
                    onDoubleClick: onRowDoubleClick ? (e) => onRowDoubleClick(e, record) : undefined, // 双击行
                    onContextMenu: onRowContextMenu ? (e) => onRowContextMenu(e, record) : undefined, // 内容菜单
                    onMouseEnter: onRowMouseEnter ? (e) => onRowMouseEnter(e, record) : undefined, // 鼠标移入行
                    onMouseLeave: onRowMouseLeave ? (e) => onRowMouseLeave(e, record) : undefined, // 鼠标移出行
                };
            }}
            rowClassName={rowClassName ? rowClassName : (record, index) => {
                return (index % 2) ? 'ho-table-row-background-dark' : 'ho-table-row-background-light';
            }}
            rowSelection={rowSelection}
            {...extra}
        />
        
        if (anim) {
            return <QueueAnim type={['bottom', 'top']} delay={animDelay}>{tblComponent}</QueueAnim>
        } else {
            return tblComponent;
        }
    }
}
HoTable.defaultProps = {
    columns: [], // 列头定义
    data: [], // data数据
    rowKey: 'key', // data数据的key字段名
    loading: false, // 是否在loading
    pagination: undefined, // 分页、排序、过滤信息
    total: 0, // 总数
    rowClassName: undefined, // 单行的样式
    bordered: true,
    showHeader: true,
    showPagination: true,
    anim: false,
    animDelay: 0,
    emptyBehavior: 'first',
    paginationPosition: 'bottom',
    onColumnChange: undefined, // 分页、排序、过滤变化
    onRowClick: undefined, // 一行被单击
    onRowDoubleClick: undefined, 
    onRowContextMenu: undefined, 
    onRowMouseEnter: undefined, 
    onRowMouseLeave: undefined,
    extra: {},
    size: 'default',
};
