/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Card, Result } from 'antd';
import { menus } from '../laylout/menu';
import { HoBreadcrumb, HoIconFont } from '../util/hoComponent';

import './page.less'
import { Fragment } from 'react';

class Page extends Component {
    
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        let magicNumber = (Math.random() * 100).toFixed(0);
        let pathSnippets = this.props.location.pathname.split('/').filter(i => i);
        let subMenus = [];
        let title = undefined;
        for (let index = 0; index < pathSnippets.length; index++) {
            const url = `${pathSnippets.slice(-index-1).join('/')}`;
            let ret = menus.find(menu => menu.key === url)
            if (ret) {
                subMenus = ret.children;
                title = ret.title;
                break;
            } else {
                let ret2 = undefined;
                for (let menu of menus) {
                    ret2 = menu.children?.find(subMenu => subMenu.key === url)
                    if (ret2) {
                        subMenus = ret2.children;
                        title = ret2.title;
                        break
                    }
                }
                if (ret2) {
                    break
                }
            }
        }

        if (subMenus.length === 0 && pathSnippets?.length === 1 && pathSnippets?.[0] === "conference") {
            subMenus = menus
        }

        if (subMenus.length === 0) {
            title = "未知页面"
        }

        return <Fragment>
            <HoBreadcrumb
                items={[{title: title}]}
            />
            <ul className="menu-page-list">
                {
                    subMenus.length > 0 ? subMenus.map((menu, index) => {
                        return <li key={index}>
                            <Card
                            className={"menu-card menu-background" + ((magicNumber + index) % 8 + 1)}
                                style={{ width: 300, height: 200 }}
                                onClick={() => {
                                    window.goToMenu(menu.key)
                                }}
                            >
                                <Card.Meta title={<Fragment>
                                        <HoIconFont className="menu-page-icon" type={menu.icon} />
                                        <span>{menu.title}</span>
                                    </Fragment>}/>
                            </Card>
                        </li>
                    }) : undefined
                }
            </ul>
            {
                subMenus.length > 0 ? <div className="menu-page-title">选择一个你感兴趣的菜单</div> : <Result className="menu-page-empty" status="404" title="404" subTitle="没有发现你要找的页面"/>
            }
        </Fragment>
    }
}


export default connect(
    null, 
    null
)(Page);
