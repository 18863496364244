/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import moment from 'moment';
import { Menu, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer, Descriptions, Form
} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import {
    dataTerminalLicenseForbidden, dataTerminalLicensePenewal, dataTerminalLicenseCtrlList
} from '../../../../../../api/terminalLicence';

import {getLoginUserInfo} from '../../../../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, hoMessage, HoTooltipIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoHistoryButton, HoIconFont, HoBackButton,
} from '../../../../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../../../util/tableComponent';
import { LicenseType } from '../genForm';
import { penewalPageDom, penewalPageShow, operationActive, remainingDate } from '../common';

import './activation.less';

class ActivationCT extends Component{
    constructor(props){
        super(props);

        const columns = [
            hoTableColumn('状态', 'IsForbidden', 'int', false, false, '7%', {
                render: (text, record) => {
                    let tags = []
                    if (record.IsForbidden) {
                        tags.push({ color: red[3], tag: '禁用' })
                    }
                    tags.push({ color: blue[5], tag: '已激活' })
                    return hoTableColumnTagButton(tags)
                }
            }),
            hoTableColumn('ID', 'LicenseId', 'str', false, false, '15%'),
            hoTableColumn('用途', 'LicenseType', 'str', false, false, '7%', {
                render: (text, record) => LicenseType.getDescription(text)
            }),
            hoTableColumn('能力', 'CapabilitySet', 'str', false, false, '10%', {
                render: (text, record) => {
                    let caps = {};
                    try {
                        caps = JSON.parse(record.CapabilitySet)
                    } catch (error) {
                        
                    }
                    
                    return <ul className="table-column-caps-set" column={1}>
                        <li><HoIconFont type={caps?.base ? "icon-duihao": "icon-cuo"}/>基础能力</li>
                        <li><HoIconFont type={caps?.jz ? "icon-duihao": "icon-cuo"}/>极真能力</li>
                    </ul>
                }
            }),
            hoTableColumnDate('剩余时间', 'EndTime', false, '10%', {
                render: (text, record) => {
                    if (!record?.EndTime) {
                        return "未激活"
                    } else {
                        return remainingDate(record?.EndTime)
                    }
                }
            }),
            hoTableColumnDate('激活时间', 'ActiveTime', false, '10%', {
                render: (text, record) => record?.ActiveTime || "未激活"
            }),
            hoTableColumn('备注', 'Remark', 'str', false, false, '10%', {
                render: (text, record) => record.Issued ? (record.IssueRemark || record.Remark) : (record.Remark || record.IssueRemark)
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let operations = [];
                if (record.IsForbidden) {
                    operations.push({title: '启用', onClick: (e) => this.tblRowActiveOnClick(e, record, true)})
                }else{
                    operations.push({title: '禁用', onClick: (e) => this.tblRowActiveOnClick(e, record, false)})
                }
                operations.push({ title: '续期', onClick: (e) => penewalPageShow.bind(this)(e, record) })
                operations.push({ title: '详情', onClick: (e) => this.tblRowDetailOnClick(e, record) })
                return hoTableColumnActionButton(operations)
            }, '10%'),
        ];

        this.state = {
            columns: columns,
            data: undefined,
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
            },
            genData: undefined,
        }
    }

    trans2ApiData(data) {

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            if (key === "CapabilitySet") {
                d[key] = JSON.stringify(this.trans2ApiData(data[key]))
            } else {
                d[key] = data[key].value
            }
        }

        transInt(d, 'Duration');
        transInt(d, 'Amount');

        return d;
    }

    searchOnChange(search, pag) {
        let filter1 = [];
        if (search?.text?.length > 0) {
            filter1.push(['LicenseId', 'like', search.text]);
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = [
            filter1,
        ]

        paginationInfo.pagination.sort = {
            field: 'EndTime',
            order: 0
        }
        
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            loading: true,
        }, () => {
            dataTerminalLicenseCtrlList(this.props, paginationInfo,
                (_, rsp, req) => {
                    this.setState({
                        loading: false,
                        data: {
                            paginationInfo: hoTableInitPaginationInfo(req, rsp.TotalNum),
                            records: rsp?.RecordList || [],
                        }
                    })
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                }
            )

        })
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record) {
        window.goToMenu('devops/setting/license/terminal/detail/', record.LicenseId, 'devops/setting/license/terminal/activation')
    }

    // '禁用/启用'按钮被点击
    tblRowActiveOnClick(e, record, active) {
        operationActive.bind(this)(record, active, () => {
            this.mainRefreshOnClick()
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text) {
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[
                { title: "配置管理", url: "devops/setting" },
                { title: "License", url: "devops/setting/license" },
                { title: "终端" },
                { title: "已激活" }
            ]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoBackButton onClick={() => {
                window.goToMenu('devops/setting/license/terminal')
            }}>返回</HoBackButton>
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick(e)}/>
        </div>);
        return {title: <span>已激活License</span>, buttons: buttons}; 
    }

    mkTable(){
        let { columns, data, loading } = this.state;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return <div className="ho-license-table">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{display: 'flex'}}>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder="搜索License ID"
                            enterButton
                            allowClear={true}
                            onSearch={(text) => this.mainSearchOnClick(text)}
                            onChange={(e) => this.mainSearchOnChange(e)}
                            />
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'LicenseId'}
                loading={loading}
                onColumnChange={(pag) => this.searchOnChange(this.state.search, pag)}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const penewalComponent = penewalPageDom.bind(this)();
        return (<div>
            {penewalComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(ActivationCT);

