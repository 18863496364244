/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, Input } from 'antd';

const CustomizedForm = Form.create({
    name: '_gen_license_form',
    onFieldsChange(props, changedFields) {
      props.onChange(changedFields);
    },
    mapPropsToFields(props) {
      return {
        ProductCode: Form.createFormField({
            ...props.ProductCode,
            value: props.ProductCode?.value,
        }),
        MaxCall: Form.createFormField({
            ...props.MaxCall,
            value: props.MaxCall?.value,
        }),
        GenNum: Form.createFormField({
            ...props.GenNum,
            value: props.GenNum?.value,
        }),
        Remark: Form.createFormField({
            ...props.Remark,
            value: props.Remark?.value,
        }),
      };
    },
})(props => {
    const { form } = props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 4 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 20 },
            sm: { span: 20 },
        },
    };

    return (
        <Form>
            <Form.Item label="数量">
                {getFieldDecorator('GenNum', {
                    normalize: (value) => value ? parseInt(value) : 0,
                    initialValue: 1,
                    rules: [
                        { type: 'number', message: 'License数量必须大于0小于500', asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                let v = parseInt(value);
                                if (v > 500 || v <= 0) {
                                    reject('invalid');
                                } else {
                                    resolve();
                                }
                            })
                        }},
                        { required: true, message: 'License数量无效' }
                    ],
                })(<Input placeholder="License数量"/>)}
            </Form.Item>
            <Form.Item label="产品编码">
                {getFieldDecorator('ProductCode', {
                    initialValue: '',
                    rules: [{ required: false, message: '请输入产品编码', whitespace: false }],
                })(<Input placeholder="License归属的产品"/>)}
            </Form.Item>
            <Form.Item label="总容量(方)">
                {getFieldDecorator('MaxCall', {
                    normalize: (value) => value ? parseInt(value) : 0,
                    initialValue: 0,
                    rules: [
                        { type: 'number', message: '总容量'},
                    ],
                })(<Input placeholder="License所允许媒体服务器所容纳的会议方数"/>)}
                <span className="ant-form-text">0代表不限制</span>
            </Form.Item>
            <Form.Item label="备注">
                {getFieldDecorator('Remark')(<Input.TextArea placeholder="备注" rows={3}/>)}
            </Form.Item>
        </Form>
    );
  });

class OGenForm extends Component {
    render() {
        const { fields, onChange } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} onChange={onChange}/>
            </div>
        );
    }
}

let GenForm = Form.create({ name: 'gen_license_form' })(OGenForm);

export { GenForm }