/* eslint-disable no-unused-vars */
import moment from 'moment';
import { dataNewRoom, dataGetRoom, dataDelRoom, dataStartRoom, dataEndRoom, dataInviteMember, dataKickMember, dataMuteMember } from '../../room/roomRD';
import { dataListMember } from '../../member/memberRD';
import { hoTableInitPaginationInfo } from '../../../util/tableComponent';
import { randomFind } from '../../../util/logic';

const actions_list = [
    {
        group: '控制会议脚本',
        children: [
            {key: 'appointment', value: '预约会议'},
            {key: 'cancel_appointment', value: '取消预约'},
            {key: 'start', value: '开始会议'},
            {key: 'stop', value: '结束会议'},
        ]
    },
    {
        group: '控制成员脚本',
        children: [
            {key: 'invite', value: '邀请成员'},
            {key: 'kick', value: '踢出成员'},
            {key: 'mute', value: '成员静音'},
            {key: 'unmute', value: '解除静音'},
        ]
    }
]
export const actions = {
    list: actions_list,
    map: function(key) {
        for (let action of actions_list) {
            let ret = action.children.find(c => key === c.key);
            if (ret) {
                return ret.value;
            } 
        }
    },
    flatten: function(field = 'key') {
        let ret = [];
        for (let action of actions_list) {
            ret = ret.concat(action.children.map(a => a[field]));
        }
        return ret;
    }
}

function roll() {
    return Math.floor((Math.random()) * 100);
}

function random([start, end]) {
    let num = Math.floor((Math.random()) * (end + 1 - start)) + start;
    return Math.min(num, end)
}

function difference(arr1, arr2) {
    var set1 = new Set(arr1);
    var set2 = new Set(arr2);

    var subset = [];

    for (let item of set1) {
        if (!set2.has(item)) {
            subset.push(item);
        }
    }

    return subset;
};

function generateArray (start, end) {
    return Array.from(new Array(end + 1).keys()).slice(start)
}

function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
 
    var uuid = s.join("");
    return uuid;
}

export const ScriptStatus = {
    NONE: 0,
    DOING: 1,
    SUCCESS: 2,
    WARN: 3,
    ERROR: 4,
}

class Script {
    constructor(key, props, jobId, jobFlagIndex, jobUuid, extra) {
        this.key = key
        this.props = props;
        this.uuid = uuid();
        this.jobId = jobId;
        this.jobFlagIndex = jobFlagIndex;
        this.jobUuid = jobUuid;
        this.extra = extra;

        this.startTime = 0;
        this.endTime = 0;
        this.status = ScriptStatus.NONE;
        this.reason = '';
        this.description = '';
        this.apis = [];

        this.endCallback = undefined;

        this.errorFun = this.errorFun.bind(this);
    }

    errorFun(dispatch, rspStatus, rspStatusMsg, reqBody, path, _, __, elapse) {
        this.status = ScriptStatus.ERROR;
        this.reason = rspStatusMsg;
        this.endTime = moment().unix();
        this.apis.push({
            req: {
                path: path,
                body: reqBody,
            },
            rsp: {
                body: {
                    Status: rspStatus,
                    StatusMsg: rspStatusMsg,
                },
                elapse: elapse,
            }
        })

        if (rspStatus === 2002 && this.endCallback) {
            this.endCallback();
        }
        return true;
    }

    run(endCallback = undefined) {
        let that = this;
        this.endCallback = endCallback;
        this.startTime = moment().unix();
        this.endTime = 0;
        this.status = ScriptStatus.DOING;

        switch(this.key) {
            case 'appointment':{
                let pag = hoTableInitPaginationInfo();
                pag.pagination.page.size = this.extra.config.members[1]; // 按照配置最大人数作为一页数据
                pag.pagination.page.number = this.jobFlagIndex + 1; // 页数按照id自增
                pag.pagination.filters = [[['UserType', '=', 3]]]; // 只选择测试用户
                pag.pagination.sort = {
                    field: 'CreateTime',
                    order: 1
                }
                dataListMember(this.props, pag, (dispatch, rsp, req, path, _, __, elapse) => {
                    // 选出满足要求的用户数
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse}
                    })
                    if (rsp.Status === 0) {
                        let ms = rsp.UserList.slice(0, that.extra.members);
                        dataNewRoom(that.props, {Title: `#模拟演习会议室#${that.jobId}@${that.jobFlagIndex}`, Members: ms}, (dispatch, rsp2, req2, path2, _, __, elapse) => {
                            if (rsp.Status === 0) {
                                that.apis.push({
                                    req: { path: path2, body: req2, },
                                    rsp: { body: rsp2, elapse: elapse }
                                })
                                let pag = hoTableInitPaginationInfo();
                                pag.pagination.page.size = that.extra.config.members[1];
                                dataGetRoom(that.props, rsp2, pag, (dispatch, rsp3, req3, path3, _, __, elapse) => {
                                    that.apis.push({
                                        req: { path: path3, body: req3, },
                                        rsp: { body: rsp3, elapse: elapse }
                                    })
                                    that.extra.conference = {
                                        ConferenceId: rsp2.ConferenceId,
                                        ...rsp3
                                    };
                                    that.status = ScriptStatus.SUCCESS;
                                    that.endTime = moment().unix();
                                }, that.errorFun)
                            } else {
                                that.errorFun(dispatch, rsp2, req2, path, _, __, elapse)
                            }
                        }, that.errorFun)
                    } else {
                        that.errorFun(dispatch, rsp, req, path, _, __, elapse)
                    }

                }, this.errorFun);
                break;
            }
            case 'cancel_appointment': {
                dataDelRoom(this.props, this.extra.conference, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.Status === 0) {
                        that.status = ScriptStatus.SUCCESS;
                        that.endTime = moment().unix();
                    }
                }, this.errorFun)
                break;
            }
            case 'start': {
                dataStartRoom(this.props, this.extra.conference, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.Status === 0) {
                        that.status = ScriptStatus.SUCCESS;
                        that.endTime = moment().unix();
                    }
                }, this.errorFun)
                break;
            }
            case 'stop': {
                dataEndRoom(this.props, this.extra.conference, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.Status === 0) {
                        that.status = ScriptStatus.SUCCESS;
                        that.endTime = moment().unix();
                    }
                }, this.errorFun)
                break;
            }
            case 'invite': {
                let pag = hoTableInitPaginationInfo();
                pag.pagination.page.size = that.extra.config.members[1];
                dataGetRoom(this.props, this.extra.conference, pag, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.State === "finished") {
                        that.status = ScriptStatus.ERROR;
                        that.reason = '会议已结束';
                        that.endTime = moment().unix();
                        if (that.endCallback) {
                            that.endCallback();
                        }
                        return;
                    }
                    if (rsp.Status === 0) {
                        that.extra.conference = {
                            ConferenceId: this.extra.conference.ConferenceId,
                            ...rsp
                        };
                        let member = randomFind(rsp.ConfMebmbers, (m) => m.ConfState !== 'joined')
                        if (member) {
                            dataInviteMember(that.props, that.extra.conference, [member.SipNum], [member], (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                that.apis.push({
                                    req: { path: path2, body: req2, },
                                    rsp: { body: rsp2, elapse: elapse }
                                })
                                if (rsp2.Status === 0) {
                                    that.status = ScriptStatus.SUCCESS;
                                    that.description = `成员SIP号：${member.SipNum}`;
                                    that.endTime = moment().unix();
                                }
                            }, that.errorFun)
                        } else {
                            that.status = ScriptStatus.WARN;
                            that.reason = '找不到未入会的成员';
                            that.endTime = moment().unix();
                        }
                    }
                }, this.errorFun)
                break;
            }
            case 'kick': {
                let pag = hoTableInitPaginationInfo();
                pag.pagination.page.size = that.extra.config.members[1];
                dataGetRoom(this.props, this.extra.conference, pag, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.State === "finished") {
                        that.status = ScriptStatus.ERROR;
                        that.reason = '会议已结束';
                        that.endTime = moment().unix();
                        if (that.endCallback) {
                            that.endCallback();
                        }
                        return;
                    }
                    if (rsp.Status === 0) {
                        that.extra.conference = {
                            ConferenceId: this.extra.conference.ConferenceId,
                            ...rsp
                        };
                        let cnt = 0;
                        rsp.ConfMebmbers.forEach(m => {
                            if (m.ConfState === 'joined') {
                                cnt++;
                            }
                        });
                        let member = randomFind(rsp.ConfMebmbers, (m) => m.ConfState === 'joined');
                        if (member && cnt >= 2) {
                            dataKickMember(that.props, that.extra.conference, [member.SipNum], (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                that.apis.push({
                                    req: { path: path2, body: req2, },
                                    rsp: { body: rsp2, elapse: elapse }
                                })
                                if (rsp2.Status === 0) {
                                    that.status = ScriptStatus.SUCCESS;
                                    that.description = `成员SIP号：${member.SipNum}`;
                                    that.endTime = moment().unix();
                                }
                            }, that.errorFun)
                        } else if (member) {
                            that.status = ScriptStatus.WARN;
                            that.reason = '仅一个成员在会，不可踢';
                            that.endTime = moment().unix();
                        } else {
                            that.status = ScriptStatus.WARN;
                            that.reason = '找不到已入会的成员';
                            that.endTime = moment().unix();
                        }
                    }
                }, this.errorFun)
                break;
            }
            case 'mute': {
                let pag = hoTableInitPaginationInfo();
                pag.pagination.page.size = that.extra.config.members[1];
                dataGetRoom(this.props, this.extra.conference, pag, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.State === "finished") {
                        that.status = ScriptStatus.ERROR;
                        that.reason = '会议已结束';
                        that.endTime = moment().unix();
                        if (that.endCallback) {
                            that.endCallback();
                        }
                        return;
                    }
                    if (rsp.Status === 0) {
                        that.extra.conference = {
                            ConferenceId: this.extra.conference.ConferenceId,
                            ...rsp
                        };
                        let member = randomFind(rsp.ConfMebmbers, (m) => m.Mute === 0 && m.ConfState === 'joined');
                        if (member) {
                            dataMuteMember(that.props, that.extra.conference, [member.SipNum], true, (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                that.apis.push({
                                    req: { path: path2, body: req2, },
                                    rsp: { body: rsp2, elapse: elapse }
                                })
                                if (rsp2.Status === 0) {
                                    that.status = ScriptStatus.SUCCESS;
                                    that.description = `成员SIP号：${member.SipNum}`;
                                    that.endTime = moment().unix();
                                }
                            }, that.errorFun)
                        } else {
                            // that.status = ScriptStatus.WARN;
                            // that.reason = '找不到已入会且未被静音的成员';
                            // that.endTime = moment().unix();
                            let member = randomFind(rsp.ConfMebmbers, (m) => m.Mute === 1 && m.ConfState === 'joined');
                            if (member) {
                                that.key = 'unmute'; // 切换脚本
                                dataMuteMember(that.props, that.extra.conference, [member.SipNum], false, (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                    that.apis.push({
                                        req: { path: path2, body: req2, },
                                        rsp: { body: rsp2, elapse: elapse }
                                    })
                                    if (rsp2.Status === 0) {
                                        that.status = ScriptStatus.SUCCESS;
                                        that.description = `成员SIP号：${member.SipNum}`;
                                        that.reason = '切换脚本';
                                        that.endTime = moment().unix();
                                    }
                                }, that.errorFun)
                            } else {
                                that.status = ScriptStatus.WARN;
                                that.reason = '无在会成员，无法完成静音操作';
                                that.endTime = moment().unix();
                            }
                        }
                    }
                }, this.errorFun)
                break;
            }
            case 'unmute': {
                let pag = hoTableInitPaginationInfo();
                pag.pagination.page.size = that.extra.config.members[1];
                dataGetRoom(this.props, this.extra.conference, pag, (dispatch, rsp, req, path, _, __, elapse) => {
                    that.apis.push({
                        req: { path: path, body: req, },
                        rsp: { body: rsp, elapse: elapse }
                    })
                    if (rsp.State === "finished") {
                        that.status = ScriptStatus.ERROR;
                        that.reason = '会议已结束';
                        that.endTime = moment().unix();
                        if (that.endCallback) {
                            that.endCallback();
                        }
                        return;
                    }
                    if (rsp.Status === 0) {
                        that.extra.conference = {
                            ConferenceId: this.extra.conference.ConferenceId,
                            ...rsp
                        };
                        let member = randomFind(rsp.ConfMebmbers, (m) => m.Mute === 1 && m.ConfState === 'joined');
                        if (member) {
                            dataMuteMember(that.props, that.extra.conference, [member.SipNum], false, (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                that.apis.push({
                                    req: { path: path2, body: req2, },
                                    rsp: { body: rsp2, elapse: elapse }
                                })
                                if (rsp2.Status === 0) {
                                    that.status = ScriptStatus.SUCCESS;
                                    that.description = `成员SIP号：${member.SipNum}`;
                                    that.endTime = moment().unix();
                                }
                            }, that.errorFun)
                        } else {
                            // that.status = ScriptStatus.WARN;
                            // that.reason = '找不到已入会且被静音的成员';
                            // that.endTime = moment().unix();
                            let member = randomFind(rsp.ConfMebmbers, (m) => m.Mute === 0 && m.ConfState === 'joined');
                            if (member) {
                                that.key = 'mute'; // 切换脚本
                                dataMuteMember(that.props, that.extra.conference, [member.SipNum], true, (dispatch, rsp2, req2, path2, _, __, elapse) => {
                                    that.apis.push({
                                        req: { path: path2, body: req2, },
                                        rsp: { body: rsp2, elapse: elapse }
                                    })
                                    if (rsp2.Status === 0) {
                                        that.status = ScriptStatus.SUCCESS;
                                        that.description = `成员SIP号：${member.SipNum}`;
                                        that.reason = '切换脚本';
                                        that.endTime = moment().unix();
                                    }
                                }, that.errorFun)
                            } else {
                                that.status = ScriptStatus.WARN;
                                that.reason = '无在会成员，无法完成静音操作';
                                that.endTime = moment().unix();
                            }
                        }
                    }
                }, this.errorFun)
                break;
            }
            default: {
                this.status = ScriptStatus.ERROR;
                this.reason = '不支持的脚本';
                this.endTime = moment().unix();
                break;
            }
        }
    }
}

class Job {
    constructor(seq, flagIndex, config, props, stopCallback) {

        this.stopNotify = this.stopNotify.bind(this)

        this.config = config;
        this.props = props;

        this.stopCallback = stopCallback;
        this.seq = seq;
        this.flagIndex = flagIndex;
        this.uuid = uuid();
        this.members = 0;
        this.isStart = false;

        this.runScriptIndex = 0;
        this.scripts = []

        this.startTime = 0;
        this.endTime = 0;

        this.data = {};
        this.prepare();
    }

    scriptCount(key = '') {
        let count = 0;
        for (let script of this.scripts) {
            if (script.key === key) {
                count++;
            }
        }
        return count;
    }

    stopNotify() {
        this.stop(false)
    }

    loop(first = false) {
        let that = this;
        let timeout = first ? 0 : random(this.config.operationInterval) * 1000;
        let scriptLen = that.scripts.length;
        setTimeout(() => {
            if (!that.isStart) {
                return;
            }

            if (that.runScriptIndex >= scriptLen) {
                that.stop()
                return;
            } else {
                let script = that.scripts[that.runScriptIndex];
                if (that.runScriptIndex === 0) {
                    that.runScriptIndex++;
                    script.run(that.stopNotify);
                } else {
                    let ret = true;
                    if (that.runScriptIndex === 1 || that.runScriptIndex === 2) {
                        // 执行1,2脚本时必须要前面所有脚本成功才可以，如果失败了，则整个job失败
                        for (let script of that.scripts.slice(0, that.runScriptIndex)) {
                            if (script.status === ScriptStatus.ERROR) {
                                ret = false;
                                break;
                            }
                        }
                    }
                    if (ret) {
                        let preScript = that.scripts[that.runScriptIndex - 1]
                        if (preScript.status !== ScriptStatus.DOING) {
                            that.runScriptIndex++;
                            script.run(that.stopNotify);
                        }
                    } else {
                        that.stop()
                        return;
                    }
                }
            }
            that.loop()
        }, timeout);
        // console.log(`job#${this.flagIndex} start timer ${timer} for script#${this.runScriptIndex}(${this.scripts.length}) after ${timeout / 1000}s`);
    }

    prepare() {
        // 为job自动生成脚本
        // 先预约一个会议
        this.members = random(this.config.members); // 确定人数
        this.data = {members: this.members, config: this.config}
        this.scripts.push(new Script('appointment', this.props, this.seq, this.flagIndex, this.uuid, this.data))
        if (roll() < 10) {
            // 直接取消预约（脚本结束）
            this.scripts.push(new Script('cancel_appointment', this.props, this.seq, this.flagIndex, this.uuid, this.data))
            return;
        } else {
            // 开始会议
            this.scripts.push(new Script('start', this.props, this.seq, this.flagIndex, this.uuid, this.data))
            
            // 先把所有人邀请进来
            // let i = 0;
            // for (i = 0; i < this.members; i++) {
            //     this.scripts.push(new Script('invite', this.props, this.seq, this.flagIndex, this.uuid, this.data))
            // }
            // 随机加入脚本（除去appointment，cancel_appointment，start，stop）
            let acts = actions.flatten();
            let targetScripts = random(this.config.scripts)
            while(this.scripts.length < targetScripts) {
                let act = acts[roll() % acts.length]
                if (act === 'appointment' || act === 'cancel_appointment' || act === 'start' || act === 'stop' ) {
                    continue;
                } else if (act === 'invite') {
                    // 邀请人数超过会议最大成员数，重新选个脚本
                    let cur = this.scriptCount('invite') - this.scriptCount('kick')
                    if (cur >= this.members) {
                        continue;
                    }
                } else if (act === 'kick') {
                    // 当前会议至少要有一个人，不能踢人，重新选个脚本
                    let cur = this.scriptCount('invite') - this.scriptCount('kick')
                    if (cur <= 1) {
                        continue;
                    }
                } else if (act === 'mute') {
                    // 静音人数超过解除人数，重新选个脚本
                    let cur = this.scriptCount('mute') - this.scriptCount('unmute');
                    if (cur >= this.members) {
                        continue;
                    }
                } else if (act === 'unmute') {
                    // 解除人数超过会议人数（默认入会是静音的），重新选个脚本
                    let cur = this.scriptCount('mute') - this.scriptCount('unmute');
                    if (cur <= 0) {
                        continue;
                    }
                }
                this.scripts.push(new Script(act, this.props, this.seq, this.flagIndex, this.uuid, this.data))
            }
            // 最后加入结束会议脚本（默认最后一个脚本做清理工作）
            this.scripts.push(new Script('stop', this.props, this.seq, this.flagIndex, this.uuid, this.data))
        }
    }

    start() {
        this.isStart = true;
        this.startTime = moment().unix();
        this.endTime = 0;

        this.loop(true);
    }

    stop(force = false) {
        this.isStart = false;
        this.endTime = moment().unix();
        if (!force) {
            if (this.stopCallback) {
                this.stopCallback(this.uuid)
            }
        } else {
            // 强行停止，则执行最后一个脚本（默认最后一个脚本做清理工作）
            let scriptLen = this.scripts.length; 
            if (this.runScriptIndex < scriptLen - 1) {
                let script = this.scripts[scriptLen - 1];
                script.run();
            }
        }
        
        // console.log(`job#${this.flagIndex} has finished and spent ${this.endTime - this.startTime}s`)
    }

}

class Pipline {

    constructor(config, props) {
        this.config = config;
        this.props = props;
        this.jobs = [];
        this.jobHistories = [];
        this.isStart = false;
        this.seq = 0;
        this.startTime = 0;
        this.endTime = 0;
    }

    jobStopFun(jobUuid) {
        let index = this.jobs.findIndex(t => t.uuid === jobUuid);
        if (index !== -1) {
            let job = this.jobs[index]
            this.jobHistories.push(job);
            this.jobs.splice(index, 1);
        }
    }

    loop(first = false) {
        let that = this;
        let timeout = first ? 0 : random(this.config.operationInterval) * 1000;
        let timer = setTimeout(() => {
            if (that.isStart) {
                let curJobs = that.jobs.length;
                if (curJobs <= that.config.conferences[0]) {
                    let targetJobs = random(that.config.conferences)
                    let addJobs = Math.max(targetJobs - curJobs, 0)
                    console.log(`pipline add ${addJobs}(=${targetJobs}-${curJobs}) job(s)`)
                    for (let i = 0; i < addJobs; i++) {
                        let flagIndex = difference(generateArray(0, that.config.conferences[1] - 1), that.jobs.map(job => job.flagIndex))[0]
                        if (flagIndex !== undefined) {
                            let job = new Job(++that.seq, flagIndex, that.config, that.props, that.jobStopFun.bind(that))
                            job.start();
                            that.jobs.push(job)
                        }
                    }
                }
                that.loop()
            }
        }, timeout);
        // console.log(`pipline start timer ${timer} after ${timeout / 1000}s`);
    }

    start() {
        console.log('pipline start')
        this.isStart = true;
        this.startTime = moment().unix();
        this.endTime = 0;
        this.jobs = [];
        this.jobHistories = [];
        this.seq = 0;
        this.loop(true);
        return true;
    }

    stop(uuid = null) {
        if (!uuid) {
            console.log('pipline stop all jobs')
            this.isStart = false;
            this.endTime = moment().unix();
            for (let job of this.jobs) {
                job.stop(true);
            }
            this.jobHistories = this.jobHistories.concat(this.jobs);
            this.jobs = [];
        } else {
            console.log(`pipline stop job@${uuid}`)
            let index = this.jobs.findIndex(job => job.uuid === uuid)
            if (index !== -1) {
                let job = this.jobs[index]
                job.stop(true);
                this.jobHistories.push(job)
                this.jobs = [...this.jobs.slice(0, index), ...this.jobs.slice(index + 1)]
            }
        }
    }
}

export {Pipline};