/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Dropdown, Button, Descriptions, Typography, Icon, Modal, Spin } from 'antd';

import { 
    HoTable, hoTableColumn, hoTableColumnAction, hoTableInitPaginationInfo, hoTableColumnTagButton,
} from '../../util/tableComponent';
import { 
    actNetRequestClear, getNetRequestStatus 
} from '../../util/netReqRD';

import { hoMessage, HoRefreshButton, HoLinkButton } from '../../util/hoComponent';
import { sipStateTags, confStateTags } from '../common/common';
import { dataGetRoom, getRoomDataSuccessHd, ACT_NET_ROOM_GET, getRoomData } from '../../pages/room/roomRD';
import SelectAllMember from './selectAllMember';

class SelectMember extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        
        this.memberOnChange = this.memberOnChange.bind(this);
        this.dataGetRoomDebounce = this.dataGetRoomDebounce.bind(this);
       
        // 已选
        this.mainDeleteOnClick = this.mainDeleteOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblDeleteOnClick = this.tblDeleteOnClick.bind(this);
        this.tblSelectOnChange = this.tblSelectOnChange.bind(this);


        const tableColumns = [
            hoTableColumn('在线状态', 'AppState', 'str', false, false, '40%', {
                render: (text, record) => hoTableColumnTagButton(sipStateTags(text, record).concat(confStateTags(text, record))),
            }),
            hoTableColumn('设备名称', 'NickName', 'str', false, false, '40%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '删除', onClick: this.tblDeleteOnClick},
            ], '10%'),
        ];

        this.state = {

            tableColumns: tableColumns,
            selectedRowKeys: [],
            selectedRows: [],
            paginationInfo: hoTableInitPaginationInfo(),

        }
    }

    dataGetRoomDebounce() {
        let {timeoutId, paginationInfo} = this.state;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {dataGetRoom(this.props, this.props.roomInfo, paginationInfo, getRoomDataSuccessHd)}, 1000) 
        this.setState({
            timeoutId: timeoutId,
        })
    }

    memberOnChange(action, record) {
        let {memberOnChange} = this.props;
        if (memberOnChange){
            memberOnChange(action,record);
        }
        this.dataGetRoomDebounce();
    }

     // '刷新'按钮被点击
    mainRefreshOnClick(e){
        this.dataGetRoomDebounce();
    }

    // '批量删除'按钮被点击
    mainDeleteOnClick(e) {
        const {selectedRows} = this.state;
        if (selectedRows.length === 0){
            hoMessage({type: 'warning', msg: `请至少勾选一个设备`});
        } else {
            this.memberOnChange('group.del', selectedRows);
            this.setState({
                selectedRowKeys: [],
                selectedRows: [],
            });
        }
    }

    tblDeleteOnClick(e, record){
        e.stopPropagation();
        this.memberOnChange('del', record);
    }

    tblSelectOnChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataGetRoom(this.props, this.props.roomInfo, paginationInfo, getRoomDataSuccessHd)
        this.setState({
            paginationInfo: paginationInfo,
            selectedRowKeys: [],
            selectedRows: [],
        });
    }
    

    mkTable(){
        let { tableColumns, selectedRowKeys} = this.state;
        const { roomInfo, getRoomStatus } = this.props;
        const { TotalMemberNum, ConfMebmbers } = roomInfo || {};        
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: this.tblSelectOnChange,
        };
        // console.log("mkTable", data);

        return (<HoTable
            className="ho-select-member-done-table"
            columns={tableColumns}
            data={ConfMebmbers}
            loading={getRoomStatus === 1}
            emptyBehavior={'decrease'}
            bordered={false}
            size={'small'}
            rowKey={'UserId'}
            onColumnChange={this.tblColOnChange}
            rowSelection={rowSelection}
            total={TotalMemberNum}
        />);
    }


    // clear handle
    componentWillUnmount(){
    }

    componentDidMount(){
        const {paginationInfo} = this.state;
        dataGetRoom(this.props, this.props.roomInfo, paginationInfo, getRoomDataSuccessHd)
    }

    render() {
        const {selectedRowKeys} = this.state;
        const {style} = this.props;
        // 主页面
        const tableComponent = this.mkTable();

        const deleteDisable = selectedRowKeys.length === 0;
        return (<div style={style}>
            <Row>
                <Col span={11} style={{border: 'solid 1px #e8e8e8', borderRadius: '4px', padding: '1rem'}}>
                    <span style={{fontWeight: 500, fontSize: '16px'}}>已选</span>
                    <div style={{margin: '0.5rem 0'}}>
                        <HoLinkButton disabled={deleteDisable} icon="delete" onClick={this.mainDeleteOnClick} >批量删除</HoLinkButton>
                        <HoLinkButton icon="sync" style={{float: 'right'}} onClick={this.mainRefreshOnClick} >刷新</HoLinkButton>
                    </div>
                    {tableComponent}
                </Col>
                <Col span={2}>
                </Col>
                <Col span={11} style={{border: 'solid 1px #e8e8e8', borderRadius: '4px', padding: '1rem'}}>
                    <SelectAllMember
                        reqUserInfo={this.props.reqUserInfo}
                        memberOnChange={this.memberOnChange}
                    />
                </Col>
            </Row>
            
        </div>);
    }
}

const mapState = (state) => ({
    getRoomStatus: getNetRequestStatus(state, ACT_NET_ROOM_GET), 
    roomInfo: getRoomData(state),
});


export default connect(
    mapState, 
    null
)(SelectMember);

