/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import { netRequest_reducer } from '../util/netReqRD'
import { login_reducer } from '../pages/login/loginRD'
import { room_reducer } from '../pages/room/roomRD'
import { user_reducer } from '../pages/user/userRD'
import { channel_reducer } from '../pages/devops/channel/channelRD';
import { conference_reducer } from '../pages/devops/conference/conferenceRD';
import { registration_reducer } from '../pages/devops/registration/registrationRD';
import { member_reducer } from '../pages/member/memberRD';
import { mqtt_reducer } from '../component/mqtt/mqtt';
import { monitor_reducer } from '../pages/monitor/monitorRD';
import { cdr_reducer } from '../pages/bill/cdr/cdrRD';
import { warn_reducer } from '../pages/devops/warn/warnRD';
import { blacklist_reducer } from '../pages/devops/blacklist/blacklistRD';
import { practice_reducer } from '../pages/devops/practice/practiceRD';
import { jizhen_reducer } from '../pages/jizhen/jizhenRD';

export const reducer = combineReducers(
    {
        login: login_reducer,
        net_request: netRequest_reducer, 

        room: room_reducer,
        user: user_reducer,
        conference: conference_reducer,
        channel: channel_reducer,
        registration: registration_reducer,
        member: member_reducer,
        mqtt: mqtt_reducer,
        monitor: monitor_reducer,
        cdr: cdr_reducer,
        warn: warn_reducer,
        blacklist: blacklist_reducer,
        practice: practice_reducer,
        jizhen: jizhen_reducer,
    }
);




