/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import { Tooltip } from 'antd';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';
import { 
    listConferenceData, ACT_NET_CONFERENCE_LIST, dataListConference, listConferenceDataSuccessHd,
} from './conferenceRD';
import {getLoginUserInfo} from '../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import { 
    HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';
import { dataListSignalServer, dataListMediaServer } from '../setting/server/serverRD';


class ConferenceCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.dataListConferenceSuccessHd = this.dataListConferenceSuccessHd.bind(this);

        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblRowDetailOnClick = this.tblRowDetailOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
      
        const columns = [
            hoTableColumnTag('状态', 'state', 
                (text, record) => {
                    let tags = [];
                    switch(record.state){
                        case 'idle':
                            tags.push({color: blue[3], tag:'空闲'});
                            break;
                        case 'meeting':
                            tags.push({color: green[3], tag:'与会中'});
                            break;
                        case 'wait_release':
                            tags.push({color: gold[3], tag:'等待释放'});
                            break;
                        case 'completed':
                            tags.push({color: grey[3], tag:'已结束'});
                            break;
                        default:
                            break;
                    }
                    
                    return hoTableColumnTagButton(tags, record);
                }, 
            '8%', {nolight: true, filters: [{text:'空闲', value: 'idle'}, {text:'与会中', value: 'meeting'}, {text:'等待释放', value: 'wait_release'}, {text:'已结束', value: 'completed'}]}),
            hoTableColumn('会议号', 'name', 'str', false, false, '10%', {
                render: (text, record) => <span>{record.extra?.code}</span>,
                nolight: true,
            }),
            hoTableColumn('SIP号', 'name', 'str', false, true, '15%'),
            hoTableColumn('信令服务器', 'signalServerName', 'str', false, false, '15%', {
                render: (text, record) => <Tooltip title={record.signalServerName}>
                    <span className="ho-item-link" onClick={(e) => {
                        window.goToMenu('devops/setting/server/group/', `signal/${record.signalServerGroupId}`, {
                            GroupName: "信令组",
                            GroupType: 1,
                            Id: 0,
                            Remark: "信令组",
                        })
                    }}>{record.signalServerName}</span>
                </Tooltip>,
                nolight: true,
            }),
            hoTableColumn('媒体服务器', 'mediaServerName', 'str', false, false, '15%', {
                render: (text, record) => <Tooltip title={record.mediaServerName}>
                    <span className="ho-item-link" onClick={(e) => {
                        window.goToMenu('devops/setting/server/group/', `media/${record.mediaServerGroupId}`)
                    }}>{record.mediaServerName}</span>
                </Tooltip>,
                nolight: true,
            }),
            hoTableColumnDate('创建时间', 'createTime', true, '15%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '详情', onClick: this.tblRowDetailOnClick},
            ], '12%'),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
        }
    }

    dataListConferenceSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        let mediaServerIds = [];
        // eslint-disable-next-line no-unused-expressions
        rspBody.pagination?.records?.forEach(record => {
            mediaServerIds.push(record.extra?.mediaServer?.Id)
        })
        let filters = []
        let paginationInfo = hoTableInitPaginationInfo();
        Array.from(new Set(mediaServerIds)).forEach(e => {
            filters.push([['Id', '=', e]])
        })
        paginationInfo.pagination.filters = filters;
        dataListMediaServer(that.props, paginationInfo, (dispatch, rsp1, req) => {
            dataListSignalServer(that.props, (dispatch, rsp2, req) => {
                rspBody.pagination.records = rspBody.pagination.records.map(r => {
                    let ret1 = rsp1?.RecordList?.find(e => r.extra?.mediaServer?.Id === e.Id)
                    let ret2 = rsp2?.SsList?.find(e => r.extra?.signalServer?.Id === e.Id)
                    return {
                        ...r,
                        mediaServerName: ret1?.MsName || "",
                        mediaServerGroupId: ret1?.GroupId,
                        signalServerName: ret2?.SsName || "",
                        signalServerGroupId: 0,
                    }
                })
                listConferenceDataSuccessHd(dispatch, rspBody, reqBody)
            })
        });
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataListConference(this.props, paginationInfo, this.dataListConferenceSuccessHd);
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
        this.tblRowDetailOnClick(e, record);
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record){
        e.stopPropagation();
        window.goToMenu('devops/analysis/conference', `detail/${record.name}`, {conference: record})
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        const { paginationInfo } = this.state;
        dataListConference(this.props, paginationInfo, this.dataListConferenceSuccessHd);
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "会议"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>会议</span>, buttons: buttons}; 
    }

    mkTable(){
        let { columns } = this.state;
        const { data, getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        // console.log("mkTable", data);

        return (<HoTable
            columns={columns}
            data={records}
            rowKey={'id'}
            loading={getStatus === 1}
            onColumnChange={this.tblColOnChange}
            onRowDoubleClick={this.tblRowOnClick}
            total={total}
        />);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_CONFERENCE_LIST));
    }

    componentDidMount(){
        const { paginationInfo } = this.state;
        dataListConference(this.props, paginationInfo, this.dataListConferenceSuccessHd);
    }

    render(){
        const {reqUserInfo} = this.props;

        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_CONFERENCE_LIST), 
    data: listConferenceData(state),

});


export default connect(
    mapState, 
    null
)(ConferenceCT);

