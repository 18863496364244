/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Modal, Slider, Button, Col, Row } from 'antd';
import { CaretUpOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined, LoadingOutlined } from '@ant-design/icons';
import { dataInviteMember, dataListCurRoom } from '../../pages/room/roomRD';
import { apiVmeetingSdkAppAccountList, apiVmeetingSdkAppList } from '../../pages/member/memberRD';
import { hoTableInitPaginationInfo } from '../../util/tableComponent';
import { config } from '../../util/version';

import './cameraControl.less';


class CameraControl extends Component {
    constructor(props){
        super(props);
        
        this.initSdk = this.initSdk.bind(this);

        this.sdkListener = this.sdkListener.bind(this);
        this.roomListener = this.roomListener.bind(this);

        this.previewStart = this.previewStart.bind(this);
        this.previewStop = this.previewStop.bind(this);

        
        this.state = {
            speed: 4,
            status: 0,
        };
    }

    sdkListener(e) {
        // console.log("sdkListener type: " + e.type);
        switch (e.type) {
            case window.vmeeting.EVENT.SDK.SUCCESS:
                // SDK初始化成功
                // console.log("SDK启动成功！现在你可以输入主题创建房间或输入房间号加入房间")
                this.profile = e.profile
                this.setState({
                    status: 1,
                    reason: undefined,
                })
                break;
            case window.vmeeting.EVENT.SDK.ERROR:
                // SDK初始化失败
                console.error("SDK启动失败！错误码：" + e.code + " 详细信息：" + e.description)
                window.vmeetingsdk.uninit()
                this.initSdk()
                this.setState({
                    status: 0,
                    reason: e.description,
                })
                break;
            default:
                break;
        }
    }

    roomListener(e) {
        let { camera } = this.props;
        // console.log("roomListener type: " + e.type);
        let room = undefined;
        switch (e.type) {
            case window.vmeeting.EVENT.SESSION.ROOM: {
                switch (e.action) {
                    case window.vmeeting.ACTION.ROOM.PROCESS: {
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            // 创建/加入房间失败
                            console.error("创建/加入房间失败！错误码：" + e.code + " 详细信息：" + e.description)
                            // console.error("create/join room error. code: " + e.code)
                            this.setState({
                                status: 1,
                            })
                        }
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.CONNECTED: {
                        // 创建/加入房间成功
                        room = e.room;
                        // console.log(room);
                        // console.log("创建/加入房间成功。房间号：" + room.accessCode + " 主题：" + room.subject)
                        window.vmeetingsdk.bindRemoteVideo("html_video_remote");
                        window.vmeetingsdk.bindRemoteAudio("html_audio_remote");
                        
                        let paginationInfo = hoTableInitPaginationInfo();
                        paginationInfo.pagination.filters = [[['ConferenceCode', 'equal', room.accessCode]]]
                        dataListCurRoom(this.props, paginationInfo,
                            (_, rsp, req) => {
                                let conf = rsp.ConferenceList?.[0];
                                if (conf) {
                                    dataInviteMember(this.props, { ConferenceId: conf.ConferenceId }, [camera.SipNum], [],
                                        (_, rsp, req) => {
                                            this.setState({
                                                status: 3,
                                            })
                                        },
                                        () => {
                                            this.setState({
                                                status: 1,
                                            })
                                        }
                                    )
                                }
                            },
                            () => {
                                this.setState({
                                    status: 1,
                                })
                            }
                        )
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.TERMINATED:
                        // 退出房间
                        // console.log("当前无会话")
                        window.vmeetingsdk.unbindRemoteVideo("html_video_remote");
                        window.vmeetingsdk.unbindRemoteAudio("html_audio_remote");
                        this.setState({
                            status: 1,
                        })
                        break;
                    case window.vmeeting.ACTION.ROOM.ONCHANGE:
                        // 房间信息变化（成员入会、离会、被静音等）
                        room = e.room;
                        // console.log(room);
                        break;
                    default:
                        break;
                }
                break;
            }
            
            default:
                break;
        }
    }

    previewStart() {
        function loop() {
            let { status } = this.state;
            if (window.vmeetingsdk && status === 1) {
                this.setState({
                    status: 2,
                })
                window.vmeetingsdk.createRoom({
                    subject: `camera preview ${this.profile?.userId}`,
                    eventListener: this.roomListener,
                });
            } else {
                this.previewStartTimer = setTimeout(() => {
                    loop.bind(this)()
                }, 1000)
            }
        }

        this.previewStop()
        loop.bind(this)()
    }

    previewStop() {
        window.vmeetingsdk && window.vmeetingsdk.destroyRoom();
        clearTimeout(this.previewStartTimer);
    }

    initSdk() {
        if (window.vmeetingsdk) {
            window.vmeetingsdk.uninit()
            window.vmeetingsdk = null;
        }
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = [[['AppName', 'equal', 'mntn_camera']]]
        apiVmeetingSdkAppList(this.props, paginationInfo,
            (dispatch, rsp, req) => {
                let app = rsp?.AppList?.[0];
                if (app) {
                    let paginationInfo = hoTableInitPaginationInfo();
                    paginationInfo.pagination.page = {
                        number: 1,
                        size: 100,
                    }
                    apiVmeetingSdkAppAccountList(this.props, app.Id, paginationInfo,
                        (dispatch, rsp, req) => {
                            let accounts = rsp?.AccountList || [];
                            let configure = {
                                appid: app.AppId,
                                appCode: app.AppCode,
                                account: accounts[Math.floor(Math.random() * 100, 0) % accounts.length]?.Account,
                                eventListener: this.sdkListener,
                                host: {
                                    ws: config.host.ws,
                                    as: config.host.as,
                                }
                            }
                            window.vmeetingsdk = new window.vmeeting.Sdk(configure)
                        }
                    );
                }
                
            }
        )
    }

    componentWillReceiveProps(nextProps) {
        let { visible } = this.props;
        if (visible !== nextProps.visible) {
            if (nextProps.visible) {
                this.previewStart()
            } else {
                this.previewStop()
            }
        }
    }

    componentDidMount() {
        this.initSdk();
    }

    componentWillUnmount() {
        clearTimeout(this.previewStartTimer);
        this.previewStop();
        if (window.vmeetingsdk) {
            window.vmeetingsdk.uninit()
            window.vmeetingsdk = null;
        }
    }

    render() {
        let { speed, status, reason } = this.state;
        let { visible, cancelOnClick, camera, cameraOnClick } = this.props;

        function control(Icon, className, dir) {
            return <div>
                <Icon className={"action-item " + className}
                    onMouseDown={() => cameraOnClick && cameraOnClick("start", dir, speed)}
                    onTouchStart={() => cameraOnClick && cameraOnClick("start", dir, speed)}
                    onMouseUp={() => cameraOnClick && cameraOnClick("stop")}
                    onTouchEnd={() => cameraOnClick && cameraOnClick("stop")}
                />
            </div>
        }

        return <Modal
            className="page camera-control-page"
            title={`摄像头云台 - ${camera?.NickName}`}
            width="80%"
            visible={visible}
            onCancel={cancelOnClick}
            footer={null}
        >
            <div className="camera-control-page-content">
                <div className="camera-control-page-content-preview">
                    {(status === 2 || status === 1) ? <div className="loading" ><LoadingOutlined/><div>正在加载图像...</div></div> : undefined}
                    <video id="html_video_remote" className="camera-control-page-content-preview-video" autoPlay muted></video>
                    <audio id="html_audio_remote" autoPlay></audio>
                    <div className="error">{reason}</div>
                </div>
                <div className="camera-control-page-content-direction">
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left-up", "leftUp")}
                        {control(CaretUpOutlined, "up", "up")}
                        {control(CaretUpOutlined, "right-up", "rightUp")}
                    </div>
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left", "left")}
                        {control(RedoOutlined, "reset", "reset")}
                        {control(CaretUpOutlined, "right", "right")}
                    </div>
                    <div className="camera-control-page-content-direction-wrapper">
                        {control(CaretUpOutlined, "left-down", "leftDown")}
                        {control(CaretUpOutlined, "down", "down")}
                        {control(CaretUpOutlined, "right-down", "rightDown")}
                    </div>
                </div>
                <div className="camera-control-page-content-control">
                    <div className="camera-control-page-content-control-wrapper">
                        {control(ZoomInOutlined, "zoom-in", "zoomIn")}
                        {control(ZoomOutOutlined, "zoom-out", "zoomOut")}
                    </div>
                    <div className="camera-control-page-content-control-speed">
                        <div className="camera-control-page-content-control-speed-title">速率</div>
                        <Slider value={speed} tipFormatter={null} max={7} min={1}
                            marks={{
                                1: '1',
                                2: '2',
                                3: '3',
                                4: {
                                    style: { color: '#1890ff' },
                                    label: <strong>4</strong>,
                                },
                                5: '5',
                                6: '6',
                                7: '7',
                            }}
                            onChange={(e) => {
                                this.setState({
                                    speed: e
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            
        </Modal>

    }
}

const mapState = (state) => ({
});

export default connect(
    mapState, 
    null,
    null,
)(CameraControl);