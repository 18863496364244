/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo, hoTableTransPaginationInfo } from "../../util/tableComponent";
import { netRequestThunk2 } from "../../util/netReqRD";
import {loginNoauthHd} from "../login/loginRD";

const ACT_LIST_ROOM = 'ACT_LIST_ROOM';
const ACT_MOD_ROOM = 'ACT_MOD_ROOM';
const ACT_GET_ROOM = 'ACT_GET_ROOM';
const ACT_GET_ROOM2 = 'ACT_GET_ROOM2';
const ACT_CLEAN_ROOM = 'ACT_CLEAN_ROOM';
const ACT_LIST_LAYOUT = 'ACT_LIST_LAYOUT';


export const ACT_NET_ROOM_LIST = 'listRoom';
export const ACT_NET_ROOM_CUR_LIST = 'listCurRoom';
export const ACT_NET_ROOM_MOD = 'modRoom';
export const ACT_NET_ROOM_NEW = 'newRoom';
export const ACT_NET_ROOM_GET = 'getRoom';
export const ACT_NET_ROOM_GET2 = 'getRoom2';
export const ACT_NET_ROOM_DEL = 'delRoom';
export const ACT_NET_ROOM_START = 'startRoom';
export const ACT_NET_ROOM_END = 'startRoom';
export const ACT_NET_MEMBER_DEL = 'delMember';
export const ACT_NET_MEMBER_INVITE = 'inviteMember';
export const ACT_NET_MEMBER_NEW = 'newMember';
export const ACT_NET_MEMBER_PUSH_START = 'pushStartMember';
export const ACT_NET_MEMBER_PUSH_STOP = 'pushStopMember';
export const ACT_NET_MEMBER_PUSH_LIST = 'pushListMember';
export const ACT_NET_MEMBER_MUTE = 'muteMember';
export const ACT_NET_LAYOUT_LIST = 'listLayout';
export const ACT_NET_LAYOUT_SET = 'setLayout';
export const ACT_NET_LAYOUT_UNSET = 'unsetLayout';

// reducer handle
const roomState = {
    rooms: undefined, 
    roomInfo: undefined,
    roomInfo2: undefined,
    layouts: undefined,
}

export const room_reducer = (state=roomState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_ROOM:
            return {
                ...state,
                rooms: action.rooms,
            };

        case ACT_GET_ROOM:
            return {
                ...state,
                roomInfo: action.roomInfo,
            };

        case ACT_GET_ROOM2:
            return {
                ...state,
                roomInfo2: action.roomInfo2,
            };

        case ACT_CLEAN_ROOM:
            return {
                rooms: undefined,
            };
        
        case ACT_LIST_LAYOUT:
            return {
                ...state,
                layouts: action.layouts,
            };

        default:
            return state;
    }
}


// 列表
export const listRoomData = (state) => {
    return state.room !== undefined ? state.room.rooms : undefined;
}

export const getRoomData = (state) => {
    return state.room !== undefined ? state.room.roomInfo : undefined;
}

export const getRoomData2 = (state) => {
    return state.room !== undefined ? state.room.roomInfo2 : undefined;
}

export const actRoomClear = () => {
    return {
        type: ACT_CLEAN_ROOM,
    }
}

export function listRoomDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_ROOM, 
        rooms: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
            records: rspBody.ConferenceList,
        }, 
    })
}

export function getRoomDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_GET_ROOM, 
        roomInfo: Object.assign(rspBody, {ConferenceId: reqBody.ConferenceId}), 
    })
}

export function getRoomDataSuccessHd2(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_GET_ROOM2, 
        roomInfo2: Object.assign(rspBody, {ConferenceId: reqBody.ConferenceId}), 
    })
}

export function dataListRoom(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;

    let path = '/cs/v1/mntn/conference/list';
    if (props.match.path !== "/conference/ctrl/room"){
        path = '/cs/v1/mntn/conference/historyList';
    }
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_LIST, successHd, errorHd))
    );
}

export function dataListCurRoom(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    let path = '/cs/v1/mntn/conference/list';
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_CUR_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_CUR_LIST, successHd, errorHd))
    );
}

export function dataGetRoom(props, roomInfo, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/detail'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        MemberPageNum: paginationInfo.pagination.page.number,
        MemberPageSize: paginationInfo.pagination.page.size,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_GET, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_GET, successHd, errorHd))
    );
}

export function dataGetRoom2(props, roomInfo, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/detail'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        MemberPageNum: paginationInfo.pagination.page.number,
        MemberPageSize: paginationInfo.pagination.page.size,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_GET2, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_GET2, successHd, errorHd))
    );
}


export const dataNewRoom = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/create'
    const msgBody = {
        Title: roomInfo.Title,
        ExpectStartTime: roomInfo.ExpectStartTime?.format("YYYY-MM-DD hh:mm:ss") || undefined,
        ExpectEndTime: roomInfo.ExpectEndTime?.format("YYYY-MM-DD hh:mm:ss") || undefined,
        MemberIds: roomInfo.Members.map(item => item.UserId),
        MediaServerId: parseInt(roomInfo.MediaServerId),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_NEW, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_NEW, successHd, errorHd))
    );
}

export const dataDelRoom = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/del'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_DEL, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_DEL, successHd, errorHd))
    );
}

export const dataModRoom = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {dispatch, reqUserInfo} = props;
    const path = '/cs/v1/mntn/conference/mod'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        Title: roomInfo.Title,
        ExpectStartTime: roomInfo.ExpectStartTime ? roomInfo.ExpectStartTime.format("YYYY-MM-DD hh:mm:ss") : undefined,
        ExpectEndTime: roomInfo.ExpectEndTime ? roomInfo.ExpectEndTime.format("YYYY-MM-DD hh:mm:ss") : undefined
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_MOD, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_MOD, successHd, errorHd))
    );
}

export const dataStartRoom = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {dispatch, reqUserInfo} = props;
    const path = '/cs/v1/mntn/conference/start'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_START, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_START, successHd, errorHd))
    );
}

export const dataEndRoom = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {dispatch, reqUserInfo} = props;
    const path = '/cs/v1/mntn/conference/finish'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_ROOM_END, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_ROOM_END, successHd, errorHd))
    );
}

export const dataAddMember = (props, roomInfo, members, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/add/members'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        UserIds: members,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_NEW, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_NEW, successHd, errorHd))
    );
}

export const dataDelMember = (props, roomInfo, members, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/del/members'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        UserIds: members,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_DEL, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_DEL, successHd, errorHd))
    );
}

export const dataInviteMember = (props, roomInfo, sipNums, privateMembers, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/invite'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        SipNums: sipNums,
        _PrivateMembers: privateMembers.map(item => {return {SipNum: item.SipNum, NickName: item.NickName}}),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_INVITE, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_INVITE, successHd, errorHd))
    );
}

export const dataKickMember = (props, roomInfo, sipNums, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/kick'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        SipNums: sipNums,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_DEL, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_DEL, successHd, errorHd))
    );
}

export const dataPushStartMember = (props, roomInfo, member, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/pusher/start'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        SipNum: member.SipNum,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_PUSH_START, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_PUSH_START, successHd, errorHd))
    );
}

export const dataPushStopMember = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/pusher/stop'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_PUSH_STOP, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_PUSH_STOP, successHd, errorHd))
    );
}

export const dataPushListMember = (props, roomInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/pusher/list'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_PUSH_LIST, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_PUSH_LIST, successHd, errorHd))
    );
}

export const dataMuteMember = (props, roomInfo, sipNums, mute, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/mute'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        SipNums: sipNums,
        Mute: mute,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_MUTE, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_MUTE, successHd, errorHd))
    );
}


// 画布列表
export const listLayoutData = (state) => {
    return state.room !== undefined ? state.room.layouts : undefined;
}

export function listLayoutDataSuccessHd(dispatch, rspBody, reqBody) {
    // console.log("listLayoutDataSuccessHd", rspBody);
    dispatch({
        type: ACT_LIST_LAYOUT, 
        layouts: rspBody.LayoutList, 
    })
}

export function dataListLayout(props, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/screenLayoutList'
    const msgBody = {
        
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_PUSH_LIST, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_PUSH_LIST, successHd, errorHd))
    );
}

export const dataSetLayout = (props, roomInfo, layoutInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/screen/set'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        ...layoutInfo,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_LAYOUT_SET, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_LAYOUT_SET, successHd, errorHd))
    );
}

export const dataUnsetLayout = (props, roomInfo, sipNums, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/conference/screen/unset'
    const msgBody = {
        ConferenceId: roomInfo.ConferenceId,
        SipNums: sipNums,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_LAYOUT_UNSET, successHd, errorHd, 
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_LAYOUT_UNSET, successHd, errorHd))
    );
}

