/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import VideoPlayer from '../../component/videoPlayer/videoPlayer';

class RtmpCT extends Component{
    constructor(props){
        super(props);

        let query = this.props.location.search;
        const url = query.substr(5);
        this.state = {
            url: url,
        }
    }

    componentDidMount() {
        document.title = "直播会场"
    }

    componentWillUnmount() {

    }

    render() {
        const {url} = this.state;

        const videoJsOptions = {
            autoplay: true,  //自动播放
            language: 'zh-CN', 
            controls: true,  //控制条
            preload: 'auto',  //自动加载
            errorDisplay: true,  //错误展示
            // width: 640,  //宽
            // height: 360,  //高
            fluid: true,  //跟随外层容器变化大小，跟随的是外层宽度
            // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但还是存在
            // textTrackDisplay: false,  // 不渲染字幕相关DOM
            // userActions: {
            //     hotkeys: true  //是否支持热键
            // },
            sources: [
                {
                    src: url,
                    type: "rtmp/flv",  //类型可加可不加，目前未看到影响
                    // type: 'video/mp4',
                }
            ],
        };
        return <div className="ho-preview-video-player-box">
            <div className="ho-preview-video-player-video">
                <VideoPlayer className="video" {...videoJsOptions}/>
            </div>
        </div>
    }
}

const mapState = (state) => ({
});


export default connect(
  mapState, 
  null
)(RtmpCT);