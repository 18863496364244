/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo, hoTableTransPaginationInfo } from "../../util/tableComponent";
import { netRequestThunk2 } from "../../util/netReqRD";
import { loginNoauthHd } from "../login/loginRD";

const ACT_LIST_MEMBER = 'ACT_LIST_MEMBER';

export const ACT_NET_JIZHEN_GROUP_NEW = 'newJizhenGroup';
export const ACT_NET_JIZHEN_GROUP_DEL = 'delJizhenGroup';
export const ACT_NET_JIZHEN_GROUP_MOD = 'modJizhenGroup';
export const ACT_NET_JIZHEN_GROUP_LIST = 'listJizhenGroup';

export const ACT_NET_JIZHEN_MEMBER_NEW = 'newJizhenMember';
export const ACT_NET_JIZHEN_MEMBER_DEL = 'delJizhenMember';
export const ACT_NET_JIZHEN_MEMBER_MOD = 'modJizhenMember';
export const ACT_NET_JIZHEN_MEMBER_LIST = 'listJizhenMember';

// reducer handle
const jizhenState = {
    list: undefined, 
}

export const jizhen_reducer = (state=jizhenState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_MEMBER:{
            return {
                ...state,
                list: action.list,
            };
        }

        default:
            return state;
    }
}


export const listJihenData = (state) => {
    return state.jizhen !== undefined ? state.jizhen.list : undefined;
}

export function listJizhenDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_MEMBER, 
        list: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
            records: rspBody.RecordList,
        }, 
    })
}

export function dataAddJizhenGroup(props, jizhen, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/add'
    const msgBody = jizhen;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_GROUP_NEW, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_GROUP_NEW, successHd, errorHd))
    );
}

export function dataDelJizhenGroup(props, jizhen, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/del'
    const msgBody = jizhen;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_GROUP_DEL, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_GROUP_DEL, successHd, errorHd))
    );
}

export function dataModJizhenGroup(props, jizhen, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/mod'
    const msgBody = jizhen;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_GROUP_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_GROUP_MOD, successHd, errorHd))
    );
}

export function dataListJizhenGroup(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_GROUP_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_GROUP_LIST, successHd, errorHd))
    );
}

export function dataAddJizhenMember(props, groupId, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/member/add'
    const msgBody = {
        ClientId: groupId,
        ...member,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_MEMBER_NEW, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_MEMBER_NEW, successHd, errorHd))
    );
}

export function dataDelJizhenMember(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/member/del'
    const msgBody = member;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_MEMBER_DEL, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_MEMBER_DEL, successHd, errorHd))
    );
}

export function dataModJizhenMember(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/member/mod'
    const msgBody = member;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_MEMBER_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_MEMBER_MOD, successHd, errorHd))
    );
}

export function dataListJizhenMember(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/reallyClient/member/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_JIZHEN_MEMBER_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_JIZHEN_MEMBER_LIST, successHd, errorHd))
    );
}





