/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import QueueAnim from 'rc-queue-anim';

import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import { Result, Collapse, Button, BackTop, Menu, Dropdown, Row, Col, Icon, Tooltip, Card, Tabs, Radio, Select} from 'antd';
import { ChartCard, Field, MiniArea, Bar, MiniBar, MiniProgress, Pie, Gauge } from 'ant-design-pro/lib/Charts';
import { G2, Chart, Geom, Axis, Tooltip as BZTooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util,} from "bizcharts";

import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'
import { 
    dataListProblem, dataListHistory, dataListGroup, dataListHost, dataListItem,
    ZABBIX_HOST_IDS, ZABBIX_CALL_COUNT_IDS, ZABBIX_CONFERENCE_COUNT_IDS, ZABBIX_CUP_IDEL_IDS, ZABBIX_FILESYSTEM_FREE_IDS, ZABBIX_FILESYSTEM_TOTAL_IDS,
    ZABBIX_MEMORY_FREE_IDS, ZABBIX_MEMORY_TOTAL_IDS, ZABBIX_NETWORK_INCOMING, ZABBIX_NETWORK_OUTGOING, 
} from '../zabbix/zabbixRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoIconFont,
} from '../../../util/hoComponent';
import { getNetWorkDesc } from '../../../util/logic';

import './server.css';

class NetworkCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/

        // 数据回调
        this.dataListCallSuccessHd = this.dataListCallSuccessHd.bind(this);
        this.dataListCallTimerSuccessHd = this.dataListCallTimerSuccessHd.bind(this);
        this.dataListServerSuccessHd = this.dataListServerSuccessHd.bind(this);
        this.dataListServerTimerSuccessHd = this.dataListServerTimerSuccessHd.bind(this);

        // 
        this.monitorOnTimer = this.monitorOnTimer.bind(this);
        this.monitorInitData = this.monitorInitData.bind(this);
        this.mainTimeOnChange = this.mainTimeOnChange.bind(this);


        this.state = {
            timerInterval: 30000,
            server: {
                timeFormat: (clock) => parseInt(clock) * 1000,
                getTitle: (m = [], t = "") => { 
                    const item = m.find((e) => e.value === t);
                    return item ? item.title : "无";
                },
                network: {
                    type: 'today.this',
                    typeMap: [
                        {value:"today.hour.1", title: "最近1小时"},
                        {value:"today.this", title: "今天"},
                        {value:"today.ago.1", title: "昨天"},
                        {value:"today.ago.2", title: "前天"},
                    ],
                    total: 0,
                    list: [],
                    format: (value) => parseInt(value),
                },
                call: {
                    list: [],
                    max: null,
                    min: null,
                },
            }
        }
    }

    dataListCallTimerSuccessHd(dispatch, rspBody, reqBody) {
        let server = this.state.server;
        let result = rspBody.result;

        let tagetItem = server.call.list.find((e) => e.clock === result[0].clock);
        if (tagetItem) {
            return true;
        }

        // 数据是按时间降序排的，这里删除最后一个即为最老的数据，新增数据在最前头
        server.call.list.pop();
        server.call.list.unshift(result[0])

        this.setState({
            server: server,
        })

        let params = {
            hostids: ZABBIX_HOST_IDS, 
            history: 3, 
            limit: 1, 
        }

        dataListHistory(this.props, {...params, itemids: ZABBIX_NETWORK_OUTGOING}, this.dataListServerTimerSuccessHd, () => {return true;})
    }

    dataListCallSuccessHd(dispatch, rspBody, reqBody) {
        let server = this.state.server;
        server.call.list = rspBody.result;
        server.call.last = rspBody.result?.[0]?.value || 0;

        this.setState({
            server: server,
        })

        let params = {
            hostids: ZABBIX_HOST_IDS, 
            history: 3, 
            time_from: reqBody.params.time_from,
            time_till: reqBody.params.time_till,
        }

        dataListHistory(this.props, {...params, itemids: ZABBIX_NETWORK_OUTGOING}, this.dataListServerSuccessHd, () => {return true;})
    }

    dataListServerSuccessHd(dispatch, rspBody, reqBody) {
        // console.log(reqBody.params.itemids, rspBody.result[0].value)
        if (!rspBody.result) {
            return true;
        }
        let server = this.state.server;
        let result = rspBody.result;
        let itemid = reqBody.params.itemids;
        
        if (server.network.list && server.network.list.length > 0) {

            server.network.list.forEach(item => {
                let target = result.find((e) => Math.abs(e.clock - item.clock) <= 30);
                if (target) {
                    if (itemid === ZABBIX_NETWORK_INCOMING) {
                        item.incoming = server.network.format(target.value);
                    } else if (itemid === ZABBIX_NETWORK_OUTGOING) {
                        item.outgoing = server.network.format(target.value);
                    }
                }
            })

        } else {
            server.network.list = result.map(item => {
                let callItem = server.call.list.find((e) => Math.abs(e.clock - item.clock) <= 30);

                if (callItem && callItem.value > server.call.max) {
                    server.call.max = callItem.value;
                }
                if (callItem && callItem.value < server.call.min) {
                    server.call.min = callItem.value;
                }
                let content = {
                    time: server.timeFormat(item.clock), 
                    clock: item.clock,
                    call: callItem ? callItem.value : null,
                }

                if (itemid === ZABBIX_NETWORK_INCOMING) {
                    content.incoming = server.network.format(item.value);
                } else if (itemid === ZABBIX_NETWORK_OUTGOING) {
                    content.outgoing = server.network.format(item.value);
                }

                return content;
            })
        }

        if (itemid === ZABBIX_NETWORK_OUTGOING) {
            let params = {
                hostids: ZABBIX_HOST_IDS, 
                history: 3, 
                time_from: reqBody.params.time_from,
                time_till: reqBody.params.time_till,
            }
            dataListHistory(this.props, {...params, itemids: ZABBIX_NETWORK_INCOMING}, this.dataListServerSuccessHd, () => {return true;})
        }
        
        this.setState({
            server: server,
        })
    }

    dataListServerTimerSuccessHd(dispatch, rspBody, reqBody) {
        if (!rspBody.result) {
            return true;
        }
        let server = this.state.server;
        let result = rspBody.result;
        let newItem = undefined;
        let tagetItem = server.network.list.find((e) => Math.abs(e.clock - result[0].clock) <= 30);
        if (!tagetItem) {
            let callItem = server.call.list.find((e) => Math.abs(e.clock - result[0].clock) <= 30);
            newItem = {
                time: server.timeFormat(result[0].clock),
                clock: result[0].clock,
                call: callItem ? callItem.value : null,
            }
        }

        if (reqBody.params.itemids === ZABBIX_NETWORK_INCOMING) {
            if (tagetItem) {
                tagetItem.incoming = server.network.format(result[0].value);
            } else if (newItem) {
                newItem.incoming = server.network.format(result[0].value);
            }
        } else if (reqBody.params.itemids === ZABBIX_NETWORK_OUTGOING) {
            if (tagetItem) {
                tagetItem.outgoing = server.network.format(result[0].value);
            } else if (newItem) {
                newItem.outgoing = server.network.format(result[0].value);
            }
        }

        // 数据是按时间降序排的，这里删除最后一个即为最老的数据，新增数据在最前头
        if (newItem) {
            server.network.list.pop();
            server.network.list.unshift(newItem);
        }

        if (reqBody.params.itemids === ZABBIX_NETWORK_OUTGOING) {
            let params = {
                hostids: ZABBIX_HOST_IDS, 
                history: 3, 
                limit: 1, 
            }
            dataListHistory(this.props, {...params, itemids: ZABBIX_NETWORK_INCOMING}, this.dataListServerTimerSuccessHd, () => {return true;})
        }

        this.setState({
            server: server,
        })

    }

    monitorOnTimer() {
        let that = this;
        const { dispatch, zabbixUserInfo } = this.props;
        let server = this.state.server;

        if (server.network.type.startsWith("today.ago")) {
            return true;
        }

        function dataZabbix() {
            const params = {
                hostids: ZABBIX_HOST_IDS, 
                itemids: ZABBIX_CALL_COUNT_IDS, 
                history: 3, 
                limit: 1,
            }
            dataListHistory(that.props, params, that.dataListCallTimerSuccessHd, () => {return true;})
        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    monitorInitData() {
        let that = this;
        const { dispatch, zabbixUserInfo } = this.props;

        function dataZabbix() {
            const params = {
                hostids: ZABBIX_HOST_IDS, 
                itemids: ZABBIX_CALL_COUNT_IDS, 
                history: 3, 
                time_from: moment().startOf('day').unix(),
                time_till: moment().endOf('day').unix(),
            }
            dataListHistory(that.props, params, that.dataListCallSuccessHd, () => {return true;})
        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    mainTimeOnChange(value) {
        let server = this.state.server;
        server.network.type = value;
        server.network.list = [];
        this.setState({
            server: server,
        })

        let timeFrom = undefined;
        let timeTill = undefined;

        switch(value) {
            case 'today.hour.1':
                timeTill = moment().unix();
                timeFrom = timeTill - 1 * 3600;
                break;
            case 'today.this':
                timeFrom = moment().startOf('day').unix()
                timeTill = moment().endOf('day').unix()
                break;
            case 'today.ago.1':
                timeFrom = moment().add('day', -1).startOf('day').unix()
                timeTill = moment().add('day', -1).endOf('day').unix()
                break;
            case 'today.ago.2':
                timeFrom = moment().add('day', -2).startOf('day').unix()
                timeTill = moment().add('day', -2).endOf('day').unix()
                break;
            default:
                break;         
        }

        const params = {
            hostids: ZABBIX_HOST_IDS, 
            itemids: ZABBIX_CALL_COUNT_IDS, 
            history: 3, 
            time_from: timeFrom,
            time_till: timeTill,
        }
        dataListHistory(this.props, params, this.dataListCallSuccessHd, () => {return true;})
    }

    mkNetwork() {
        let that = this;
        const { server } = this.state
        let scale = {
            time: {
                type: 'time',
                alias: '时刻',
                tickCount: 12,
                mask: 'YYYY-MM-DD HH:mm:ss',
            },
            outgoing: {
                type: 'pow',
                alias: '上行(outgoing)流量',
            },
            incoming: {
                type: 'pow',
                alias: '下行(incoming)流量',
            },
            call: {
                min: 0,
                type: 'linear',
                alias: '入会方数',
            }
        }

        if (server.call.max < 100) {
            scale.call.ticks = Array.from({length: 11}, (v,k) => 10 * k);
        } else if (server.call.max < 500) {
            scale.call.ticks = Array.from({length: 11}, (v,k) => 50 * k);
        } else {
            scale.call.ticks = Array.from({length: 11}, (v,k) => 100 * k);
        }

        let ticks = Array.from({length: 6}, (v,k) => 1024 * 1024 * 1 * Math.pow(2, k));
        ticks.unshift(0);
        scale.incoming.ticks = scale.outgoing.ticks = ticks;

        return <ChartCard
            key="network-card"
            className="network-card"
            bordered={false}
            title={<Tooltip 
                placement="right"
                openClassName="tooltip-wrapper"
                title={<div className="tooltip" style={{width: '260px'}}>
                    <Row>
                        <span className="title">{server.network.list[0] ? moment(server.network.list[0].time).format("YYYY-MM-DD HH:mm:ss") : undefined}</span>
                    </Row>
                    <Row >
                        <Col span={2}><span className="ant-badge-status-dot" style={{backgroundColor: '#3182bd'}}></span></Col><Col span={14}>入会方数</Col><Col span={8}>{server.call.list[0] ? server.call.list[0].value : 0}</Col>
                    </Row>
                    <Row >
                        <Col span={2}><span className="ant-badge-status-dot" style={{backgroundColor: '#00D9DF'}}></span></Col><Col span={14}>上行(outgoing)流量</Col><Col span={8}>{server.network.list[0] ? getNetWorkDesc(server.network.list[0].outgoing) : undefined}</Col>
                    </Row>
                    <Row >
                        <Col span={2}><span className="ant-badge-status-dot" style={{backgroundColor: '#ffae6b'}}></span></Col><Col span={14}>下行(incoming)流量</Col><Col span={8}>{server.network.list[0] ? getNetWorkDesc(server.network.list[0].incoming) : undefined}</Col>
                    </Row>
                </div>}>
                {/* <QueueAnim type={['left', 'right']} delay={100}> */}
                    <span key="network-title" className="title"><HoIconFont type="icon-bandwidth-grey" className="icon"/><span className="description">网络</span></span>
                {/* </QueueAnim> */}
            </Tooltip>}            
            action={
                <Dropdown overlay={
                    <Menu onClick={(e) => this.mainTimeOnChange(e.key)}>
                        {server.network.typeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
                    </Menu>}
                >
                    <Button type="primary">
                        {server.getTitle(server.network.typeMap, server.network.type)}
                        <Icon type="down"/>
                    </Button>
                </Dropdown>
            }
            contentHeight={404}
        >
            <QueueAnim type={['bottom', 'up']}>
                <Chart scale={scale} height={404} data={server.network.list} forceFit padding={[48, 48, 72, 72]} onGetG2Instance={chart => {that.chartIns = chart;}}>
                    <Axis name="time" title={false} 
                        label={{
                            offset: 25,
                            formatter: (val) => {
                                const timesp = val.split(' ');
                                if (timesp[0] === moment().format("YYYY-MM-DD")) {
                                    return timesp[1];
                                } else if (timesp[0] === moment().subtract(1, 'days').format("YYYY-MM-DD")){ 
                                    return "昨天\n" + timesp[1];
                                } else {
                                    return `${timesp[0]}\n${timesp[1]}`;
                                }
                            }}
                        }/>
                    <Axis name="outgoing" label={{formatter: (val) => `${Math.floor(val / (1024 * 1024))} Mbps`, }}/>
                    <Axis name="incoming" grid={null} title={false} label={{formatter: (val) => ``, textStyle: {fill: "#fdae6b"}}}/>
                    <Axis name="call" grid={null} label={{ textStyle: { fill: "#3182bd" } }}/>
                    <Legend
                        custom={true}
                        position="top-right"
                        offsetY={-10}
                        allowAllCanceled={true}
                        itemFormatter={val => {
                            switch(val) {
                                case 'call': return "入会方数";
                                case 'outgoing': return "上行(outgoing)流量";
                                case 'incoming': return "下行(incoming)流量";
                                default: return "";
                            }
                        }}
                        items={[{
                            value: "call",
                            marker: { symbol: "hyphen", stroke: "#3182bd", radius: 5, lineWidth: 3 }
                        }, {
                            value: "outgoing",
                            marker: { symbol: "hyphen", stroke: "#00D9DF", radius: 5, lineWidth: 3 }
                        }, {
                            value: "incoming",
                            marker: { symbol: "hyphen", stroke: "#ffae6b", radius: 5, lineWidth: 3 }
                        }]}
                        onClick={ev => {
                            const item = ev.item;
                            const value = item.value;
                            const checked = ev.checked;
                            const geoms = that.chartIns.getAllGeoms();
            
                            for (let i = 0; i < geoms.length; i++) {
                            const geom = geoms[i];
                            if (geom.getYScale().field === value) {
                                    if (checked) {
                                        geom.show();
                                    } else {
                                        geom.hide();
                                    }
                            }
                            }
                        }}
                    />
                    <BZTooltip showTitle={true}/>
                    <Geom type="line" position="time*outgoing" color="#00D9DF" size={1} shape="dotSmooth"
                        tooltip={["time*outgoing", (time, outgoing) => {
                            return {name: "上行(outgoing)流量", value: getNetWorkDesc(outgoing)};
                        }]}
                    />
                    <Geom type="line" position="time*incoming" color="#fdae6b" size={1} shape="dotSmooth"
                        tooltip={["time*incoming", (time, incoming) => {
                            return {name: "下行(incoming)流量", value: getNetWorkDesc(incoming)};
                        }]}
                    />
                    <Geom type="line" position="time*call" color="#3182bd" size={1} shape="dotSmooth" />
                    <Guide>
                        <Guide.Line
                            top // {boolean} 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
                            start={['start', 16 * 1024 *1024]} // {object} | {function} | {array} 辅助线结束位置，值为原始数据值，支持 callback
                            end={['end', 16 * 1024 *1024]} // 同 start
                            lineStyle={{
                                stroke: 'red', // 线的颜色
                                lineDash: [0, 2, 2], // 虚线的设置
                                lineWidth: 1, // 线的宽度
                            }} // 图形样式配置 https://bizcharts.net/products/bizCharts/api/graphic
                            text={{
                                position: 'start', // 'start' | 'center' | 'end' | '39%' | 0.5 文本的显示位置
                                autoRotate: true, // {boolean} 是否沿线的角度排布，默认为 true
                                style: { fill: 'red' }, // {object}文本图形样式配置
                                offsetX: 20, // {number} x 方向的偏移量
                                offsetY: 20, // {number} y 方向的偏移量
                                content: `预警线 16 Mbps`,
                            }}
                        />
                    </Guide>
                </Chart>
            </QueueAnim>
        </ChartCard>
    }

    // clear handle
    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentDidMount(){
        setTimeout(() => {
            this.monitorInitData();
        }, 500);
        this.timer = setInterval(() => {this.monitorOnTimer()}, this.state.timerInterval);
    }

    render(){
        return <div>
            {this.mkNetwork()}
        </div>
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    zabbixUserInfo: getLoginZabbixInfo(state),
});


export default connect(
    mapState, 
    null
)(NetworkCT);

