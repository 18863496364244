/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {Provider} from 'react-redux';
import AnimatedRouter from 'react-animated-router';

import {configStore, loadStoreState} from './redux/store';

import * as serviceWorker from './serviceWorker';

import './css/animate.css'; 
import './css/custom.less'; 
import './css/antd.css'; 
import './css/ant-design-pro.css';

import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import MainLayout from './laylout/mainLayout';
import LoginCT from './pages/login/loginCT';
import RtmpCT from './pages/preview/rtmpCT';
import FlvCT from './pages/preview/flvCT';
import MonitorCT from './pages/monitor/monitorCT';
import ADSCT from './pages/ads/adsCT';

moment.locale('zh-cn');

const initState = loadStoreState();
const store = configStore(initState);

const app = (
    <ConfigProvider locale={zh_CN}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    {/* 登录页 */}
                    <Route exact path='/' component={LoginCT} />
                    {/* 这些页面是全屏，不需要导航 */}
                    <Route path='/conference/preview/rtmp/' component={RtmpCT} />
                    <Route path='/conference/preview/flv/' component={FlvCT} />
                    <Route path='/conference/monitor/' component={MonitorCT} />
                    <Route path='/conference/ads/' component={ADSCT} />
                    {/* 业务路由页面 */}
                    <Route path='/conference' component={MainLayout} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
