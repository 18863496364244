/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import moment from 'moment';
import * as echarts from 'echarts';
import ChinaGeo from '../../geoJson/china.json';
import './map.less';

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
        }
    }

    initChartMap() {
        echarts.registerMap('china', ChinaGeo);
        let chartMap = echarts.init(document.getElementById('mainMap'), null, {renderer: 'svg'});
        let option = {
            tooltip: {
                formatter: function(params, ticket, callback){
                    return `${params.value[3]}<br/>累计通话${params.value[2]}分钟`
                },
                backgroundColor: 'rgba(255,255,255,0.85)',
                textStyle: {
                    fontSize: 12,
                    color: '#595959',
                },
                // extraCssText: 'box-shadow: 0 0 10px rgba(255, 255, 255, 0.65);'
            },
            visualMap: {
                type: 'piecewise',
                top: '5%',
                orient: 'horizontal',
                min: 0,
                max: 300,
                dimension: 2,
                splitNumber: 5,
                // color: ['rgba(244,122,117, .95)','rgba(253,216,69,.95)','rgba(0,130,252,.95)'],
                color: ['rgba(244,122,117, 0.6875)','rgba(0,130,252,0.6875)','rgba(34,237,124,0.6875)',],
                textStyle: {
                    color: '#fff'
                }
            },
            geo: {
                map: 'china',
                roam: false,
                top: '15%',
                zoom: 1.23,
                label: {
                    show: true,
                    fontSize:'10',
                    color: 'rgba(255,255,255,0.7)'
                },
                itemStyle: {
                    borderColor: 'rgba(255, 255, 255, 0.2)',
                    areaColor: 'rgba(0, 0, 0, 0.2)',
                },
                emphasis: {
                    itemStyle: {
                        areaColor: '#F3B329',
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowBlur: 20,
                        borderWidth: 0,
                        shadowColor: 'rgba(255, 255, 255, 0.6875)'
                    }
                }
            },
            series : [
                {
                    name: '累计通话时长',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        }
                    },
                    data: [],
                },{
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    data: [],
                }
            ]
        };
        chartMap.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartMap.resize();
            })
        });

        this.setState({
            chartMap: chartMap,
        })

        return chartMap;
    }

    initChartTable() {
        let chartTable = echarts.init(document.getElementById('mainTable'), null, {renderer: 'svg'});
        let option = {
            color: ['#09b0d3',],
            title: {
                text: <span>{`${moment().startOf('month').format('M月')}通话时长 \t TOP5`}</span>,
                textStyle: {
                    color: '#fff',
                    fontSize: 12,
                },
                itemGap: 0,
            },
            grid: {
                top: '32px',
                left: '6px',
                bottom: '0px',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.6],
                show:false,
            },
            visualMap: {
                show: false,
                type: 'piecewise',
                splitNumber: 5,
                min: 0,
                max: 1000,
                dimension: 0,
                color: ['rgba(244,122,117, 0.6875)','rgba(0,130,252,0.6875)','rgba(34,237,124,0.6875)',],

            },
            yAxis: {
                type: 'category',
                data: [],
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                }
            },
            series: [
                {
                    type: 'bar',
                    data: [],
                    showBackground: true,
                    cursor: 'default',
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (params) => {
                            return `${params.value} 分钟`
                        },
                        color: 'inherit',
                    },
                    
                }
            ]
        }

        chartTable.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartTable.resize();
            })
        });

        this.setState({
            chartTable: chartTable,
        })

        return chartTable;
        
    }

    componentDidMount() {
        this.initChartMap();
        this.initChartTable();
    }

    updateData(data, nameKey, valueKey, title) {
        if (!data) {
            return;
        }

        let { chartMap, chartTable } = this.state;
        if (chartMap && chartTable) {
            
            data = data.sort((a, b) => b[valueKey] - a[valueKey])
            let max = Math.floor(data[0][valueKey] / 60);
            max = Math.floor((max / 1000) + 1) * 1000;
            let sizeFactor = Math.floor(max / 20);
            let sortData = data.slice(0, 5);
            let sortData2 = data.slice(5);
            sortData.reverse();

            let chartMapOption = chartMap.getOption();
            chartMapOption.series[0].data = sortData2.map(d => [d.lng, d.lat, Math.floor(d[valueKey] / 60), d[nameKey]])
            chartMapOption.series[0].symbolSize = (values) => Math.max(values[2] / sizeFactor, 5)
            chartMapOption.series[1].data = sortData.map(d => [d.lng, d.lat, Math.floor(d[valueKey] / 60), d[nameKey]])
            chartMapOption.series[1].symbolSize = (values) => Math.max(values[2] / sizeFactor, 5)
            chartMapOption.visualMap[0].max = max;
            chartMapOption.visualMap[0].range = [1, max]
            chartMap.setOption(chartMapOption);  

            let chartTableOption = chartTable.getOption();
            chartTableOption.title[0].text = `${title}通话时长\t TOP5`;
            chartTableOption.yAxis[0].data = sortData.map(d => { return {value: d[nameKey], textStyle:{color: '#fff'}}})
            chartTableOption.series[0].data = sortData.map(d => Math.floor(d[valueKey] / 60))
            chartTableOption.visualMap[0].max = max;
            chartTableOption.visualMap[0].range = [1, max]
            chartTable.setOption(chartTableOption);  
        }
    }

    render() {
        let { className, style } = this.props;
        return (
            <div className={className + " map"} style={style}>
                <div id="mainMap" className="china-map" style={{height: style.height, width: '100%'}}/>
                <div id="mainTable" className="table" style={{height: '220px', width: '30%'}}/>
            </div>
        );
    }
}

export default Map;