/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { PageHeader, Menu, Dropdown, Button, Descriptions, Typography, Icon, Modal, Spin, Tag } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import { 
    HoCard, HoBreadcrumb, hoMessage, HoMoreDropdownButton, HoRefreshButton, HoStartButton, HoStopButton, HoNewButton, HoButton, HoAlertDialog, HoDivider, HoBackButton, HoIconFont, HoCancelButton, HoOkButton
} from '../../../../../../util/hoComponent';
import { Fragment } from 'react';
import { formatValidTime } from '../../../../../../util/logic';
import { hoTableColumnTagButton, hoTableInitPaginationInfo } from '../../../../../../util/tableComponent';
import { dataTerminalLicenseCtrlList, dataTerminalLicenseForbidden, dataTerminalLicenseList } from '../../../../../../api/terminalLicence';
import { getLoginUserInfo } from '../../../../../login/loginRD';
import { LicenseType } from '../genForm';
import { durationDate, operationActive, penewalPageDom, penewalPageShow, remainingDate } from '../common';

import './detail.less'

class DetailCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 数据回调
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面回调
        
        this.state = {
            licenseId: this.props.match.params.id,
            prepage: this.props.location.state,
            paginationInfo: hoTableInitPaginationInfo(),

        }
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e) {
        this.setState({
            loading: true,
        }, () => {
            let { paginationInfo, licenseId } = this.state;
            paginationInfo.pagination.filters = [[["LicenseId", "equal", licenseId]]];
            dataTerminalLicenseList(this.props, paginationInfo,
                (_, rsp, req) => {
                    let records = rsp.RecordList
                    dataTerminalLicenseCtrlList(this.props, paginationInfo,
                        (_, rsp2) => {
                            this.setState({
                                loading: false,
                                issuedInfo: records?.[0],
                                activationInfo: rsp2.RecordList?.[0]
                            })
                        },
                        () => {
                            this.setState({
                                loading: false,
                            })
                        }
                    )
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                }
            )
        })
    }

    activeOnClick(e, active) {
        let { activationInfo } = this.state;
        operationActive.bind(this)(activationInfo, active, () => {
            this.mainRefreshOnClick()
        })
    }

    // 面包屑
    mkBreadcrumb() {
        let { prepage } = this.state;
        switch (prepage) {
            case 'terminal/activation':
                return <HoBreadcrumb
                    items={[{title: "配置管理", url: "devops/setting"}, {title: "License", url: "devops/setting/license/terminal/activation"}, {title: "终端"}, {title: "详情"}]}
                />
        
            default:
                return <HoBreadcrumb
                    items={[{title: "配置管理", url: "devops/setting"}, {title: "License", url: "devops/setting/license/terminal"}, {title: "终端"}, {title: "详情"}]}
                />
        }
    }

    mkHeaderInfo() {
        let { activationInfo, prepage } = this.state;
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu(prepage)} />
            {
                activationInfo?.IsForbidden ?
                    <HoButton icon="check-circle" onClick={(e) => this.activeOnClick(e, true)}>启用</HoButton> :
                    <HoButton icon="minus-circle" type="danger" onClick={(e) => this.activeOnClick(e, false)}>禁用</HoButton>
            }
            <HoButton icon="thunderbolt" onClick={(e) => penewalPageShow.bind(this)(e, activationInfo)}>续期</HoButton>
            <HoRefreshButton key="refresh" onClick={this.mainRefreshOnClick}/>
        </div>);
        
        return {title: <span>详情</span>, buttons: buttons}; 
    }

    mkBase(){
        let { issuedInfo, activationInfo, Icon } = this.state;

        let tags = []
        if (activationInfo) {
            if (activationInfo.IsForbidden) {
                tags.push({ color: red[3], tag: '禁用' })
            }
            tags.push({ color: blue[5], tag: '已激活' })
        } else {
            tags.push({
                color: issuedInfo?.Issued ? gold[5] : green[5], tag: issuedInfo?.Issued ? '已发行' : '未发行'
            })
        }

        let activationCaps = {};
        let issueCaps = {};
        try {
            activationCaps = JSON.parse(activationInfo?.CapabilitySet || "{}")
            issueCaps = JSON.parse(issuedInfo?.CapabilitySet || "{}")
        } catch (error) {
            
        }

        // 有效期
        let activationExpire = (activationInfo && remainingDate(activationInfo.EndTime)) || "未激活";
        let issueExpire = durationDate(issuedInfo?.Duration);
        
        const mainContent = <div></div>;
        const extraContent = <div>
            <Descriptions size="small" layout="vertical" column={4}>
                <Fragment>
                    <Descriptions.Item label="License ID">
                        <Typography.Paragraph>{issuedInfo?.LicenseId || activationInfo?.LicenseId}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="状态" className="descriptions-state">
                        {hoTableColumnTagButton(tags)}
                    </Descriptions.Item>
                    <Descriptions.Item label="用途">
                        <Typography.Paragraph>{LicenseType.getDescription(issuedInfo?.LicenseType || activationInfo?.LicenseType)}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="剩余时间">
                        <Typography.Paragraph>{activationExpire}</Typography.Paragraph>
                    </Descriptions.Item>
                </Fragment>
                
                <Fragment>
                    <Descriptions.Item label="创建时间">
                        <Typography.Paragraph>{formatValidTime(issuedInfo?.CreateTime)}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="创建备注" span={3}>
                        <Typography.Paragraph>{issuedInfo?.Remark || "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                </Fragment>

                {issuedInfo ? <Fragment>
                    <Descriptions.Item label="发行时间">
                        <Typography.Paragraph>{formatValidTime(issuedInfo?.IssuedTime, "未发行")}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="发行有效期">
                        <Typography.Paragraph>{issueExpire}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="发行能力">
                        <Typography.Paragraph>
                            <ul className="detail-caps-set">
                                <li><HoIconFont type={issueCaps?.base ? "icon-duihao": "icon-cuo"}/>基础能力</li>
                                <li><HoIconFont type={issueCaps?.jz ? "icon-duihao": "icon-cuo"}/>极真能力</li>
                            </ul>
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="发行备注">
                        <Typography.Paragraph>{issuedInfo?.IssueRemark || "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                </Fragment> : undefined}
                
                
                {activationInfo ? <Fragment>
                    <Descriptions.Item label="激活时间">
                        <Typography.Paragraph>{formatValidTime(activationInfo?.ActiveTime, "未激活")}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="激活终端序列号">
                        <Typography.Paragraph>{activationInfo?.BindSn || "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="当前能力">
                        <Typography.Paragraph>
                            <ul className="detail-caps-set">
                                <li><HoIconFont type={activationCaps?.base ? "icon-duihao": "icon-cuo"}/>基础能力</li>
                                <li><HoIconFont type={activationCaps?.jz ? "icon-duihao": "icon-cuo"}/>极真能力</li>
                            </ul>
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="激活备注">
                        <Typography.Paragraph>{activationInfo?.Remark || "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                </Fragment> : undefined}
                

            </Descriptions>
        </div>;

        return <div>
            <PageHeader className="ho-pageheader">
                <div className="content">
                    <div className="main">{mainContent}</div>
                    <div className="extra">{extraContent}</div>
                </div>
            </PageHeader>
        </div>
    }

    // clear handle
    componentWillUnmount(){
    }

    componentDidMount(){
        this.mainRefreshOnClick()
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const baseComponent = this.mkBase();
        const penewalComponent = penewalPageDom.bind(this)();

        return (<div>
            {penewalComponent}
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {baseComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(DetailCT);

