/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../../util/tableComponent";
import { netRequestThunk } from '../../../util/netReqRD';

const ACT_LIST_CHANNEL = 'ACT_LIST_CHANNEL';
const ACT_CLEAN_CHANNEL = 'ACT_CLEAN_CHANNEL';


export const ACT_NET_CHANNEL_LIST = 'listChannel';
export const ACT_NET_CHANNEL_QUERY = 'queryChannel';

// reducer handle
const channelState = {
    data: undefined, 
}

export const channel_reducer = (state=channelState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_CHANNEL:{
            return {
                ...state,
                data: action.data
            };
        }

        case ACT_CLEAN_CHANNEL:
            return {
                data: undefined,
            };

        default:
            return state;
    }
}


// 数据
export function listChannelData(state) {
    return state.channel !== undefined ? state.channel.data : undefined;
}


export function actChannelClear() {
    return {
        type: ACT_CLEAN_CHANNEL,
    }
}

export function listChannelDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_CHANNEL, 
        data: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export const dataListChannel = (props, paginationInfo, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/channel/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CHANNEL_LIST, successFun, errorFun));
}

export const dataQueryChannel = (props, uuid, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/channel/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        channel: {
            uuid: uuid,
        }
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CHANNEL_QUERY, successFun, errorFun));
}





