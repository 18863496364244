import React, { Component } from 'react';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
TweenOne.plugins.push(Children);

class Number extends Component {

    render() {
        let { className, style, defaultValue, value, duration, floatLength } = this.props;
        return <TweenOne
            style={style}
            className={className}
            animation={{
                Children: { 
                    value: value || parseInt(value),
                    floatLength: floatLength || 0,
                }, 
                duration: duration || 1000,
            }}
        >
            {defaultValue}
        </TweenOne>
    }
}

export default Number;