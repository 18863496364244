/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { PageHeader, Tabs, Divider, Collapse, Descriptions, Typography, Result, Tooltip, Spin } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';

import {getLoginUserInfo} from '../../login/loginRD'
import { 
    ACT_NET_CHANNEL_QUERY, dataQueryChannel,
} from './channelRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoBackButton, HoTag,
} from '../../../util/hoComponent';

class ChannelDetailCT extends Component{
    constructor(props){
        super(props);
        
        // 回调
        this.dataQueryChannelSuccessHd = this.dataQueryChannelSuccessHd.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkMain = this.mkMain.bind(this);

        this.state = {
            uuid: this.props.match.params.uuid,
            channel: this.props.location.state && this.props.location.state.channel,
        }
    }

    dataQueryChannelSuccessHd(dispatch, rspBody, reqBody) {
        this.setState({
            channel: rspBody.channel,
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        dataQueryChannel(this.props, this.state.uuid, this.dataQueryChannelSuccessHd);
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "呼叫", url: "devops/analysis/channel"}, {title: this.state.uuid}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("devops/analysis/channel")}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>详情</span>, buttons: buttons}; 
    }


    mkMain(){
        const {channel} = this.state;
        if (!channel){
            return undefined;
        }
        // console.log(channel);
        let tag = [];
        switch(channel.state){
            case 'setup':
                tag.push(<HoTag key="setup" color={gold[3]} className='ho-pageheader-state'>正在呼叫</HoTag>);
                break;
            case 'stable':
                tag.push(<HoTag key="stable" color={blue[3]} className='ho-pageheader-state'>通话中</HoTag>);
                break;
            case 'completed':
            case 'completed2': // 此为强制结束（被后台任务与freeswitch数据库对比得出的不正常呼叫）
                if (!channel.answerInfo || !channel.answerInfo.answer) {
                    tag.push(<HoTag key="completed" color={red[3]} className='ho-pageheader-state'>没有接通</HoTag>);
                    tag.push(<HoTag key="hangupCase" color={red[3]} className='ho-pageheader-state'>{channel.hangupInfo.hangup.cause}({channel.hangupInfo.sip.termCause})</HoTag>);
                }else{
                    tag.push(<HoTag key="completed" color={grey[3]} className='ho-pageheader-state'>已结束</HoTag>);
                }
                break;
            default:
                break;
        }

        const mainContent = (
            <div>
                <span>{tag}</span>
            </div>
        );

        let conf = undefined;
        const {conference} = channel.hangupInfo || {}
        if (conference && conference.application === 'conference'){
            conf = <div>
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="会议SIP号"><Typography.Paragraph copyable style={{color: geekblue[3]}}>{conference.name}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="成员ID"><Typography.Paragraph>{conference.memberId}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="召集者"><Typography.Paragraph>{conference.moderator ? "是" : "否"}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="入会参数"><Typography.Paragraph>{conference.data}</Typography.Paragraph></Descriptions.Item>
                </Descriptions>
            </div>;
        }

        const extraContent = (
            <div style={{marginTop: '1rem'}}>
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="主叫SIP号"><Typography.Paragraph copyable style={{color: geekblue[3]}}>{channel.fromNumber}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="呼叫时间"><Typography.Paragraph copyable>{channel.createTime}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="被叫SIP号"><Typography.Paragraph copyable style={{color: red[3]}}>{channel.toNumber}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="UUID"><Tooltip title="此UUID既是FREESWITCH的会话ID也是SIP信令的Call-ID"><Typography.Paragraph copyable>{channel.uuid}</Typography.Paragraph></Tooltip></Descriptions.Item>
                </Descriptions>
                {conf}
            </div>
        );

        return <div>
            <PageHeader className="ho-pageheader">
                <div className="content">
                    <div className="main">{mainContent}</div>
                    <div className="extra">{extraContent}</div>
                </div>
            </PageHeader>
        </div>
    }

    mkTabSetup(){
        const {channel} = this.state;
        if (!channel){
            return undefined;
        }
        const info = channel.createInfo;
        if (!info || !info.create) {
            return <Result status="warning" title="暂无数据"/>;
        }

        let media = undefined;
        let remote = '远端'
        let dir = '入呼'
        if (info.create.direction === 'outbound') {
            media = <div>
                <Divider orientation="left">音频</Divider>
                <Descriptions size="small" column={1}>
                    <Descriptions.Item label="本端内部IP"><span>{info.audio.local.ip}</span></Descriptions.Item>
                    <Descriptions.Item label="本端外部IP"><span>{info.audio.local.advertisedIp}</span></Descriptions.Item>
                    <Descriptions.Item label="本端端口"><span>{info.audio.local.port}</span></Descriptions.Item>
                </Descriptions>

                <Divider orientation="left">视频</Divider>
                <Descriptions size="small" column={1}>
                    <Descriptions.Item label="本端内部IP"><span>{info.video.local.ip}</span></Descriptions.Item>
                    <Descriptions.Item label="本端外部IP"><span>{info.video.local.advertisedIp}</span></Descriptions.Item>
                    <Descriptions.Item label="本端端口"><span>{info.video.local.port}</span></Descriptions.Item>
                </Descriptions>
            </div>
            remote = '本端'
            dir = '出呼'
        }

        return <div>
            <Divider orientation="left">基本信息</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="呼叫时间"><span>{moment(new Date(info.create.time * 1000)).format("YYYY-MM-DD HH:mm:ss")}</span></Descriptions.Item>
                <Descriptions.Item label="呼叫方向"><span>{info.create.direction}({dir})</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">SIP</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="request"><span>{info.sip.request}</span></Descriptions.Item>
                <Descriptions.Item label="from"><span>{info.sip.from}</span></Descriptions.Item>
                <Descriptions.Item label="to"><span>{info.sip.to}</span></Descriptions.Item>
                <Descriptions.Item label="contact"><span>{info.sip.contact}</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">SDP Offer({remote})</Divider>
            {info.sdp.offer.split('\n').map((item, index) => <div key={index}>{item}</div>)}
        </div>
    }

    mkTabStable(){
        const {channel} = this.state;
        if (!channel){
            return undefined;
        }
        const info = channel.answerInfo;
        if (!info || !info.answer) {
            return <Result status="warning" title="暂无数据"/>;
        }

        return <div>
            <Divider orientation="left">基本信息</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="振铃时间"><span>{moment(new Date(info.ring.time * 1000)).format("YYYY-MM-DD HH:mm:ss")}</span></Descriptions.Item>
                <Descriptions.Item label="应答时间"><span>{moment(new Date(info.answer.time * 1000)).format("YYYY-MM-DD HH:mm:ss")}</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">音频</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="编解码"><span>{info.audio.codec}</span></Descriptions.Item>
                <Descriptions.Item label="码流方向"><span>{info.audio.flow}</span></Descriptions.Item>
                <Divider orientation="left"/>
                <Descriptions.Item label="本端内部IP"><span>{info.audio.local.ip}</span></Descriptions.Item>
                <Descriptions.Item label="本端外部IP"><span>{info.audio.local.advertisedIp}</span></Descriptions.Item>
                <Descriptions.Item label="本端端口"><span>{info.audio.local.port}</span></Descriptions.Item>
                <Descriptions.Item label="本端SSRC"><span>{info.audio.local.ssrc}</span></Descriptions.Item>
                <Descriptions.Item label="本端PT"><span>{info.audio.local.pt}</span></Descriptions.Item>
                <Divider orientation="left"/>
                <Descriptions.Item label="远端IP"><span>{info.audio.remote.ip}</span></Descriptions.Item>
                <Descriptions.Item label="远端端口"><span>{info.audio.remote.port}</span></Descriptions.Item>
                <Descriptions.Item label="远端PT"><span>{info.audio.remote.pt}</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">视频</Divider>
            <Descriptions size="small" column={1}>
            <Descriptions.Item label="编解码"><span>{info.video.codec}</span></Descriptions.Item>
                <Descriptions.Item label="码流方向"><span>{info.video.flow}</span></Descriptions.Item>
                <Divider orientation="left"/>
                <Descriptions.Item label="本端内部IP"><span>{info.video.local.ip}</span></Descriptions.Item>
                <Descriptions.Item label="本端外部IP"><span>{info.video.local.advertisedIp}</span></Descriptions.Item>
                <Descriptions.Item label="本端端口"><span>{info.video.local.port}</span></Descriptions.Item>
                <Descriptions.Item label="本端SSRC"><span>{info.video.local.ssrc}</span></Descriptions.Item>
                <Descriptions.Item label="本端PT"><span>{info.video.local.pt}</span></Descriptions.Item>
                <Divider orientation="left"/>
                <Descriptions.Item label="远端IP"><span>{info.video.remote.ip}</span></Descriptions.Item>
                <Descriptions.Item label="远端端口"><span>{info.video.remote.port}</span></Descriptions.Item>
                <Descriptions.Item label="远端PT"><span>{info.video.remote.pt}</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">SIP</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="call-id"><span>{info.sip.callId}</span></Descriptions.Item>
                <Descriptions.Item label="user-agent"><span>{info.sip.userAgent}</span></Descriptions.Item>
            </Descriptions>

            {channel.createInfo.create.direction === 'outbound' ? <div>
                <Divider orientation="left">SDP Answer</Divider>
                {info.sdp.answer.split('\n').map((item, index) => <div key={index}>{item}</div>)}
            </div> : undefined}
            
        </div>
    }

    mkTabCompleted(){
        const {channel} = this.state;
        if (!channel){
            return undefined;
        }
        const info = channel.hangupInfo;
        if (!info || !info.hangup) {
            return <Result status="warning" title="暂无数据"/>;
        }

        let duration = 0;
        if (channel.answerInfo && channel.answerInfo.answer) {
            duration = info.hangup.time - channel.answerInfo.answer.time;
        }

        const panelStyle = {
            background: '#f7f7f7',
            borderRadius: 4,
            marginBottom: '0.5rem',
            border: 0,
            overflow: 'hidden',
        };

        return <div>
            <Divider orientation="left">基本信息</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="挂断时间"><span>{moment(new Date(info.hangup.time * 1000)).format("YYYY-MM-DD HH:mm:ss")}</span></Descriptions.Item>
                <Descriptions.Item label="呼叫时长"><span>{info.hangup.duration}秒</span></Descriptions.Item>
                <Descriptions.Item label="通话时长"><span>{duration}秒</span></Descriptions.Item>
                <Descriptions.Item label="挂断原因"><span>{info.hangup.cause}({info.sip.termCause})</span></Descriptions.Item>
                <Descriptions.Item label="挂断方向"><span>{info.hangup.disposition}</span></Descriptions.Item>
            </Descriptions>

            <Divider orientation="left">音频</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="远端IP"><span>{info.audio.remote.ip}</span></Descriptions.Item>
                <Descriptions.Item label="远端端口"><span>{info.audio.remote.port}</span></Descriptions.Item>
            </Descriptions>
            {info.audio.rtp && info.audio.rtcp ? 
            <Collapse bordered={false}>
                <Collapse.Panel header="RTP统计" key="1" style={panelStyle}>
                    <Divider orientation="left">进方向</Divider>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="raw bytes"><span>{info.audio.rtp.in.rawBytes}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.audio.rtp.in.packet.count}</span></Descriptions.Item>
                        <Descriptions.Item label="packet jitter"><span>{info.audio.rtp.in.packet.jitter}</span></Descriptions.Item>
                        <Descriptions.Item label="packet skip"><span>{info.audio.rtp.in.packet.skip}</span></Descriptions.Item>
                        <Descriptions.Item label="packet dtmf"><span>{info.audio.rtp.in.packet.dtmf}</span></Descriptions.Item>
                        <Descriptions.Item label="packet cng"><span>{info.audio.rtp.in.packet.cng}</span></Descriptions.Item>
                        <Descriptions.Item label="packet flush"><span>{info.audio.rtp.in.packet.flush}</span></Descriptions.Item>
                        <Descriptions.Item label="mos"><span>{info.audio.rtp.in.mos}</span></Descriptions.Item>
                        <Descriptions.Item label="mean interval"><span>{info.audio.rtp.in.meanInterval}</span></Descriptions.Item>
                        <Descriptions.Item label="largest jitter buffer size"><span>{info.audio.rtp.in.largestJbSize}</span></Descriptions.Item>
                        <Descriptions.Item label="quality percentage"><span>{info.audio.rtp.in.qualityPercentage}</span></Descriptions.Item>
                        <Descriptions.Item label="flaw total"><span>{info.audio.rtp.in.flawTotal}</span></Descriptions.Item>
                    </Descriptions>

                    <Divider orientation="left">出方向</Divider>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="raw bytes"><span>{info.audio.rtp.out.rawBytes}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.audio.rtp.out.packet.count}</span></Descriptions.Item>
                        <Descriptions.Item label="packet skip"><span>{info.audio.rtp.out.packet.skip}</span></Descriptions.Item>
                        <Descriptions.Item label="packet dtmf"><span>{info.audio.rtp.out.packet.dtmf}</span></Descriptions.Item>
                        <Descriptions.Item label="packet cng"><span>{info.audio.rtp.out.packet.cng}</span></Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
                <Collapse.Panel header="RTCP统计" key="2" style={panelStyle}>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="octet count"><span>{info.audio.rtcp.octetCount}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.audio.rtcp.packetCount}</span></Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            </Collapse> : undefined}

            <Divider orientation="left">视频</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="远端IP"><span>{info.video.remote.ip}</span></Descriptions.Item>
                <Descriptions.Item label="远端端口"><span>{info.video.remote.port}</span></Descriptions.Item>
            </Descriptions>
            {info.video.rtp && info.video.rtcp ? 
            <Collapse bordered={false}>
                <Collapse.Panel header="RTP统计" key="1" style={panelStyle}>
                    <Divider orientation="left">进方向</Divider>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="raw bytes"><span>{info.video.rtp.in.rawBytes}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.video.rtp.in.packet.count}</span></Descriptions.Item>
                        <Descriptions.Item label="packet jitter"><span>{info.video.rtp.in.packet.jitter}</span></Descriptions.Item>
                        <Descriptions.Item label="packet skip"><span>{info.video.rtp.in.packet.skip}</span></Descriptions.Item>
                        <Descriptions.Item label="packet cng"><span>{info.video.rtp.in.packet.cng}</span></Descriptions.Item>
                        <Descriptions.Item label="packet flush"><span>{info.video.rtp.in.packet.flush}</span></Descriptions.Item>
                        <Descriptions.Item label="mos"><span>{info.video.rtp.in.mos}</span></Descriptions.Item>
                        <Descriptions.Item label="mean interval"><span>{info.video.rtp.in.meanInterval}</span></Descriptions.Item>
                        <Descriptions.Item label="largest jitter buffer size"><span>{info.video.rtp.in.largestJbSize}</span></Descriptions.Item>
                        <Descriptions.Item label="quality percentage"><span>{info.video.rtp.in.qualityPercentage}</span></Descriptions.Item>
                        <Descriptions.Item label="flaw total"><span>{info.video.rtp.in.flawTotal}</span></Descriptions.Item>
                    </Descriptions>

                    <Divider orientation="left">出方向</Divider>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="raw bytes"><span>{info.video.rtp.out.rawBytes}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.video.rtp.out.packet.count}</span></Descriptions.Item>
                        <Descriptions.Item label="packet skip"><span>{info.video.rtp.out.packet.skip}</span></Descriptions.Item>
                        <Descriptions.Item label="packet dtmf"><span>{info.video.rtp.out.packet.dtmf}</span></Descriptions.Item>
                        <Descriptions.Item label="packet cng"><span>{info.video.rtp.out.packet.cng}</span></Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
                <Collapse.Panel header="RTCP统计" key="2" style={panelStyle}>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="octet count"><span>{info.video.rtcp.octetCount}</span></Descriptions.Item>
                        <Descriptions.Item label="packet count"><span>{info.video.rtcp.packetCount}</span></Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            </Collapse> : undefined}
            

            <Divider orientation="left">SIP</Divider>
            <Descriptions size="small" column={1}>
                <Descriptions.Item label="status"><span>{info.sip.termStatus}</span></Descriptions.Item>
                <Descriptions.Item label="call-id"><span>{info.sip.callId}</span></Descriptions.Item>
            </Descriptions>

        </div>
    }

    // clear handle
    componentWillUnmount(){
        // let {dispatch} = this.props;
        // dispatch(actNetRequestClear(ACT_NET_CHANNEL_LIST));
    }

    componentDidMount(){
        const {uuid, channel} = this.state;
        if (!channel){
            dataQueryChannel(this.props, uuid, this.dataQueryChannelSuccessHd);
        }
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const mainComponent = this.mkMain();

        return (<Spin spinning={this.props.queryStatus === 1} tip="数据加载中...">
            <div>
                {breadcrumbComponent}
                <HoCard title={title} buttons={buttons} >
                    {mainComponent}
                </HoCard>
                <Divider style={{background: '#f0f0f0', margin: 0}}></Divider>
                <Tabs type="card" defaultActiveKey="setup" className="ho-channel-tabs">
                    <Tabs.TabPane tab="呼叫信息" key="setup">
                        {this.mkTabSetup()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="应答信息" key="stable">
                        {this.mkTabStable()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="挂断信息" key="completed">
                        {this.mkTabCompleted()}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Spin>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    queryStatus: getNetRequestStatus(state, ACT_NET_CHANNEL_QUERY), 
});


export default connect(
    mapState, 
    null
)(ChannelDetailCT);

