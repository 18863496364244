
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
const ACT_INIT_CLIENT = 'ACT_INIT_CLIENT';

// reducer handle
const mqttState = {
    client: undefined,
}

export const mqtt_reducer = (state=mqttState, action) => {
    switch(action.type){
        case ACT_INIT_CLIENT:{
            return {
                ...state,
                client: action.client,
            };
        }

        default:
            return state;
    }
}

export function getMqttClient(state) {
    return state.mqtt !== undefined ? state.mqtt.client : undefined;
}

export function initMqttClient(dispatch, host, port, username, password, clientId) {
    var mqtt = require('mqtt')
    var client  = mqtt.connect('', 
    {
        host: host,
        port: port,
        username: username,
        password: password,
        // protocolVersion: 4,
        clientId: clientId,
        protocol: 'wss',
    }
    )
    dispatch({
        type: ACT_INIT_CLIENT, 
        client: client, 
    })

    return client;
}

