/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';

import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { Result, Collapse, Button, BackTop, Menu, Dropdown, Row, Col, Icon, Tooltip, Card, Tabs, Radio, Select} from 'antd';
import { ChartCard, Field, MiniArea, Bar, MiniBar, MiniProgress, Pie, Gauge } from 'ant-design-pro/lib/Charts';

import { G2, Chart, Geom, Axis, Tooltip as BZTooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util,} from "bizcharts";
import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';
import { 
    dataListCdr, listCdrDataSuccessHd, listCdrData, ACT_NET_CDR_LIST, dataDurationCdr, dataDistributionCdr, dataQueryIp,
} from './cdrRD';
import {getLoginUserInfo} from '../../login/loginRD'

import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoIconFont,
} from '../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';
import { dataListMediaServer } from '../../devops/setting/server/serverRD';
import { getTimeDesc, isValidTime } from '../../../util/logic';
import { dataGetMemberBaseInfo, dataListMember } from '../../member/memberRD';
import Number from '../../../component/tweenOneIcon/number';
import { deviceTypeMap } from '../../../defined/memberDevice';
import { dataListRoom } from '../../room/roomRD';
    
class CdrCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        this.getSipNum = this.getSipNum.bind(this);
        // 数据处理回调
        this.dataCommonSuccessHd = this.dataCommonSuccessHd.bind(this);
        this.dataDistributionCdrSuccessHd = this.dataDistributionCdrSuccessHd.bind(this);
        this.dataListCdrDataSuccessHd = this.dataListCdrDataSuccessHd.bind(this);
        this.dataDurationCdrSuccessHd = this.dataDurationCdrSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblRowDetailOnClick = this.tblRowDetailOnClick.bind(this);

        this.distributionTypeOnChange = this.distributionTypeOnChange.bind(this);
        this.distributionGroupOnChange = this.distributionGroupOnChange.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        const columns = [
            hoTableColumn('会议号', 'conferenceCode', 'str', false, false, '10%', {
                render: (text, record) => {
                    let sipNum = this.getSipNum(record, true)
                    return <Tooltip title={`会议SIP号: ${sipNum}`}>
                        <span className="ho-item-link" onClick={(e) => {
                            let pag = hoTableInitPaginationInfo();
                            pag.pagination.filters = [[['SipCode', 'equal', sipNum]]]
                            dataListRoom(this.props, pag,
                                (_, rsp) => {
                                    let confId = rsp?.ConferenceList?.[0]?.ConferenceId;
                                    if (confId) {
                                        window.goToMenu('/ctrl/room/', `detail/${confId}`)
                                    }
                                },
                                () => {
                                    return true;
                                }
                            )
                        }}>{record.conferenceCode}</span>
                    </Tooltip>
                },
                nolight: true,
            }),
            hoTableColumn('设备名称', 'nickName', 'str', false, false, '15%'),
            hoTableColumn('设备SIP号', 'calleeNumber', 'str', false, false, '15%', {
                render: (text, record) => {
                    const number = this.getSipNum(record);
                    return <Tooltip title={number}>
                        <span className="ho-item-link" onClick={(e) => {
                            if (record.userId) {
                                window.goToMenu('member', `detail/${record.userId}`, 'bill/cdr')
                            }
                        }}>{number}</span>
                    </Tooltip>
                },
                nolight: true,
            }),
            hoTableColumn('设备类型', 'deviceType', 'str', false, false, '7%', {
                render: (text, record) => deviceTypeMap.find(item => item.value === text)?.title || text,
            }),
            hoTableColumn('媒体服务器', 'mediaServerName', 'str', false, false, '10%', {
                render: (text, record) => <Tooltip title={record.mediaServerName}>
                    <span className="ho-item-link" onClick={(e) => {
                        window.goToMenu('devops/setting/server/group/', `media/${record.mediaServerGroupId}`)
                    }}>{record.mediaServerName}</span>
                </Tooltip>,
                nolight: true,
            }),
            hoTableColumn('媒体代理', 'mediaProxyMethod', 'str', false, false, '5%', {
                render: (text, record) => record.mediaProxyMethod === "internal" ? "内网" : "云端",
                nolight: true,
            }),
            hoTableColumn('来源', 'addr', 'str', false, false, '8%', {
                render: (text, record) => {
                    let addr = <span>{record.addr}</span>
                    if (!record.addr) {
                        addr = <span className="ho-item-link" onClick={(e) => {
                            window.goToMenu(`http://ip.taobao.com/ipSearch?ipAddr=${record.ip}`, "", undefined, false, true)
                        }}>{record.ip}</span>
                    }
                    return <Tooltip title={`IP地址: ${record.ip}`}>{addr}</Tooltip>
                },
                nolight: true,
            }),
            hoTableColumnDate('结算时间', 'answerTime', true, '20%', {
                render: (text, record) => {
                    if (isValidTime(record.answerTime) || isValidTime(record.endTime)) {
                        return "(无)"
                    } else {
                        return `${record.answerTime} ~ ${record.endTime}`;
                    }
                },
                nolight: true,
            }),
            hoTableColumn('通话时长(分钟)', 'billsec', 'str', false, false, '7%', {
                render: (text, record) => {
                    return <Tooltip title={getTimeDesc(record.billsec)}>{(record.billsec / 60).toFixed(2)}</Tooltip>
                },
                nolight: true,
            }),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            duration: {
                total: 0,
                today: 0,
                week: 0,
                month: 0,
            },

            distribution: {
                type: 'today.this',
                group: 'hour',
                groupExtra: [],
                title: '',
                list: [],
                tgMap: [{
                    value: 'today',
                    title: '今天',
                    menus: [{value: 'this', title: '今天'}, {value: 'ago.1', title: '昨天'}, {value: 'ago.2', title: '前天'}],
                    groups: [{value: 'hour', title: '按时段'}]
                }, {
                    value: 'week',
                    title: '本周',
                    menus: [{value: 'this', title: '本周'}, {value: 'ago.1', title: '上周'}],
                    groups: [{value: 'day', title: '按天'}, {value: 'hour', title: '按时段'}],
                }, {
                    value: 'month',
                    title: '本月',
                    menus: [{value: 'this', title: '本月'}, {value: 'ago.1', title: '上月'}],
                    groups: [{value: 'day', title: '按天'}, {value: 'hour', title: '按时段'}],
                }, {
                    value: 'quarter',
                    title: '本季度',
                    menus: [{value: 'this', title: '本季度'}, {value: 'ago.1', title: '上季度'}],
                    groups: [{value: 'month', title: '按月'}, {value: 'hour', title: '按时段'}],
                }, {
                    value: 'year',
                    title: '今年',
                    menus: [{value: 'this', title: '今年'}, {value: 'ago.1', title: '去年'}],
                    groups: [{value: 'quarter', title: '按季度'}, {value: 'month', title: '按月'}, {value: 'hour', title: '按时段'}]
                }],
            },
        }
    }

    getSipNum(item, confSipNum) {
        if (item.direction === "outbound") {
            return confSipNum ? item.callerNumber : item.calleeNumber
        } else {
            return confSipNum ? item.calleeNumber : item.callerNumber
        }
    }

    dataCommonSuccessHd(dispatch, rspBody, reqBody)  {
        hoMessage({type:'success', msg:'操作成功'});
    }

    dataDurationCdrSuccessHd(dispatch, rspBody, reqBody) {
        this.setState({
            duration: rspBody.duration,
        })
    }

    dataDistributionCdrSuccessHd(dispatch, rspBody, reqBody) {
        let distribution = this.state.distribution;
        distribution.list = rspBody.distributions;
        distribution.title = rspBody.title;
        distribution.groupExtra = rspBody.groupExtra;
        this.setState({
            distribution: distribution,
        })
    }

    dataListCdrDataSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        let sipNums = rspBody.pagination.records.map(item => this.getSipNum(item))
        // 去重
        sipNums = Array.from(new Set(sipNums))
        if (sipNums && sipNums.length > 0) {
            dataGetMemberBaseInfo(this.props, sipNums, (d, rsp, req) => {
                let records = []
                let mediaServerIds = []
                rspBody.pagination.records.forEach(item => {
                    mediaServerIds.push(item.mediaServerId)
                    let index = rsp.BaseInfoList ? rsp.BaseInfoList.findIndex((e) => {
                        return e.SipNum === this.getSipNum(item)
                    }) : -1;
                    let newItem = undefined;
                    if (index !== -1) {
                        newItem = {
                            ...item,
                            nickName: rsp.BaseInfoList[index].NickName
                        }
                    } else {
                        newItem = {
                            ...item,
                            nickName: "(无)"
                        }
                    }
                    let addrs = [];
                    if (newItem.country !== '' && newItem.country !== 'XX') {
                        addrs.push(newItem.country)
                    }
                    if (newItem.region !== '' && newItem.region !== 'XX') {
                        addrs.push(newItem.region)
                    }
                    if (newItem.city !== '' && newItem.city !== 'XX') {
                        addrs.push(newItem.city)
                    }
                    records.push({
                        ...newItem,
                        addr: addrs.join('-')
                    })
                })
                rspBody.pagination.records = records;
                let filters = []
                let paginationInfo = hoTableInitPaginationInfo();
                Array.from(new Set(mediaServerIds)).forEach(e => {
                    filters.push([['Id', '=', e]])
                })
                paginationInfo.pagination.filters = filters;
                dataListMediaServer(that.props, paginationInfo,
                    (dispatch, rsp3, req) => {
                        rspBody.pagination.records = records.map(r => {
                            let ret = rsp3?.RecordList?.find(e => r.mediaServerId === e.Id)
                            return {
                                ...r,
                                mediaServerName: ret?.MsName || "",
                                mediaServerGroupId: ret?.GroupId,
                            }
                        })
                        paginationInfo.pagination.filters = sipNums.map(u => [['SipNum', 'equal', u]])
                        paginationInfo.pagination.sort = {}
                        dataListMember(this.props, paginationInfo,
                            (dispatch, rsp4, req) => {
                                // eslint-disable-next-line no-unused-expressions
                                rspBody.pagination.records = rspBody.pagination.records.map(r => {
                                    let member = rsp4?.UserList?.find(e => this.getSipNum(r) === e.SipNum);
                                    return {
                                        ...r,
                                        deviceType: member?.DeviceType,
                                        userId: member?.UserId,
                                    }
                                })
                                listCdrDataSuccessHd(dispatch, rspBody, reqBody)
                            },
                            () => {
                                listCdrDataSuccessHd(dispatch, rspBody, reqBody)
                                return true;
                            }
                        )
                    },
                    () => {
                        listCdrDataSuccessHd(dispatch, rspBody, reqBody)
                        return true;
                    }
                );

                
            }, () => {
                listCdrDataSuccessHd(dispatch, rspBody, reqBody)
            })
        } else {
            listCdrDataSuccessHd(dispatch, rspBody, reqBody)
        }
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataListCdr(this.props, paginationInfo, this.dataListCdrDataSuccessHd)
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record){
        e.stopPropagation();
        window.goToMenu('bill/cdr', `detail/${record.cdrId}`, record)
    }

    distributionTypeOnChange(e) {
        // console.log("distributionTypeOnChange", e);
        let distribution = this.state.distribution;
        distribution.type = e;
        const select = distribution.tgMap.find((e) => distribution.type.split(".")[0] === e.value);
        distribution.group = select ? select.groups[0].value : undefined;
        distribution.list = [];
        this.setState({
            distribution: distribution,
        })

        dataDistributionCdr(this.props, distribution.type, distribution.group, distribution.start, distribution.end, this.dataDistributionCdrSuccessHd)
    }

    distributionGroupOnChange(e) {
        // console.log("distributionGroupOnChange", e);
        let distribution = this.state.distribution;
        distribution.group = e;
        distribution.list = [];
        this.setState({
            distribution: distribution,
        })

        dataDistributionCdr(this.props, distribution.type, distribution.group, distribution.start, distribution.end, this.dataDistributionCdrSuccessHd)
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        const { paginationInfo, distribution } = this.state;
        dataListCdr(this.props, paginationInfo, this.dataListCdrDataSuccessHd);
        dataDurationCdr(this.props, undefined, this.dataDurationCdrSuccessHd);
        dataDistributionCdr(this.props, distribution.type, distribution.group, distribution.start, distribution.end, this.dataDistributionCdrSuccessHd)
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "结算管理", url: "bill"}, {title: "话单"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>话单</span>, buttons: buttons}; 
    }

    mkCallTotal() {
        const { duration, Icon } = this.state

        return <ChartCard
            className="total"
            avatar={<QueueAnim type={['left', 'right']}><HoIconFont key="avatar1" className="avatar" type="icon-call-zong"/></QueueAnim>}
            bordered={false}
            title={<QueueAnim type={['top', 'bottom']}><div key="title1">总时长(分钟)</div></QueueAnim>}
            total={<QueueAnim type={['bottom', 'top']}><div key="total1"><Number defaultValue={0} value={(duration.total / 60).toFixed(0) || 0}/></div></QueueAnim>}
        />
    }

    mkCallToday() {
        const { duration, Icon } = this.state

        return <ChartCard
            className="today"
            avatar={<QueueAnim type={['left', 'right']}><HoIconFont key="avatar2" className="avatar" type="icon-call-other"/></QueueAnim>}
            bordered={false}
            title={<QueueAnim type={['top', 'bottom']}><div key="title2">今日时长(分钟)</div></QueueAnim>}
            total={<QueueAnim type={['bottom', 'top']}><div key="total2"><Number defaultValue={0} value={(duration.today / 60).toFixed(0) || 0}/></div></QueueAnim>}
        />
    }

    mkCallWeek() {
        const { duration, Icon } = this.state

        return <ChartCard
            className="week"
            avatar={<QueueAnim type={['left', 'right']}><HoIconFont key="avatar3" className="avatar" type="icon-call-other"/></QueueAnim>}
            bordered={false}
            title={<QueueAnim type={['top', 'bottom']}><div key="title3">本周时长(分钟)</div></QueueAnim>}
            total={<QueueAnim type={['bottom', 'top']}><div key="total3"><Number defaultValue={0} value={(duration.week / 60).toFixed(0) || 0}/></div></QueueAnim>}
        />
    }

    mkCallMonth() {
        const { duration, Icon } = this.state

        return <ChartCard
            className="month"
            avatar={<QueueAnim type={['left', 'right']}><HoIconFont key="avatar4" className="avatar" type="icon-call-other"/></QueueAnim>}
            bordered={false}
            title={<QueueAnim type={['top', 'bottom']}><div key="title4">本月时长(分钟)</div></QueueAnim>}
            total={<QueueAnim type={['bottom', 'top']}><div key="total4"><Number defaultValue={0} value={(duration.month / 60).toFixed(0) || 0}/></div></QueueAnim>}
        />
    }

    mkCallTrend() {
        const { duration } = this.state
        const { data } = this.props;
        const { records } = data || {};

        let top = 0;
        let trendData = [];
        if (records) {
            records.forEach(item => {
                if (item.billsec > top) {
                    top = item.billsec;
                }
                trendData.push({
                    x: `${item.endTime} ${item.nickName}`,
                    y: parseFloat((item.billsec / 60).toFixed(2)),
                    z: item.billsec,
                })
            })
        }
        trendData.reverse()

        const scale = {
            x: {
                type: 'cat',
                // alias: '设备',
            },
            y: {
                min: 0,
                alias: '时长(分钟)',
            }
        };

        return <ChartCard
            bordered={false}
            className="ho-cdr-call-today-card"
            contentHeight={256}
        >
            {/* <MiniBar data={trendData} /> */}
            <Chart height={244} data={trendData} scale={scale} forceFit padding={[24, 0, 35, 56]} >
                <Axis name="x" label={{formatter: () => {}}}/>
                <Axis name="y" title={true}/>
                <BZTooltip showTitle={false}/>
                <Geom type="interval" position="x*y*z" 
                    tooltip={['x*y*z', function (x, y, z) {
                        return {name: x, value: getTimeDesc(z)};
                    }]}/>
            </Chart>

        </ChartCard>
    }

    mkCallDistribution() {
        const { distribution } = this.state
        const scaleDesc = (group) => {
            switch (group){
                case 'hour': return "时段";
                case 'day': return "日期";
                case 'month': return "月份";
                case 'quarter': return "季度";
                case 'year': return "年份";
                default:
                    return group;
            }
        }
        let scale = {
            total: {
                min: 0,
                alias: '时长(分钟)',
            },
            count: {
                min: 0,
                alias: '数量',
            }
        }
        scale[distribution.group] = {
            type: 'cat',
            alias: scaleDesc(distribution.group),
        }

        const xFormatter = (val) => {
            switch (distribution.group){
                case 'hour': {
                    return val === "23" ? `23:00 ~ 0:00` : `${val}:00 ~ ${parseInt(val) + 1}:00`
                }
                case 'day': {
                    return `${val}日`
                }
                case 'month': {
                    return `${val}月`
                }
                case 'quarter': {
                    return `${val}季度`
                }
                default:
                    return val;
            }
        }
        let that = this;
        return <ChartCard
                key="chart-card"
                bordered={false}
                title={distribution.title}
                className="ho-cdr-call-today-card"
                contentHeight={404}
            >
            <Chart scale={scale} height={404} data={distribution.list} forceFit padding={[54, 56, 56, 56]} onGetG2Instance={chart => {that.chartIns = chart;}}>
                <Axis name={distribution.group} title={true} label={{formatter: xFormatter, }}/>
                <Axis name="count" grid={null} label={{ textStyle: { fill: "#3182bd" } }}/>
                <Axis name="total" label={{formatter: (val) => `${(val / 60).toFixed(0)}`, textStyle: {fill: "#ffae6b"}}}/>
                <Legend
                    custom={true}
                    position="top-right"
                    offsetY={-10}
                    allowAllCanceled={true}
                    itemFormatter={val => {
                        switch(val) {
                            case 'count': return "数量";
                            case 'total': return "时长(分钟)";
                            default: return "";
                        }
                    }}
                    items={[{
                        value: "count",
                        marker: {
                            symbol: "square",
                            fill: "#3182bd",
                            radius: 5
                        }
                    }, {
                        value: "total",
                        marker: {
                            symbol: "hyphen",
                            stroke: "#ffae6b",
                            radius: 5,
                            lineWidth: 3
                        }
                    }]}
                    onClick={ev => {
                        const item = ev.item;
                        const value = item.value;
                        const checked = ev.checked;
                        const geoms = that.chartIns.getAllGeoms();
        
                        for (let i = 0; i < geoms.length; i++) {
                        const geom = geoms[i];
                        if (geom.getYScale().field === value) {
                                if (checked) {
                                    geom.show();
                                } else {
                                    geom.hide();
                                }
                        }
                        }
                    }}
                />
                <BZTooltip showTitle={false}/>
                <Geom type="interval" position={`${distribution.group}*count`} color="#3182bd" />
                <Geom
                    type="line"
                    position={`${distribution.group}*total`}
                    color="#fdae6b"
                    size={3}
                    shape="smooth"
                    tooltip={[`${distribution.group}*total`, (x, total) => {
                        return {name: "时长", value: `${(total / 60).toFixed(2)}分钟`};
                    }]}
                />
                <Geom
                    type="point"
                    position={`${distribution.group}*total`}
                    color="#fdae6b"
                    size={3}
                    shape="circle"
                />
            </Chart>
        </ChartCard>    
    }

    mkTab() {
        const { distribution } = this.state
        const tagType = distribution.type.split(".")[0];
        const options = distribution.tgMap.find((e) => tagType === e.value);

        let title = undefined;
        const content = distribution.tgMap.map((item, index) => {
            return <Dropdown 
                key={index}
                overlay={<Menu onClick={(e) => this.distributionTypeOnChange(e.key)}>{
                    item.menus.map(menu => {
                        const key = `${item.value}.${menu.value}`
                        if (distribution.type === key) {
                            title = menu.title;
                        }
                        return <Menu.Item key={key}>{menu.title}</Menu.Item>
                    })
                }</Menu>}
            >
                {item.value === tagType ? <Radio.Button value={distribution.type}>{title}</Radio.Button> : <Radio.Button value={`${item.value}.${item.menus[0].value}`}>{item.menus[0].title}</Radio.Button>}
            </Dropdown>
        })

        return <Tabs key="tabs" className="ho-cdr-tab" size={"large"} tabBarExtraContent={
            <div>
            <Radio.Group value={distribution.type} defaultValue="today.this" buttonStyle="solid" onChange={(e) => this.distributionTypeOnChange(e.target.value)}>
                {content}
            </Radio.Group>
            <Select style={{width: 96, marginLeft: '1rem'}} value={distribution.group} defaultValue="hour" onChange={this.distributionGroupOnChange}>
                {options ? options.groups.map(item => {
                    return <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                }) : undefined}
            </Select>
            </div>
        }>
            <Tabs.TabPane tab="话单统计" key="1">
                {this.mkCallDistribution()}
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="时段分析" key="2">
                {this.mkCallDistribution()}
            </Tabs.TabPane> */}
        </Tabs>
    }

    mkTable(){
        let { columns } = this.state;
        const { data, getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = paginationInfo ? paginationInfo.pagination : 0;
    
        // console.log("mkTable", records ? records.length : 0, total);

        return (<HoTable
            className="ho-cdr-table"
            size="small"
            bordered={false}
            columns={columns}
            data={records}
            rowKey={'uuid'}
            loading={getStatus === 1}
            onColumnChange={this.tblColOnChange}
            onDoubleClick={this.tblRowOnClick}
            total={total}
        />);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_CDR_LIST));
    }

    componentDidMount(){
        const { paginationInfo, distribution } = this.state;
        setTimeout(() => {
            dataListCdr(this.props, paginationInfo, this.dataListCdrDataSuccessHd);
            dataDurationCdr(this.props, undefined, this.dataDurationCdrSuccessHd);
            dataDistributionCdr(this.props, distribution.type, distribution.group, distribution.start, distribution.end, this.dataDistributionCdrSuccessHd)
        }, 0)
    }

    render(){

        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const callTotalComponent = this.mkCallTotal();
        const callTodayComponent = this.mkCallToday();
        const callMonthComponent = this.mkCallMonth();
        const callWeekComponent = this.mkCallWeek();
        const tabComponent = this.mkTab();

        return (<div style={{padding: 0}}>
            {breadcrumbComponent}
            <Row key="row1" className="ho-cdr-statistics">
                <Col span={6} style={{paddingRight: '0.5rem'}}>
                    {callTotalComponent}
                </Col>
                <Col span={6} style={{padding: '0 0.5rem'}}>
                    {callTodayComponent}
                </Col>
                <Col span={6} style={{padding: '0 0.5rem'}}>
                    {callWeekComponent}
                </Col>
                <Col span={6} style={{paddingLeft: '0.5rem'}}>
                    {callMonthComponent}
                </Col>
            </Row>
            <Row key="row2" className="ho-cdr-statistics">
                <Col span={24}>
                    <QueueAnim type={['bottom', 'top']} delay={200}>
                        {tabComponent}
                    </QueueAnim>
                </Col>
            </Row>
            <Row key="row3" className="ho-cdr-statistics">
                <QueueAnim type={['bottom', 'top']} delay={300}>
                    <Card
                        key="ho-cdr-table"
                        bordered={false}
                        className="ho-cdr-table"
                        title="明细"
                        extra={<HoRefreshButton onClick={this.mainRefreshOnClick}/>}
                    >
                        {tableComponent}
                    </Card>
                </QueueAnim>
            </Row>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_CDR_LIST), 
    data: listCdrData(state),
});


export default connect(
    mapState, 
    null
)(CdrCT);

