/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';

import { Popconfirm, Button, Modal, Form, Input, Slider, Row, Col, Result } from 'antd';
import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'
import { 
    HoCard, HoBreadcrumb, HoButton, HoNewButton, HoStartButton, HoStopButton, hoMessage,
} from '../../../util/hoComponent';
import NewForm from './newForm';
import { getPracticeConfig, setPracticeConfig, getPracticePipline, setPracticePipline, clearPractice, dataSippAccountRegister, } from './practiceRD';
import { DetailHeader, DetailPipLine } from './detail';
import { Pipline } from './pipline';


class PracticeCT extends Component{
    constructor(props){
        super(props);

        this.update = this.update.bind(this);

        this.state = {
            ...this.initData(),
        }
    }

    initData(){
        return {
            newPageVisible: false,
        };
    }

    update() {
        this.setState({
            flag: !this.state.flag,
        })
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "运维工具", url: "devops/tool"}, {title: "模拟演习"}]}
        />
    }

    mkHeaderInfo(){
        let { dispatch, config, pipline } = this.props;
        let buttons = [
            <Button key="join" type="primary" onClick={(e) => {
                config.title = `模拟任务_${moment().format('YYYY-MM-DD HH:mm:ss')}`
                setPracticeConfig(dispatch, config);
                this.setState({
                    newPageVisible: true,
                })
            }}>模拟入会</Button>
        ];
        if (pipline && pipline.isStart) {
            buttons = [
                <Popconfirm key="end" title="是否确认停止？"
                    okText="是的"
                    onConfirm={(e) => {
                        pipline.stop();
                        this.update();
                    }} 
                    cancelText="我再想想">
                    <Button type="danger" key="stop" icon="stop" >停止</Button>
                </Popconfirm>
            ];
        }

        buttons.unshift(
            <Button key="register" type="primary" style={{marginRight: '1rem'}} onClick={(e) => {
                dataSippAccountRegister(this.props, () => {
                    hoMessage({type: 'success', msg: `操作成功`});
                });
            }}>模拟注册</Button>
        )

        return {title: <span>模拟演习</span>, buttons: buttons}; 
    }

    mkNewModal(){

        let { dispatch, config } = this.props;
        let { newPageVisible } = this.state;

        return <Modal 
            width="50%"
            title="模拟入会配置"
            visible={newPageVisible}
            onCancel={() => {
                this.setState({
                    newPageVisible: false,
                });
            }}
            footer={[
                <HoStartButton key="start" onClick={() => {
                    let pipline = new Pipline(config, this.props)
                    if (pipline.start()) {
                        setPracticePipline(dispatch, pipline);
                        this.setState({
                            newPageVisible: false,
                        });
                    } else {
                        console.error('start pipline failed');
                    }
                }}>开始演习</HoStartButton>,
            ]}
        >
            <NewForm 
                info={config}
                onChange={(info) => {
                    setPracticeConfig(dispatch, info)
                    this.update();
                }}/>
        </Modal>
    }

    mkMain(){
        let { dispatch, config, pipline } = this.props;
        let component = undefined;

        if (pipline) {
            // 已经开始了
            component = <DetailHeader config={config} pipline={pipline}/>
        } else {
            // 还没开始，需要做模拟入会
            component = <Result 
                key="result"
                status="404" 
                title="当前没有演习"
                >
            </Result>
        }
        return component;
    }

    mkPipline(){
        let { config, pipline } = this.props;
        let component = undefined;

        if (pipline) {
            // 已经开始了
            component = <DetailPipLine config={config} pipline={pipline}/>
        }
        return component;
    }

    // clear handle
    componentWillUnmount(){
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidMount(){
        this.interval = setInterval(() => {
            this.update();
        }, 1000);
    }

    render(){
        // 主页面
        let breadcrumbComponent = this.mkBreadcrumb();
        let {title, buttons} = this.mkHeaderInfo();
        let mainComponent = this.mkMain();
        let piplineComponent = this.mkPipline();

        let newComponent = this.mkNewModal();

        return (<div>
            {newComponent}
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {mainComponent}
            </HoCard>
            {piplineComponent}
        </div>);
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    config: getPracticeConfig(state),
    pipline: getPracticePipline(state),
});


export default connect(
    mapState, 
    null
)(PracticeCT);

