/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */

import { netRequestThunk } from '../../util/netReqRD';

const ACT_QUERY_MONITOR = 'ACT_QUERY_MONITOR';
const ACT_CLEAN_MONITOR = 'ACT_CLEAN_MONITOR';


export const ACT_NET_MONITOR_QUERY = 'queryMonitor';

// reducer handle
const monitorState = {
    data: undefined, 
}

export const monitor_reducer = (state=monitorState, action) => {
    switch(action.type){

        case ACT_QUERY_MONITOR:
            return {
                data: action.data,
            };

        case ACT_CLEAN_MONITOR:
            return {
                data: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const monitorData = (state) => {
    return state.monitor !== undefined ? state.monitor.data : undefined;
}


export function actMonitorClear(dispatch) {
    return dispatch({
        type: ACT_CLEAN_MONITOR,
    })
}

export function queryMonitorDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_QUERY_MONITOR, 
        data: rspBody.statistics, 
    })
}

export function dataQueryMonitor(props, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/statistics/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_MONITOR_QUERY, successFun, errorFun));
}





