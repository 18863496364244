/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import * as echarts from 'echarts';
import QueueAnim from 'rc-queue-anim';
import { Progress, Row, Col, Icon, Tooltip, Card } from 'antd';
import { ChartCard } from 'ant-design-pro/lib/Charts';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import Number from '../../component/tweenOneIcon/number';

import { 
    monitorData, queryMonitorDataSuccessHd, dataQueryMonitor,
} from './monitorRD';
import {getLoginUserInfo, getLoginZabbixInfo, loginRequestZabbix} from '../login/loginRD'
import Clock from '../../component/common/clock';
import Map from '../../component/map/map';
import { HoIconFont, hoMessage, hoMessageDestroy, HoLinkButton, HoTag } from '../../util/hoComponent';
import { hoTableInitPaginationInfo, hoTableColumnTagButton,  } from '../../util/tableComponent';
import { screenUnwatchFull, screenWatchFull, screenRequestFull, screenExitFull } from '../../component/common/screenFull';
import { listCdrData, listCdrDataSuccessHd, dataListCdr, dataDurationCdr, dataQueryIp, dataAreaCdr } from '../bill/cdr/cdrRD';
import { dataListMember, dataGetMemberBaseInfo } from '../member/memberRD';
import { 
    dataListProblem, dataListHistory, dataListGroup, dataListHost, dataListItem,
    ZABBIX_HOST_IDS, ZABBIX_CALL_COUNT_IDS, ZABBIX_CONFERENCE_COUNT_IDS, ZABBIX_CUP_IDEL_IDS, ZABBIX_FILESYSTEM_FREE_IDS, ZABBIX_FILESYSTEM_TOTAL_IDS,
    ZABBIX_MEMORY_FREE_IDS, ZABBIX_MEMORY_TOTAL_IDS, ZABBIX_NETWORK_INCOMING, ZABBIX_NETWORK_OUTGOING, 
} from '../devops/zabbix/zabbixRD';
import { getNetWorkDesc, getTimeDesc, getUrlParam } from '../../util/logic';
import { Loading1, Loading2 } from '../../component/loading/loading';
import { switchEnvironment, version } from '../../util/version';
import { Decoration2 } from '../../component/decoration/decoration';

import "./monitor.css";

class MonitorCT extends Component{

    constructor(props){
        super(props);

        this.resize = this.resize.bind(this);
        this.dataListCdrDataSuccessHd = this.dataListCdrDataSuccessHd.bind(this);
        this.dataListServerSuccessHd = this.dataListServerSuccessHd.bind(this);

        this.screenOnChange = this.screenOnChange.bind(this);
        this.titleOnClick = this.titleOnClick.bind(this);
    
        let env = getUrlParam("env", window.location?.search) || window.localStorage?.getItem("environment") || (version.isInternal() ? "internal" : "production");
        switchEnvironment(env)

        this.state = {
            ...this.resize(true, false),
            isFullScreen: false,
            timerInterval: 15 * 1000,
            timerInterval2: 5 * 60 * 1000,

            // 话单
            cdrPaginationInfo: hoTableInitPaginationInfo({pagination: {page: {size: 5, number: 1}}}),
            
            // 设备
            deviceTotal: 0,

            // 告警
            dataWarn: {},

            // 服务器负载
            dataServerLoad: {},

            // 通话数趋势
            dataCall: {},
            dataCallDuration: {},
            // 会议数趋势
            dataConference: {},
        }
    }

    constructBarData(records, listMax = 120) {
        let list = [];
        let x = [];
        let data = []
        let lastHourMax = 0;
        let lastHourMaxTime = "";
        let todayMax = 0;
        let todayMaxTime = "";
        let last = 0;
        if (records) {
            records.forEach((item, index) => {
                let value = parseInt(item.value);
    
                if (index === 0) {
                    last = value;
                }

                if (index < listMax){
                    list.push([
                        moment(item.clock, "X").format('YYYY-MM-DD HH:mm:ss'),
                        value,
                    ])
                    // x.push(moment(item.clock, "X").format('YYYY-MM-DD HH:mm:ss'))
                    // data.push(value)
                }
    
                if (index < listMax && value > lastHourMax) {
                    lastHourMax = value;
                    lastHourMaxTime = moment(item.clock, "X").format('YYYY-MM-DD HH:mm:ss')
                }
    
                if (value > todayMax) {
                    todayMax = value;
                    todayMaxTime = moment(item.clock, "X").format('YYYY-MM-DD HH:mm:ss')
                }
            })
        }
    
        return {
            list: list.reverse(),
            x: x.reverse(),
            data: data.reverse(),
            lastHourMax: lastHourMax,
            lastHourMaxTime: lastHourMaxTime, 
            todayMax: todayMax,
            todayMaxTime: todayMaxTime,
            last: last,
        }
    }

    resize(isGet = false, isFullScreen = false) {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        let resolution = 0;
        if (width === 1440) {
            resolution = 1;
        } else if (Math.abs(width * 9 - height * 16) < 200 || width > 1900) {
            resolution = 2; // 16：9
        }
        let ret = {
            height: height,
            width: width,
            resolution: resolution,
            baseHeight: resolution === 2 ? (isFullScreen ? 330 : 256) : 230
        };

        if (!isGet) {
            this.setState(ret)
        } else {
            return ret;
        }
    }

    dataListCdrDataSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        function queryIp(rsp) {
            let ips = [];
            rsp.pagination.records.forEach((item) => {
                if (item.ip && item.ip.length > 0) {
                    ips.push(item.ip)
                }
            })
            ips = Array.from(new Set(ips))
            if (ips && ips.length > 0) {
                dataQueryIp(that.props, ips, (d, rsp2, req) => {
                    let records = []
                    rsp.pagination.records.forEach(item => {
                        let index = rsp2.records ? rsp2.records.findIndex((e) => {
                            return e.queryIp === item.ip
                        }) : -1;
                        let addr = item.ip;
                        if (index !== -1) {
                            let target = rsp2.records[index];
                            let addrs = [];
                            if (target.country !== 'XX') {
                                addrs.push(target.country);
                            }
                            if (target.region !== 'XX') {
                                addrs.push(target.region);
                            }
                            if (target.city !== 'XX') {
                                addrs.push(target.city);
                            }
                            addr = addrs.join('-')
                        }
                        records.push({
                            ...item,
                            addr: addr,
                        })
                    })
                    rsp.pagination.records = records;
                    listCdrDataSuccessHd(dispatch, rsp, reqBody)
                }, () => {
                    listCdrDataSuccessHd(dispatch, rsp, reqBody)
                });
            } else {
                listCdrDataSuccessHd(dispatch, rsp, reqBody)
            }
        }

        let sipNums = rspBody.pagination.records.map(item => {
            return item.direction === "outbound" ? item.calleeNumber : item.callerNumber;
        })
        // 去重
        sipNums = Array.from(new Set(sipNums))
        if (sipNums && sipNums.length > 0) {
            dataGetMemberBaseInfo(this.props, sipNums, (d, rsp, req) => {
                let records = []
                rspBody.pagination.records.forEach(item => {
                    let index = rsp.BaseInfoList ? rsp.BaseInfoList.findIndex((e) => {
                        return e.SipNum === (item.direction === "outbound" ? item.calleeNumber : item.callerNumber)
                    }) : -1;
                    if (index !== -1) {
                        records.push({
                            ...item,
                            nickName: rsp.BaseInfoList[index].NickName
                        })
                    } else {
                        records.push({
                            ...item,
                            nickName: "(无)"
                        })
                    }
                })
                rspBody.pagination.records = records;
                queryIp(rspBody)
            }, () => {
                queryIp(rspBody)
            })
        } else {
            queryIp(rspBody)
        }
    }

    dataListServerSuccessHd(dispatch, rspBody, reqBody) {
        // console.log(reqBody.params.itemids, rspBody.result[0].value)
        if (!rspBody.result || !rspBody.result[0]) {
            return true;
        }
        let itemid = reqBody.params.itemids;
        let value = rspBody.result[0].value;
        let dataServerLoad = this.state.dataServerLoad;
        switch(itemid){
            case ZABBIX_CUP_IDEL_IDS:
            case ZABBIX_NETWORK_INCOMING:
            case ZABBIX_NETWORK_OUTGOING:
                dataServerLoad[itemid] = value
                break;
            case ZABBIX_FILESYSTEM_TOTAL_IDS:
            case ZABBIX_FILESYSTEM_FREE_IDS:
            case ZABBIX_MEMORY_TOTAL_IDS:
            case ZABBIX_MEMORY_FREE_IDS:
                dataServerLoad[itemid] = value / (1024 * 1024 * 1024);
                break;
            default:
                break;
        }
        this.setState({
            dataServerLoad: dataServerLoad,
        })
    }

    chartShowLoading(chart) {
        // let opt = {
        //     text: '加载中...',
        //     color: '#c23531',
        //     textColor: '#fff',
        //     maskColor: 'rgba(255, 255, 255, 0)'
        // }
        // chart.showLoading('default', opt);
    }

    chartHideLoading(chart) {
        // chart.hideLoading();
    }

    initDeviceChart() {
        let chartDevice = echarts.init(document.getElementById('monitor-current-device'), null);
        this.chartShowLoading(chartDevice);
        let textStyle = {
            rich:{
                a: {
                    color: 'rgba(253,216,69)',
                    padding: [0, 10, 0, 0]
                },
                b: {
                    color: 'rgba(0,130,252)',
                    padding: [0, 10, 0, 0]
                },
                c: {
                    color: 'rgba(244,122,117)',
                    padding: [0, 10, 0, 0]
                },
                d: {
                    color: 'rgba(0,157,178)',
                    padding: [0, 10, 0, 0]
                }
            }
        };
        let option = {
            color: ['rgba(253,216,69, 0.6875)', 'rgba(0,130,252, 0.6875)', 'rgba(244,122,117, 0.6875)'],
            legend: {
                // orient: 'vertical',
                inactiveColor: '#d9d9d9',
                bottom: 0,
                textStyle: textStyle,
                data: [{name: '入会', icon: 'circle'}, {name: '空闲', icon: 'circle'}, {name: '离线', icon: 'circle'}, {name: '全部', icon: 'circle'}]
            },
            series: [{
                type: 'pie',
                radius: ['0px', '60px'],
                center: ['50%', '50%'],
                label: {
                    show: true,
                    ...textStyle,
                    position: 'outer',
                    alignTo: 'labelLine',
                    bleedMargin: 5,
                    formatter: '{b} {c} ({d}%)',
                    color: 'inherit',
                },
                labelLine: {
                    show: true
                },
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.6875)'
                    }
                }
            },{
                type: 'pie',
                radius: '0px',
                center: ['50%', '50%'],
                itemStyle: {
                    color: 'rgba(0,157,178,0.6875)',
                },
                label: {
                    show: false,
                    position: 'center',
                    color: 'inherit',
                },
                data: [],
            }]
        };
        chartDevice.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartDevice.resize();
            })
        });

        this.setState({
            chartDevice: chartDevice,
        })

        return chartDevice;
    }

    initCallChart() {
        let chartCall = echarts.init(document.getElementById('monitor-current-call'), null, {renderer: 'svg'});
        this.chartShowLoading(chartCall);
        let option = {
            tooltip:{
                trigger: 'axis',
                formatter: function(params){
                    if (params[0] && params[0].value) {
                        return `${params[0].value[0]}<br/>入会设备数: ${params[0].value[1]}个`
                    }
                },
                backgroundColor: 'rgba(255,255,255,0.85)',
                textStyle: {
                    fontSize: 12,
                    color: '#595959',
                },
                // extraCssText: 'box-shadow: 0 0 10px rgba(255, 255, 255, 0.65);'
            },
            animation: false,
            grid: {
                top: '16px',
                left: 0,
                bottom: 0,
                right: 0,
            },
            xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [{
                name: 'amount',
                showSymbol : false,
                smooth: 0.6,
                type: 'line',
                itemStyle: {
                    color: 'rgba(0,130,252, 0.85)',
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgb(0,130,252, 0.6875)'
                        }, {
                            offset: 1, color: 'rgb(0,130,252, 0)'
                        }],
                        global: false
                    }
                },
                data: [],
            }]
        };

        chartCall.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartCall.resize();
            })
        });

        this.setState({
            chartCall: chartCall,
        })

        return chartCall;
    }

    initConferenceChart() {
        let chartConference = echarts.init(document.getElementById('monitor-current-conference'), null, {renderer: 'svg'});
        this.chartShowLoading(chartConference);
        let option = {
            tooltip:{
                trigger: 'axis',
                formatter: function(params){
                    if (params[0] && params[0].value) {
                        return `${params[0].value[0]}<br/>召集会议数: ${params[0].value[1]}个`
                    }
                },
                backgroundColor: 'rgba(255,255,255,0.85)',
                textStyle: {
                    fontSize: 12,
                    color: '#595959',
                },
                // extraCssText: 'box-shadow: 0 0 10px rgba(255, 255, 255, 0.65);'
            },
            animation: false,
            grid: {
                top: '16px',
                left: 0,
                bottom: 0,
                right: 0,
            },
            xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [{
                showSymbol : false,
                smooth: 0.6,
                type: 'line',
                itemStyle: {
                    color: 'rgba(0,130,252, 0.85)',
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgb(0,130,252, 0.6875)'
                        }, {
                            offset: 1, color: 'rgb(0,130,252, 0)'
                        }],
                        global: false
                    }
                },
                data: [],
            }]
        };

        chartConference.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartConference.resize();
            })
        });

        this.setState({
            chartConference: chartConference,
        })

        return chartConference;
    }

    initChart() {
        this.initDeviceChart();
        this.initCallChart();
        this.initConferenceChart();
    }

    monitorOnTimer() {
        let that = this;
        let { dispatch, zabbixUserInfo } = this.props;
        let { cdrPaginationInfo } = this.state;

        function dataZabbix() {
            // 获取告警列表
            dataListProblem(that.props, {hostids: ZABBIX_HOST_IDS}, (dispatch, rsp, req) => {
                that.setState({
                    dataWarn: {
                        list: rsp.result ? rsp.result.slice(0, 5) : [],
                    }
                })
            }, () => {return true;})
            // 获取呼叫趋势
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_CALL_COUNT_IDS, history: 3, limit: 2880, }, (dispatch, rsp, req) => {
                let { chartCall } = that.state;
                let data = that.constructBarData(rsp.result)
                that.setState({
                    dataCall: data
                }, () => {
                    if (chartCall) {
                        that.chartHideLoading(chartCall);
                        let option = chartCall.getOption();
                        option.series[0].data = data.list;
                        chartCall.clear();
                        chartCall.setOption(option);
                    }
                })
                
            }, () => {return true;})
            // 获取会议趋势
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_CONFERENCE_COUNT_IDS, history: 3, limit: 2880, }, (dispatch, rsp, req) => {
                let { chartConference } = that.state;
                let data = that.constructBarData(rsp.result)
                that.setState({
                    dataConference: data
                }, () => {
                    if (chartConference) {
                        that.chartHideLoading(chartConference);
                        let option = chartConference.getOption();
                        option.series[0].data = data.list;
                        chartConference.clear();
                        chartConference.setOption(option, true)
                    }
                })
            }, () => {return true;})
            // 获取CPU空闲数
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_CUP_IDEL_IDS, history: 0, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取磁盘容量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_FILESYSTEM_TOTAL_IDS, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取磁盘剩余量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_FILESYSTEM_FREE_IDS, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取内存容量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_MEMORY_TOTAL_IDS, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取内存剩余量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_MEMORY_FREE_IDS, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取网卡下行量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_NETWORK_INCOMING, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
            // 获取网卡上行量
            dataListHistory(that.props, {hostids: ZABBIX_HOST_IDS, itemids: ZABBIX_NETWORK_OUTGOING, history: 3, limit: 1, }, that.dataListServerSuccessHd, () => {return true;})
        }

        // 获取话单
        dataListCdr(this.props, cdrPaginationInfo, this.dataListCdrDataSuccessHd, () => {return true});
        // 获取全局累计通话量
        dataDurationCdr(this.props, 'total', (dispatch, rsp, req) => {
            that.setState({
                dataCallDuration: rsp.duration
            })
        }, () => {return true;});
        // 获取通话量按城市分组按时间统计
        dataAreaCdr(this.props, 'month.this', 'city', (dispatch, rsp, req) => {
            that.setState({
                dataCallArea: rsp.records
            })
            if (that.mapDOM) {
                that.mapDOM.updateData(rsp.records, 'city', 'billsec', `${moment().startOf('month').format('MM月')}`)
            }
        }, () => {return true;});
        // 获取呼叫，注册等统计
        dataQueryMonitor(this.props, (dispatch, rsp, req) => {
            let { chartDevice, deviceTotal } = that.state;
            if (chartDevice) {
                let option = chartDevice.getOption();
                let data = rsp.statistics;
                if (deviceTotal <= 0) {
                    deviceTotal = data ? data.registration.count : 0;
                }
                let join = data ? data.call.count : 0;
                let free = data ? data.registration.count - data.call.count : 0;
                let offline = data ? (deviceTotal - data.registration.count > 0 ? deviceTotal - data.registration.count : 0) : 0;
                option.legend[0].formatter = function(name){
                    switch(name){
                        case '入会': return `{a|${name}}`;
                        case '空闲': return `{b|${name}}`;
                        case '离线': return `{c|${name}}`;
                        case '全部': return `{d|${name}} {d|${deviceTotal}}`;
                        default: return ``;
                    }
                }
                option.series[0].data = [
                    {value: join, name: '入会'},
                    {value: free, name: '空闲'},
                    {value: offline, name: '离线'},
                ]
                option.series[1].data = [
                    {value: deviceTotal, name: '全部'},
                ] 
                that.chartHideLoading(chartDevice);
                chartDevice.setOption(option)
            }
            queryMonitorDataSuccessHd(dispatch, rsp, req)
        }, () => {return true});
        // 获取设备用户总数
        dataListMember(this.props, {pagination:{page:{size: 1, number: 1}}}, (dispatch, rspBody, reqBody) => {
            this.setState({deviceTotal: rspBody.TotalNum || 0})
        })
        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {
                dataZabbix()
            }))
        } else {
            dataZabbix()
        }
    }

    monitorOnTimer2() {

    }

    genData(x = 100, offset = 10) {
        let data = [];
        let beginDay = new Date().getTime();
        for (let i = 24; i > 0; i -= 1) {
            data.push({
                x: moment(new Date(beginDay - 1000 * 60 * 60 * i)).format('YYYY-MM-DD HH:00'),
                y: Math.floor(Math.random() * x) + offset,
            });
        }
        return data;
    }

    screenOnChange(e) {
        // console.log(e);
        let fullScreen = !this.state.isFullScreen
        this.setState({
            isFullScreen: fullScreen,
        });
        this.resize(false, fullScreen);
        hoMessageDestroy();
    }

    goToMenu(key) {
        let path = '/conference/' + key;
        window.open(path, "_blank");
    }

    titleOnClick(e, param) {
        // console.log("titleOnClick ", param);
        switch(param) {
            case "trend.call":
                break;
            case "trend.conference":
                break;
            case "statistics.conference.total":
                break;
            case "statistics.call.duration":
                this.goToMenu('bill/cdr')
                break;
            case "statistics.call.count":
                this.goToMenu('member/device')
                break;
            case "server":
            case "server.cpu":
            case "server.memory":
            case "server.disk":
            case "server.bandwidth":
                this.goToMenu('devops/analysis/server')
                break;
            case "list.cdr":
                this.goToMenu('bill/cdr')
                break;
            case "list.warn":
                this.goToMenu('devops/tool/warn')
                break;
            default:
                break
        }
    }

    mkCard(content = undefined, height = '256px', backgroud = true) {
        return <div style={{ margin: "0.5rem 0.5rem 0 0.5rem"}}>
            <div className={backgroud ? "horn" : ""} style={{height: height}}>
                {content}
                {backgroud ? <div className="lt"></div> : undefined}
                {backgroud ? <div className="rt"></div> : undefined}
                {backgroud ? <div className="rb"></div> : undefined}
                {backgroud ? <div className="lb"></div> : undefined}
            </div>
        </div>;

        // return <div style={{ margin: "0.5rem 0.5rem 0 0.5rem"}}>
        //     <BorderBox12 className="monitor-border" style={{height: height}}>
        //         {content}
        //     </BorderBox12>
        // </div>;
    }

    mkHeader() {
        let {isFullScreen, height, width, debug} = this.state;
        let icon = <Tooltip title={isFullScreen ? "退出全屏": "全屏"} placement="bottomLeft">
            <HoIconFont type={isFullScreen ? "icon-zoom-out" : "icon-zoom-in"} style={{marginLeft: '0.5rem'}} onClick={() => {
                if (isFullScreen) {
                    screenExitFull(isFullScreen);
                } else {
                    screenRequestFull(isFullScreen);
                }
            }}/>
        </Tooltip>
        return <Row type="flex" justify="center" align="middle" className="monitor-header">
            <Col span={4}>
                {debug ? <div style={{color: '#FFF'}}>{width} * {height}</div> : undefined}
            </Col>
            <Col span={16} className="title">
                <div className="monitor-d2"/>
                <span style={{textAlign: 'center'}}>云会议管理系统数据监控</span>
            </Col>
            <Col span={4}>
                <div style={{textAlign: 'right', margin: '0 0.5rem 0 0'}}>
                    <Clock className="monitor-clock" onDoubleClick={() => this.setState({debug: !this.state.debug})}/>
                    {icon}
                </div>
            </Col>
        </Row>;
    }

    mkMain() {
        let { height, dataCallDuration } = this.state;
        let { data } = this.props;
        let content = <div className="header">
            <Row justify="center" type="flex" align="middle">
                <Col span={12}>
                    <NumberInfo
                        className="left"
                        subTitle={<span style={{color: '#FFF'}}>当前会议</span>}
                        total={<span className="monitor-main-number" onClick={(e) => this.titleOnClick(e, 'statistics.conference.total')}>
                            <Number className="number" defaultValue={0} value={(data && data.conference.count) || 0}/>
                            <span className="unit">个</span>
                        </span>}
                    />
                </Col>
                <Col span={12}>
                    <NumberInfo
                        className="right"
                        subTitle={<span style={{color: '#FFF'}}>累计通话</span>}
                        total={<span className="monitor-main-number" onClick={(e) => this.titleOnClick(e, 'statistics.call.duration')}>
                            <Number className="number" defaultValue={0} value={(dataCallDuration.total && (dataCallDuration.total / 60).toFixed(0)) || 0}/>
                            <span className="unit">分钟</span>
                        </span>}
                    />
                </Col>
            </Row>
        </div>
        let bodyHeight = height - (16 + 62 + 120 + 32 + 16);
        let body = <div className="body">
            <Map ref={(element) => this.mapDOM = element} className="monitor-map" style={{height: bodyHeight, width: '95%'}}/>
        </div>
        return <Card className="monitor-main">
            {this.mkCard(content, '120px')}
            {this.mkCard(body, bodyHeight, false)}
        </Card>
    }

    mkServerLoad() {
        let { dataServerLoad, height, baseHeight } = this.state;

        let fHeight = Math.min(height / 3, baseHeight);

        let cpu = (100 - dataServerLoad[ZABBIX_CUP_IDEL_IDS]).toFixed(2);
        let cpuPercent = parseFloat(cpu);

        let memory = (dataServerLoad[ZABBIX_MEMORY_TOTAL_IDS] - dataServerLoad[ZABBIX_MEMORY_FREE_IDS]).toFixed(2);
        let memoryPercent = parseFloat((memory / dataServerLoad[ZABBIX_MEMORY_TOTAL_IDS] * 100).toFixed(2));

        let disk = (dataServerLoad[ZABBIX_FILESYSTEM_TOTAL_IDS] - dataServerLoad[ZABBIX_FILESYSTEM_FREE_IDS]).toFixed(2);
        let diskPercent = parseFloat((disk / dataServerLoad[ZABBIX_FILESYSTEM_TOTAL_IDS] * 100).toFixed(2));

        let netIncomingDisplay = getNetWorkDesc(dataServerLoad[ZABBIX_NETWORK_INCOMING]);
        let netOutgoingDisplay = getNetWorkDesc(dataServerLoad[ZABBIX_NETWORK_OUTGOING]);

        let content = <ChartCard
            className="monitor-current-server"
            title={<span className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'server')}><Loading1/><span>服务器负载</span></span>}
            contentHeight={fHeight}
        >
            <div className="container" style={{height: fHeight - 60}}>
                <div className="item">
                    <Row style={{color: '#FFF'}} type="flex" justify="space-between" align="bottom">
                        <Col span={4}> 
                            <Tooltip overlayClassName="monitor-tooltip" title={`警戒值80%，当前使用 ${cpuPercent}%`}>
                                <span className="monitor-link-title" onClick={(e) => this.titleOnClick(e, 'server.cpu')}><HoIconFont type="icon-cpu" style={{marginRight: '0.5rem'}}/>CPU</span>
                            </Tooltip>
                        </Col>
                        <Col span={16}>
                            <Progress className="monitor-progress" strokeColor={'#fdd845af'} strokeWidth={12} strokeLinecap="square" percent={cpuPercent} />
                        </Col>
                        <Col span={4} style={{color:'#fdd845', textAlign: "end"}}>
                            <Number style={{display: 'inline'}} defaultValue={0} value={cpu} floatLength={2}/>%
                        </Col>
                    </Row>
                </div>
                <div className="item">
                    <Row style={{color: '#FFF'}} type="flex" justify="space-between" align="bottom">
                        <Col span={4}>
                            <Tooltip overlayClassName="monitor-tooltip" title={`警戒值80%，当前使用 ${memoryPercent}%`}>
                            <span className="monitor-link-title" onClick={(e) => this.titleOnClick(e, 'server.memory')}><HoIconFont type="icon-memory" style={{marginRight: '0.5rem'}}/>内存</span>
                            </Tooltip>
                        </Col>
                        <Col span={16}>
                            <Progress className="monitor-progress" strokeColor={'#0082fcaf'} strokeWidth={12} strokeLinecap="square" percent={memoryPercent} />
                        </Col>
                        <Col span={4} style={{color:'#0082fc', textAlign: "end"}}>
                            <Number style={{display: 'inline'}} defaultValue={0} value={memory} floatLength={2}/>G
                        </Col>
                    </Row>
                </div>
                <div className="item">
                    <Row style={{color: '#FFF'}} type="flex" justify="space-between" align="bottom">
                        <Col span={4}>
                            <Tooltip overlayClassName="monitor-tooltip" title={`警戒值80%，当前使用 ${diskPercent}%`}>
                            <span className="monitor-link-title" onClick={(e) => this.titleOnClick(e, 'server.disk')}><HoIconFont type="icon-disk" style={{marginRight: '0.5rem'}}/>磁盘</span>
                            </Tooltip>
                        </Col>
                        <Col span={16}>
                            <Progress className="monitor-progress" strokeColor={'#009db2af'} strokeWidth={12} strokeLinecap="square" percent={diskPercent} />
                        </Col>
                        <Col span={4} style={{color:'#009db2', textAlign: "end"}}>
                            <Number style={{display: 'inline'}} defaultValue={0} value={disk} floatLength={2}/>G
                        </Col>
                    </Row>
                </div>
                <div className="item">
                    <Row style={{color: '#FFF'}} type="flex" justify="space-between" align="bottom">
                        <Col span={6}>
                            <span className="monitor-link-title" onClick={(e) => this.titleOnClick(e, 'server.bandwidth')}><HoIconFont type="icon-bandwidth" style={{marginRight: '0.5rem'}}/>网络</span>
                        </Col>
                        <Col span={18} style={{textAlign: "end"}}>
                            <div className="monitor-bandwidth-down">
                                <span>{netIncomingDisplay}</span>
                                <HoIconFont className="icon" type="icon-bandwidth-down"/>
                            </div>
                            <div className="monitor-bandwidth-up">
                                <span>{netOutgoingDisplay}</span>
                                <HoIconFont className="icon" type="icon-bandwidth-up"/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </ChartCard>

        return this.mkCard(content, Math.floor(fHeight))
    }

    mkDevice() {
        let { height, baseHeight } = this.state;
        let fHeight = Math.min(height / 3, baseHeight);
        fHeight = Math.floor(height - (16 + 62 + fHeight * 2 + 8 + 32 + 16));
        let content = <ChartCard
            className="monitor-current-device"
            title={<span className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'statistics.call.count')}><Loading1/><span>设备统计</span></span>}
            contentHeight={fHeight}
        >
            <div id="monitor-current-device" style={{height: fHeight - 60, width: '100%'}}/>
        </ChartCard>

        return this.mkCard(content, fHeight);
    }

    mkConference() {
        let { dataConference, height, baseHeight } = this.state;
        let fHeight = Math.min(height / 3, baseHeight)
        let content = <ChartCard
            className="monitor-current-conference"
            title={<Row className="monitor-decoration-title" justify="space-between" type="flex">
                <Col span={12}>
                    <span className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'trend.conference')}><Loading1/><span>召集会议趋势</span></span>
                </Col>
                <Col span={12}>
                    <Decoration2 className="decoration"/>
                </Col>
            </Row>}
            total={<span style={{color: '#FFF'}}><Number defaultValue={0} value={dataConference ? dataConference.last : 0}/></span>}
            footer={<div>
                <div className="monitor-field"><span className="label">最近一小时峰值</span><span className="value">{dataConference.lastHourMax || 0}</span></div>
                <div className="monitor-field"><span className="label">今日峰值</span><span className="value">{dataConference.todayMax || 0}</span></div>
            </div>}
            contentHeight={fHeight - 160}
        >
            <div id="monitor-current-conference" style={{height: fHeight - 160, width: '100%'}}/>
        </ChartCard>

        return this.mkCard(content, Math.floor(fHeight))
    }

    mkCall() {
        let { dataCall, height, baseHeight } = this.state;
        let fHeight = Math.min(height / 3, baseHeight)
        let content = <ChartCard
            className="monitor-current-call"
            title={<Row className="monitor-decoration-title" justify="space-between" type="flex">
                <Col span={12}>
                    <span className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'trend.call')}><Loading1/><span>设备入会趋势</span></span>
                </Col>
                <Col span={12}>
                    <Decoration2 className="decoration"/>
                </Col>
            </Row>}
            total={<span style={{color: '#FFF'}}><Number defaultValue={0} value={dataCall ? dataCall.last : 0}/></span>}
            footer={<div>
                <div className="monitor-field"><span className="label">最近一小时峰值</span><span className="value">{dataCall.lastHourMax || 0}</span></div>
                <div className="monitor-field"><span className="label">今日峰值</span><span className="value">{dataCall.todayMax || 0}</span></div>
            </div>}
            contentHeight={fHeight - 160}
        >
            <div id="monitor-current-call" style={{height: fHeight - 160, width: '100%'}}/>
        </ChartCard>

        return this.mkCard(content, Math.floor(fHeight))
    }

    mkCdr() {
        let { height, baseHeight } = this.state;
        let { cdrData } = this.props;
        let { records } = cdrData || {};

        let fHeight = Math.min(height / 3, baseHeight);

        let content = <Card className="monitor-current-cdr monitor-table" title={<span className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'list.cdr')}><Loading1/><span>话单</span></span>}>
            <div className="thead" key="a">
                <ul className="trow">
                    <li className="titem1">来源</li>
                    <li className="titem2">结算时间</li>
                    <li className="titem3">通话时长</li>
                </ul>
            </div>
            <div className="tbody" key="b">
                <QueueAnim component="ul" type={['right', 'left']} leaveReverse>
                    {
                        records ? records.map((item, index) => {
                            return <div key={item.uuid} className={(index % 2) ? 'trow monitor-table-row-2' : 'trow monitor-table-row-1'} onClick={(e)=>{this.goToMenu('bill/cdr')}}>
                                <li className="titem1">{item.addr}</li>
                                <li className="titem2">{item.endTime}</li>
                                <li className="titem3">{getTimeDesc(item.billsec)}</li>
                            </div>
                        }) : null}
                </QueueAnim>
            </div>
        </Card>
      

        return this.mkCard(content, Math.floor(height - (16 + 62 + fHeight * 2 + 8 + 32 + 16)))
    }

    mkWarn() {
        let { dataWarn, height, baseHeight } = this.state;

        // console.log("mkTable", records);
        let fHeight = Math.min(height / 3, baseHeight);
        let warnFlash = undefined;
        if (dataWarn.list) {
            for(let item of dataWarn.list) {
                if (item.r_clock === "0") {
                    warnFlash = <div className="warn-container monitor-link-title monitor-title">
                        <div className="warn-pulse warn-pulse-fix"/>
                        <div className="warn-pulse-big warn-pulse-big-fix"/>
                    </div>
                    break;
                }
            }
        }

        function level(record) {
            let tags = [];
            switch(record.severity){
                case '0':
                   tags.push({color: '#024b517f', tag:'未分类'});
                   break;
                case '1':
                   tags.push({color: '#22ed7c7f', tag:'信息'});
                   break;
                case '2':
                    tags.push({color: '#1d27c97f', tag:'告警'});
                    break;
                case '3':
                    tags.push({color: '#f9e2647f', tag:'一般严重'});
                    break;
                case '4':
                    tags.push({color: '#fdd8457f', tag:'严重'});
                    break;
                case '5':
                    tags.push({color: '#f5222d7f', tag:'灾难'});
                    break;
               
               default:
                   break;
           }
           
           return hoTableColumnTagButton(tags, record);
        }

        function state(record) {
            if (record.r_clock === "0") {
                return <span className="warn-state-norecorvery">未恢复</span>;
            } else {
                return <span className="warn-state-done">已解决</span>;
            }
        }

        let head = undefined;
        if (dataWarn.list && dataWarn.list.length > 0) {
            head = <div className="thead" key="a">
                <ul className="trow">
                    <li className="titem1">等级</li>
                    <li className="titem2">状态</li>
                    <li className="titem3">告警时间</li>
                    <li className="titem4">描述</li>
                </ul>
            </div>
        }

        let body = undefined;
        if (dataWarn.list && dataWarn.list.length > 0) {
            let rows = dataWarn.list.map((item, index) => {
                return <div key={index} className={(index % 2) ? 'trow monitor-table-row-2' : 'trow monitor-table-row-1'} onClick={(e)=>{this.goToMenu('devops/tool/warn')}}>
                    <li className="titem1">{level(item)}</li>
                    <li className="titem2">{state(item)}</li>
                    <li className="titem3">{moment(parseInt(item.clock) * 1000).format("YYYY-MM-DD HH:mm:ss")}</li>
                    <li className="titem4"><Tooltip overlayClassName="monitor-tooltip" placement="bottomRight" title={item.name}>{item.name}</Tooltip></li>
                </div>
            })
            body = <div className="tbody" key="b">
                <QueueAnim component="ul" type={['right', 'left']} leaveReverse>
                    {rows}
                </QueueAnim>
            </div>
        } else {
            body = <div className="container" style={{height: fHeight - 60}}>
                <div className="radar-wrapper">
                    <div className="radar">
                        {/* <span className="tag">A</span> */}
                        <div className="scanner"/>
                    </div>
                    <div className="legend">
                        <ul>
                            <li className="row-score">
                                <span className="tag">A</span>
                                <div className="bg"/>
                            </li>
                            <li className="row"><span className="title monitor-link-title" onClick={() => this.goToMenu('devops/analysis/server')}>服务器负载</span><HoTag className="tag" color="#22ed7caf">正常</HoTag></li>
                            <li className="row"><span className="title monitor-link-title" onClick={() => this.goToMenu('devops/tool/warn')}>告警</span><HoTag className="tag" color="#22ed7caf">正常</HoTag></li>
                            <li className="row"><span className="title monitor-link-title" onClick={() => this.goToMenu('devops/monitor/topology')}>组网拓扑</span><HoTag className="tag" color="#22ed7caf">正常</HoTag></li>
                            <li className="row"><span className="title monitor-link-title" onClick={() => this.goToMenu('devops/tool/blacklist')}>防火墙</span><HoTag className="tag" color="#22ed7caf">正常</HoTag></li>
                        </ul>
                    </div>
                    {/* <div className="description">实时扫描中...</div> */}
                </div>
            </div>
        }

        let content = <Card className="monitor-current-warn monitor-table" 
            title={<div className="monitor-link-title monitor-title" onClick={(e) => this.titleOnClick(e, 'list.warn')}><Loading1/><span>系统监控</span></div>}>
            {warnFlash}
            {head}
            {body}
        </Card>;

        return this.mkCard(content, Math.floor(fHeight))
    }

    mkGlobalWarn() {
        let { dataWarn } = this.state;
        let warnFlash = undefined;
        if (dataWarn.list) {
            for(let item of dataWarn.list) {
                if (item.r_clock === "0") {
                    warnFlash = <div className="warn-breathe"/>
                    break;
                }
            }
        }

        return warnFlash;
    }

    // clear handle
    componentWillUnmount(){
        screenUnwatchFull(this.screenOnChange);
        clearInterval(this.timer);
    }

    componentDidMount(){
        let { timerInterval, timerInterval2 } = this.state;
        this.initChart();

        screenWatchFull(this.screenOnChange);
        this.monitorOnTimer();
        this.monitorOnTimer2();

        // 定时刷新数据
        this.timer = setInterval(() => {this.monitorOnTimer()}, timerInterval);
        this.timer = setInterval(() => {this.monitorOnTimer2()}, timerInterval2);
        setTimeout(() => {
            if (!this.state.isFullScreen) {
                hoMessage({
                    type: "info", 
                    msg: <span>
                        <span>点击全屏以获得更好效果</span>
                        <HoLinkButton onClick={() => {
                            hoMessageDestroy();
                            screenRequestFull(this.state.isFullScreen);
                        }}>全屏</HoLinkButton>
                    </span>, 
                    duration: 10,
                })
            }
        }, 3000)
    }

    render(){
        let { resolution } = this.state;

        let foctor1 = (resolution === 1 && 8) || (resolution === 2 && 6) || 8;
        let foctor2 = (resolution === 1 && 8) || (resolution === 2 && 12) || 8;
        let foctor3 = (resolution === 1 && 8) || (resolution === 2 && 6) || 8;

        let headerComponent = this.mkHeader();
        let cdrComponent = this.mkCdr();
        let callComponent = this.mkCall();
        let conferenceComponent = this.mkConference();
        let mainComponent = this.mkMain();
        let serverLoadComponent = this.mkServerLoad();
        let deviceCallComponent = this.mkDevice();
        let warnComponent = this.mkWarn();
        let globalWarnComponent = this.mkGlobalWarn();

        return <div className="monitor">
            {globalWarnComponent}
            <div className="monitor-component">
                {headerComponent}
                <Row className="monitor-body">
                    <Col span={foctor1}>
                        {callComponent}
                        {conferenceComponent}
                        {cdrComponent}
                    </Col>
                    <Col span={foctor2}>
                        {mainComponent}
                    </Col>
                    <Col span={foctor3}>
                        {warnComponent}
                        {serverLoadComponent}
                        {deviceCallComponent}
                    </Col>
                </Row>
                {/* <Row className="monitor-body">
                    <Col span={12}>
                        {cdrComponent}
                    </Col>
                    <Col span={12}>
                        {warnComponent}
                    </Col>
                </Row> */}
            </div>
        </div>;
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    zabbixUserInfo: getLoginZabbixInfo(state),

    data: monitorData(state),
    cdrData: listCdrData(state),
});


export default connect(
    mapState, 
    null
)(MonitorCT);

