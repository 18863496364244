/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getIconSrc } from '../resource/resource';

class Logo extends Component {
    constructor(props){
        super(props)
    }

    render(){
        let titleComponent = undefined;
        const titleClassName = this.props.theme === 'dark' ? 'ho-title-dark' : 'ho-title-light';
        const logClassName = this.props.theme === 'dark' ? 'ho-title-log-dark' : 'ho-title-log-light';

        if (this.props.collapsed){
            titleComponent = <div className={logClassName} onClick={() => {
                window.goToMenu('/')
            }}>
                <img style={{display: 'inlineBlock',height: '32px',}} alt="" src={getIconSrc('logo')} ></img>
            </div> 
        }else{
            titleComponent = <p className={titleClassName} >云会议管理系统</p>
        }
        return titleComponent;
    }
}

export default connect(
    null, 
    null
)(Logo);