/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';

import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import CpuCT from './cpuCT';
import MemoryCT from './memoryCT';
import DiskCT from './diskCT';
import NetworkCT from './networkCT';


class ServerCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/

        this.state = {

        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "服务器"}]}
        />
    }

    mkCpu() {
        return 
    }

    // clear handle
    componentWillUnmount(){
        
    }

    componentDidMount(){
        
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();

        return (<div>
            {breadcrumbComponent}
            <div className="ho-devops-server">
                <CpuCT/>
                <div style={{marginTop: '1rem'}}></div>
                <MemoryCT/>
                <div style={{marginTop: '1rem'}}></div>
                <DiskCT/>
                <div style={{marginTop: '1rem'}}></div>
                <NetworkCT/>
            </div>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    zabbixUserInfo: getLoginZabbixInfo(state),
});


export default connect(
    mapState, 
    null
)(ServerCT);

