import moment from 'moment';
import { apiAliOssStsToken } from '../../../api/aliOssStsToken';

export class Alioss {

    constructor(bucket, props) {
        this.bucket = bucket
        this.props = props
    }

    getOSS(callback) {
        if (!this.ossExpiration || moment().add(30, 'm') > this.ossExpiration) {
            apiAliOssStsToken(this.props,
                (_, rsp) => {
                    let OSS = require('ali-oss');
                    let client = new OSS({
                        region: "oss-cn-shenzhen",
                        accessKeyId: rsp.AccessKeyId,
                        accessKeySecret: rsp.AccessKeySecret,
                        bucket: this.bucket,
                        stsToken: rsp.SecurityToken,
                    });
                    this.ossClient = client;
                    this.ossExpiration = moment(rsp.Expiration)
                    callback && callback(this.ossClient)
                },
                () => {
                    callback && callback(null)
                }
            )
        } else {
            callback && callback(this.ossClient)
        }
    }

    fileList(prefix = '', successHd = undefined, errorHd = undefined) {
        let that = this;
        let options = {
            prefix: prefix,
            delimiter: '/',
            marker: '',
            'max-keys': 1000,
        }
    
        function getErrorHd(e) {
            errorHd && errorHd()
        }
    
        function getFileSuccessHd(res, dirs) {
            //  console.log(prefix, res, dirs);
             let records = [];
             let prefixLen = prefix.length;
             let prefixSpLen = prefix.split('/').length;
            if (res.objects) {
                res.objects.forEach((file, index) => {
                    let sps = file.name.split('/');
                    // console.log(prefixLen, prefixSpLen, sps)
                    if (sps.length === prefixSpLen && sps[sps.length - 1] !== "") {
                        records.push({
                            ...file,
                            lastModified: moment(new Date(file.lastModified)),
                            id: `file${index}`,
                            path: file.name,
                            name: prefixLen > 1 ? file.name.slice(prefixLen) : file.name,
                        })
                    }
                })
            }
    
            records.sort((a, b) => (a.lastModified && b.lastModified) ? b.lastModified.unix() - a.lastModified.unix() : false)
            successHd && successHd(prefix, records)
        }
        
        function getDirSuccessHd(dirRes) {
            // 获取文件
            options.delimiter = '';
            try {
                that.getOSS((ossClient) => {
                    ossClient.list(options).then((res) => getFileSuccessHd(res, dirRes.prefixes)).catch(getErrorHd);
                })
            } catch (error) {
                console.error(error)
            }
        }
    
        // 获取文件夹
        try {
            that.getOSS((ossClient) => {
                ossClient.list(options).then(getDirSuccessHd).catch(getErrorHd);
            })
        } catch (error) {
            console.error(error)
        }
    }

    download(path, name, successHd, errorHd) {
        let response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(name)}`
        }
        try {
            this.getOSS((ossClient) => {
                if (ossClient) {
                    let url = ossClient.signatureUrl(path, { response });
                    successHd && successHd(url);
                } else {
                    errorHd && errorHd("oss client is null");
                }
                
            })
        } catch (error) {
            errorHd && errorHd(error);
        }
    }
}