/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Spin, Modal, Tooltip } from 'antd';
import { hoTableInitPaginationInfo } from '../../util/tableComponent';
import { config } from '../../util/version';
import { apiVmeetingSdkAppAccountList, apiVmeetingSdkAppList } from '../member/memberRD';
import { HoIconFont } from '../../util/hoComponent';

import './ads.less';

class Conference extends Component {
    constructor(props){
        super(props);
        
        this.initSdk = this.initSdk.bind(this);

        this.sdkListener = this.sdkListener.bind(this);
        this.roomListener = this.roomListener.bind(this);

        this.state = {
            status: 0,
        };
        this.maxViewNum = 4;
    }

    summary(members, key) {
        return members?.reduce((total, cur) => { 
            total[cur[key]] = total[cur[key]] ? total[cur[key]] + 1 : 1;
            return total
        }, {}) || {}
    }

    sdkListener(e) {
        let { onInitOk, onInitError } = this.props;
        // console.log("sdkListener type: " + e.type);
        switch (e.type) {
            case window.vmeeting.EVENT.SDK.SUCCESS: {
                // SDK初始化成功
                // console.log("SDK启动成功！现在你可以输入主题创建房间或输入房间号加入房间")
                let profile = e.profile;
                this.setState({
                    status: 1,
                    profile: profile,
                    reason: undefined,
                }, () => {
                    onInitOk && onInitOk(profile);
                })
                break;
            }
            case window.vmeeting.EVENT.SDK.WARN: {
                this.setState({
                    status: 0,
                    reason: e.description,
                }, () => {
                    onInitError && onInitError(e.code, e.description);
                })
                break;
            }
                
            case window.vmeeting.EVENT.SDK.ERROR: {
                // SDK初始化失败
                // console.error("SDK启动失败！错误码：" + e.code + " 详细信息：" + e.description)
                this.setState({
                    status: 0,
                    reason: e.description,
                }, () => {
                    onInitError && onInitError(e.code, e.description);
                    this.initSdk()
                })
                break;
            }
                
            default:
                break;
        }
    }

    roomListener(e) {
        let members = this.members || [];
        let { onRoomOk, onRoomError, onRoomDestroy, onRoomChange, onMemberChange, onViewChange } = this.props;
        // console.log("roomListener type: " + e.type);
        switch (e.type) {

            case window.vmeeting.EVENT.SESSION.ROOM: {
                switch (e.action) {
                    case window.vmeeting.ACTION.ROOM.PROCESS:
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            // 创建/加入房间失败
                            // console.error("创建/加入房间失败！错误码：" + e.code + " 详细信息：" + e.description)
                            // console.error("create/join room error. code: " + e.code)
                            this.setState({
                                status: 1,
                            }, () => {
                                onRoomError && onRoomError(e.code, e.description)
                            })
                        }
                        break;
                    
                    case window.vmeeting.ACTION.ROOM.CONNECTED: {
                        // 创建/加入房间成功
                        let room = e.room;
                        // console.log(room);
                        // console.log("创建/加入房间成功。房间号：" + room.accessCode + " 主题：" + room.subject)
                        this.vmeetingsdk.bindRemoteVideo("html_video_remote0", 0);
                        this.vmeetingsdk.bindRemoteVideo("html_video_remote1", 1);
                        this.vmeetingsdk.bindRemoteVideo("html_video_remote2", 2);
                        this.vmeetingsdk.bindRemoteVideo("html_video_remote3", 3);
                        this.vmeetingsdk.bindRemoteAudio("html_audio_remote0", 0);
                        onRoomOk && onRoomOk(room)
                        this.vmeetingsdk.inviteMember({
                            userIds: members.map(m => m.SipNum)
                        })
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.TERMINATED: {
                        // 退出房间
                        this.vmeetingsdk.unbindRemoteVideo("html_video_remote0");
                        this.vmeetingsdk.unbindRemoteVideo("html_video_remote1");
                        this.vmeetingsdk.unbindRemoteVideo("html_video_remote2");
                        this.vmeetingsdk.unbindRemoteVideo("html_video_remote3");
                        this.vmeetingsdk.unbindRemoteAudio("html_audio_remote0");
                        onRoomDestroy && onRoomDestroy()
                        this.setState({
                            status: 1,
                            room: undefined,
                            members: [],
                        })
                        break;
                    }
                    case window.vmeeting.ACTION.ROOM.ONCHANGE: {
                        // 房间信息变化（成员入会、离会、被静音等）
                        let room = e.room;
                        console.log("ONROOMCHANGE", e.code, room);
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            return;
                        }

                        clearTimeout(this.roomChangeTimer);
                        this.roomChangeTimer = setTimeout(() => {
                            this.roomChangeTimer = 0;
                            this.setState({
                                room: room,
                            }, () => {
                                onRoomChange && onRoomChange(room)
                            })
                        }, 500);
                        break;
                    }
                
                    default:
                        break;
                }
                break;
            }
                
 
            case window.vmeeting.EVENT.SESSION.MEMBER: {
                
                let { profile } = this.state;
                let member = e.member;
                let action = e.action;
                let curMembers = e.members || [];

                console.log("ONMEMBERCHANGE", e.code, action, member);
                if (e.code !== window.vmeeting.CODE.SUCCESS) {
                    return;
                }

                let arrays = Array.from({ length: this.maxViewNum });
                let views = [];
                let media = Object.assign([], this.view?.media || []);
                let ssrc = Object.assign([], this.view?.ssrc || []);
                let flag = false;

                onMemberChange && onMemberChange(action, member, curMembers)

                let process = (actionMembers, curMembers) => {

                    actionMembers.forEach(am => {
                        let { action, member } = am;
                        switch (action) {
                            case window.vmeeting.ACTION.MEMBER.JOINED:
                                if (member.userId === profile?.userId) {
                                    return;
                                }
                                let ret = media.find(userId => userId === member.userId)
                                if (!ret && media.length < this.maxViewNum) {
                                    for (let streamId in arrays) {
                                        let index = ssrc.findIndex(s => `${s}` === `${streamId}`)
                                        if (index === -1) {
                                            flag = true;
                                            ssrc.push(parseInt(streamId))
                                            media.push(member.userId)
                                            break;
                                        }
                                    }
                                }
                                break;
                            case window.vmeeting.ACTION.MEMBER.LEAVED:
                                if (member.userId === profile?.userId) {
                                    return;
                                }
                                let index = media.findIndex(userId => userId === member.userId)
                                if (index !== -1) {
                                    // 离会成员影响选流了
                                    // 剩下在会成员是否足够填补这个空缺
                                    for (let m of curMembers) {
                                        if (!media.find(userId => userId === m.userId) && m.status === window.vmeeting.STATUS.MEMBER.ONLINE && m.userId !== profile?.userId) {
                                            media[index] = m.userId;
                                            flag = true;
                                            break;
                                        }
                                    }
        
                                    // 没有人能填补，清除这个位置
                                    if (!flag) {
                                        delete media[index];
                                        delete ssrc[index];
                                        flag = true;
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    
    
                    if (flag) {
                        ssrc.forEach((ss, index) => {
                            views.push({
                                streamId: ss,
                                userId: media[index],
                                definition: 4,
                            })
                        })

                        if (views?.length > 0) {
                            this.vmeetingsdk.viewMediaMember({
                                userId: profile.userId,
                                medias: views
                            })
                        }
                        
                    }
                }
                
                if (action === window.vmeeting.ACTION.MEMBER.JOINED || action === window.vmeeting.ACTION.MEMBER.LEAVED) {
                    this.actionMembers.push({
                        action, member
                    })
                }
                clearTimeout(this.viewTimer);
                this.viewTimer = setTimeout(() => {
                    process(this.actionMembers, curMembers)
                    this.actionMembers = [];
                }, 500);
                
                this.setState({
                    members: curMembers,
                }, () => {
                    this.forceUpdate()
                })

                break;
            }
                
            case window.vmeeting.EVENT.SESSION.MEDIA: {

                switch (e.action) {
                    case window.vmeeting.ACTION.MEDIA.ONCHANGE: {
                        // 视频选流变化
                        let { profile } = this.state;
                        let curView = e.mediaOrg;
                        let view = JSON.parse(JSON.stringify(this.view || {}));
                        console.log("ONVIEWCHANGE", e.code, curView, view);
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {
                            return;
                        }
                        let selfMediaIndex = curView?.media?.findIndex(userId => userId === profile?.userId);
                        if (selfMediaIndex !== -1) {
                            delete curView?.media[selfMediaIndex];
                            delete curView?.ssrc[selfMediaIndex];
                            delete curView?.definition[selfMediaIndex];
                        }

                        // 比较前后选流的差异。重新刷新下video的视频
                        Array.from({ length: this.maxViewNum }).forEach((_, streamId) => {

                            let orgSsrcIndex = view?.ssrc?.findIndex(ss => ss === streamId);
                            let orgSipNum = view?.media?.[orgSsrcIndex]

                            let curSsrcIndex = curView?.ssrc?.findIndex(ss => ss === streamId);
                            let curSipNum = curView?.media?.[curSsrcIndex]
                            // console.log("compare view", streamId, curSsrcIndex, curSipNum, orgSsrcIndex, orgSipNum);
                            if (orgSipNum !== curSipNum) {
                                if (orgSipNum && !curSipNum) {
                                    // 原来有，现在没有（移除）
                                    console.log("remove view", streamId);
                                    this.unbindView(streamId)
                                } else if (orgSipNum && curSipNum) {
                                    // 原来有，现在也有（发生变化）
                                    clearTimeout(this[`refreshViewTimer${streamId}`])
                                    this[`refreshViewTimer${streamId}`] = setTimeout(() => {
                                        this[`refreshViewTimer${streamId}`] = 0;
                                        console.log("refresh view", streamId);
                                        this.refreshView(streamId)
                                    }, 500);
                                } else if (!orgSipNum && curSipNum) {
                                    // 原来没有，现在有（新增）
                                    console.log("add view", streamId);
                                    this.bindView(streamId)
                                } else {

                                }
                            }
                        });
                        this.view = curView;
                        onViewChange && onViewChange(this.view)
                        this.forceUpdate();
                        break;
                    }
                        
                    case window.vmeeting.ACTION.MEDIA.PROCESS: {
                        if (e.code !== window.vmeeting.CODE.SUCCESS) {

                        }
                        break;
                    }
                
                    default:
                        break;
                }

                break;
            }

            default:
                break;
        }
    }

    initSdk() {
        if (this.vmeetingsdk) {
            this.vmeetingsdk.uninit()
            this.vmeetingsdk = null;
        }
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = [[['AppName', 'equal', 'mntn_camera']]]
        apiVmeetingSdkAppList(this.props, paginationInfo,
            (dispatch, rsp, req) => {
                let app = rsp?.AppList?.[0];
                if (app) {
                    let paginationInfo = hoTableInitPaginationInfo();
                    paginationInfo.pagination.page = {
                        number: 1,
                        size: 100,
                    }
                    apiVmeetingSdkAppAccountList(this.props, app.Id, paginationInfo,
                        (dispatch, rsp, req) => {
                            let accounts = rsp?.AccountList || [];
                            let configure = {
                                // logLevel: 'debug',
                                appid: app.AppId,
                                appCode: app.AppCode,
                                account: accounts[Math.floor(Math.random() * 100, 0) % accounts.length]?.Account,
                                eventListener: this.sdkListener,
                                host: {
                                    ws: config.host.ws,
                                    as: config.host.as,
                                    cs: config.host.cs,
                                }
                            }
                            this.vmeetingsdk = new window.vmeeting.Sdk(configure)
                        },
                        () => {
                            return true;
                        }
                    );
                }
            },
            () => {
                return true;
            }
        )
    }

    createRoom(members) {
        this.members = members;
        this.actionMembers = [];
        this.view = {}
        function loop() {
            let { status } = this.state;
            if (this.vmeetingsdk && status === 1) {
                this.setState({
                    status: 2,
                }, () => {
                    this.vmeetingsdk.createRoom({
                        subject: `调度会议 ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
                        eventListener: this.roomListener,
                        autoViewDisable: true,
                    });
                })
            } else {
                this.createRoomTimer = setTimeout(() => {
                    loop.bind(this)()
                }, 1000)
            }
        }

        this.destroyRoom()
        loop.bind(this)()
    }

    joinRoom(accessCode) {
        this.actionMembers = [];
        this.vmeetingsdk.joinRoom({
            accessCode: accessCode,
            eventListener: this.roomListener,
        });
    }

    addressBook(pag, onOk, onError) {
        this.vmeetingsdk.addressBook({
            pag: pag,
            onOk2: onOk,
            onError: onError,
        });
    }

    inviteMember(members) {
        this.vmeetingsdk.inviteMember({
            userIds: members.map(m => m.SipNum)
        })
    }

    kickMember(members, onOk, onError) {
        this.vmeetingsdk.kickMember({
            userIds: members.map(m => m.SipNum)
        })
    }

    viewMember(member, streamId, onOk, onError) {
        let { profile } = this.state;
        let media = Object.assign([], this.view?.media || []);
        let ssrc = Object.assign([], this.view?.ssrc || []);
        let views = [];

        let mediaIndex = media.findIndex(userId => userId === member.SipNum)
        let ssrcIndex = ssrc.findIndex(ss => ss === streamId)
        if (mediaIndex !== -1) {
            // 这个成员在选看框中
            if (ssrcIndex !== -1) {
                if (mediaIndex === ssrcIndex) {
                    return;
                }
                media[mediaIndex] = media[ssrcIndex];
                media[ssrcIndex] = member.SipNum;
            } else {
                ssrc[mediaIndex] = streamId;
            }
        } else {
            // 这个成员不在选看框中
            if (ssrcIndex !== -1) {
                media[ssrcIndex] = member.SipNum;
            } else {
                ssrc.push(streamId);
                media.push(member.SipNum);
            }
        }

        ssrc.forEach((ss, index) => {
            views.push({
                streamId: ss,
                userId: media[index],
                definition: 4,
            })
        })
        this.vmeetingsdk.viewMediaMember({
            userId: profile.userId,
            medias: views
        })
    }

    destroyRoom() {
        this.vmeetingsdk && this.vmeetingsdk.destroyRoom();
        clearTimeout(this.createRoomTimer);
    }

    refreshView(index) {
        if (0 <= index && index <= 3) {
            this.vmeetingsdk.rebindRemoteVideo(`html_video_remote${index}`, index);
        }
    }

    bindView(index) {
        this.vmeetingsdk.bindRemoteVideo(`html_video_remote${index}`, index);
    }

    unbindView(index) {
        this.vmeetingsdk.unbindRemoteVideo(`html_video_remote${index}`, index);
    }

    mkVideo() {
        let { profile, members } = this.state;
        let view = this.view;
        let { viewLayout } = this.props;
        let ms = {};

        Array.from({ length: viewLayout }).forEach((_, streamId) => {
            let userId = view?.media?.[view?.ssrc?.findIndex(ss => ss === streamId)];
            ms[streamId] = members?.find(m => m.userId === userId && m.userId !== profile?.userId && userId !== undefined);
        })

        let showHidden = (index, flag) => {
            let ret = (ms[index] || (viewLayout === 1 && index !== 0))
            ret = flag ? !ret : ret
            return ret ? "hidden" : "show" 
        }

        let noStreamTips = (index) => {
            return  <div className={`no-stream-tips-${index} ` + showHidden(index)} >
            <div className="icons">
                <HoIconFont type="icon-forbidden" className="icon-forbidden"/>
                <HoIconFont type="icon-camera-off-1" className="icon-camera-off"/>
            </div>
            <span className="description">暂无视频预览</span>
        </div>
        }

        return <div className={`videos layout-${viewLayout}`}>
            <div className={`video-warpper-${0} `}>
                <div className={`nickname-${0}`}>{ms[0]?.nickName}</div>
                {noStreamTips(0)}
                <video id={`html_video_remote${0}`} className={`preview-video-${0} `} autoPlay muted></video>
            </div>
            <div className={`video-warpper-${1} `}>
                <div className={`nickname-${1}`}>{ms[1]?.nickName}</div>
                {noStreamTips(1)}
                <video id={`html_video_remote${1}`} className={`preview-video-${1} `} autoPlay muted></video>
            </div>
            <div className={`video-warpper-${2} `}>
                <div className={`nickname-${3}`}>{ms[2]?.nickName}</div>
                {noStreamTips(2)}
                <video id={`html_video_remote${2}`} className={`preview-video-${2} `} autoPlay muted></video>
            </div>
            <div className={`video-warpper-${3} `}>
                <div className={`nickname-${3}`}>{ms[3]?.nickName}</div>
                {noStreamTips(3)}
                <video id={`html_video_remote${3}`} className={`preview-video-${3} `} autoPlay muted></video>
            </div>
        </div>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = () => {
            return;
        };
        this.destroyRoom();
        if (this.vmeetingsdk) {
            this.vmeetingsdk.uninit()
            this.vmeetingsdk = null;
        }
    }

    componentDidMount() {
        this.initSdk();
    }

    render() {
        let { reason } = this.state;
        let { visible } = this.props;
        return <div className={"conference " + (visible ? "show" : "hidden")}>
            {this.mkVideo()}
            <audio id="html_audio_remote0" autoPlay></audio>
            <div className="error">{reason}</div>
        </div>
    }
}

export default Conference;