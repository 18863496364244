/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Empty, Select, Radio, Button, Icon, Modal, Tag, Tooltip, Steps, Input } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { 
    HoTableExtend, hoTableColumnTagButton, hoTableExtendColumn,
} from '../../util/tableComponent';

import { hoMessage, HoRefreshButton, HoAlertDialog } from '../../util/hoComponent';
import { sipStateTags, confStateTags } from '../common/common';
import SelectJoinedMember from './selectJoinedMember';
import SelectAppointmentMember from './selectAppointmentMember';

class SelectView extends Component{

    constructor(props){
        super(props);

        this.LAYOUT_4 = 1
        this.LAYOUT_9 = 2
        this.LAYOUT_PIP = 3
        
        /********************************** 主页面 ***********************************/
        //

        // 主页面按钮
        this.setpNextOnClick = this.setpNextOnClick.bind(this);
        this.setpPrevOnClick = this.setpPrevOnClick.bind(this);
        this.setpOkOnClick = this.setpOkOnClick.bind(this);

        // 主页面回调
        this.setpOnChange = this.setpOnChange.bind(this);

        // 第一页
        this.targetMemberOnChange = this.targetMemberOnChange.bind(this);

        // 第二页
        this.layoutOnSelect = this.layoutOnSelect.bind(this);

        this.layoutMemberRender = this.layoutMemberRender.bind(this);
        this.layoutMemberOnClick = this.layoutMemberOnClick.bind(this);
        this.layoutMemberDeleteOnClick = this.layoutMemberDeleteOnClick.bind(this);
        this.layoutMemberPageCancelOnClick = this.layoutMemberPageCancelOnClick.bind(this);
        this.layoutMemberPageMemberSelectHd = this.layoutMemberPageMemberSelectHd.bind(this);


        this.state = {
            flag: false,
            currentStep: 0, 
            steps: [
                {
                    key: 'step1',
                    title: '选择设备',
                    description: '',
                }, {
                    key: 'step2',
                    title: '配置画面布局',
                    description: '',
                }, {
                    key: 'step3',
                    title: '最终确认',
                    description: '',
                }
            ],

            layoutId: this.LAYOUT_PIP,

            targetMembers: [], // 选中的需要锁定画面的成员

            layoutMemberMap: {}, // 布局位置与成员绑定关系
            layoutMemberPageVisible: false, // 
            layoutMemberPosColor: {
                0: red[3],
                1: blue[3],
                2: gold[3],
                3: orange[3],
                4: cyan[3],
                5: green[3],
                6: geekblue[3],
                7: purple[3],
                8: magenta[3],
            }
        }
    }

    // 布局的代号
    posCode(layoutId, pos) {
        switch(layoutId){
            case this.LAYOUT_4:
                return String.fromCharCode(65 + (pos / 2)) + (pos % 2 + 1)
            case this.LAYOUT_9:
                return String.fromCharCode(65 + (pos / 3)) + (pos % 3 + 1)
            case this.LAYOUT_PIP:
                return pos === 0 ? 'A1' : ('B' + pos)
            default:
                return '未知';
        }
    }

    // '下一步'按钮被点击
    setpNextOnClick(e) {
        const currentStep = this.state.currentStep + 1;
        this.setState({ currentStep });
    }
    
    // '上一步'按钮被点击
    setpPrevOnClick(e) {
        const currentStep = this.state.currentStep - 1;
        this.setState({ currentStep });
    }

    // '完成'按钮被点击
    setpOkOnClick(e) {
        const { layoutId, targetMembers, layoutMemberMap } = this.state;
        const { onOk } = this.props;

        if (targetMembers.length === 0){
            hoMessage({type: 'warning', msg:'请选择需要锁定画面的设备'});
            setTimeout((e) => this.setState({currentStep: 0}), 2000);
            return;
        }

        let views = [];
        for (let k in layoutMemberMap) {
            const member = layoutMemberMap[k];
            if (member) {
                views.push({
                    SipNum: member.SipNum,
                    StreamId: parseInt(k),
                    Definition: layoutId === this.LAYOUT_PIP && parseInt(k) === 0 ? 4 : 1,
                })
            }
        }

        if (views.length === 0){
            hoMessage({type: 'warning', msg:'请配置画面布局'});
            setTimeout((e) => this.setState({currentStep: 1}), 2000);
            return;
        }

        if (onOk){
            onOk(layoutId, targetMembers.map(item => item.SipNum), views);
        }
    }

    setpOnChange(current) {
        this.setState({ currentStep: current });
    };

    targetMemberOnChange(action, member) {
        let {targetMembers} = this.state;

        // console.log("targetMemberOnChange", action, member)

        const add = (member) => {
            if (targetMembers.find((e) => {return e.UserId === member.UserId}) === undefined){
                targetMembers.push(member);
            }
        }

        const del = (member) => {
            const pos = targetMembers.findIndex((e) => {return e.UserId === member.UserId})
            if (pos !== -1){
                targetMembers.splice(pos, 1)
            }
        }

        switch(action){

            case 'add':
                add(member);
                break;
            
            case 'group.add':
                member.forEach(item => {
                    add(item);
                })
                break;

            case 'del':
                del(member);
                break;

            case 'group.del':
                member.forEach(item => {
                    del(item);
                })
                break;

            default:
                break;
        }

        this.setState({
            targetMembers: targetMembers,
        })
    }

    layoutOnSelect(e) {

        // if (onChange){
        //     onChange(e.target.value, targetMembers.map(item => item.SipNum), views);
        // }

        this.setState({
            layoutId: e.target.value,
            layoutMemberMap: {},
        })
    }

    layoutMemberOnClick(e, pos) {
        this.setState({
            layoutMemberPageVisible: true,
            layoutMemberPos: pos,
        })
    }

    layoutMemberPageCancelOnClick(e) {
        this.setState({
            layoutMemberPageVisible: false,
        })
    }

    layoutMemberPageMemberSelectHd(member) {
        let {layoutMemberMap, layoutMemberPos} = this.state;
        // console.log(layoutMemberPos, member)
        for (let pos in layoutMemberMap) {
            if (layoutMemberMap[pos] && layoutMemberMap[pos].UserId === member.UserId) {
                layoutMemberMap[pos] = undefined;
            }
        }

        layoutMemberMap[layoutMemberPos] = member;

        this.setState({
            layoutMemberMap: layoutMemberMap,
            layoutMemberPos: 0,
            layoutMemberPageVisible: false,
        });
    }

    layoutMemberDeleteOnClick(e, pos) {
        let { layoutMemberMap } = this.state;
        layoutMemberMap[pos] = undefined;
        this.setState({
            layoutMemberMap: layoutMemberMap,
        });
    }

    layoutPoses(){
        const {layoutId} = this.state;
        let layoutRang = [];

        switch(layoutId){
            case this.LAYOUT_4:
                layoutRang = Array.from(new Array(3).keys());
                break;
            case this.LAYOUT_9:
                layoutRang = Array.from(new Array(8).keys());
                break;
            case this.LAYOUT_PIP:
                layoutRang = Array.from(new Array(9).keys());
                break;
            default:
                break;
        }

        return layoutRang.map((item) => {
            return {
                pos: item,
                posCode: this.posCode(layoutId, item),
            }
        })

    }

    layoutMemberRender(text, record){
        const { layoutMemberMap, layoutMemberPosColor } = this.state;
        const value = layoutMemberMap[record.pos] ? layoutMemberMap[record.pos].NickName : "请选择...";
        const suffix = layoutMemberMap[record.pos] ? <Tooltip title="删除"><Icon type="close" style={{fontSize: '12px'}} onClick={(e) => this.layoutMemberDeleteOnClick(e ,record.pos)} /></Tooltip> : undefined;
        const style = layoutMemberMap[record.pos] ? {color: layoutMemberPosColor[record.pos]} : {color: '#d9d9d9'};

        // console.log(record)
        return <div className="ho-select-view-pos-input">
            <Input style={style} value={value} suffix={suffix} onClick={(e) => this.layoutMemberOnClick(e, record.pos)}/>
        </div>
    }

    mkWillMember() {
        const {layoutMemberPageVisible} = this.state;
        const {reqUserInfo, roomInfo} = this.props;

        return <HoAlertDialog
            width="80%"
            title="选择成员"
            visible={layoutMemberPageVisible}
            onCancelClick={this.layoutMemberPageCancelOnClick}
            footer={[
                <Button key="plus" type="primary" onClick={this.layoutMemberPageCancelOnClick}>
                    关闭
                </Button>,
            ]}
        >
            <SelectJoinedMember
                roomInfo={roomInfo}
                reqUserInfo={reqUserInfo}
                memberOnSelect={this.layoutMemberPageMemberSelectHd}
            />
        </HoAlertDialog>
    }

    mkStepHeader() {
        const { currentStep, steps, } = this.state;

        return <Steps current={currentStep} type="navigation" size="small" onChange={this.setpOnChange}>
            {steps.map(item => <Steps.Step key={item.key} title={item.title} description={item.description} />)}
        </Steps>
    }

    mkStepBody(){
        const { currentStep, steps, } = this.state;
        let content = undefined;
        const stepKey = steps[currentStep].key;
        switch(stepKey){

            // 第一步，选锁定的成员
            case 'step1': {
                const tableComponent = this.mkTable(stepKey);
                content = <Row type="flex" align="top" justify="center" >
                    <Col span={23} style={{textAlign: 'center'}}>
                        {tableComponent}
                    </Col>
                </Row>
                break;
            }   

            // 第二步，选画面布局
            case 'step2': {
                const tableComponent = this.mkTable(stepKey);
                const previewComponent = this.mkPreview(true);
                content = <Row type="flex" align="top" justify="center" >
                    <Col span={12} style={{textAlign: 'center', width: '450px'}}>
                        {previewComponent}
                    </Col>
                    <Col span={1} style={{textAlign: 'center'}}>
                        {/* <Divider style={{height: '360px', marginTop: '18px'}} type={"vertical"}/> */}
                    </Col>
                    <Col span={11} style={{marginTop: '3rem'}}>
                        {tableComponent}
                    </Col>
                </Row>
                break;
            }

            // 第三步，确认完成
            case 'step3': {
                const tableComponent = this.mkTable(stepKey);
                const previewComponent = this.mkPreview(false);
                content = <Row type="flex" align="top" justify="center" >
                    <Col span={11} >
                        <div style={{textAlign: 'left', marginBottom: '1rem', fontWeight: 500}}>锁定设备</div>
                        {tableComponent}
                    </Col>
                    <Col span={1} style={{textAlign: 'center'}}>
                        {/* <Divider style={{height: '360px', marginTop: '18px'}} type={"vertical"}/> */}
                    </Col>
                    <Col span={12} style={{textAlign: 'center', width: '450px'}}>
                        <div style={{textAlign: 'left', marginBottom: '1rem', fontWeight: 500}}>画面布局</div>
                        {previewComponent}
                    </Col>
                </Row>
                break;
            }
            default:
                break;
        }

        return content;
    }

    mkStepFooter() {
        let buttons = [];
        const { currentStep, steps, } = this.state;
        
        if (currentStep > 0) {
            buttons.push(<Button key="prev" style={{ marginRight: '1rem' }} icon="left" onClick={() => this.setpPrevOnClick()}>上一步</Button>)
        }

        if (currentStep < steps.length - 1) {
            buttons.push(<Button key="next" type="primary" onClick={() => this.setpNextOnClick()}>下一步<Icon type="right"/></Button>);
        } else if (currentStep === steps.length - 1) {
            buttons.push(<Button key="complete" type="primary" icon="lock" onClick={this.setpOkOnClick}>锁定</Button>)
        }

        return buttons;
    }

    mkTable(step) {
        let {targetMembers} = this.state;
        let {reqUserInfo, roomInfo} = this.props;
        let columns = [];

        switch(step){
            case 'step1':
                return <SelectAppointmentMember
                    mode={"join"}
                    roomInfo={roomInfo}
                    reqUserInfo={reqUserInfo}
                    selectedMembers={targetMembers}
                    memberOnChange={this.targetMemberOnChange}
                />

            case 'step2':
                columns = [
                    hoTableExtendColumn('位置', 'posCode', 'str', false, false, '20%', 
                    // {
                    //     render: (text, record) => {
                    //         const {layoutMemberPosColor} = this.state;
                    //         return <span style={{marginLeft: '2px', color: layoutMemberPosColor[record.pos]}}>{text}</span>
                    //     },
                    // },
                    ),
                    hoTableExtendColumn('设备', 'pos', 'str', false, false, '80%', {
                        render: (text, record) => this.layoutMemberRender(text, record),
                    }),
                ];
                return <HoTableExtend
                    columns={columns}
                    data={this.layoutPoses()}
                    bordered={false}
                    size={'small'}
                    rowKey={'pos'}
                    pagination={false}
                />

            case 'step3':
                columns = [
                    hoTableExtendColumn('状态', 'ConfState', 'str', false, false, '40%', {
                        render: (text, record) => hoTableColumnTagButton(sipStateTags(text, record).concat(confStateTags(text, record))),
                    }),
                    hoTableExtendColumn('设备名称', 'NickName', 'str', false, false, '40%'),
                ];
                return <HoTableExtend
                    columns={columns}
                    data={targetMembers}
                    bordered={false}
                    size={'small'}
                    rowKey={'SipNum'}
                />
            default:
                break;
        }
    }

    mkPreview(enable=true){
        const { layoutId, layoutMemberMap, layoutMemberPosColor } = this.state;
        const { layouts } = this.props;
        let preview = undefined;
        let that = this;

        function content(width, height, pos, background='#9c9c9c', more=false, local=false) { 
            let display = undefined;
            if (!more){
                let member = layoutMemberMap[pos];
                if (member) {
                    if (layoutId === that.LAYOUT_PIP && pos === 0){
                        display = <Tag style={{marginRight: 0, marginBottom: '125px', whiteSpace: 'normal', maxWidth: '130px', fontSize: '16px', padding: '4px'}} color={layoutMemberPosColor[pos]}>{member.NickName}</Tag>;
                    } else {
                        display = <Tag style={{marginRight: 0, whiteSpace: 'normal', maxWidth: '130px', fontSize: '14px', padding: '4px'}} color={layoutMemberPosColor[pos]}>{member.NickName}</Tag>;
                    }
                } else if (local) { 
                    display = '本地预览(不可选择)';
                } else {
                    display = undefined;
                }
            }  else {
                display = '...';
            }

            return <div style={{height: height, width: width, textAlign: 'center'}}>
                <div style={{height: height, width: width, background: background, position: 'absolute', border: '1px solid #fff', zIndex:1}}></div>
                {/* <div style={{zIndex: 2, textAlign: 'end', padding:'0.5rem 0.5rem', position: 'absolute', color: layoutMemberPosColor[pos]}}>{that.posCode(layoutId, pos)}</div> */}
                <div style={{zIndex: 2, textAlign: 'end', padding:'0.5rem 0.5rem', position: 'absolute'}}>{that.posCode(layoutId, pos)}</div>
                <div style={{zIndex: 3, lineHeight: height + 'px',  position: 'relative'}}>{display}</div>
            </div>
        }

        switch (layoutId) {
            case this.LAYOUT_4: // 田字格
                preview = <div>
                    <Row type="flex" align="top">
                        <Col span={12}>{content(225, 225, 0)}</Col>
                        <Col span={12}>{content(225, 225, 1)}</Col>
                    </Row>
                    <Row type="flex" align="top">
                        <Col span={12}>{content(225, 225, 2)}</Col>
                        <Col span={12}>{content(225, 225, 3, '#9c9c9c', false, true)}</Col>
                    </Row>
                </div>
                break;

            case this.LAYOUT_9: // 九宫格
                preview = <div>
                    <Row  type="flex" align="top">
                        <Col span={8}>{content(150, 150, 0)}</Col>
                        <Col span={8}>{content(150, 150, 1)}</Col>
                        <Col span={8}>{content(150, 150, 2)}</Col>
                    </Row>
                    <Row  type="flex" align="top">
                        <Col span={8}>{content(150, 150, 3)}</Col>
                        <Col span={8}>{content(150, 150, 4)}</Col>
                        <Col span={8}>{content(150, 150, 5)}</Col>
                    </Row>
                    <Row  type="flex" align="top">
                        <Col span={8}>{content(150, 150, 6)}</Col>
                        <Col span={8}>{content(150, 150, 7)}</Col>
                        <Col span={8}>{content(150, 150, 8, '#9c9c9c', false, true)}</Col>
                    </Row>
                </div>
                break;

            case this.LAYOUT_PIP: // 画中画
                preview = <div>
                    <Row type="flex" align="top">
                        <Col span={24}>{content(450, 300, 0, '#9c9c9c', false)}</Col>
                    </Row>
                    <Row type="flex" align="middle" justify="space-around">
                        <Col span={8}>{content(150, 150, 1, '#c7c7c7', false)}</Col>
                        <Col span={8}>{content(150, 150, 2, '#c7c7c7', false)}</Col>
                        <Col span={8}>{content(151, 150, 3, '#c7c7c7', true)}</Col>
                    </Row>
                    <Row type="flex" style={{marginTop: '1rem', textAlign: 'left'}} >
                        <Col span={24} >
                            <span style={{color: red[3]}}>*布局的浮动窗口数量不定，这里只展示3个小窗口</span>
                        </Col>
                    </Row>
                </div>
                break;
        
            default:
                preview = <div style={{display:'table', height: 300, margin: '0 auto'}}>
                    <Empty
                        style={{display: 'table-cell', verticalAlign: 'middle'}}
                        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                        imageStyle={{height: 60}}
                        description={<span>暂无预览，请选择画面布局</span>}
                    />
                </div>
                break;
        }

        return <div>
            {enable ? <div style={{display:'table', margin: '0 auto 1rem', position: 'relative'}}>
                <Radio.Group value={layoutId} style={{display: 'table-cell', verticalAlign: 'middle'}} onChange={this.layoutOnSelect}>
                    {layouts ? layouts.map(item => <Radio.Button key={item.Id} value={item.Id}>{item.LayoutName}</Radio.Button>) : undefined}
                </Radio.Group>
            </div> : undefined}
            {preview}
        </div>
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
    }

    componentDidMount(){
    }

    render(){
        const {style} = this.props;
        // 主页面
        const stepHeaderComponent = this.mkStepHeader();
        const stepBodyComponent = this.mkStepBody();
        const stepFooterComponent = this.mkStepFooter();

        const willMemberComponent = this.mkWillMember();

        return (<div style={style}>
            {willMemberComponent}
            <div className="ho-select-view-steps-header">
                {stepHeaderComponent}
            </div>
            <div className="ho-select-view-steps-body">
                {stepBodyComponent}
            </div>
            <div className="ho-select-view-steps-footer">
                {stepFooterComponent}
            </div>
        </div>);
    }
}

const mapState = (state) => ({
    
});


export default connect(
    mapState, 
    null
)(SelectView);

