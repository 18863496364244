/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import { Row, Col, Card, Input, Button, Form, Icon, Select, message, Tooltip, Drawer } from 'antd';
import {getLoginDefaultUserName, loginRequestThunk, ACT_NET_LOGIN} from './loginRD';
import {getNetRequestStatus, actNetRequestClear} from '../../util/netReqRD';
import { hoMessage } from '../../util/hoComponent';
import { LockOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { getIconSrc } from '../../resource/resource';
import { config, switchEnvironment, updateHostEnv, version } from '../../util/version';
import { getUrlParam } from '../../util/logic';

import 'rc-banner-anim/assets/index.css';
import './less/antMotionStyle.less';
import './login.less'

class LoginCT extends PureComponent {

    constructor(props){
        super(props);

        this.dataLoginSuccessHd = this.dataLoginSuccessHd.bind(this);

        this.submitOnClick = this.submitOnClick.bind(this);

        this.state = {
            username: props.defaultUser, 
            password: '',
        };
    }

    dataLoginSuccessHd(reqUserInfo) {
        let { history, dispatch } = this.props;
        let defaultLoginTo = version.isInternal() ? '/conference/member/account' : '/conference/member/device';
        window.localStorage && window.localStorage.setItem("environment", version.environment);
        switch (reqUserInfo.user.role.type){
            case 'superadmin':
            case 'admin':
            case 'test':
                message.open({
                    icon: <Icon type='smile' theme="twoTone" />,
                    key: 'welcome',
                    content: `欢迎你，${reqUserInfo.user.name}`,
                    duration: 5
                });
                history.push(defaultLoginTo);
                break;
            case 'user':
                message.open({
                    icon: <Icon type='smile' theme="twoTone" />,
                    key: 'welcome',
                    content: `欢迎你，${reqUserInfo.company.name}，${reqUserInfo.user.name}`,
                    duration: 5
                });
                history.push(defaultLoginTo);
                break;
            case 'vmeetingsdk-demo':
                message.open({
                    icon: <Icon type='smile' theme="twoTone" />,
                    key: 'welcome',
                    content: `欢迎体验vmeeting-sdk地图调度会议系统`,
                    duration: 5
                });
                history.push("/conference/ads");
                break;
            default:
                hoMessage({type: 'error', msg: '非管理员账号不能登录本管理系统'})
                dispatch(actNetRequestClear(ACT_NET_LOGIN));
                break;
        }
    }

    submitOnClick(e){
        e.preventDefault()
        let {username, password} = this.state;
        let {dispatch} = this.props;

        const msgBody = {
            username: username, 
            password: password
        };
        dispatch(loginRequestThunk(msgBody, this.dataLoginSuccessHd));
    }

    mkElement1() {
        let { username, password, settingVisible } = this.state;
        let follow = {
            delay: 1000,
            minMove: 0.1,
            data: [
                { id: `bg0`, value: 30, type: 'x' },
                { id: `wrapperBlock0`, value: -30, type: 'x' },
                { id: `wrapperBlock1`, value: -30, type: 'x' },
            ],
        }

        let titleComponent = (
            <QueueAnim
                type={['top', 'bottom', ]}
                delay={1000}
                key="text1"
                className="banner2-text-wrapper"
                id={`wrapperBlock0`}
            >
                <div key="logo" className="banner2-title">云会议管理系统</div>
            </QueueAnim>
        );

        let feature1 = (
            <QueueAnim
                type={['bottom', 'top']}
                delay={1000}
                key="text2"
                className="banner2-text-wrapper"
                id={`wrapperBlock1`}
            >
                <div key="content" className="banner2-content">让数据一目了然</div>
            </QueueAnim>
        );

        let usernameComponent = (
            <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名'}]}
                >
                <div className="username">
                    <div className="title">用户名</div>
                    <Input 
                        ref={(input) => this.loginUserNameDOM = input} 
                        value={username} 
                        autoFocus={true} 
                        size="large" 
                        placeholder="请输入用户名" 
                        prefix={<UserOutlined />} 
                        onChange={(e) => {
                            this.setState({
                                username: e.target.value,
                            });
                        }} 
                        onPressEnter={(e) => this.loginPasswordDOM.focus()}
                    />
                </div>
            </Form.Item>
        )

        let passwordComponent = (
            <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码"}]}
                >
                <div className="password">
                    <div className="title">密码</div>
                    <Input.Password 
                        ref={(input) => this.loginPasswordDOM = input} 
                        autoComplete="on" 
                        value={password} 
                        size="large" 
                        placeholder="请输入密码" 
                        prefix={<LockOutlined />} 
                        onChange={(e) => {
                            this.setState({
                                password: e.target.value,
                            });
                        }} 
                        onPressEnter={this.submitOnClick}
                    />
                </div>
            </Form.Item>
        )

        let evnComponent = version.isInternal() ? undefined : (
            <Form.Item name="env" >
                <div className="env">
                    <div className="title">服务器</div>
                    <Select
                        dropdownClassName="env-select"
                        size="large"
                        value={version.environment} 
                        onChange={(e) => {
                            switchEnvironment(e, true)
                            this.setState({
                                flag: !this.state.flag
                            })
                        }} 
                    >
                        <Select.Option value="production">正式环境</Select.Option>
                        <Select.Option value="show">展厅环境</Select.Option>
                        <Select.Option value="test">测试环境</Select.Option>
                    </Select>
                </div>
            </Form.Item>
        )

        let buttonComponent = (
            <Form.Item>
                <div className="login">
                    <Button type="primary" size="large" block onClick={this.submitOnClick}>登录</Button>
                </div>
            </Form.Item>
        )

        let inputComponent = (
            <QueueAnim
                type={['right', 'left']}
                delay={1000}
                duration={2000}
                key="text3"
                className="banner2-text-wrapper"
                id={`wrapperBlock2`}
            >
                <div key="control" className="login-control">
                    <Card 
                        bordered={false}
                        title={<span>
                            <img alt="" className="logo" src={getIconSrc('logo')} />
                            <span className="title">登录</span>
                        </span>}
                        extra={<SettingOutlined className="setting-icon" onClick={() => {
                            this.setState({
                                settingVisible: !settingVisible,
                            })
                        }} />}
                    >
                        <div className="dialog">
                            <Form name="basic" className="content">
                                {usernameComponent}
                                {passwordComponent}
                                {evnComponent}
                                {buttonComponent}
                            </Form>
                            <Drawer
                                className="setting"
                                placement="top"
                                closable={false}
                                destroyOnClose={true}
                                width="100%"
                                height={'calc(100% - 1px)'}
                                onClose={() => {
                                    this.setState({
                                        settingVisible: false,
                                    })
                                }}
                                afterVisibleChange={() => {
                                    let csIp = window.location.hostname;
                                    let csPort = "6443";
                                    let cmIp = window.location.hostname;
                                    let cmPort = "6443";
                                    let asIp = window.location.hostname;
                                    let asPort = "6443";
                                    let wsIp = window.location.hostname;
                                    let wsPort = "6443";
                                    let zabbixIp = window.location.hostname;
                                    let zabbixPort = "6443";
                                    if (window.localStorage) {
                                        csIp = window.localStorage.getItem("mntn::csIp") || csIp;
                                        csPort = window.localStorage.getItem("mntn::csPort") || csPort;
                                        cmIp = window.localStorage.getItem("mntn::cmIp") || cmIp;
                                        cmPort = window.localStorage.getItem("mntn::cmPort") || cmPort;
                                        asIp = window.localStorage.getItem("mntn::asIp") || asIp;
                                        asPort = window.localStorage.getItem("mntn::asPort") || asPort;
                                        wsIp = window.localStorage.getItem("mntn::wsIp") || wsIp;
                                        wsPort = window.localStorage.getItem("mntn::wsPort") || wsPort;
                                        zabbixIp = window.localStorage.getItem("mntn::zabbixIp") || zabbixIp;
                                        zabbixPort = window.localStorage.getItem("mntn::zabbixPort") || zabbixPort;
                                    }
                                    this.setState({
                                        csIp,
                                        csPort,
                                        cmIp,
                                        cmPort,
                                        asIp,
                                        asPort,
                                        wsIp,
                                        wsPort,
                                        zabbixIp,
                                        zabbixPort,
                                    })
                                }}
                                visible={settingVisible}
                                getContainer={false}
                                style={{ position: 'absolute' }}
                                >
                                <Form layout="vertical">
                                    <Form.Item label="业务服务器（CS）地址">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '70%' }}
                                                placeholder="IP地址"
                                                value={this.state.csIp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        csIp: e.target.value
                                                    })
                                                }}
                                            />
                                            <Input
                                                style={{ width: '30%' }}
                                                placeholder="端口"
                                                value={this.state.csPort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        csPort: e.target.value
                                                    })
                                                }}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="控制服务器（CM）地址">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '70%' }}
                                                placeholder="IP地址"
                                                value={this.state.cmIp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        cmIp: e.target.value
                                                    })
                                                }}
                                            />
                                            <Input
                                                style={{ width: '30%' }}
                                                placeholder="端口"
                                                value={this.state.cmPort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        cmPort: e.target.value
                                                    })
                                                }}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="消息服务器（WebSocket）地址">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '70%' }}
                                                placeholder="IP地址"
                                                value={this.state.wsIp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        wsIp: e.target.value
                                                    })
                                                }}
                                            />
                                            <Input
                                                style={{ width: '30%' }}
                                                placeholder="IP地址"
                                                value={this.state.wsPort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        wsPort: e.target.value
                                                    })
                                                }}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="代理服务器（AS）地址">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '70%' }}
                                                placeholder="IP地址"
                                                value={this.state.asIp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        asIp: e.target.value
                                                    })
                                                }}
                                            />
                                            <Input
                                                style={{ width: '30%' }}
                                                placeholder="端口"
                                                value={this.state.asPort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        asPort: e.target.value
                                                    })
                                                }}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="监控服务器（Zabbix）地址">
                                        <Input.Group compact>
                                            <Input
                                                style={{ width: '70%' }}
                                                placeholder="IP地址"
                                                value={this.state.zabbixIp}
                                                onChange={(e) => {
                                                    this.setState({
                                                        zabbixIp: e.target.value
                                                    })
                                                }}
                                            />
                                            <Input
                                                style={{ width: '30%' }}
                                                placeholder="端口"
                                                value={this.state.zabbixPort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        zabbixPort: e.target.value
                                                    })
                                                }}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                                <div className="operations">
                                    <Button type="danger" onClick={() => {
                                        this.setState({
                                            settingVisible: false,
                                        })
                                    }}>取消</Button>
                                    <Button type="primary" onClick={() => {
                                        updateHostEnv({
                                            cs: {
                                                ip: this.state.csIp,
                                                port: this.state.csPort,
                                            },
                                            cm: {
                                                ip: this.state.cmIp,
                                                port: this.state.cmPort,
                                            },
                                            as: {
                                                ip: this.state.asIp,
                                                port: this.state.asPort,
                                            },
                                            ws: {
                                                ip: this.state.wsIp,
                                                port: this.state.wsPort,
                                            },
                                            zabbix: {
                                                ip: this.state.zabbixIp,
                                                port: this.state.zabbixPort,
                                            },
                                        })
                                        this.setState({
                                            settingVisible: false,
                                        })
                                    }}>保存</Button>
                                </div>
                            </Drawer>
                        </div>
                    </Card>
                </div>
            </QueueAnim>
        )

        let element1 = (
            <Element key={0} followParallax={follow} prefixCls="banner-user-elem" >
                <Element.BgElement key="bg" className="bg bg0" id="bg0" />
                <div className="home-page banner2-page">
                <Row className="element">
                    <Col span={12}>
                        <Row>{titleComponent}</Row>
                        <Row>{feature1}</Row>
                    </Col>
                    <Col span={12}>
                        <Row>{inputComponent}</Row>
                    </Col>
                </Row>
                </div>
            </Element>
        );

        return element1;
    }

    componentDidMount() {
        let that = this;
        function resize() {
            that.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            })
        }
        window.addEventListener('resize', resize);
        resize();

        let env = getUrlParam("env", window.location?.search) || window.localStorage?.getItem("environment") || (version.isInternal() ? "internal" : "production");
        switchEnvironment(env)
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        let { height, width } = this.state;
        let elementComponent1 = this.mkElement1();
        return (
            <div className="templates-wrapper login" style={{height: height, width: width}}>
                <div className="banner2">
                <div className="login-version">{version.software}</div>
                <div className="login-footer">深圳陆地桥科技有限公司 copyright©2021</div>
                <TweenOneGroup
                    key="bannerGroup"
                    enter={{ opacity: 0, type: 'from' }}
                    leave={{ opacity: 0 }}
                    component=""
                >
                    <BannerAnim key="BannerAnim1">
                        {elementComponent1}
                    </BannerAnim>
                </TweenOneGroup>
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({
  defaultUser: getLoginDefaultUserName(state), 
  loginStatus: getNetRequestStatus(state, ACT_NET_LOGIN), 
});

export default connect(
  mapState, 
  null
)(LoginCT);
