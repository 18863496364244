/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import {actNetRequestPending, 
        actNetRequestFailed, 
        actNetRequestSuccess,
        netRequestThunk2} from '../../util/netReqRD';

import { sendRequest, sendRequestZabbix } from '../../util/net';
import { hoMessage, hoMessageDestroy } from '../../util/hoComponent';
import { initMenu } from '../../laylout/menu';
import { encrypt } from '../../util/logic';

export const ACT_NET_LOGIN = 'loginRequest';
export const ACT_NET_LOGIN_ZABBIX = 'loginRequestZabbix';
export const ACT_NET_LOGIN_TOKEN_REFRESH = 'loginTokenRefresh';

const ACT_LOGIN_SUCCESS = "ACT_LOGIN_SUCCESS"
const ACT_CLEAN_LOGIN = "ACT_CLEAN_LOGIN"
const ACT_LOGIN_ZABBIX_SUCCESS = "ACT_LOGIN_ZABBIX_SUCCESS"
const ACT_CLEAN_ZABBIX_LOGIN = "ACT_CLEAN_ZABBIX_LOGIN"

// functions
const saveLoginInfo = (reqUserInfo, defaultUser) => {
    const loginInfo = {
        reqUserInfo: reqUserInfo, 
        defaultUser: defaultUser
    };

    try {
        const binState = JSON.stringify(loginInfo);
        localStorage.setItem('cs_mntn_login_info', binState);

    }catch (err){
        console.log('save store loginInfo failed! err: ' + err);
    }
}

const getSavedLoginInfo = () => {
    try {
        const binState = localStorage.getItem('cs_mntn_login_info');
        if (binState === null){
            return undefined;
        }
        return JSON.parse(binState);

    }catch (err){
        return undefined;
    }
}



// reducer handle
const loginState = {
    defaultUser: undefined, 
    reqUserInfo: undefined,
    zabbix: undefined,
};

export const login_reducer = (state=loginState, action) => {
    switch(action.type){
        case ACT_LOGIN_SUCCESS:
            return {
                ...state, 
                defaultUser: action.defaultUser, 
                reqUserInfo: action.reqUserInfo
            };

        case ACT_CLEAN_LOGIN:
            return {
                defaultUser: undefined, 
                reqUserInfo: undefined,
            };

        case ACT_LOGIN_ZABBIX_SUCCESS:
            return {
                ...state, 
                zabbix: action.zabbix, 
            };

        case ACT_CLEAN_ZABBIX_LOGIN:
            return {
                zabbix: undefined, 
            };

        default:
        return state;
    }
}

// select handle
export const getLoginDefaultUserName = (state) => {
    return state.login && state.login.defaultUser;
}

export const getLoginUserInfo = (state) => {
    return state.login && state.login.reqUserInfo;
}

export const getLoginZabbixInfo = (state) => {
    return state.login && state.login.zabbix;
}


// actions handle
// ACT_LOGIN_SUCCESS
export const actLoginSuccess = (reqUserInfo, defaultUser) => {
    
    return {
        type: ACT_LOGIN_SUCCESS, 
        reqUserInfo: reqUserInfo, 
        defaultUser: defaultUser
    }
}

// ACT_LOGIN_SUCCESS
export const actLoginZabbixSuccess = (authID) => {
    
    return {
        type: ACT_LOGIN_ZABBIX_SUCCESS, 
        zabbix: {
            authID: authID,
        }, 
    }
}

export const actLoginClear = () => {
    return {
        type: ACT_CLEAN_LOGIN,
    }
}

// thunk handle
export const loginRequestThunk = (req, successHd = undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(ACT_NET_LOGIN));

    const rsp1 = await sendRequest('/cs/v1/mntn/user/security/get/token', {
        username: encrypt(req.username),
        password: encrypt(req.password),
    });
    if (rsp1 !== undefined){
        if (rsp1.Status !== 0){
            dispatch(actNetRequestFailed(ACT_NET_LOGIN));
            switch(rsp1.Status){
                case 1000: // 密码错误
                default:
                    hoMessage({type: 'error', msg: '登录失败，用户名或密码错误！'});
                    // hoMessage({type: 'error', msg: '服务端错误！'});
                    return;
            }
        }
    }else{
        dispatch(actNetRequestFailed(ACT_NET_LOGIN));
        return;
    }

    const rsp2 = await sendRequest('/cs/v1/mntn/user/info', {}, {Token: rsp1.Token});
    if (rsp2 !== undefined){
        if (rsp2.Status !== 0){
            dispatch(actNetRequestFailed(ACT_NET_LOGIN));
            switch(rsp2.Status){
                default:
                    hoMessage({type: 'error', msg: '登录失败，用户名或密码错误！'});
                    // hoMessage({type: 'error', msg: '服务端错误！'});
                    return;
            }
        } else {
            let roleType = "none"
            switch (rsp2.UserType) {
                // 管理员
                case 1:
                    roleType = "superadmin"
                    break;
                
                // 测试用户
                case 6:
                    roleType = "test"
                    break;
                
                // vmeeting-sdk体验地图用户
                case 8:
                    roleType = "vmeetingsdk-demo"
                    break;
                
                default:
                    roleType = "test"
                    break;
            }

            initMenu(roleType);

            const login = {
                user: {
                    id: rsp2.UserId,
                    role: {
                        type: roleType,
                    },
                    name: rsp2.NickName,
                },
                auth: {
                    token: rsp1.Token,
                    expire: rsp1.Expire,
                    refreshToken: rsp1.RefreshToken,
                },
                company: {
                    // appid: 'UqXs7yDV5WQQo4GzsfxQpVmPLGnKq275',
                    appid: 'sMW677MB65mfgT3AVSv4QvKhx2gfvfwE',
                },
                mqttInfo: rsp2.mqttInfo,
            }
            // console.log("login, ", login)
            dispatch(actLoginSuccess(login, req.username));
            saveLoginInfo(login, req.username);
            dispatch(actNetRequestSuccess(ACT_NET_LOGIN));

            if (successHd) {
                successHd(login);
            }
        }
    }else{
        dispatch(actNetRequestFailed(ACT_NET_LOGIN));
    }

    dispatch(loginRequestZabbix())
}

export function loginNoauthHd(dispatch, orgPath, orgBody, orgHeader, reqUserInfo, routeCode, successFun, errorFun) {
    hoMessageDestroy();
    // window.goToMenu();

    // 重新刷新token
    if (reqUserInfo?.auth?.refreshToken) {
        const path = `/cs/v1/mntn/user/refresh/token`;
        let body = {
            RefreshToken: reqUserInfo?.auth?.refreshToken,
        }
        dispatch(netRequestThunk2(path, body, {}, ACT_NET_LOGIN_TOKEN_REFRESH,
            (dispatch, rsp, req) => {
                // 刷新成功
                reqUserInfo.auth = {
                    token: rsp.Token,
                    expire: rsp.Expire,
                    refreshToken: rsp.RefreshToken,
                }
                dispatch(actLoginSuccess(reqUserInfo));

                // 再次发出之前的请求
                let header = Object.assign(orgHeader, {
                    Token: reqUserInfo?.auth?.token,
                })
                dispatch(netRequestThunk2(orgPath, orgBody, header, routeCode, successFun, errorFun));
            },
            (dispatch, rsp) => {
                switch(rsp.Status){
                    case 100008:
                    default:
                        hoMessage({type: 'error', msg: `Token已失效，请重新登录`, duration: 5});
                        break;
                }
                return true;
            }
        ));

        return true;
    }
    
    return false;

}


// thunk handle
export const loginRequestZabbix = (req, successHd = undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(ACT_NET_LOGIN_ZABBIX));

    const rsp = await sendRequestZabbix({
        "jsonrpc": "2.0",
        "method": "user.login",
        "params": {
            "user": (req && req.username) || "cm",
            "password": (req && req.password) || "online",
        },
        "id": 0,
    }, undefined);
    // console.log(rsp)
    if (rsp !== undefined){
        if (rsp.id !== 0 || rsp.jsonrpc !== "2.0"){
            dispatch(actNetRequestFailed(ACT_NET_LOGIN_ZABBIX));
        }else{
            // console.log("login, ", login)
            dispatch(actLoginZabbixSuccess(rsp.result));
            dispatch(actNetRequestSuccess(ACT_NET_LOGIN_ZABBIX));
            
            if (successHd) {
                successHd(rsp);
            }
        }
    }else{
        dispatch(actNetRequestFailed(ACT_NET_LOGIN_ZABBIX));
    }
}

