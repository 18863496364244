/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Button, Tooltip, Badge } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import { apiAliOssStsToken } from '../../../api/aliOssStsToken';
import { getLoginUserInfo } from '../../login/loginRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoIconFont, HoLinkButton,
} from '../../../util/hoComponent';
import { 
    HoTable, hoTableColumn, hoTableColumnAction, hoTableColumnActionButton
} from '../../../util/tableComponent';
import { config } from '../../../util/version';
import { getNetWorkDesc } from '../../../util/logic';

import "./alioss.css"


class AliossCT extends Component{

    constructor(props){
        super(props);

        this.NODE_TYPE = {
            DIR: 'dir',
            FILE: 'file',
            BACK: 'back',
        }
        
        /********************************** 主页面 ***********************************/
        this.fileList = this.fileList.bind(this);

        // 主页面回调
        this.tblRenderAction = this.tblRenderAction.bind(this);
        this.tblRowDownloadOnClick = this.tblRowDownloadOnClick.bind(this);

        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkTable = this.mkTable.bind(this);

        this.state = {
            path: this.props.match?.path,
            columns: [
                hoTableColumn('文件名', 'name', 'str', false, false, '50%' , {
                    render: (text, record) => {
                        let component = undefined;
                        let { prefix } = this.state;
                        switch(record.nodeType) {
                            case this.NODE_TYPE.DIR: {
                                let sps = text.split('/');
                                let dir = sps[Math.max(sps.length - 2 ,0)]
                                component = <span className="row">
                                    <HoIconFont className="icon" type="icon-dir"/>
                                    {/* <Tooltip title={`进入目录：${text}`} placement="rightBottom"> */}
                                        <HoLinkButton className="btn-dir" onClick={(e) => {
                                            this.fileList(text);
                                        }}>{dir}</HoLinkButton>
                                    {/* </Tooltip> */}
                                </span>
                                break;
                            }
                            case this.NODE_TYPE.FILE: {
                                component = <span className="row">
                                    <HoIconFont className="icon" type="icon-file"/>
                                    {/* <Tooltip title={`点击下载：\r\n${text}`} placement="rightBottom"> */}
                                        <HoLinkButton className="btn-file" onClick={(e) => this.tblRowDownloadOnClick(e, record)}>{text}</HoLinkButton>
                                    {/* </Tooltip> */}
                                </span>
                                break;
                            }
                            case this.NODE_TYPE.BACK: {
                                let sps = prefix.split('/')
                                let newSps = sps.slice(0, sps.length - 1);
                                component = <span className="row">
                                    <Tooltip title="返回上一级">
                                        <HoIconFont className="icon" type="icon-back" onClick={(e) => {
                                            let newPrefix = sps.slice(0, sps.length - 2).join('/');
                                            this.fileList(newPrefix.length > 0 ? newPrefix + "/" : "");
                                        }}/>
                                    </Tooltip>
                                    {/* <Tooltip title={`当前目录：${prefix}`}> */}
                                        {
                                            newSps.map((node, index) => {
                                                let sep = undefined;
                                                if (index > 0) {
                                                    sep = <span>/</span>
                                                }
                                                return <span key={index} className="cur-dir">
                                                    {sep}
                                                    <HoLinkButton className="btn-cur-dir" onClick={(e) => {
                                                        if (index + 1 !== newSps.length) {
                                                            let newPrefix = newSps.slice(0, index + 1).join('/');
                                                            this.fileList(`${newPrefix}/`);
                                                        }
                                                    }}>{node}</HoLinkButton>
                                                </span>
                                            })
                                        }
                                    {/* </Tooltip> */}
                                </span>
                                break;
                            }
                            default:
                                break;
                        }
                        return component;
                    }, nolight: true,
                }),
                hoTableColumn('文件大小', 'size', 'str', false, false, '10%', {
                    render: (text, record) => {
                        if (text && text !== '') {
                            return getNetWorkDesc(text, "B");
                        }
                        return text;
                    }, nolight: true,
                }),
                hoTableColumn('存储类型', 'storageClass', 'str', false, false, '10%', {
                    render: (text, record) => {
                        let str = text
                        switch(text) {
                            case 'Standard':
                                str = '标准存储'
                                break;
                            default:
                                break;
                        }
                        return str;
                    }, nolight: true,
                }),
                hoTableColumn('更新时间', 'lastModified', 'str', false, false, '20%', {
                    render: (text, record) => {
                        if (record.lastModified) {
                            return record.lastModified.format("YYYY-MM-DD HH:mm:ss");
                        }
                        return text;
                    }, nolight: true,
                }),
                hoTableColumnAction('操作', 'operation', this.tblRenderAction, '15%'),
            ],
            prefix: '',
            subDirs: [],
            files: [],
        }
    }

    classifyMap(path) {
        switch(path) {
            case '/conference/devops/tool/log': 
                return {
                    titles: [{title: '运维工具', url: "devops/tool"}, {title: '用户日志'}], 
                    bucket: config.alioss.logBucket,
                };
            case '/conference/devops/tool/version': 
                return {
                    titles: [{title: '运维工具', url: "devops/tool"}, {title: '版本'}], 
                    bucket: config.alioss.versionBucket,
                };
            default: 
                return {};
        }
    }

    getOSS(path, callback) {
        let OSS = require('ali-oss');

        if (!this.ossExpiration || moment().add(30, 'm') > this.ossExpiration || this.state.path !== path) {
            apiAliOssStsToken(this.props,
                (_, rsp) => {
                    let client = new OSS({
                        region: "oss-cn-shenzhen",
                        accessKeyId: rsp.AccessKeyId,
                        accessKeySecret: rsp.AccessKeySecret,
                        bucket: this.classifyMap(path)?.bucket,
                        stsToken: rsp.SecurityToken,
                    });
                    this.ossClient = client;
                    this.ossExpiration = moment(rsp.Expiration)
                    callback && callback(this.ossClient)
                },
                () => {
                
                }
            )
        } else {
            callback && callback(this.ossClient)
        }
    }

    fileList(prefix = '') {
        let that = this;
        let options = {
            prefix: prefix,
            delimiter: '/',
            marker: '',
            'max-keys': 1000,
        }

        this.setState({
            loading: true,
        })

        function getErrorHd(e) {
            console.log(e);
            that.setState({
                loading: false,
            })
        }

        function getFileSuccessHd(res, dirs) {
            //  console.log(prefix, res, dirs);
             let records = [];
             let prefixLen = prefix.length;
             let prefixSpLen = prefix.split('/').length;
             if (dirs) {
                let tmpDirs = dirs.map((dir, index) => {
                    return {
                        id: `dir${index}`,
                        nodeType: that.NODE_TYPE.DIR,
                        name: dir,
                        lastModified: null,
                        size: '',
                        path: '',
                        storageClass: '',
                    }
                });
                tmpDirs.reverse();
                records = records.concat(tmpDirs);
            }
            if (res.objects) {
                res.objects.forEach((file, index) => {
                    let sps = file.name.split('/');
                    // console.log(prefixLen, prefixSpLen, sps)
                    if (sps.length === prefixSpLen && sps[sps.length - 1] !== "") {
                        records.push({
                            ...file,
                            lastModified: moment(new Date(file.lastModified)),
                            id: `file${index}`,
                            nodeType: that.NODE_TYPE.FILE,
                            path: file.name,
                            name: prefixLen > 1 ? file.name.slice(prefixLen) : file.name,
                        })
                    }
                })
            }

            // 不在根目录下，则添加一个返回的节点
            if (prefixLen) {
                records.unshift({
                    id: `back`,
                    nodeType: that.NODE_TYPE.BACK,
                    name: prefix,
                })
            }

            records.sort((a, b) => (a.lastModified && b.lastModified) ? b.lastModified.unix() - a.lastModified.unix() : false)
            that.setState({
                prefix: prefix,
                records: records,
                loading: false,
            })
        }
        
        function getDirSuccessHd(dirRes) {
            // 获取文件
            options.delimiter = '';
            try {
                that.getOSS(that.state.path, (ossClient) => {
                    ossClient.list(options).then((res) => getFileSuccessHd(res, dirRes.prefixes)).catch(getErrorHd);
                })
            } catch (error) {
                console.error(error)
            }
        }

        // 获取文件夹
        try {
            this.getOSS(this.state.path, (ossClient) => {
                ossClient.list(options).then(getDirSuccessHd).catch(getErrorHd);
            })
        } catch (error) {
            console.error(error)
        }
    }

    tblRowDownloadOnClick(e, record) {
        let response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(record.name)}`
        }
        // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
        try {
            this.getOSS(this.state.path, (ossClient) => {
                let url = ossClient.signatureUrl(record.path, { response });
                window.location.href = url;
            })
        } catch (error) {
            console.error(error)
        }
    }

    tblRenderAction(text, record){
        let actions = [];
        switch(record.nodeType) {
            case this.NODE_TYPE.FILE: {
                actions.push({title: '下载', onClick: this.tblRowDownloadOnClick})
                break;
            }
            case this.NODE_TYPE.DIR: {
                actions.push({title: '进入', onClick: (e) => this.fileList(record.name)})
                break;
            }
            case this.NODE_TYPE.BACK: {
                actions.push({title: '返回', onClick: (e) => {
                    let { prefix } = this.state;
                    let sps = prefix.split('/')
                    let newPrefix = sps.slice(0, sps.length - 2).join('/');
                    this.fileList(newPrefix.length > 0 ? newPrefix + "/" : "");
                }})
                break;
            }
            default: {
                break;
            }
        }
        
        return hoTableColumnActionButton(actions, record);
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        let { prefix } = this.state;
        this.fileList(prefix)
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={this.classifyMap(this.state.path)?.titles}
        />
    }

    mkHeaderInfo(){
        let titles = this.classifyMap(this.state.path)?.titles || []
        let title = titles && titles.length > 0 ? titles[titles.length - 1].title : '';
        const buttons = (<div>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>{title}</span>, buttons: buttons}; 
    }

    mkTable(){
        let { loading, columns, records } = this.state;

        return <HoTable
            size="small"
            bordered={false}
            showPagination={false}
            loading={loading}
            columns={columns}
            data={records}
            rowKey={'id'}
            rowClassName={"row"}
        />
    }

    componentWillUnmount(){

    }

    componentDidMount(){
        this.getOSS(this.state.path, () => {
            this.fileList();
        })
        
    }

    componentWillReceiveProps(nextProps){
        // 路由变化则去刷新页面
        if (this.state.path !== nextProps.match.path) {
            this.getOSS(nextProps.match.path, () => {
                this.fileList();
            })
            this.setState({
                path: nextProps.match.path,
            })
        }
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});

export default connect(
    mapState, 
    null
)(AliossCT);

