/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, Input, Select, Checkbox } from 'antd';
import moment from 'moment';

import './terminal.less';


let LicenseTypeList = [
    { key: "test", description: "测试" },
    { key: "try", description: "试用" },
    { key: "official", description: "商用" },
]
export let LicenseType = {
    list: LicenseTypeList,
    getDescription(key) {
        return this.list.find(e => e.key === key)?.description;
    }
}

const CustomizedForm = Form.create({
    name: '_gen_terminal_license_form',
    onFieldsChange(props, changedFields, allFields) {
        console.log(changedFields);
        props.onChange && props.onChange(changedFields);
    },
    onValuesChange(props, changedValues, allValues) {

    },
    mapPropsToFields(props) {
      return {
        Amount: Form.createFormField({
            ...props.Amount,
            value: props.Amount?.value,
        }),
        LicenseType: Form.createFormField({
            ...props.LicenseType,
            value: props.LicenseType?.value,
        }),
        Version: Form.createFormField({
            ...props.Version,
            value: props.Version?.value,
        }),
        Duration: Form.createFormField({
            ...props.Duration,
            value: props.Duration?.value,
        }),
        CapabilitySet: Form.createFormField({
            ...props.CapabilitySet,
            value: props.CapabilitySet?.value,
        }),
        Remark: Form.createFormField({
            ...props.Remark,
            value: props.Remark?.value,
        }),
      };
    },
})(props => {
    const { form } = props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 4 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 20 },
            sm: { span: 20 },
        },
    };

    props.Duration.value = parseInt(props.Duration?.value);
    let duration = moment.duration(props.Duration?.value, 'd')
    let years = duration.years();
    let months = duration.months();
    let days = duration.days();

    let durationSelectValue = 0;
    if (!months && !days) {
        durationSelectValue = years || 0
        years = 0
    } else {
        duration = moment.duration(props.Duration?.value + 1, 'd')
        if (!duration.months() && !duration.days()) {
            years = duration.years()
            months = duration.months();
            days = duration.days();
            durationSelectValue = years || 0
            years = 0
        }
    }
    return (
        <Form>
            <Form.Item label="数量">
                {getFieldDecorator('Amount', {
                    normalize: (value) => value ? parseInt(value) : 0,
                    initialValue: 1,
                    rules: [
                        { type: 'number', message: 'License数量必须大于0并且小于100', asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                let v = parseInt(value);
                                if (v > 100 || v <= 0) {
                                    reject('invalid');
                                } else {
                                    resolve();
                                }
                            })
                        }},
                        { required: true, message: 'License数量无效' }
                    ],
                })(<Input placeholder="License数量"/>)}
            </Form.Item>
            <Form.Item label="用途">
                {getFieldDecorator('LicenseType', {
                    initialValue: 'test',
                    rules: [{ message: '请选择License类型', whitespace: false }],
                })(<Select style={{ width: '100%' }}>
                    {LicenseType.list.map(e => <Select.Option key={e.key} value={e.key}>{e.description}</Select.Option>)}
                </Select>,
                )}
            </Form.Item>
            <Form.Item label="版本">
                {getFieldDecorator('Version', {
                    initialValue: 'v1.0',
                    rules: [
                        { message: '请选择版本', whitespace: false },
                        { required: true, message: '输入无效' }
                    ],
                })(<Select style={{ width: '100%' }}>
                    <Select.Option key="v1.0" value="v1.0">v1.0</Select.Option>
                </Select>,
                )}
            </Form.Item>
            <Form.Item className="duration" label="有效期（天）" required>
                <Form.Item className="duration-input">
                    {getFieldDecorator('Duration', {
                        normalize: (value) => value ? parseInt(value) : "",
                        initialValue: '1095',
                        rules: [
                            { pattern: new RegExp(/^(\d+)$/), message: '输入无效'},
                            { required: true }
                        ],
                    })(<Input placeholder="有效期" />)}
                </Form.Item>
                <Form.Item className="duration-split">
                    <div>-</div>
                </Form.Item>
                <Form.Item className="duration-select">
                    <Select style={{ width: '100%' }} value={durationSelectValue} onChange={(key) => {
                        props.onChange && props.onChange({
                            Duration: {
                                dirty: false,
                                errors: undefined,
                                name: "Duration",
                                touched: true,
                                validating: false,
                                value: moment().startOf('day').add(key, 'y').diff(moment().startOf('day'), 'd'),
                            }
                        })
                    }}>
                        <Select.Option disabled={true} key={0} value={0}></Select.Option>
                        {Array.from({ length: 10 }).map((e, index) => {
                            return <Select.Option key={index + 1} value={index + 1}>{index + 1}年</Select.Option>
                        })}
                        <Select.Option key={100} value={100}>永久（100年）</Select.Option>
                    </Select>
                    
                </Form.Item>
                <div className="duration-description">{(years ? `${years}年` : "") + (months ? `${months}个月` : "") + (days ? `${days}天` : "")}</div>
            </Form.Item>
            <Form.Item className="form-caps-set" label="能力">
                {getFieldDecorator('CapabilitySetTmp', {
                    rules: [
                        { required: true }
                    ],
                })(<div>
                    <Form.Item className="form-caps-set-item">
                        {getFieldDecorator('CapabilitySet.base', {
                            initialValue: false,
                        })(<Checkbox disabled={true} checked={props.CapabilitySet?.base?.value}>基础能力</Checkbox>)}
                    </Form.Item>
                    <Form.Item className="form-caps-set-item">
                        {getFieldDecorator('CapabilitySet.jz', {
                            initialValue: false,
                        })(<Checkbox checked={props.CapabilitySet?.jz?.value}>极真能力</Checkbox>)}
                    </Form.Item>
                </div>)}
            </Form.Item>
            <Form.Item label="备注">
                {getFieldDecorator('Remark')(<Input.TextArea rows={3}/>)}
            </Form.Item>
            
        </Form>
    );
  });

class OGenForm extends Component {
    render() {
        const { fields, onChange } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} onChange={onChange}/>
            </div>
        );
    }
}

let GenForm = Form.create({ name: 'gen_terminal_license_form' })(OGenForm);

export { GenForm }