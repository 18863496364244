/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import { Input, Row, Col, Form } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import {
    dataTerminalLicenseList, dataTerminalLicensePublish, dataTerminalLicenseMod, dataTerminalLicenseGen,
    dataTerminalLicenseCtrlList, dataTerminalLicenseDownload
} from '../../../../../api/terminalLicence';

import {getLoginUserInfo} from '../../../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoButton, HoRefreshButton, HoNewButton, HoAlertDialog, HoIconFont, hoMessage,
} from '../../../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../../util/tableComponent';
import { LicenseType, ClientType, GenForm } from './genForm';
import { version } from '../../../../../util/version';
import { remainingDate } from './common';

import './terminal.less';

class TerminalCT extends Component{
    constructor(props){
        super(props);

        const columns = [
            hoTableColumn('状态', 'IsForbidden', 'int', false, false, '7%', {
                render: (text, record) => {
                    let tags = []
                    if (record.ActiveInfo) {
                        if (record.ActiveInfo.IsForbidden) {
                            tags.push({ color: red[3], tag: '禁用' })
                        }
                        tags.push({ color: blue[5], tag: '已激活' })
                    } else {
                        tags.push({
                            color: record.Issued ? gold[5] : green[5], tag: record.Issued ? '已发行' : '未发行'
                        })
                    }
                    return hoTableColumnTagButton(tags)
                }
            }),
            hoTableColumn('ID', 'LicenseId', 'str', false, false, '15%'),
            hoTableColumn('用途', 'LicenseType', 'str', false, false, '5%', {
                render: (text, record) => LicenseType.getDescription(text)
            }),
            hoTableColumn('能力', 'CapabilitySet', 'str', false, false, '7%', {
                render: (text, record) => {
                    let caps = {};
                    try {
                        caps = JSON.parse(record.CapabilitySet)
                    } catch (error) {
                        
                    }
                    
                    return <ul className="table-column-caps-set">
                        <li><HoIconFont type={caps?.base ? "icon-duihao": "icon-cuo"}/>基础能力</li>
                        <li><HoIconFont type={caps?.jz ? "icon-duihao": "icon-cuo"}/>极真能力</li>
                    </ul>
                }
            }),
            hoTableColumnDate('剩余时间', 'ActiveInfo.EndTime', false, '7%', {
                render: (text, record) => {
                    if (!record.ActiveInfo?.EndTime) {
                        return "未激活"
                    } else {
                        return remainingDate(record.ActiveInfo?.EndTime)
                    }
                }
            }),
            hoTableColumnDate('激活时间', 'ActiveInfo.ActiveTime', false, '10%', {
                render: (text, record) => record.ActiveInfo?.ActiveTime || "未激活"
            }),
            hoTableColumnDate('发行时间', 'IssuedTime', false, '10%', {
                render: (text, record) => record.Issued ? record.IssuedTime : "未发行"
            }),
            hoTableColumnDate('创建时间', 'CreateTime', false, '10%'),
            hoTableColumn('下载次数', 'DownloadTimes', 'str', false, false, '7%'),
            hoTableColumn('备注', 'Remark', 'str', false, false, '10%', {
                render: (text, record) => record.Issued ? (record.IssueRemark || record.Remark) : (record.Remark || record.IssueRemark)
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                if (!record.Issued) {
                    actions.push({
                        title: '发行', onClick: (e, record) => {
                            this.setState({
                                pagePublishVisible: true,
                                pageData: [record],
                            })
                        }
                    })
                } else {
                    actions.push({ title: '下载', onClick: (e, record) => this.tblRowDownloadOnClick(e, record) })
                }

                // if (record.IsForbidden) {
                //     actions.push({ title: '启用', onClick: (e, record) => this.tblRowEnableOnClick(e, record) })
                // } else {
                //     actions.push({ title: '禁用', onClick: (e, record) => this.tblRowDisableOnClick(e, record) })
                // }
                actions.push({ title: '详情', onClick: this.tblRowDetailOnClick })
                return hoTableColumnActionButton(actions, record);
            }, '15%'),
        ];

        this.state = {
            columns: columns,
            data: undefined,
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
            },
            genPageVisiable: false,
            genData: undefined,
        }
    }

    trans2ApiData(data) {

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            if (key === "CapabilitySet") {
                d[key] = JSON.stringify(this.trans2ApiData(data[key]))
            } else {
                d[key] = data[key].value
            }
        }

        transInt(d, 'Duration');
        transInt(d, 'Amount');

        return d;
    }

    searchOnChange(search, pag) {
        let filter1 = [];
        if (search?.text?.length > 0) {
            filter1.push(['LicenseId', 'like', search.text]);
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = [
            filter1,
        ]

        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            loading: true,
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            dataTerminalLicenseList(this.props, paginationInfo,
                (_, rsp, req) => {
                    let records = rsp.RecordList
                    let paginationInfo2 = JSON.parse(JSON.stringify(paginationInfo))
                    paginationInfo2.pagination.filters = records?.map(l => [["LicenseId", "equal", l.LicenseId]])
                    paginationInfo2.pagination.page.number = 1;

                    dataTerminalLicenseCtrlList(this.props, paginationInfo2,
                        (_, rsp2) => {
                            for (let r of records) {
                                r.ActiveInfo = rsp2.RecordList?.find(r2 => r2.LicenseId === r.LicenseId)
                            }
                            this.setState({
                                loading: false,
                                data: {
                                    paginationInfo: hoTableInitPaginationInfo(req, rsp.TotalNum),
                                    records: records,
                                }
                            })
                        },
                        () => {
                            this.setState({
                                loading: false,
                                data: {
                                    paginationInfo: hoTableInitPaginationInfo(req, rsp.TotalNum),
                                    records: records,
                                }
                            })
                        }
                    )
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                }
            )

        })
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        this.searchOnChange(this.state.search, paginationInfo)
    }

    // '禁用'按钮被点击
    tblRowDisableOnClick(e, record){
        e.stopPropagation();
        let license = Object.assign({}, record);
        license.IsForbidden = 1;
        dataTerminalLicenseMod(this.props, license, () => {
            this.mainRefreshOnClick()
        });
    }

    // '启用'按钮被点击
    tblRowEnableOnClick(e, record){
        e.stopPropagation();
        let license = Object.assign({}, record);
        license.IsForbidden = 0;
        dataTerminalLicenseMod(this.props, license, () => {
            this.mainRefreshOnClick()
        });
    }

    // '下载'按钮被点击
    tblRowDownloadOnClick(e, record){
        e.stopPropagation();
        dataTerminalLicenseDownload(this.props, [record.LicenseId],
            (_, rsp, req) => {
                window.location.href = rsp?.DownloadUrl;
                this.mainRefreshOnClick()
            }
        )
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record) {
        window.goToMenu('devops/setting/license/terminal/detail/', record.LicenseId, 'devops/setting/license/terminal')
    }

    // '生成'按钮被点击
    mainGenOnClick() {
        this.setState({
            genPageVisiable: true,
            genData: {
                Amount: { value: "1" },
                LicenseType: { value: "official" },
                ClientType: { value: "DefaultClient" },
                Version: { value: "v1.0" },
                Duration: { value: "1095" },
                CapabilitySet: {
                    base: {
                        value: true,
                    },
                    jz: {
                        value: false,
                    },
                },
                Remark: { value: "" }
            },
        })
    }

    mainPublishOnClick() {
        let { selectedRows } = this.state;
        if (selectedRows?.length === 0) {
            hoMessage({type: "warning", msg: "请勾选License"})
            return;
        }

        this.setState({
            pagePublishVisible: true,
            pageData: selectedRows.filter(r => !r.Issued),
        })
    }

    mainDownloadOnClick(e) {
        e.stopPropagation();
        let { selectedRows } = this.state;

        dataTerminalLicenseDownload(this.props, selectedRows?.map(r => r.LicenseId),
            (_, rsp) => {
                window.location.href = rsp?.DownloadUrl;
                this.mainRefreshOnClick()
            }
        )
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text) {
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[{title: "配置管理", url: "devops/setting"}, {title: "License", url: "devops/setting/license"}, {title: "终端"}, {title: "发行管理"}]}
        />
    }

    mkHeaderInfo() {
        const buttons = (<div>
            {
                version.isProduction() ? <Fragment>
                    <HoNewButton onClick={(e) => this.mainGenOnClick(e)}>生成</HoNewButton>
                    <HoButton icon="rocket" onClick={(e) => this.mainPublishOnClick(e)}>批量发行</HoButton>
                    <HoButton icon="download" onClick={(e) => this.mainDownloadOnClick(e)}>批量下载</HoButton>
                </Fragment>: undefined
            }
            <HoButton icon="bulb" onClick={() => {
                window.goToMenu('devops/setting/license/terminal/activation')
            }} extra={{type: "primary"}}>查看已激活License</HoButton>
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick(e)}/>
        </div>);
        return {title: <span>发行管理</span>, buttons: buttons}; 
    }

    mkGenPage() {
        let that = this;
        let { genData, genPageVisiable } = this.state;

        return <HoAlertDialog
            width="50%"
            title="生成"
            visible={genPageVisiable}
            onOkClick={() => {
                dataTerminalLicenseGen(that.props, that.trans2ApiData(genData), () => {
                    this.setState({
                        genData: undefined,
                        genPageVisiable: false,
                    })
                    that.mainRefreshOnClick()
                })
            }}
            onCancelClick={() => {
                this.setState({
                    genPageVisiable: false,
                })
            }}
        >
            <GenForm
                fields={genData}
                onChange={(changedFields) => {
                    let { genData } = this.state;
                    if (changedFields.CapabilitySet) {
                        genData.CapabilitySet = { ...genData.CapabilitySet, ...changedFields.CapabilitySet }
                        this.setState({
                            genData: genData,
                        });
                    } else {
                        this.setState({
                            genData: { ...genData, ...changedFields },
                        });
                    }
                }}
            />
        </HoAlertDialog>
    }

    mkPublishPage() {
        let { pageData, pagePublishVisible, publishRemark } = this.state;
        return <HoAlertDialog
            width="50%"
            title={"发行 - " + pageData?.slice(0,2).map(r => r.LicenseId).join("，") + ` 等${pageData?.length}个License`}
            visible={pagePublishVisible}
            onOkClick={() => {
                dataTerminalLicensePublish(this.props, pageData?.map(r => r.LicenseId) || [], publishRemark, () => {
                    this.setState({
                        pagePublishVisible: false,
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                    
                }) 
            }}
            onCancelClick={() => {
                this.setState({
                    pagePublishVisible: false,
                })
            }}
        >
            <Form>
                <Form.Item label="备注">
                    <Input.TextArea rows={3} value={publishRemark} onChange={(e) => {
                        this.setState({
                            publishRemark: e.target.value
                        })
                    }}/>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data, loading, selectedRowKeys } = this.state;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: selectedRows,
                })
            },
        };

        return <div className="ho-license-table">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{display: 'flex'}}>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder="搜索License ID"
                            enterButton
                            allowClear={true}
                            onSearch={(text) => this.mainSearchOnClick(text)}
                            onChange={(e) => this.mainSearchOnChange(e)}
                            />
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'LicenseId'}
                loading={loading}
                onColumnChange={(pag) => this.tblColOnChange(pag)}
                rowSelection={rowSelection}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const genPageComponent = this.mkGenPage();
        const publishPageComponent = this.mkPublishPage();
        return (<div>
            {publishPageComponent}
            {genPageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(TerminalCT);

