/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import moment from 'moment';

import { Menu, Input, Button, Descriptions, Row, Col, Icon, Dropdown, Form, InputNumber, Switch } from 'antd';
import { 
    dataListMember, dataModMember, apiCameraContrlStart, apiCameraContrlStop, apiCameraAccountGen, apiMemberAccountPublish, 
} from '../member/memberRD';
import { apiManufacturerAdd, apiManufacturerList } from '../../api/manufacturer';

import {getLoginUserInfo} from '../login/loginRD'
import {
    HoForm, hoValidateZeroFormat, hoValidateEmptyFormat, HoFormRowColInputNumber, 
} from '../../util/formComponent';
import { 
    HoCard, HoBreadcrumb, hoMessage, HoRefreshButton, HoGenButton, HoAlertDialog, HoButton, 
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTagButton, hoTableColumnActionButton
} from '../../util/tableComponent';
import { callStateTags } from '../../component/common/common';
import { stateMap } from '../../defined/memberDevice';
import { formatValidTime } from '../../util/logic';
import CameraControl from '../../component/subpages/cameraControl';

import './camera.less'

class CameraCT extends Component{
    constructor(props){
        super(props);

        const columns = [
            hoTableColumn('状态', 'AppState', 'str', false, false, '15%', {
                render: (text, record) => hoTableColumnTagButton(callStateTags(text, record)),
            }),
            hoTableColumn('名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('能力', 'CameraProfile.Manufacturer', 'str', false, false, '20%', {
                render: (text, record) => {
                    let { manufacturers } = this.state;
                    let manufacturer = manufacturers?.find(m => m.Manufacturer === record.CameraProfile?.Manufacturer && m.Model === record.CameraProfile?.Model)
                    if (manufacturer) {
                        // 已经配置了摄像头能力
                        return <Descriptions column={1} className="camera-table-col-attr-descriptions">
                            <Descriptions.Item label="厂商名称">{manufacturer.DisManufacturer}</Descriptions.Item>
                            <Descriptions.Item label="厂商标识">{manufacturer.Manufacturer}</Descriptions.Item>
                            <Descriptions.Item label="设备型号">{manufacturer.Model}</Descriptions.Item>
                            <Descriptions.Item label="云台">{manufacturer.SupportPtz ? "支持" : "不支持"}</Descriptions.Item>
                        </Descriptions>
                    } else {
                        // 没有配置相关能力
                        return <Descriptions column={1} className="camera-table-col-attr-descriptions">
                            <Descriptions.Item label="厂商标识">
                                <span>{record.CameraProfile?.Manufacturer}</span>
                                {
                                    record.CameraProfile?.Manufacturer && record.CameraProfile?.Model ? <Button type="link" onClick={() => {
                                        this.setState({
                                            addManufacturerPageVisible: true,
                                            manufacturer: {
                                                DisManufacturer: '',
                                                Manufacturer: record.CameraProfile?.Manufacturer,
                                                Model: record.CameraProfile?.Model,
                                                SupportPtz: 0,
                                            }
                                        })
                                    }}>暂无能力，点击配置</Button> : undefined
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="设备型号">{record.CameraProfile?.Model}</Descriptions.Item>
                        </Descriptions>
                    }
                }
            }),
            hoTableColumn('SIP号', 'SipNum', 'str', false, false, '15%'),
            hoTableColumnDate('账号发行时间', 'IssuedInfo.IssuedTime', false, '10%'),
            hoTableColumnDate('最近在线时间', 'OnlineTime', false, '10%', {
                render: (text, record) => {
                    if (record.AppState === 'online' && record.SipState === 'online') {
                        return moment().format("YYYY-MM-DD HH:mm:ss")
                    } else {
                        return formatValidTime(record.OnlineTime);
                    }
                },
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let { manufacturers } = this.state;
                let actions = [{
                    title: '编辑', onClick: (e, record) => {
                        e.stopPropagation();
                        this.setState({
                            modPageVisible: true,
                            data: Object.assign({}, record),
                        });
                } }];
                switch (record.AppState) {
                    case 'online':
                        let manufacturer = manufacturers?.find(m => m.Manufacturer === record.CameraProfile?.Manufacturer && m.Model === record.CameraProfile?.Model)
                        if (manufacturer?.SupportPtz) {
                            actions.push({
                                title: '云台控制', onClick: (e, record) => {
                                    this.setState({
                                        data: Object.assign({}, record),
                                        cameraControlPageVisible: true,
                                    })
                            } });
                        }
                        break;
                
                    default:
                        break;
                }
                if (!record.IssuedInfo?.IsIssued) {
                    actions.push({
                        title: '发行账号', onClick: (e, record) => {
                            this.setState({
                                data: Object.assign({}, record),
                                publishPageVisible: true,
                            })
                        }
                    });
                }
                actions.push({
                    title: '详情', onClick: (e, record) => {
                        this.goToDetail(record);
                    }
                });
                return hoTableColumnActionButton(actions, record);
            }, '20%')
        ];

        let pag = hoTableInitPaginationInfo();
        this.state = {
            columns: columns,
            paginationInfo: pag,
            search: {
                text: '',
                stateType: 'online',
                stateMap: stateMap,
            },
            data: {},
        }
    }

    goToDetail(record){
        window.goToMenu('member', `detail/${record.UserId}`, 'member/camera')
    }

    searchOnChange(search, pag) {
        let nickNameFilters = [];
        let sipNumFilters = [];

        if (search.text?.length > 0) {
            nickNameFilters.push(['NickName', 'like', search.text]);
            sipNumFilters.push(['SipNum', 'like', search.text]);

            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
                sipNumFilters.push(['AppState', 'equal', search.stateType])
            }

            nickNameFilters.push(['DeviceType', 'equal', 'CAMERA-28181'])
            sipNumFilters.push(['DeviceType', 'equal', 'CAMERA-28181'])
            
        } else {
            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
            }
            nickNameFilters.push(['DeviceType', 'equal', 'CAMERA-28181'])
        }

        let filters = [];
        if (nickNameFilters.length > 0) {
            filters.push(nickNameFilters)
        }
        if (sipNumFilters.length > 0) {
            filters.push(sipNumFilters)
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = filters;
        paginationInfo.pagination.sort = {
            field: 'UserId',
            order: 1
        }
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            status: 1,
        }, () => {
            dataListMember(this.props, paginationInfo,
                (dispatch, rsp, req) => {
                    let manufacturerMap = {}
                    let filters = [];
                    // eslint-disable-next-line no-unused-expressions
                    rsp?.UserList?.forEach(camera => {
                        manufacturerMap[`${camera.CameraProfile?.Manufacturer}_${camera.CameraProfile?.Model}`] = camera.CameraProfile || {
                            Manufacturer: "",
                            Model: "",
                        }
                    })
                    for (let key in manufacturerMap) {
                        let manufacturer = manufacturerMap[key]
                        if (manufacturer?.Manufacturer?.length > 0 && manufacturer?.Model?.length > 0)
                        filters.push([['Manufacturer', 'equal', manufacturer?.Manufacturer],['Model', 'equal', manufacturer?.Model]])
                    }
                    let pag = hoTableInitPaginationInfo();
                    pag.pagination.page.size = paginationInfo.pagination.page.size
                    pag.pagination.filters = filters;
                    apiManufacturerList(this.props, pag,
                        (_, rsp2, req) => {
                            this.setState({
                                manufacturers: rsp2?.RecordList,
                                cameras: rsp?.UserList,
                                total: rsp?.TotalNum,
                                status: 2,
                            })
                        },
                        () => {
                            this.setState({
                                cameras: rsp?.UserList,
                                total: rsp?.TotalNum,
                                status: 2,
                            })
                        }
                    )
                },
                () => {
                    this.setState({
                        status: 3,
                    })
                }
            )
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    // 面包屑
    mkBreadcrumb() {
        return <HoBreadcrumb 
            items={[{title: "用户管理", url: "member"}, {title: "摄像头"}]}
        />
    }

    mkHeaderInfo() {
        let { reqUserInfo } = this.props;
        let title = "摄像头"
        let buttons = (<div>
            {
                reqUserInfo?.user?.role?.type === "superadmin" ? <Fragment>
                    <HoButton icon="unordered-list" onClick={() => {
                        window.goToMenu("member/camera/manufacturer");
                    }}>厂商能力配置</HoButton>
                    <HoGenButton onClick={() => {
                        this.setState({
                            genPageVisible: true,
                            genData: {
                                number: 10,
                            },
                        })
                    }}>生成账户</HoGenButton>
                </Fragment> :undefined
            }
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick()} />
        </div>);
        return { title: <span>{title}</span>, buttons: buttons};
    }

    mkCameraControl() {
        let { data, cameraControlPageVisible } = this.state;

        return <CameraControl
            {...this.props}
            camera={data}
            visible={cameraControlPageVisible}
            cancelOnClick={() => {
                this.setState({
                    cameraControlPageVisible: false,
                })
            }}
            cameraOnClick={(cmd, action, speed) => {
                switch (cmd) {
                    case "start":
                        apiCameraContrlStart(this.props, data?.SipNum, action, speed)
                        break;
                    case "stop":
                        apiCameraContrlStop(this.props, data?.SipNum)
                        break;
                    default:
                        break;
                }
            }}
        />
    }

    mkPublishModal(){
        let { publishPageVisible, data } = this.state;

        return <HoAlertDialog
            title="发行账号"
            visible={publishPageVisible}
            onOkClick={() => {
                apiMemberAccountPublish(this.props, data,
                    () => {
                        this.setState({
                            publishPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick()
                        })
                    }
                );
            }}
            onCancelClick={() => {
                this.setState({
                    publishPageVisible: false,
                });
            }}
        >
            <Form className="member-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="备注">
                    <Input.TextArea rows={3} value={data.PublishRemark} onChange={(e) => {
                        data.PublishRemark = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input.TextArea>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkModModal(){
        let { modPageVisible, data } = this.state;

        return <HoAlertDialog
            title="编辑设备"
            visible={modPageVisible}
            onOkClick={() => {
                dataModMember(this.props, data,
                    () => {
                        this.setState({
                            modPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick()
                        })
                    }
                );
            }}
            onCancelClick={() => {
                this.setState({
                    modPageVisible: false,
                });
            }}
        >
            <Form className="member-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="名称" {...hoValidateEmptyFormat(data.NickName, "名称不能为空")}>
                    <Input value={data.NickName} onChange={(e) => {
                        data.NickName = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkManufacturerPage() {
        let { addManufacturerPageVisible, manufacturer } = this.state;

        return <HoAlertDialog
            title="新增厂商能力"
            visible={addManufacturerPageVisible}
            onOkClick={() => {
                apiManufacturerAdd(this.props, manufacturer,
                    () => {
                        this.setState({
                            addManufacturerPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick()
                        })
                    }
                );
            }}
            onCancelClick={() => {
                this.setState({
                    addManufacturerPageVisible: false,
                });
            }}
        >
            <Form className="member-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="名称" {...hoValidateEmptyFormat(manufacturer?.DisManufacturer, "名称不能为空")}>
                    <Input value={manufacturer?.DisManufacturer} onChange={(e) => {
                        manufacturer.DisManufacturer = e.target.value;
                        this.setState({
                            manufacturer: manufacturer,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="厂商标识">
                    <Input disabled value={manufacturer?.Manufacturer}></Input>
                </Form.Item>
                <Form.Item label="设备型号">
                    <Input disabled value={manufacturer?.Model}></Input>
                </Form.Item>
                <Form.Item label="支持云台">
                    <Switch checked={!!manufacturer?.SupportPtz} onChange={(e) => {
                        manufacturer.SupportPtz = e ? 1 : 0;
                        this.setState({
                            manufacturer: manufacturer,
                        })
                    }}></Switch>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkGenModal(){
        const { genPageVisible, genData } = this.state;

        return <HoAlertDialog
            className="member-modal"
            title="生成摄像头账户"
            visible={genPageVisible}
            onOkClick={() => {
                apiCameraAccountGen(this.props, genData?.number,
                    () => {
                        this.setState({
                            genPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick();
                        });
                    }
                )
            }}
            onCancelClick={() => {
                this.setState({
                    genPageVisible: false,
                });
            }}
        >
            <Form className="member-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="数量">
                    <InputNumber value={genData?.number} max={10} min={1} onChange={(e) => {
                        genData.number = e;
                        this.setState({
                            genData: genData,
                        })
                    }}></InputNumber>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, search, paginationInfo, status, cameras, total } = this.state;

        // console.log("mkTable", search);
        const stateOptions = search.stateMap.find((e) => search.stateType === e.value);
        const stateMenu = (
            <Menu onClick={(e) => {
                let {search, paginationInfo} = this.state;
                search.stateType = e.key;
                this.searchOnChange(search, paginationInfo)
            }}>
                {search.stateMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        return <div className="ho-member">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{ display: 'flex' }}>
                        <Dropdown overlay={stateMenu}>
                            <Button className={"type1 " + search.stateType} type="primary" size="large">
                                {stateOptions?.title} <Icon type="down" />
                            </Button>
                        </Dropdown>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder="搜索设备名称或SIP号"
                            enterButton
                            allowClear={true}
                            onSearch={(text) => {
                                let {search, paginationInfo} = this.state;
                                search.text = text;
                                this.searchOnChange(search, paginationInfo)
                            }}
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    let {search, paginationInfo} = this.state;
                                    search.text = "";
                                    this.searchOnChange(search, paginationInfo)
                                }
                            }}
                            /> 
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={cameras}
                rowKey={'UserId'}
                loading={status === 1}
                onColumnChange={(paginationInfo) => {
                    this.searchOnChange(this.state.search, paginationInfo)
                }}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount(){
    }

    componentDidMount(){
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const { title, buttons } = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const modComponent = this.mkModModal();
        const genComponent = this.mkGenModal();
        const publishComponent = this.mkPublishModal();
        const addManufacturerComponent = this.mkManufacturerPage();
        const cameraControlComponent = this.mkCameraControl();
        
        return (<div>
            {modComponent}
            {genComponent}
            {publishComponent}
            {addManufacturerComponent}
            {cameraControlComponent}
            {breadcrumbComponent}
            {/* {statisticsComponent} */}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(CameraCT);

