/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Menu, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer
} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk2 } from '../../../../util/netReqRD';
import { 
    dataListMediaServerGroup2, dataModLicense, ACT_NET_MEDIA_SERVER_GROUP_LIST, dataAddMediaServerGroup, dataDelMediaServerGroup, dataModMediaServerGroup,
} from './serverRD';
import {getLoginUserInfo} from '../../../login/loginRD'

import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoTooltipIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoDeleteAlertDialog,
} from '../../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../util/tableComponent';
import { GroupType, MediaGroupForm } from './mediaGroupForm';

import './server.css';

class ServerGroupCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 数据处理回调
        this.dataCommonSuccessHd = this.dataCommonSuccessHd.bind(this);
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowEditOnClick = this.tblRowEditOnClick.bind(this);
        this.tblRowDelOnClick = this.tblRowDelOnClick.bind(this);
        this.tblRowDetailOnClick = this.tblRowDetailOnClick.bind(this);
        
        // 主页面按钮
        this.mainAddOnClick = this.mainAddOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        const columns = [
            hoTableColumn('组名', 'GroupName', 'str', false, false, '10%'),
            hoTableColumn('类型', 'GroupType', 'str', false, false, '10%', {
                render: (text, record) => GroupType.getDescription(`${text}`)
            }),
            hoTableColumn('备注', 'Remark', 'str', false, false, '10%'),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                if (record.Id) {
                    actions = [
                        { title: '查看', onClick: this.tblRowDetailOnClick },
                        { title: '编辑', onClick: this.tblRowEditOnClick },
                        { title: '删除', onClick: this.tblRowDelOnClick },
                    ]
                } else {
                    actions = [
                        { title: '查看', onClick: this.tblRowDetailOnClick }
                    ]
                }
                return hoTableColumnActionButton(actions, record);
            }, '10%'),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            pageVisiable: false,
            data: undefined,
            objOrg: undefined,
            objCopy: undefined,
        }
    }

    trans2ApiData(data) {

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            d[key] = data[key].value;
        }
        transInt(d, 'GroupType');
        return d;
    }

    trans2LocalData(data) {

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = `${d[name].value}`;
            }
        }

        let d = {};
        if (data) {
            for (let key in data) {
                d[key] = { value: data[key] }
            }
            transInt(d, 'GroupType');
        } else {
            d = undefined;
        }
        return d;
    }

    dataCommonSuccessHd(dispatch, rspBody, reqBody) {
        dataListMediaServerGroup2(this.props, this.state.paginationInfo, this.dataListSuccessHd)
    }

    dataListSuccessHd(dispatch, rspBody, reqBody) {
        if (rspBody.RecordList) {
            rspBody.RecordList.unshift({
                GroupName: "信令组",
                GroupType: 1,
                Id: 0,
                Remark: "信令组",
            })
        }
        this.setState({
            data: {
                paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
                records: rspBody.RecordList,
            }
        })
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo) {
        dataListMediaServerGroup2(this.props, paginationInfo, this.dataListSuccessHd)
    }

    // '编辑'按钮被点击
    tblRowEditOnClick(e, record) {
        // console.log('edit on click', record)
        e.stopPropagation();
        let data = this.trans2LocalData(record);
        this.setState({
            pageVisiable: true,
            operation: 'edit',
            objOrg: data,
            objCopy: data,
        })
    }

    // '删除'按钮被点击
    tblRowDelOnClick(e, record) {
        let that = this;
        Modal.confirm({
            content: <div>是否删除<span className="warn">{record.GroupName}</span>？</div>,
            onOk() {
                dataDelMediaServerGroup(that.props, { Id: record.Id }, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
    }

    // '查看'按钮被点击
    tblRowDetailOnClick(e, record) {
        if (record.Id) {
            window.goToMenu('devops/setting/server', `group/media/${record.Id}`, record)
        } else {
            window.goToMenu('devops/setting/server', `group/signal/${record.Id}`, record)
        }
    }

    // '新增'按钮被点击
    mainAddOnClick() {
        this.setState({
            pageVisiable: true,
            operation: 'add',
            objOrg: undefined,
            objCopy: undefined,
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        dataListMediaServerGroup2(this.props, this.state.paginationInfo, this.dataListSuccessHd)
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[{title: "配置管理", url: "devops/setting"}, {title: "服务器"}, {title: "群组"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoNewButton onClick={this.mainAddOnClick}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>群组</span>, buttons: buttons}; 
    }

    mkPage() {
        let that = this;
        let { objCopy, pageVisiable, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title={operation === 'add' ? "新增" : "编辑"}
            visible={pageVisiable}
            okText={operation === 'add' ? "确定" : "保存"}
            onOkClick={() => {
                if (operation === 'add') {
                    dataAddMediaServerGroup(that.props, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                } else {
                    dataModMediaServerGroup(that.props, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                }
            }}
            onCancelClick={() => {
                this.setState({
                    pageVisiable: false,
                })
            }}
        >
            <MediaGroupForm
                fields={objCopy}
                onChange={(changedFields) => {
                    this.setState(({ objCopy }) => ({
                        objCopy: { ...objCopy, ...changedFields },
                    }));
                }}
            />
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data } = this.state;
        const { getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return <div className="ho-server-group-table">
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'Id'}
                loading={getStatus === 1}
                onColumnChange={this.tblColOnChange}
                onRowDoubleClick={this.tblRowDetailOnClick}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount(){
        this.props.dispatch(actNetRequestClear(ACT_NET_MEDIA_SERVER_GROUP_LIST));
    }

    componentDidMount(){
        dataListMediaServerGroup2(this.props, this.state.paginationInfo, this.dataListSuccessHd)
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const pageComponent = this.mkPage();

        return (<div>
            {pageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_MEDIA_SERVER_GROUP_LIST), 
});


export default connect(
    mapState, 
    null
)(ServerGroupCT);

