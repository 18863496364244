/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ADSMap from '../../component/map/adsMap';
import './test.css';

class TestCT extends Component{

    componentWillUnmount(){
    }

    componentDidMount(){
    }

    render(){
        return <ADSMap style={{height: 'calc(100vh - 70px - 64px)', width: '100%'}}/>
    }
}

const mapState = (state) => ({

});


export default connect(
    mapState, 
    null
)(TestCT);

