/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import moment from 'moment';
import { Menu, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer
} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import { 
    dataListLicense, dataModLicense, dataNewLicense,
} from './mcuRD';
import {getLoginUserInfo} from '../../../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, hoMessage, HoTooltipIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoDeleteAlertDialog,
} from '../../../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../../util/tableComponent';
import { GenForm } from './genForm';

import './mcu.less';

class LicenseCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 数据处理回调
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowDisableOnClick = this.tblRowDisableOnClick.bind(this);
        this.tblRowEnableOnClick = this.tblRowEnableOnClick.bind(this);
        this.tblRowPublishOnClick = this.tblRowPublishOnClick.bind(this);
        
        // 主页面按钮
        this.mainGenOnClick = this.mainGenOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainSearchOnClick = this.mainSearchOnClick.bind(this);
        this.mainSearchOnChange = this.mainSearchOnChange.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        const columns = [
            hoTableColumn('状态', 'IsForbidden', 'int', false, false, '7%', {
                render: (text, record) => hoTableColumnTagButton([{color: record.IsForbidden ? red[5] : blue[5], tag: record.IsForbidden ? '禁用' : '启用'}]),
            }),
            hoTableColumn('激活码', 'Identity', 'str', false, false, '7%'),
            hoTableColumn('产品编码', 'ProductCode', 'str', false, false, '15%'),
            hoTableColumn('服务器序列号', 'BindSn', 'str', false, false, '15%'),
            hoTableColumnDate('发行时间', 'IssuedTime', false, '15%', {
                render: (text, record) => record.Issued ? record.IssuedTime : "未发行"
            }),
            hoTableColumnDate('激活时间', 'ActiveTime', false, '15%', {
                render: (text, record) => record.IsActive ? record.ActiveTime : "未激活"
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                if (!record.Issued) {
                    actions.push({ title: '发行', onClick: this.tblRowPublishOnClick })
                }

                if (record.IsForbidden) {
                    actions.push({ title: '启用', onClick: this.tblRowEnableOnClick })
                } else {
                    actions.push({ title: '禁用', onClick: this.tblRowDisableOnClick })
                }
                return hoTableColumnActionButton(actions, record);
            }, '15%'),
        ];

        this.state = {
            columns: columns,
            data: undefined,
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
            },
            genPageVisiable: false,
            genData: undefined,
        }
    }

    trans2ApiData(data) {
        let d = {}
        for (let key in data) {
            d[key] = data[key].value
        }
        return d;
    }

    searchOnChange(search, pag) {
        let filter1 = [];
        let filter2 = [];
        if (search.text.length > 0) {
            filter1.push(['Identity', 'like', search.text]);
            filter2.push(['BindSn', 'like', search.text]);
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = [
            filter1,
            filter2,
        ]
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            loading: true,
        })
        dataListLicense(this.props, paginationInfo, this.dataListSuccessHd)
    }

    dataListSuccessHd(dispatch, rspBody, reqBody){
        this.setState({
            loading: false,
            data: {
                paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
                records: rspBody.RecordList,
            }
        })
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        this.searchOnChange(this.state.search, paginationInfo)
    }

    // '禁用'按钮被点击
    tblRowDisableOnClick(e, record){
        e.stopPropagation();
        let that = this;
        let license = Object.assign({}, record);
        license.IsForbidden = 1;
        dataModLicense(this.props, license, () => {
            that.mainRefreshOnClick()
        });
    }

    // '启用'按钮被点击
    tblRowEnableOnClick(e, record){
        e.stopPropagation();
        let that = this;
        let license = Object.assign({}, record);
        license.IsForbidden = 0;
        dataModLicense(this.props, license, () => {
            that.mainRefreshOnClick()
        });
    }

    // '发行'按钮被点击
    tblRowPublishOnClick(e, record){
        e.stopPropagation();
        let that = this;
        let license = Object.assign({}, record);
        license.Issued = 1;
        license.IssuedTime = moment().format("YYYY-MM-DD HH:mm:ss");
        dataModLicense(this.props, license, () => {
            that.mainRefreshOnClick()
        });
    }

    // '生成'按钮被点击
    mainGenOnClick() {
        this.setState({
            genPageVisiable: true,
            genData: undefined,
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text) {
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[{title: "配置管理", url: "devops/setting"}, {title: "License", url: "devops/setting/license"}, {title: "MCU"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoNewButton onClick={this.mainGenOnClick}>生成</HoNewButton>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>MCU License</span>, buttons: buttons}; 
    }

    mkGenPage() {
        let that = this;
        let { genData, genPageVisiable } = this.state;

        return <HoAlertDialog
            width="50%"
            title="生成MCU License"
            visible={genPageVisiable}
            okText="生成"
            onOkClick={() => {
                dataNewLicense(that.props, that.trans2ApiData(genData), () => {
                    this.setState({
                        genData: undefined,
                        genPageVisiable: false,
                    })
                    that.mainRefreshOnClick()
                })
            }}
            onCancelClick={() => {
                this.setState({
                    genPageVisiable: false,
                })
            }}
        >
            <GenForm
                fields={genData}
                onChange={(changedFields) => {
                    this.setState(({ genData }) => ({
                        genData: { ...genData, ...changedFields },
                    }));
                }}
            />
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data, loading } = this.state;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return <div className="ho-license-table">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{display: 'flex'}}>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder="搜索激活码或服务器序列号"
                            enterButton
                            allowClear={true}
                            onSearch={this.mainSearchOnClick}
                            onChange={this.mainSearchOnChange}
                            />
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'Identity'}
                loading={loading}
                onColumnChange={this.tblColOnChange}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const genPageComponent = this.mkGenPage();

        return (<div>
            {genPageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(LicenseCT);

