/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { netRequestThunk2 } from "../../../util/netReqRD";
import { loginNoauthHd } from "../../login/loginRD";
import { actions } from './pipline';

const ACT_SET_PRACTICE_CONFIG = 'ACT_SET_PRACTICE_CONFIG';
const ACT_SET_PRACTICE_PIPLINE = 'ACT_SET_PRACTICE_PIPLINE';
const ACT_CLEAN_PRACTICE = 'ACT_CLEAN_PRACTICE';

export const ACT_NET_SIPP_ACCOUNT_REGISTER = 'sippAccountRegister';

// reducer handle
const defaultState = {
    config: {
        title: '',
        conferences: [5, 25],
        members: [2, 4],
        actions: actions.flatten(),
        scripts: [20, 40],
        operationInterval: [5, 10],
    }, 
    pipline: undefined,
}
const practiceState = Object.assign({}, defaultState);

export const practice_reducer = (state=practiceState, action) => {
    switch(action.type){
        case ACT_SET_PRACTICE_CONFIG: {
            return {
                ...state,
                config: action.config
            };
        }
        case ACT_SET_PRACTICE_PIPLINE: {
            return {
                ...state,
                pipline: action.pipline
            };
        }

        case ACT_CLEAN_PRACTICE:
            return defaultState;

        default:
            return state;
    }
}


// 取数据
export const getPracticeConfig = (state) => {
    return state.practice !== undefined ? state.practice.config : {};
}

export const getPracticePipline = (state) => {
    return state.practice !== undefined ? state.practice.pipline : undefined;
}

// 设置数据
export function clearPractice(dispatch) {
    return dispatch({
        type: ACT_CLEAN_PRACTICE,
    })
}

export function setPracticeConfig(dispatch, config) {
    dispatch({
        type: ACT_SET_PRACTICE_CONFIG, 
        config: config, 
    })
}

export function setPracticePipline(dispatch, pipline) {
    dispatch({
        type: ACT_SET_PRACTICE_PIPLINE, 
        pipline: pipline, 
    })
}

export function dataSippAccountRegister(props, successFun = undefined, errorFun = undefined) {
    const { reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/sippAccountRegister2'
    const msgBody = {
        RegisterCount: 100,
        Desc: 1,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_SIPP_ACCOUNT_REGISTER, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_SIPP_ACCOUNT_REGISTER, successFun, errorFun))
    );
}


