/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import QueueAnim from 'rc-queue-anim';

import { PageHeader, Menu, Tooltip, Button, Descriptions, Typography, Icon, Modal, notification, Tabs, Divider, Switch, Dropdown, Spin } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import {
    HoForm, HoFormRowColInput, HoFormRowColTime, HoFormRowColSelect, HoSelectOption, HoFormRowColTextArea,
    hoValidateEmptyFormat, hoValidateCustomFormat, HoFormRowColDate,
} from '../../util/formComponent';
import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoMoreDropdownButton, HoRefreshButton, HoStartButton, HoStopButton, HoNewButton, HoButton, HoAlertDialog, HoDivider, HoIconFont, HoLinkButton, HoBackButton
} from '../../util/hoComponent';
import { 
    HoTable, hoTableColumn, hoTableColumnAction, hoTableColumnTagButton, hoTableColumnActionButton, hoTableInitPaginationInfo
} from '../../util/tableComponent';
import {
    getLoginUserInfo
} from '../login/loginRD';
import { 
    dataGetRoom, dataModRoom, dataDelRoom, dataStartRoom, dataEndRoom,
    dataAddMember, dataDelMember, dataInviteMember, dataKickMember, dataPushStartMember, dataPushStopMember, dataMuteMember,
    getRoomData, getRoomDataSuccessHd, listLayoutData, dataListLayout, listLayoutDataSuccessHd, dataSetLayout, dataUnsetLayout,
} from './roomRD';
import { sipStateTags, confStateTags, confStateTags2 } from '../../component/common/common';
import { getTimeRangeDesc } from '../../util/logic';
import { initMqttClient } from '../../component/mqtt/mqtt';
import SelectMember from '../../component/subpages/selectMember';
import VideoPlayer from '../../component/videoPlayer/videoPlayer';
import SelectView from '../../component/subpages/selectView';
import UnlockViewMember from '../../component/subpages/unlockViewMember';
import EventAction from '../../component/subpages/eventAction';
import { dataListMediaServer, dataListSignalServer } from '../devops/setting/server/serverRD';
import { deviceTypeMap } from '../../defined/memberDevice';

class RoomDetailCT extends Component{

    constructor(props){
        super(props);

        this.GROUP_NOTIFY_MAX_ITEM = 20;    // 聚合通知单次最大个数
        this.GROUP_NOTIFY_INTERVAL = 1000;  // 聚合通知防抖间隔（毫秒）
        this.INVITE_MEMBER_TIMEOUT = 60000;        // 邀请成员超时时间（毫秒）
        
        /********************************** 主页面 ***********************************/
        // 额外操作
        this.mqttInit = this.mqttInit.bind(this);
        
        // 数据回调
        this.dataRoomGetSuccessHd = this.dataRoomGetSuccessHd.bind(this);
        this.dataCommRoomDataSuccessHd = this.dataCommRoomDataSuccessHd.bind(this);
        this.dataInviteMemberSuccessHd = this.dataInviteMemberSuccessHd.bind(this);
        this.dataInviteMemberErrorHd = this.dataInviteMemberErrorHd.bind(this);

        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainButtonsOnClick = this.mainButtonsOnClick.bind(this);

        // 主页面回调
        this.tblRenderState = this.tblRenderState.bind(this);
        this.tblRenderPerm = this.tblRenderPerm.bind(this);
        this.tblRenderAction = this.tblRenderAction.bind(this);
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblRowInviteOnClick = this.tblRowInviteOnClick.bind(this);
        this.tblRowKickOnClick = this.tblRowKickOnClick.bind(this);
        this.tblRowPushStartOnClick = this.tblRowPushStartOnClick.bind(this);
        this.tblRowPushStopOnClick = this.tblRowPushStopOnClick.bind(this);
        this.tblRowDeleteOnClick = this.tblRowDeleteOnClick.bind(this);
        this.tblRowViewOnClick = this.tblRowViewOnClick.bind(this);
        this.tblRowMuteOnClick = this.tblRowMuteOnClick.bind(this);
        this.tblSelectOnChange = this.tblSelectOnChange.bind(this);

        /********************************** 子页面(修改) ***********************************/
        // 子页面回调
        this.modPageParamOnChange = this.modPageParamOnChange.bind(this);
        
        // 子页面按钮
        this.modPageOkOnClick = this.modPageOkOnClick.bind(this);
        this.modPageCancelOnClick = this.modPageCancelOnClick.bind(this);

        // 子页面
        this.mkModModal = this.mkModModal.bind(this);

        /********************************** 子页面（编辑设备） ***********************************/
        // 子页面按钮
        this.editMemberPageCloseOnClick = this.editMemberPageCloseOnClick.bind(this);

        // 子页面回调
        this.editMemberPageOnChange = this.editMemberPageOnChange.bind(this);

        /********************************** 子页面（播放器） ***********************************/
        // 子页面按钮
        this.videoPlayerPageCloseOnClick = this.videoPlayerPageCloseOnClick.bind(this);

        /********************************** 子页面（锁定画面） ***********************************/
        // 子页面按钮
        this.lockViewPageOkOnClick = this.lockViewPageOkOnClick.bind(this);
        this.lockViewPageCloseOnClick = this.lockViewPageCloseOnClick.bind(this);

        /********************************** 子页面（解锁画面） ***********************************/
        // 子页面按钮
        this.unlockViewPageOkOnClick = this.unlockViewPageOkOnClick.bind(this);
        this.unlockViewPageCloseOnClick = this.unlockViewPageCloseOnClick.bind(this);

        // 子页面回调
        this.unlockViewPageSelectOnChange = this.unlockViewPageSelectOnChange.bind(this);

        this.inviteStatus = {};
        this.state = {
            flag: true,
            roomInfo: {
                ConferenceId: parseInt(this.props.match.params.id)
            },
            paginationInfo: hoTableInitPaginationInfo(),
            
            // 编辑成员
            editMemberPageVisible: false,

            // 预览推流
            videoPlayerPageVisible: false,
            
            // 修改预约信息
            modPageVisible: false,
            modRoomInfo: undefined,
            modRoomValidate: {
                Title: {validateStatus:"success"},
            },

            // mqtt通知
            mqttClient: undefined,
            mqttSubState: 'init',

            // 锁定会场画面
            lockViewPageVisible: false,
            lockViewInfo: {
                SipNums: [],
                LayoutId: 1,
                Views: [],
            },

            // 解锁会场画面
            unlockViewPageVisible: false,
            unlockViewSelectedKeys: [],
            unlockViewMembers: [],

            // 批量操作
            selectedRowKeys: [],
            selectedRows: [],
            
            // 会议事件
            eventPaginationInfo: hoTableInitPaginationInfo(),

        }
    }

    codeFix(code) {
        return ("00000000" + code).slice(-9)
    }

    pushingCondition(pusherInfo, record){
        if (!pusherInfo){
            return false;
        }
        const startTime = moment(pusherInfo.StartTime).unix()
        const endTime = moment(pusherInfo.EndTime).unix()
        const invalidTime = moment("2200-01-01 00:00:00").unix()
        
        if (record){
            if (((endTime === invalidTime && startTime !== invalidTime) || (startTime > endTime && startTime !== invalidTime))
                && pusherInfo.SrcSipNum === record.SipNum && record.ConfState === "joined"){
                return true;
            } else {
                return false;
            }
        } else {
            if (((endTime === invalidTime && startTime !== invalidTime) || (startTime > endTime && startTime !== invalidTime))){
                return true;
            } else {
                return false;
            }
        }
    }

    mqttInit(mqttSubState = 'init'){
        let that = this;
        let { roomInfo } = this.state;
        let { dispatch, reqUserInfo } = this.props;

        if (mqttSubState !== 'init') {
            return;
        }

        const clientId = `${reqUserInfo.mqttInfo.ClientId}_${moment().unix()}`;
        
        let mqttClient = initMqttClient(dispatch, 
            reqUserInfo.mqttInfo.ServerHost,
            reqUserInfo.mqttInfo.ServerPort,
            reqUserInfo.mqttInfo.Accout,
            reqUserInfo.mqttInfo.Password,
            clientId,
        );

        const tagTopic = `cs/mntn/notify/conference/${roomInfo.ConferenceId}`;
        console.log(`mqtt ${clientId} connected and will subscribe topic: ${tagTopic}`);

        mqttClient.on('connect', function () {
            mqttClient.subscribe(tagTopic, function (err) {
                if (!err) {
                    console.info(`mqtt subscribe success conference room: ${roomInfo.ConferenceId}`);
                    that.setState({
                        mqttResubscribeSuccess: 'done',
                    })
                }
            })
        })

        mqttClient.on('message', function (topic, message) {
            // message is Buffer
            if (topic === tagTopic){
                const content = JSON.parse(message.toString())
                let key = "";
                let description = undefined;
                // console.log(content)
                switch(content.MsgCode){
                    case 5000: {// 会议开始
                        console.log(`conference begin. ${content.ConfInfo?.ConferenceId}:${content.ConfInfo?.Title}`);

                        key = `confernece.${content.ConfInfo?.ConferenceId}.start.result`;
                        notification.info({
                            message: '会议开始通知',
                            description: <span>你的会议<span className="ho-text-hit">{content.ConfInfo?.Title}</span>已经开始</span>,
                            btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                            key: key,
                        });
                        that.mainRefreshOnClick()
                        break;
                    }
                    case 5001: {// 会议结束
                        console.log(`conference end. ${content.ConfInfo?.ConferenceId}:${content.ConfInfo?.Title}`);

                        key = `confernece.${content.ConfInfo?.ConferenceId}.start.result`;
                        notification.info({
                            message: '会议结束通知',
                            description: <span>你的会议<span className="ho-text-hit">{content.ConfInfo?.Title}</span>已结束</span>,
                            btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                            key: key,
                        });
                        break; 
                    }
                    case 5002: {// 设备入会
                        console.log(`member join. ${content.MemberInfo?.UserId}:${content.MemberInfo?.NickName}`);
                        if (that.joinTimeout) {
                            clearTimeout(that.joinTimeout);
                            that.joinTimeout = undefined;
                        } 

                        if (that.joinList === undefined){
                            that.joinList = [];
                        } 

                        if (that.joinList.length >= that.GROUP_NOTIFY_MAX_ITEM) {
                            key = `confernece.member.${content.MemberInfo?.UserId || ""}.join.result`;
                            notification.info({
                                message: '设备入会通知',
                                description: <span><span className="ho-text-hit">{that.joinList.slice(0, 10).map(item => item.NickName).join(",")}...</span>等<span className="ho-text-hit">{that.joinList.length}</span>个设备已入会</span>,
                                btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                                key: key,
                            });
                            that.joinList.forEach(item => {that.inviteStatus[item.SipNum] = undefined;});
                            that.setState({flag: !that.state.flag});
                            that.joinList = [];
                        } else {
                            that.joinTimeout = setTimeout(() => {
                                key = `confernece.member.join.result`;
                                if (that.joinList.length > 1) {
                                    description = <span><span className="ho-text-hit">{that.joinList.slice(0, 10).map(item => item.NickName).join(",")}...</span>等<span className="ho-text-hit">{that.joinList.length}</span>个设备已入会</span>
                                } else if (that.joinList.length === 1) {
                                    description = <span><span className="ho-text-hit">{that.joinList[0].NickName}</span>已入会</span>
                                }
                                notification.info({
                                    message: '设备入会通知',
                                    description: description,
                                    btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                                    key: key,
                                });
                                that.joinList.forEach(item => {that.inviteStatus[item.SipNum] = undefined;});
                                that.setState({flag: !that.state.flag});
                                that.joinTimeout = undefined;
                                that.joinList = [];
                            }, that.GROUP_NOTIFY_INTERVAL);
                            
                            that.joinList.push(content.MemberInfo);
                        }
                        
                        break;
                    }
                    case 5003: {// 设备离会
                        console.log(`member leave. ${content.MemberInfo?.UserId}:${content.MemberInfo?.NickName}`);

                        if (that.leaveTimeout) {
                            clearTimeout(that.leaveTimeout);
                            that.leaveTimeout = undefined;
                        } 

                        if (that.leaveList === undefined){
                            that.leaveList = [];
                        }
                        if (that.leaveList.length >= that.GROUP_NOTIFY_MAX_ITEM) {
                            key = `confernece.member.${content.MemberInfo?.UserId || ""}.leave.result`;
                            notification.info({
                                message: '设备离会通知',
                                description: <span><span className="ho-text-hit">{that.leaveList.slice(0, 10).join(",")}...</span>等<span className="ho-text-hit">{that.leaveList.length}</span>个设备已离会</span>,
                                btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                                key: key,
                            });
                            that.leaveList = [];
                        } else {
                            that.leaveTimeout = setTimeout(() => {
                                key = `confernece.member.leave.result`;
                                if (that.leaveList.length > 1) {
                                    description = <span><span className="ho-text-hit">{that.leaveList.slice(0, 10).join(",")}...</span>等<span className="ho-text-hit">{that.leaveList.length}</span>个设备已离会</span>
                                } else if (that.leaveList.length === 1) {
                                    description = <span><span className="ho-text-hit">{that.leaveList[0]}</span>已离会</span>
                                }
                                notification.info({
                                    message: '设备离会通知',
                                    description: description,
                                    btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                                    key: key,
                                });
                                that.leaveTimeout = undefined;
                                that.leaveList = [];
                            }, that.GROUP_NOTIFY_INTERVAL);
                            
                            that.leaveList.push(content.MemberInfo?.NickName || "");
                        }
                        break;
                    }
                    case 5004: // 选流
                        key = `confernece.member.${content.MemberInfo?.UserId || ""}.select.result`;
                        notification.info({
                            message: '设备锁定画面通知',
                            description: <span><span className="ho-text-hit">{content.MemberInfo?.NickName || ""}</span>画面已锁定</span>,
                            btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                            key: key,
                        });
                        break;

                    default:
                        break;
                }
                that.mainRefreshOnClick()
            }
        })

        that.setState({
            mqttSubState: 'doing',
            mqttClient: mqttClient,
        })
    }

    tblRenderState(text, record){
        let tags = [];
        const {roomInfo} = this.state;
        const {PusherInfo, State} = roomInfo || {}
        
        // tags = tags.concat(sipStateTags(text, record, State));
        tags = tags.concat(confStateTags2(text, record, State));
        
        if (PusherInfo && this.pushingCondition(PusherInfo, record)){
            tags.push({color: red[3], tag:'直播中'})
        }
        
        return hoTableColumnTagButton(tags);
    }

    tblRenderPerm(text, record){
        let icons = [];

        const hearTip = "可听";
        const hearType = "icon-erji-enable"
        icons.push(<Tooltip key="hear" title={hearTip}><HoIconFont style={{marginRight: '0.5rem'}} type={hearType}/></Tooltip>)

        const speakTip = !record.Mute ? "可说" : "不可说";
        const speakType = !record.Mute ? "icon-maikefeng-enable" : "icon-maikefeng-disable";
        icons.push(<Tooltip key="speak" title={speakTip}><HoIconFont style={{marginRight: '0.5rem'}} type={speakType}/></Tooltip>)
        
        const lockTip = record.LayoutInfo.LayoutMode === 1 ? "锁定布局" : "自由布局";
        const lockType = record.LayoutInfo.LayoutMode === 1 ? "icon-buju-disable" : "icon-buju-enable";
        icons.push(<Tooltip key="lock" title={lockTip}><HoIconFont style={{marginRight: '0.5rem'}} type={lockType}/></Tooltip>)
        
        return icons;
    }

    tblRenderAction(text, record){
        const {roomInfo} = this.state;
        const {PusherInfo} = roomInfo || {};

        let actions = [];
        let actionFlag = true;
        if ((roomInfo.State === "tostart" || roomInfo.State === "inprogress")){
            switch(record.ConfState){
                case 'joined': 
                    if (this.pushingCondition(PusherInfo, record)){
                        actions = [
                            {title: '预览', onClick: this.tblRowViewOnClick},
                            {title: '停止直播', onClick: this.tblRowPushStopOnClick},
                        ];
                    } else {
                        actions = [
                            {title: '开始直播', onClick: this.tblRowPushStartOnClick},
                        ];
                    }

                    let menus = [];
                    if (!record.Mute) {
                        menus.push(<Menu.Item key='mute_enable'><HoLinkButton onClick={(e) => this.tblRowMuteOnClick(e, record, true)}>禁音</HoLinkButton></Menu.Item>)
                    } else {
                        menus.push(<Menu.Item key='mute_disable'><HoLinkButton onClick={(e) => this.tblRowMuteOnClick(e, record, false)}>取消禁音</HoLinkButton></Menu.Item>)
                    }

                    actions.push({title: '踢出', onClick: this.tblRowKickOnClick})
                    actions.push({title: '更多', type:'dropdown', menu: <Menu>{menus}</Menu>})
                    
                    break;
                case 'notjoin':
                    if (roomInfo.State === "tostart"){
                        actions = [
                            {title: '删除', onClick: this.tblRowDeleteOnClick},
                        ];
                    } else {
                        if (this.inviteStatus[record.SipNum] === 'inviting'){
                            actionFlag = false;
                            actions = <span style={{color: red[3]}} ><Icon style={{marginRight: '0.5rem'}} type="loading"/>正在邀请...</span>
                        } else {
                            // 正在通话中
                            if (record.CallState !== 'idle') {
                                actions = [
                                    {title: '邀请', onClick: this.tblRowInviteOnClick},
                                    {title: '删除', onClick: this.tblRowDeleteOnClick},
                                ];
                            // 空闲状态
                            } else {
                                actions = [
                                    {title: '邀请', onClick: this.tblRowInviteOnClick},
                                    {title: '删除', onClick: this.tblRowDeleteOnClick},
                                ];
                            }
                        }
                    }
                    break;
                default: 
                    break;
            }
        }
        
        if (actionFlag){
            return hoTableColumnActionButton(actions, record);
        } else {
            return actions;
        }
    }

    dataCommRoomDataSuccessHd(dispatch, rspBody, reqBody, refresh = true) {
        if (refresh) {
            this.mainRefreshOnClick();
        }
    }

    dataRoomGetSuccessHd(dispatch, rspBody, reqBody){
        getRoomDataSuccessHd(dispatch, rspBody, reqBody)
        const roomInfo = {
            ...rspBody,
            // ExpectStartTime: moment(rspBody.ExpectStartTime),
            // ExpectEndTime: moment(rspBody.ExpectEndTime),
        }

        let that = this;
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = [[['Id', '=', rspBody.MsId]]];
        dataListMediaServer(that.props, paginationInfo,
            (dispatch, rsp1, req) => {
                dataListSignalServer(that.props,
                    (dispatch, rsp2, req) => {
                        let ret1 = rsp1?.RecordList?.find(e => rspBody.MsId === e.Id)
                        let ret2 = rsp2?.SsList?.find(e => rspBody.SsId === e.Id)
                        let roomInfo = {
                            ...rspBody,
                            mediaServerName: ret1?.MsName || "",
                            mediaServerGroupId: ret1?.GroupId,
                            signalServerName: ret2?.SsName || "",
                            signalServerGroupId: 0,
                        }
                        this.setState({
                            modRoomInfo: roomInfo,
                            roomInfo: roomInfo,
                            loading: false,
                        })
                    },
                    () => {
                        this.setState({
                            loading: false,
                        })
                    }
                )
            },
            () => {
                this.setState({
                    loading: false,
                })
            }
        );

        if (rspBody.State === "inprogress" || rspBody.State === "tostart"){
            this.mqttInit(this.state.mqttSubState);
        }
    }

    dataInviteMemberSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
    
        setTimeout(() => {
            let timeoutList = [];
            reqBody.SipNums.forEach((sipNum) => {
                if (that.inviteStatus[sipNum] === 'inviting'){
                    that.inviteStatus[sipNum] = undefined;
                    let ret = reqBody._PrivateMembers?.find(item => item.SipNum === sipNum);
                    timeoutList.push(ret?.NickName || '');
                }
            });
            if (timeoutList.length > 0) {
                const key = `confernece.member.join.result`;
                let description = undefined;
                if (timeoutList.length > 1) {
                    description = <span><span className="ho-text-hit">{timeoutList.slice(0, 10).join(",")}...</span>等<span className="ho-text-hit">{timeoutList.length}</span>个设备入会超时</span>
                } else if (timeoutList.length === 1) {
                    description = <span><span className="ho-text-hit">{timeoutList[0].nickName}</span>入会超时</span>
                }
                notification.error({
                    message: '设备入会通知',
                    description: description,
                    btn: (<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>),
                    key: key,
                    duration: 5,
                });
            }

            that.setState({
                flag: !that.state.flag,
            });
            
        }, that.INVITE_MEMBER_TIMEOUT);
    }

    dataInviteMemberErrorHd(dispatch, status, statusMsg, reqBody) {
        reqBody.SipNums.forEach(item => this.inviteStatus[item.SipNum] = undefined);
        return false;
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e){
        const { roomInfo, paginationInfo } = this.state;
        this.setState({
            loading: true,
            refreshEvent: true,
        }, () => {
            dataGetRoom(this.props, roomInfo, paginationInfo, this.dataRoomGetSuccessHd);
        })
        
        setTimeout(() => {
            this.setState({
                refreshEvent: false,
            })
        }, 500)
    }

    mainButtonsOnClick(e, param){
        // console.log("mainButtonsOnClick", e)
        let that = this;
        const { roomInfo, selectedRows } = this.state;

        switch(e.key){
            // 开始会议
            case "conference.start":
                Modal.confirm({
                    title: <span>你确定立即开始<span className="ho-text-hit">{this.state.roomInfo.Title}</span>会议？</span>,
                    content: <span>开始会议后，所有配置的在线设备将自动入会</span>,
                    onOk() {
                        dataStartRoom(that.props, roomInfo);
                    },
                });
                break;

            // 结束会议
            case "conference.end":
                Modal.confirm({
                    title: <span>你确定结束<span className="ho-text-hit">{this.state.roomInfo.Title}</span>会议？</span>,
                    content: <span>会议结束后，所有入会设备将离会</span>,
                    onOk() {
                        dataEndRoom(that.props, roomInfo);
                    },
                    onCancel() {
                        
                    },
                });
                break;

            // 取消会议预约
            case "conference.del":
                Modal.confirm({
                    title: <span>你确定取消<span className="ho-text-hit">{this.state.roomInfo.Title}</span>的预约？</span>,
                    content: <span>取消会议预约，将会使之前的配置失效</span>,
                    onOk() {
                        dataDelRoom(that.props, roomInfo, (e) => {that.props.history.goBack()});
                    },
                });
                
                break;

            // 修改预约
            case "conference.mod":
                this.setState({
                    modPageVisible: true,
                });
                break;

            // 锁定画面
            case "conference.lock":
                this.setState({
                    lockViewPageVisible: true,
                    lockViewInfo: {
                        SipNums: [],
                        LayoutId: 1,
                        Views: [],
                    }
                });
                break;

            // 解锁画面
            case "conference.unlock":
                this.setState({
                    unlockViewPageVisible: true,
                    unlockViewSelectedKeys: [],
                    unlockViewMembers: [],
                });
                break;

            // 数据分析
            case "conference.analysis":
                window.goToMenu('devops/analysis/conference', `detail/${e.param}`, undefined, true, true)
                break;

            // 编辑会议设备
            case "member.edit":
                this.setState({
                    editMemberPageVisible: true,
                })
                break;

            // 批量删除会议成员
            case "member.group.delete":
                dataDelMember(this.props, roomInfo, selectedRows.map(item => item.UserId), this.dataCommRoomDataSuccessHd);
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                break;

            // 批量踢出会议成员
            case "member.group.kick":
                dataKickMember(this.props, roomInfo, selectedRows.map(item => item.SipNum));
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                break;

            // 批量邀请会议成员
            case "member.group.invite": {
                selectedRows.forEach(item => this.inviteStatus[item.SipNum] = 'inviting');
                dataInviteMember(this.props, roomInfo, selectedRows.map(item => item.SipNum), selectedRows, this.dataInviteMemberSuccessHd, this.dataInviteMemberErrorHd);
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                });
                break;
            }
            // 批量禁音会议成员
            case "member.group.mute":
                dataMuteMember(this.props, roomInfo, selectedRows.map(item => item.SipNum), true, this.dataCommRoomDataSuccessHd);
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                break;

            // 批量取消禁音会议成员
            case "member.group.unmute":
                dataMuteMember(this.props, roomInfo, selectedRows.map(item => item.SipNum), false, this.dataCommRoomDataSuccessHd);
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                break;

            default:
                break;
        }
    }


    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        this.setState({
            paginationInfo: paginationInfo,
        }, () => {
            this.mainRefreshOnClick()
        });
    }

    // '预览'按钮被点击
    tblRowViewOnClick(e, record){
        e.stopPropagation();

        this.setState({
            videoPlayerPageVisible: true,
        })
    }

    // '禁音/取消禁音'被点击
    tblRowMuteOnClick(e, record, mute){
        e.stopPropagation();
        dataMuteMember(this.props, this.state.roomInfo, [record.SipNum], mute, this.dataCommRoomDataSuccessHd);
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
    }

    // '邀请'按钮被点击
    tblRowInviteOnClick(e, record){
        e.stopPropagation();
        setTimeout(() => {
            dataInviteMember(this.props, this.state.roomInfo, [record.SipNum], [record], this.dataInviteMemberSuccessHd, this.dataInviteMemberErrorHd);
        }, 1000);
        this.inviteStatus[record.SipNum] = 'inviting';
        this.setState({flag: !this.state.flag});
    }

    // '踢出'按钮被点击
    tblRowKickOnClick(e, record){
        e.stopPropagation();
        dataKickMember(this.props, this.state.roomInfo, [record.SipNum]);
    }

    // '开始直播'按钮被点击
    tblRowPushStartOnClick(e, record){
        let that = this;
        e.stopPropagation();
        Modal.confirm({
            title: <span>你确定选择<span className="ho-text-hit">{record.NickName}</span>作为<span className="ho-text-hit">{that.state.roomInfo.Title}</span>会议的直播推流？</span>,
            content: <span>直播开始后，其他终端可以进入会议直播室</span>,
            onOk() {
                dataPushStartMember(that.props, that.state.roomInfo, record, that.dataCommRoomDataSuccessHd);                    
            },
            onCancel() {
                
            },
        });
    }

    // '停止直播'按钮被点击
    tblRowPushStopOnClick(e, record){
        let that = this;
        e.stopPropagation();
        Modal.confirm({
            title: <span>你确定停止<span className="ho-text-hit">{that.state.roomInfo.Title}</span>会议的直播推流？</span>,
            content: <span>停止直播后，直播会场将会停止</span>,
            onOk() {
                dataPushStopMember(that.props, that.state.roomInfo, that.dataCommRoomDataSuccessHd);
            },
            onCancel() {
                
            },
        });
    }

    // '删除'按钮被点击
    tblRowDeleteOnClick(e, record){
        e.stopPropagation();
        dataDelMember(this.props, this.state.roomInfo, [record.UserId], this.dataCommRoomDataSuccessHd);
    }

    tblSelectOnChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    editMemberPageOnChange(action, member){
        switch(action){
            case 'add': {
                dataAddMember(this.props, this.state.roomInfo, [member.UserId], (a,b,c) => this.dataCommRoomDataSuccessHd(a,b,c,false));
                break;
            }

            case 'group.add': {
                dataAddMember(this.props, this.state.roomInfo, member.map(item => item.UserId), (a,b,c) => this.dataCommRoomDataSuccessHd(a,b,c,false));
                break;
            }
                
            case 'del': {
                if (member.ConfState !== "joined"){
                    dataDelMember(this.props, this.state.roomInfo, [member.UserId], (a,b,c) => this.dataCommRoomDataSuccessHd(a,b,c,false));
                }else{
                    hoMessage({type: 'error', msg: '不能删除已入会设备'})
                }
                break;
            }

            case 'group.del': {
                let userIds = [];
                member.forEach(item => {
                    if (item.ConfState !== "joined"){
                        userIds.push(item.UserId);
                    }else{
                        console.log(`不能删除已入会设备: ${item.NickName}`);
                    }
                })

                dataDelMember(this.props, this.state.roomInfo, userIds, (a,b,c) => this.dataCommRoomDataSuccessHd(a,b,c,false));
                break;
            }

            default:
                break;
        }
    }

    editMemberPageCloseOnClick(e){
        this.setState({
            editMemberPageVisible: false,
        })
        this.mainRefreshOnClick(e);
    }

    videoPlayerPageCloseOnClick(e){
        this.setState({
            videoPlayerPageVisible: false,
        })
    }

    // 修改页面'取消'按钮被点击
    modPageCancelOnClick(){
        this.setState({
            modPageVisible: false,
        });
    }

    // 修改页面'确定'按钮被点击
    modPageOkOnClick(){
        const {modRoomValidate, modRoomInfo} = this.state;

        // 点击确定前再次检查是否有字段不合法
        if (modRoomValidate.Title.validateStatus !== "success"){
            hoMessage({type:'error', msg:'输入有误，请检查'});
            return;
        }

        this.setState({
            modPageVisible: false,
        });
        dataModRoom(this.props, modRoomInfo, this.dataCommRoomDataSuccessHd);
    }


    // 新页面参数变化通知函数
    modPageParamOnChange(e, param){
        // console.log("modPageParamOnChange", e, param);
        let state = this.state;
        switch(param){
            case 'Title':
                state['modRoomInfo'][param] = e.target.value;
                break;
            case 'ExpectStartTime':
            case 'ExpectEndTime':
                state['modRoomInfo'][param] = e;
                break;
                
            default:
                break;
        }

        // 校验会议主题是否为空
        state['modRoomValidate']['Title'] = hoValidateEmptyFormat(
            state['modRoomInfo']['Title'], 
            "会议主题不能为空"
        )

        this.setState({
            ...state,
        })
    }

    lockViewPageOkOnClick(layoutId, sipNums, views) {
        this.setState({
            lockViewPageVisible: false,
        });
        dataSetLayout(this.props, this.state.roomInfo, {
            SipNums: sipNums,
            LayoutId: layoutId,
            Views: views,
        })
    }

    lockViewPageCloseOnClick() {
        this.setState({
            lockViewPageVisible: false,
        });
    }

    unlockViewPageOkOnClick() {
        this.setState({
            unlockViewPageVisible: false,
        });
        dataUnsetLayout(this.props, this.state.roomInfo, this.state.unlockViewMembers, this.dataCommRoomDataSuccessHd)
    }

    unlockViewPageCloseOnClick() {
        this.setState({
            unlockViewPageVisible: false,
        });
    }

    unlockViewPageSelectOnChange(keys, records) {
        this.setState({
            unlockViewSelectedKeys: keys,
            unlockViewMembers: records.map(item => item.SipNum),
        });
    }

    // 面包屑
    mkBreadcrumb(){
        let { roomInfo } = this.state;
        return <HoBreadcrumb
            items={[
                {title: "会议管理", url: "ctrl"}, 
                {title: (roomInfo?.State === 'finished') ? "历史会议" : "会议室", url: (roomInfo?.State === 'finished') ? 'ctrl/history-room' : 'ctrl/room'},
                {title: (roomInfo?.ConferenceCode && this.codeFix(roomInfo?.ConferenceCode)) || roomInfo?.Title}
            ]}
        />
    }

    mkHeaderInfo(){
        let mainButtons = [];
        let dropdownMenu = undefined;
        const { roomInfo } = this.state;
        if (!roomInfo){
            return {title: <span>详情</span>, buttons: undefined};
        }

        switch(roomInfo.State){
            case 'tostart':
                mainButtons.push(<Button type="primary" style={{marginLeft: '1rem'}} key="start" onClick={(e) => this.mainButtonsOnClick({key: "conference.start"})}><HoIconFont type="icon-kaishi"/>开始会议</Button>)
                dropdownMenu = <Menu onClick={this.mainButtonsOnClick}>
                    <Menu.Item key="conference.del"><Icon type="delete"/>取消预约</Menu.Item>
                    <Menu.Item key="conference.mod"><Icon type="edit"/>修改预约</Menu.Item>
                    <Menu.Item key="member.edit"><Icon type="form"/>编辑设备</Menu.Item>
                </Menu>;
                break;
            case 'inprogress':
                mainButtons.push(<Button type="primary" style={{marginLeft: '1rem'}} key="analysis" onClick={(e) => this.mainButtonsOnClick({key: "conference.analysis", param: roomInfo.SipCode})}><HoIconFont type="icon-fenxi"/>数据分析</Button>)
                mainButtons.push(<Button type="danger" style={{marginLeft: '1rem'}} key="stop" onClick={(e) => this.mainButtonsOnClick({key: "conference.end"})}><HoIconFont type="icon-jieshu"/>结束会议</Button>)
                dropdownMenu = <Menu onClick={this.mainButtonsOnClick}>
                    <Menu.SubMenu title={<span><Icon style={{marginRight: '0.5rem'}} type="block"/>会场画面</span>}>
                        <Menu.Item key="conference.lock"><Icon type="lock"/>锁定</Menu.Item>
                        <Menu.Item key="conference.unlock"><Icon type="unlock"/>解锁</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="member.edit"><Icon type="form"/>编辑设备</Menu.Item>
                </Menu>;
                break;
            case 'finished': 
                mainButtons.push(<Button type="primary" style={{marginLeft: '1rem'}} key="analysis" onClick={(e) => this.mainButtonsOnClick({key: "conference.analysis", param: roomInfo.SipCode})}><HoIconFont type="icon-fenxi"/>数据分析</Button>)
                break;
            default:
                break;
        }

        const buttons = (<div>
            <HoBackButton onClick={(e) => {
                window.goToMenu(roomInfo.State !== 'finished' ? 'ctrl/room' : 'ctrl/history-room');
            }}/>
            {mainButtons}
            {dropdownMenu ? <HoMoreDropdownButton key="more" menu={dropdownMenu}>操作</HoMoreDropdownButton> : undefined}
            <HoRefreshButton key="refresh" onClick={this.mainRefreshOnClick}/>
        </div>);
        
        return {title: <span>详情</span>, buttons: buttons}; 
    }

    mkBase(){
        const { roomInfo, loading } = this.state;
        const { reqUserInfo } = this.props;
        if (!roomInfo){
            return undefined;
        }
        // console.log(roomInfo);
        let tag = [];
        switch(roomInfo.State){
            case 'tostart':
                tag.push(<HoTag key="setup" color={gold[3]}>已预约</HoTag>);
                break;
            case 'inprogress':
                tag.push(<HoTag key="stable" color={blue[3]}>与会中</HoTag>);
                break;
            case 'finished':
                tag.push(<HoTag key="completed" color={grey[3]}>已结束</HoTag>);
                break;
            default:
                break;
        }

        const mainContent = undefined;

        const ret = roomInfo.ConfMebmbers?.find(member => member.SipNum === roomInfo.PusherInfo?.SrcSipNum && member.SipNum)
        const devNickName = ret?.NickName || undefined;
        const rtmpAddr = window.location.protocol + "//" + window.location.hostname + "/conference/preview/rtmp?url=" + roomInfo.PusherInfo?.RtmpUrl
        const flvAddr = roomInfo.PusherInfo?.FlvUrl
        const extraContent = <div key="descriptions">
            <QueueAnim type={['bottom', 'top']}>
                <Descriptions key="base" size="small" layout="vertical" column={4}>
                    <Descriptions.Item label="会议号"><Typography.Paragraph copyable style={{color: blue[3]}}>{this.codeFix(roomInfo.ConferenceCode)}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="状态" className="descriptions-state">{tag}</Descriptions.Item>
                    <Descriptions.Item label="主题"><Typography.Paragraph>{roomInfo.Title}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="SIP号"><Typography.Paragraph copyable>{roomInfo.SipCode}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="创建时间"><Typography.Paragraph>{roomInfo.CreateTime}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="创建人"><Typography.Paragraph>{roomInfo.CreateUserName || "无"}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="媒体服务器">
                        <Typography.Paragraph>
                            {roomInfo.mediaServerName}
                            {
                                reqUserInfo.user?.role?.type === "superadmin" ? <Tooltip title="点击查看服务器详情">
                                    <HoLinkButton icon="paper-clip" style={{marginLeft: '0.5rem', padding: 0, height: 'auto'}} onClick={(e) => {
                                        window.goToMenu('devops/setting/server/group/', `media/${roomInfo.mediaServerGroupId}`)
                                    }} />
                                </Tooltip> : undefined
                            }
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="信令服务器" span={2}>
                        <Typography.Paragraph>
                            {roomInfo.signalServerName}
                            <Tooltip title="点击查看服务器详情">
                                {
                                    reqUserInfo.user?.role?.type === "superadmin" ? <HoLinkButton icon="paper-clip" style={{ marginLeft: '0.5rem', padding: 0, height: 'auto' }} onClick={(e) => {
                                        window.goToMenu('devops/setting/server/group/', `signal/${roomInfo.signalServerGroupId}`, {
                                            GroupName: "信令组",
                                            GroupType: 1,
                                            Id: 0,
                                            Remark: "信令组",
                                        })
                                    }} /> : undefined
                                }
                            </Tooltip>
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="与会时间"><Typography.Paragraph>{getTimeRangeDesc(roomInfo.StartTime, roomInfo.FinishTime)}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="预约时间" span={2}><Typography.Paragraph>{getTimeRangeDesc(roomInfo.ExpectStartTime, roomInfo.ExpectEndTime)}</Typography.Paragraph></Descriptions.Item>
                </Descriptions>
            </QueueAnim>
            <QueueAnim type={['bottom', 'top']}>
                <Descriptions key="live-time" size="small" layout="vertical" column={2}>
                    <Descriptions.Item label="直播时间"><Typography.Paragraph>{getTimeRangeDesc(roomInfo.PusherInfo?.StartTime, roomInfo.PusherInfo?.EndTime)}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="主播设备名称"><Typography.Paragraph>{devNickName || "无"}</Typography.Paragraph></Descriptions.Item>
                </Descriptions>
            </QueueAnim>
            <QueueAnim type={['bottom', 'top']}>
                <Descriptions key="live-addr" size="small" layout="vertical" column={2}>
                    <Descriptions.Item label="直播url1"><Typography.Paragraph copyable={!!roomInfo.PusherInfo?.RtspUrl}>{roomInfo.PusherInfo?.RtspUrl || "无"}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="直播url2"><Typography.Paragraph copyable={!!roomInfo.PusherInfo?.RtmpUrl}>{roomInfo.PusherInfo?.RtmpUrl || "无"}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="直播预览地址1">
                        <Typography.Paragraph copyable={roomInfo.PusherInfo?.RtmpUrl ? { text: rtmpAddr } : false}>{roomInfo.PusherInfo?.RtmpUrl ? <Tooltip title="点击预览(需要Flash支持)"><a rel="noopener noreferrer" target='_blank' href={rtmpAddr}>{rtmpAddr}</a></Tooltip> : "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="直播预览地址2">
                        <Typography.Paragraph copyable={roomInfo.PusherInfo?.FlvUrl ? { text: flvAddr } : false}>{roomInfo.PusherInfo?.FlvUrl ? <Tooltip title="点击预览"><a rel="noopener noreferrer" target='_blank' href={flvAddr}>{flvAddr}</a></Tooltip> : "无"}</Typography.Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </QueueAnim>
        </div>

        return <Spin spinning={loading}>
            <PageHeader className="ho-pageheader">
                <div className="content">
                    <div className="main">{mainContent}</div>
                    <div className="extra">{extraContent}</div>
                </div>
            </PageHeader>
        </Spin>
    }

    mkMember(){
        let { roomInfo, selectedRowKeys, loading } = this.state;
        const { TotalMemberNum, ConfMebmbers } = roomInfo || {};
        if (!roomInfo){
            return undefined;
        }
        let rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: this.tblSelectOnChange,
        };
        let disabled1 = selectedRowKeys.length === 0;
        let disabled2 = selectedRowKeys.length === 0 || roomInfo.State !== "inprogress" ;
        let buttonComponent = <div style={{marginBottom: '1rem'}}>
            <Button.Group>
                <HoLinkButton disabled={disabled1} style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} icon="user-delete" onClick={(e) => this.mainButtonsOnClick({key: "member.group.delete"})}>删除</HoLinkButton>
                <HoLinkButton disabled={disabled2} style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} icon="usergroup-delete" onClick={(e) => this.mainButtonsOnClick({key: "member.group.kick"})}>踢出</HoLinkButton>
                <HoLinkButton disabled={disabled2} style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} icon="usergroup-add" onClick={(e) => this.mainButtonsOnClick({key: "member.group.invite"})}>邀请</HoLinkButton>
                <Dropdown 
                    disabled={disabled2} 
                    overlay={<Menu onClick={this.mainButtonsOnClick}>
                        <Menu.Item key="member.group.mute">禁音</Menu.Item>
                        <Menu.Item key="member.group.unmute">取消禁音</Menu.Item>
                    </Menu>}>
                    <HoLinkButton style={{marginLeft: 0, marginRight: '0.5rem', padding: 0}} icon="audio">麦克风<Icon type="down" /></HoLinkButton>
                </Dropdown>
            </Button.Group>
        </div>;

        let columns = [
            hoTableColumn('状态', 'AppState', 'str', false, false, '7%', {
                render: this.tblRenderState,
            }),
            hoTableColumn('设备名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('设备类型', 'DeviceType', 'str', false, false, '7%', {
                render: (text, record) => deviceTypeMap.find(item => item.value === text)?.title || text,
            }),
            hoTableColumn('SIP号（License ID）', 'SipNum', 'str', false, false, '15%'),
            hoTableColumn('权限', 'ConfState', 'str', false, false, '15%', {
                render: this.tblRenderPerm,
            }),
            hoTableColumn('与会时间', 'JoinTime', 'str', false, false, '25%', {
                render: (text, record) => {
                    if (record.JoinTime && !record.LeftTime) {
                        return `${record.JoinTime} 至 -`
                    } else if (record.JoinTime && record.LeftTime) {
                        return `${record.JoinTime} 至 ${record.LeftTime}`
                    } else {
                        return "未入会"
                    }
                }
            }),
        ];

        if (roomInfo.State === 'finished') {
            rowSelection = undefined;
            buttonComponent = undefined;
        } else {
            columns.push(hoTableColumnAction('操作', 'operation', this.tblRenderAction, '30%'))
        }
        
        // console.log("mkMember", roomInfo);

        return (<div>
            {buttonComponent}
            <HoTable
                className="ho-room-detail-device-table"
                columns={columns}
                data={ConfMebmbers}
                bordered={false}
                size={'small'}
                rowKey={'UserId'}
                loading={loading}
                emptyBehavior={'decrease'}
                rowSelection={rowSelection}
                onColumnChange={this.tblColOnChange}
                onRowDoubleClick={this.tblRowOnClick}
                total={TotalMemberNum}
            />
        </div>);
    }

    mkEvent() {
        if (!this.state.roomInfo || !this.state.roomInfo.SipCode) {
            return undefined;
        }

        return (<EventAction
            reqUserInfo={this.props.reqUserInfo}
            history={this.props.history}
            conferenceSipNum={this.state.roomInfo.SipCode}
            refresh={this.state.refreshEvent}
            bordered={false}
            size={'small'}
            totalOnChange={(total) => {
                this.setState({
                    eventTotal: total,
                })
            }}
        />);
    }

    mkEditMemberPage(){
        const { editMemberPageVisible, roomInfo } = this.state;
        const { reqUserInfo } = this.props;
        if (!roomInfo){
            return undefined;
        }
        return <Modal 
            title={"编辑设备"} 
            visible={editMemberPageVisible} 
            width={"90%"}
            onOk={this.editMemberPageCloseOnClick} 
            onCancel={this.editMemberPageCloseOnClick} 
            footer={[
                <Button key="close" type="primary" onClick={this.editMemberPageCloseOnClick}>
                    关闭
                </Button>,
            ]}
            destroyOnClose={true}
            >
                <SelectMember
                    reqUserInfo={reqUserInfo}
                    selectedMembers={roomInfo ? roomInfo.ConfMebmbers : undefined}
                    memberOnChange={this.editMemberPageOnChange}
                />
        </Modal>
    }

    mkLockViewPage(){
        const { lockViewPageVisible, roomInfo } = this.state;
        const { layoutList, reqUserInfo } = this.props;
        if (!roomInfo){
            return undefined;
        }
        return <Modal 
            title={"锁定会场画面"} 
            visible={lockViewPageVisible} 
            width={"90%"}
            onCancel={this.lockViewPageCloseOnClick} 
            footer={null}
            destroyOnClose={true}
            >
                <SelectView
                    reqUserInfo={reqUserInfo}
                    roomInfo={roomInfo}
                    layouts={layoutList}
                    onOk={this.lockViewPageOkOnClick}
                />
        </Modal>
    }

    mkUnlockViewPage(){
        const { unlockViewPageVisible, unlockViewSelectedKeys, roomInfo } = this.state;
        if (!roomInfo){
            return undefined;
        }
        return <Modal 
            title={"解锁会场画面"} 
            width={"80%"}
            visible={unlockViewPageVisible} 
            onOk={this.unlockViewPageOkOnClick}
            onCancel={this.unlockViewPageCloseOnClick} 
            destroyOnClose={true}
            footer={[
                <Button key="unlock" type="primary" icon="unlock" onClick={this.unlockViewPageOkOnClick}>
                    解锁
                </Button>,
            ]}
            >
                <UnlockViewMember
                    members={roomInfo?.ConfMebmbers ? roomInfo.ConfMebmbers.filter(item => item.ConfState === 'joined' && item.LayoutInfo.LayoutMode === 1) : undefined}
                    selectedRowKeys={unlockViewSelectedKeys}
                    selectOnChange={this.unlockViewPageSelectOnChange}
                />
        </Modal>
    }

    mkModModal(){

        const {modPageVisible, modRoomInfo, modRoomValidate} = this.state;

        // console.log("mkModModal", modRoomInfo);

        return <HoAlertDialog
            title="修改预约信息"
            visible={modPageVisible}
            onOkClick={this.modPageOkOnClick}
            onCancelClick={this.modPageCancelOnClick}
        >
            <HoForm >
                <HoFormRowColInput
                    label="名称"
                    placeholder="请填写会议主题(例如: XX会议)"
                    colExtra={{ span:24 }}
                    inputExtra={{value: modRoomInfo && modRoomInfo.Title}}
                    onChange={(e) => this.modPageParamOnChange(e, 'Title')}
                    validate={modRoomValidate.Title}
                />
                <HoFormRowColDate
                    label="预计开始时间"
                    placeholder="请选择预计开始时间"
                    showTime={true}
                    colExtra={{ span: 24 }}
                    onChange={(e) => this.modPageParamOnChange(e, 'ExpectStartTime')}
                    value={modRoomInfo && modRoomInfo.ExpectStartTime}
                />
                <HoFormRowColDate
                    label="预计结束时间"
                    placeholder="请选择预计结束时间"
                    showTime={true}
                    colExtra={{ span: 24 }}
                    onChange={(e) => this.modPageParamOnChange(e, 'ExpectEndTime')}
                    value={modRoomInfo && modRoomInfo.ExpectEndTime}
                />
                <div style={{ textAlign: 'left' }}>
                    <p style={{ color: "white" }}>*</p>
                </div>
            </HoForm>
        </HoAlertDialog>
    }

    mkVideoPlayer(){
        const { videoPlayerPageVisible, roomInfo } = this.state;
        if (roomInfo){
            if (!this.pushingCondition(roomInfo.PusherInfo)){
                return undefined;
            }
        } else {
            return undefined;
        }
            
        const videoJsOptions = {
            autoplay: true,  //自动播放
            language: 'zh-CN', 
            // controls: true,  //控制条
            preload: 'auto',  //自动加载
            errorDisplay: true,  //错误展示
            // width: 500,  //宽
            // height: 300,  //高
            fluid: true,  //跟随外层容器变化大小，跟随的是外层宽度
            // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但还是存在
            // textTrackDisplay: false,  // 不渲染字幕相关DOM
            // userActions: {
            //     hotkeys: true  //是否支持热键
            // },
            sources: [
                {
                    src: roomInfo.PusherInfo.RtmpUrl,
                    type: "rtmp/flv",  //类型可加可不加，目前未看到影响
                    // type: 'video/mp4',
                }
            ]
        };
        return <Modal 
            title={<span>直播预览 <Tooltip title={<span>{roomInfo.PusherInfo.RtmpUrl}</span>}>
                <Icon style={{ color: blue[3], marginLeft: '0.5rem' }} type="info-circle" />
            </Tooltip></span>}
            visible={videoPlayerPageVisible} 
            width={"50%"}
            onOk={this.videoPlayerPageCloseOnClick} 
            onCancel={this.videoPlayerPageCloseOnClick} 
            destroyOnClose={true}
            footer={[
                <Button key="close" type="primary" onClick={this.videoPlayerPageCloseOnClick}>
                    关闭
                </Button>,
            ]}
            >
                <VideoPlayer {...videoJsOptions} />
        </Modal>
    }

    // clear handle
    componentWillUnmount(){
        let {mqttClient} = this.state;
        let {dispatch} = this.props;
        if (mqttClient){
            mqttClient.end();
            this.setState({
                mqttSubState: 'init',
            })
        }
    }

    componentDidMount(){
        let {mqttClient} = this.state;
        if (mqttClient) {
            mqttClient.end();
        }

        this.inviteStatus = {};
        this.mainRefreshOnClick()
        dataListLayout(this.props, listLayoutDataSuccessHd);

        notification.config({
            placement: 'topLeft',
            // bottom: 50,
            duration: 5,
        });
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const baseComponent = this.mkBase();
        const memberComponent = this.mkMember();
        const eventComponent = this.mkEvent();
        const modRoomComponent = this.mkModModal();
        const editMemberPageComponent = this.mkEditMemberPage();
        const lockViewPageComponent = this.mkLockViewPage();
        const unlockViewPageComponent = this.mkUnlockViewPage();
        const videoPlayerComponent = this.mkVideoPlayer();

        return (<div>
            {modRoomComponent}
            {editMemberPageComponent}
            {lockViewPageComponent}
            {unlockViewPageComponent}
            {breadcrumbComponent}
            {videoPlayerComponent}
            <HoCard title={title} buttons={buttons} >
                {baseComponent}
            </HoCard>
            <Tabs type="card" defaultActiveKey="device" className="ho-channel-tabs">
                <Tabs.TabPane key="device" tab={<span>与会设备({this.state.roomInfo?.TotalMemberNum || 0})</span>}>
                    {memberComponent}
                </Tabs.TabPane>
                <Tabs.TabPane key="event" tab={<span>会议事件{this.state.eventTotal ? `(${this.state.eventTotal})` : undefined}</span>} >
                    {eventComponent}
                </Tabs.TabPane>
            </Tabs>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    layoutList: listLayoutData(state),
});


export default connect(
    mapState, 
    null
)(RoomDetailCT);

