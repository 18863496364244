/* eslint-disable no-unused-vars */
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import {reducer} from './reducer';

// 关闭redux的日志
const logger = createLogger({
    logger:undefined
});

const middleware = [ thunk ];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
}

export function configStore(initSate=undefined){
    const store = createStore(
        reducer, 
        initSate, 
        composeWithDevTools(applyMiddleware(...middleware))
    )
    return store;
}

export const loadStoreState = () => {
    try {
        const binState = localStorage.getItem('cs_mntn_login_info');
        if (binState === null){
            return undefined;
        }
        return {login: JSON.parse(binState)};
    }catch (err){
        return undefined;
    }
};

export const saveStoreState = (state) => {
    try {
        const binState = JSON.stringify(state);
        localStorage.setItem('cs_mntn_store_state', binState);

    }catch (err){
        console.log('save store state failed! err: ' + err);
    }

};

