/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Menu, Spin, Modal, Tooltip } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import { HoCard, HoBreadcrumb, HoRefreshButton, HoIconFont, HoLinkButton } from '../../util/hoComponent';
import { message } from '../../util/message';
import styleJson from './custom_map_config3.json';
import { getIconSrc } from '../../resource/resource';
import { hoTableInitPaginationInfo } from '../../util/tableComponent';
import { dataListMember } from '../member/memberRD';
import { getLoginUserInfo } from '../login/loginRD';
import { apiMemberSetLocation } from '../../api/member';
import { getUrlParam } from '../../util/logic';
import { switchEnvironment, version } from '../../util/version';
import { isFullscreen, toggleFullscreen, screenUnwatchFull, screenWatchFull } from '../../component/common/screenFull';

import MemberCT from './memberCT';
import DrawRectangle from './drawRectangle';
import Conference from './conference';

import './ads.less';

class ADSCT extends Component{

    constructor(props){
        super(props);

        let env = getUrlParam("env", window.location?.search) || window.localStorage?.getItem("environment") || (version.isInternal() ? "internal" : "production");
        switchEnvironment(env)

        this.defaultViewLayout = 4;

        this.state = {
            cameraOnlineIcon: new window.BMapGL.Icon(getIconSrc('camera-online'), new window.BMapGL.Size(25, 25)),
            cameraOfflineIcon: new window.BMapGL.Icon(getIconSrc('camera-offline'), new window.BMapGL.Size(25, 25)),
            cameraBusyIcon: new window.BMapGL.Icon(getIconSrc('camera-busy'), new window.BMapGL.Size(25, 25)),
            deviceOnlineIcon: new window.BMapGL.Icon(getIconSrc('device-online'), new window.BMapGL.Size(25, 25)),
            deviceOfflineIcon: new window.BMapGL.Icon(getIconSrc('device-offline'), new window.BMapGL.Size(25, 25)),
            deviceBusyIcon: new window.BMapGL.Icon(getIconSrc('device-busy'), new window.BMapGL.Size(25, 25)),
            labelOffset: new window.BMapGL.Size(-12, -35),
            records: [],
            lng: 0,
            lat: 0,
            tilt: 50,
            heading: 0,
            zoomLevel: 19,
            tips: <div><Spin size="small" />正在准备会议组件...</div>,
            viewLayout: this.defaultViewLayout,
            width: 1,
            height: 1,
        }
    }

    showMenuStyle(e, domId) {
        let { height, width } = this.state;
        let topOffset = 0;
        let rightOffset = 16;
        let dom = document.getElementById(domId);
        if (dom) {
            if (dom.clientHeight + e.clientY > height) {
                topOffset -= dom.clientHeight
            }
            if (dom.clientWidth + e.clientX > width) {
                rightOffset -= rightOffset * 2 + dom.clientWidth;
            }
        }

        return {
            opacity: 1,
            zIndex: 999,
            top: `${e.clientY + topOffset}px`,
            left: `${e.clientX + rightOffset}px`,
        }
    }

    hideMenuStyle() {
        return {
            opacity: 0,
            zIndex: 0,
        }
    }

    resize() {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        this.setState({
            width: width,
            height: height,
        })
    }

    screenOnChange(e) {
        console.log("screenOnChange");

        this.setState({
            isFullScreen: isFullscreen(),
        }, () => {
            message.destroy();
            this.resize();
            if (this.map) {
                this.map.checkResize();
            }
        });
    }

    mainRefreshOnClick(e, manual) {
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = [[['Lng', '!=', 0]]];
        paginationInfo.pagination.page.size = 100;
        paginationInfo.pagination.sort = {
            field: 'AppState',
            order: 1
        }
        this.setState({
            paginationInfo: paginationInfo,
            loading: true,
        })
        if (manual) {
            message.loading("正在刷新数据")
        }
        setTimeout(() => {
            dataListMember(this.props, paginationInfo,
                (dispatch, rsp, req) => {
                    this.setState({
                        records: rsp.UserList,
                        loading: false,
                    }, () => {
                        if (manual) {
                            message.success("刷新成功")
                        }
                        this.initMapDevice(rsp.UserList)
                    })
                }
            )
        }, manual ? 500 : 0);
        
    }

    initMapDevice(records) {
        let { labelOffset, members } = this.state
        this.map.clearOverlays();
        // eslint-disable-next-line no-unused-expressions
        records?.forEach(member => {
            if (!member.Lng || !member.Lat) {
                return;
            }
            let point = new window.BMapGL.Point(member.Lng, member.Lat);

            let online = 'Online';
            let device = 'camera';
            let color = '#fff';
            let labelExtra = ''
            if (member.AppState === 'online' && member.SipState === 'online') {
                if (member.CallState === 'idle') {
                    online = 'Online';
                } else {
                    online = 'Busy';
                    if (members?.find(m => m.userId === member?.SipNum)) {
                        labelExtra = '（会议中）';
                        color = gold[5];
                    } else {
                        labelExtra = '（忙碌）';
                        color = volcano[5];
                    }
                }
            } else {
                online = 'Offline';
                color = red[5];
                labelExtra = '（离线）';
            }
            if (member.DeviceType === "CAMERA-28181") {
                device = 'camera';
            } else {
                device = 'device';
            }

            let marker = new window.BMapGL.Marker(point, {
                icon: this.state[`${device}${online}Icon`],
            });
            let label = new window.BMapGL.Label(`${member.NickName}${labelExtra}`, {
                offset: labelOffset
            });
            let style = { color: color, fontSize: "12px", backgroundColor: 'transparent', borderColor: 'transparent'};
            // if (member.Lng < bounds.sw.lng || member.Lng > bounds.ne.lng || member.Lat < bounds.sw.Lat || member.Lat > bounds.ne.lat) {
            //     console.log("none", member);
            //     style.display = 'none';
            // } else {
            //     console.log("block", member);
            // }
            label.setStyle(style)
            marker.setLabel(label)
            marker.dataset = member;
            marker.addEventListener('click', (e) => {
                let { members, view } = this.state;
                this.meunMember = e?.srcElement?.dataset;
                this.menuLatlng = e.latlng;
                this.setState({
                    deviceState: (this.meunMember.AppState === 'online' && this.meunMember.SipState === 'online') ? (this.meunMember.CallState === 'idle' ? 'Online' : 'Busy') : 'Offline',
                    deviceInConference: !!members?.find(m => m.userId === this.meunMember.SipNum && m.status === 'ONLINE'),
                    deviceStreamId: view?.ssrc?.[view?.media?.findIndex(userId => userId === this.meunMember.SipNum)],
                    deviceLeftMenuStyle: this.showMenuStyle(e, "device-left-menu"),
                    deviceRightMenuStyle: this.hideMenuStyle(),
                    mapMenuStyle: this.hideMenuStyle(),
                })
            });
            marker.addEventListener('rightclick', (e) => {
                this.meunMember = e?.srcElement?.dataset;
                this.menuLatlng = e.latlng;
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceLeftMenuStyle: this.hideMenuStyle(),
                    deviceRightMenuStyle: this.showMenuStyle(e, "device-right-menu"),
                })
            });
            
            this.map.addOverlay(marker);
        })
    }

    uninitMap() {
        if (this.deviceConference) {
            this.deviceConference.destroyRoom();
        }
        this.map.destroy()
    }

    initMap() {
        let map = new window.BMapGL.Map("map");
        this.map = map;
        let lng = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapCenterLng")) || 113.97005752568613);
        let lat = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapCenterLat")) || 22.589486651330265);
        let zoomLevel = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapZoomLevel")) || 14.00).toFixed(2);
        let tilt = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapTilt")) || 50.00).toFixed(2);
        map.centerAndZoom(new window.BMapGL.Point(lng, lat));
        map.setMapStyleV2({ styleJson: styleJson });
        // map.addControl(new window.BMapGL.CityListControl({
        //     anchor: window.BMAP_ANCHOR_TOP_LEFT,
        //     offset: new window.BMapGL.Size(8, 8)
        // }));
        let navigation = new window.BMapGL.NavigationControl3D({
            anchor: window.BMAP_ANCHOR_TOP_RIGHT,
            offset: new window.BMapGL.Size(18, 18)
        })
        map.addControl(navigation);
        map.enableScrollWheelZoom();
        map.enableKeyboard();
        map.disableDoubleClickZoom();
        map.setDisplayOptions({
            poiIcon: false,
            poiText: true,
        })
        map.addEventListener('tilesloaded', (e) => {
            if (!this.tilesloadFirst) {
                this.tilesloadFirst = true;
                this.setState({
                    controlVisible: true,
                    tilt: tilt,
                    zoomLevel: zoomLevel,
                    lng: lng,
                    lat: lat,
                }, () => {
                    this.map.setTilt(tilt);
                    this.map.setZoom(zoomLevel);
                    this.mainRefreshOnClick();
                })
            }

            // let button = document.getElementsByClassName('anchorBR')?.[0]?.children?.[1];
            // if (button) {
            //     if (button.style) {
            //         button.style.display = 'none';
            //     }
            // }
        })
        map.addEventListener('click', (e) => {
            if (e.target.className !== "BMap_Marker BMap_noprint") {
                let { mapMenuStyle, deviceLeftMenuStyle, deviceRightMenuStyle } = this.state;
                if (mapMenuStyle?.opacity || deviceLeftMenuStyle?.opacity || deviceRightMenuStyle?.opacity) {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle(),
                        deviceLeftMenuStyle: this.hideMenuStyle(),
                        deviceRightMenuStyle: this.hideMenuStyle(),
                        lng: e.latlng.lng,
                        lat: e.latlng.lat,
                    })
                    return;
                }
            } 

            this.setState({
                lng: e.latlng.lng,
                lat: e.latlng.lat
            })
        });

        map.addEventListener('rightclick', (e) => {
            this.menuLatlng = e.latlng;
            if (e.target.className !== "BMap_Marker BMap_noprint") {
                this.setState({
                    mapMenuStyle: this.showMenuStyle(e.domEvent, "map-menu"),
                    deviceLeftMenuStyle: this.hideMenuStyle(),
                    deviceRightMenuStyle: this.hideMenuStyle(),
                    lng: e.latlng.lng,
                    lat: e.latlng.lat
                })
            } else {
                this.setState({
                    lng: e.latlng.lng,
                    lat: e.latlng.lat
                })
            }
        });
        map.addEventListener('zoomstart', (e) => {
            let { mapMenuStyle, deviceLeftMenuStyle, deviceRightMenuStyle } = this.state;
            if (mapMenuStyle?.opacity || deviceLeftMenuStyle?.opacity || deviceRightMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceLeftMenuStyle: this.hideMenuStyle(),
                    deviceRightMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('movestart', (e) => {
            let { mapMenuStyle, deviceLeftMenuStyle, deviceRightMenuStyle } = this.state;
            if (mapMenuStyle?.opacity || deviceLeftMenuStyle?.opacity || deviceRightMenuStyle?.opacity) {
                this.setState({
                    mapMenuStyle: this.hideMenuStyle(),
                    deviceLeftMenuStyle: this.hideMenuStyle(),
                    deviceRightMenuStyle: this.hideMenuStyle(),
                })
            }
        });
        map.addEventListener('zoomend', (e) => {
            this.setState({
                zoomLevel: e.target?.zoomLevel?.toFixed(2),
            });
        });
        map.addEventListener('dragend', (e) => {
            this.setState({
                tilt: this.map.getTilt()?.toFixed(2),
                heading: ((this.map.getHeading() % 360 + 360) % 360).toFixed(2),
            });
        });

        map.addEventListener('mousemove', (e) => {
            this.curLatlng = e.latlng
        });

        // map.addEventListener('resize', (e) => {
        //     console.log(e);
        // });

    }


    // 面包屑
    mkBreadcrumb() {
        return <HoBreadcrumb 
            items={[{title: "会议管理", url: "ctrl"}, {title: "会议调度"}]}
        />
    }

    mkHeaderInfo() {
        let title = "会议调度";
        let buttons = (<div>
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick(e, true)} />
        </div>);
        return { title: <span>{title}</span>, buttons: buttons};
    }

    mkSelectDevice() {

        let { selectDeviceVisible, selectDeviceMode } = this.state;

        return <Modal
            title={`${selectDeviceMode === 'put' ? "放置" : "定位"}设备`}
            visible={selectDeviceVisible}
            width="80%"
            onCancel={(e) => {
                this.setState({
                    selectDeviceVisible: false
                }, () => {
                    message.warning(`你已取消${selectDeviceMode === 'put' ? "放置": "定位"}设备`)
                })
            }}
            footer={null}
            destroyOnClose={true}
            >
            <MemberCT
                className=""
                mode={selectDeviceMode}
                apiAddressBook={this.deviceConference?.addressBook.bind(this.deviceConference)}
                onSelect={(m) => {
                    switch (selectDeviceMode) {
                        case "put": {
                            let data = {
                                SipNum: m.SipNum,
                                Lng: this.menuLatlng.lng,
                                Lat: this.menuLatlng.lat,
                            }
                            apiMemberSetLocation(this.props, data,
                                () => {
                                    this.setState({
                                        selectDeviceVisible: false
                                    }, () => {
                                        message.success(`放置设备${m.NickName}成功`)
                                        this.mainRefreshOnClick();
                                    })
                                },
                                () => {
                                
                                },
                            )
                            break;
                        }

                        case "aim": {
                            this.setState({
                                selectDeviceVisible: false
                            }, () => {
                                message.success(`定位设备${m.NickName}成功`)
                                if (m?.Lng && m?.Lat) {
                                    this.map.setCenter(new window.BMapGL.Point(m.Lng, m.Lat), {
                                        noAnimation: false,
                                    })
                                }
                            })
                            
                            break;
                        }
                        
                        default:
                            break;
                    }
                    
                }}
            />
        </Modal>
    }

    mkAds() {
        let {
            width, height, loading,
            lng, lat, zoomLevel, tilt, heading, orgTilt, deviceState, deviceInConference, deviceStreamId, areaSelectMode, conferenceVisible,
            records, sdkInit, tips, room, members, viewLayout, isFullScreen,
            deviceRightMenuStyle, deviceLeftMenuStyle, mapMenuStyle, drawAreaMenuStyle, drawAreaStart, drawAreaEnd, 
        } = this.state;
        let onlineState = deviceState === 'Online' ? 'online' : (deviceState === "Busy" ? "busy" : "offline");

        let deviceMenuComponent = (
            <Menu
                id="device-left-menu"
                style={deviceLeftMenuStyle}
                className="ads-menu"
                selectedKeys={[]}
                defaultSelectedKeys={[]}
                onClick={() => {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle(),
                        deviceLeftMenuStyle: this.hideMenuStyle(),
                        deviceRightMenuStyle: this.hideMenuStyle(),
                    })
                }}
            >
                {
                    (deviceInConference && room) ? <Menu.SubMenu key="switch-video" popupClassName="ads-menu-submenu" title={<span>
                        <HoIconFont className="icon-menu" type="icon-switch" />
                        <span className="description">选看</span>
                    </span>}>
                        <Menu.Item className={deviceStreamId === 0 ? "disable" : "enable"} onClick={(e) => {
                            this.deviceConference.viewMember(this.meunMember, 0)
                        }}>
                            <div className="left">
                                <HoIconFont className="icon-menu" type={viewLayout === 1 ? "icon-window-main" : "icon-window-left-top"} />
                                <span className="description">{viewLayout === 1 ? "主窗口" : "左上窗口"}</span>
                            </div>
                        </Menu.Item>
                        {
                            viewLayout === 4 ? <Menu.Item className={deviceStreamId === 1 ? "disable" : "enable"} onClick={(e) => {
                                this.deviceConference.viewMember(this.meunMember, 1)
                            }}>
                                <div className="left">
                                    <HoIconFont className="icon-menu" type="icon-window-right-top" />
                                    <span className="description">右上窗口</span>
                                </div>
                            </Menu.Item> : undefined
                        }
                        {
                            viewLayout === 4 ? <Menu.Item className={deviceStreamId === 2 ? "disable" : "enable"} onClick={(e) => {
                                this.deviceConference.viewMember(this.meunMember, 2)
                            }}>
                                <div className="left">
                                    <HoIconFont className="icon-menu" type="icon-window-left-bottom" />
                                    <span className="description">左下窗口</span>
                                </div>
                            </Menu.Item> : undefined
                        }
                        {
                            viewLayout === 4 ? <Menu.Item className={deviceStreamId === 3 ? "disable" : "enable"} onClick={(e) => {
                                this.deviceConference.viewMember(this.meunMember, 3)
                            }}>
                                <div className="left">
                                    <HoIconFont className="icon-menu" type="icon-window-right-bottom" />
                                    <span className="description">右下窗口</span>
                                </div>
                            </Menu.Item> : undefined
                        }
                    </Menu.SubMenu> : undefined
                }
                {
                    (!room) ? <Menu.Item className={`${onlineState} ${(sdkInit && !loading) ? "enable" : "disable"}`} onClick={e => {
                        this.setState({
                            conferenceVisible: true,
                            viewLayout: 1,
                            loading: true,
                        }, () => {
                            if (this.meunMember) {
                                this.deviceConference.createRoom([this.meunMember]);
                                message.loading("正在创建会议", 30, "create_room");
                            }
                        })
                    }}><HoIconFont className="icon-menu" type="icon-create" /><span className="description">创建会议</span></Menu.Item>
                    : undefined
                }
                {
                    (room && !deviceInConference) ? <Menu.Item className={`${onlineState} ${(sdkInit && !loading) ? "enable" : "disable"}`} onClick={e => {
                        this.meunMember && this.deviceConference.inviteMember([this.meunMember]);
                    }}><HoIconFont className="icon-menu" type="icon-invite" /><span className="description">邀请入会</span></Menu.Item>
                    : undefined
                }
                {
                    deviceInConference ? <Menu.Item className={members?.find(m => m.userId === this.meunMember?.SipNum) ? "online" : "offline"} onClick={e => {
                        this.meunMember && this.deviceConference.kickMember([this.meunMember]);
                    }}><HoIconFont className="icon-menu" type="icon-kick" /><span className="description">踢出会议</span></Menu.Item>
                    : undefined
                }
                
            </Menu>
        )

        let deviceCtrlMenuComponent = (
            <Menu
                id="device-right-menu"
                style={deviceRightMenuStyle}
                className="ads-menu"
                selectedKeys={[]}
                defaultSelectedKeys={[]}
                onClick={() => {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle(),
                        deviceLeftMenuStyle: this.hideMenuStyle(),
                        deviceRightMenuStyle:this.hideMenuStyle(),
                    })
                }}
            >
                <Menu.Item onClick={(e) => {
                    if (this.meunMember) {
                    Modal.confirm({
                        title: `是否移除设备${this.meunMember.NickName}？`,
                        cancelText: "我再想想",
                        onOk: () => {
                            let data = {
                                SipNum: this.meunMember.SipNum,
                                Lng: 0,
                                Lat: 0,
                            }
                            apiMemberSetLocation(this.props, data,
                                () => {
                                    this.mainRefreshOnClick();
                                    message.success(`移除设备${this.meunMember.NickName}成功`)
                                },
                                () => {
                                    
                                },
                            )}
                        })
                    }
                }}><HoIconFont className="icon-menu" type="icon-cuo"/><span className="description">移除设备</span></Menu.Item>
            </Menu>
        )

        let mapMenuComponent = (
            <Menu
                id="map-menu"
                className="ads-menu"
                // openKeys={['setting']}
                selectedKeys={[]}
                defaultSelectedKeys={[]}
                style={mapMenuStyle}
                onClick={(e) => {
                    this.setState({
                        mapMenuStyle: this.hideMenuStyle()
                    })
                }}
            >
                {
                    room ? <Menu.Item onClick={(e) => {
                        this.deviceConference.destroyRoom();
                    }}>
                        <HoIconFont className="icon-menu" type="icon-end" />
                        <span className="description">结束会议</span>
                    </Menu.Item> : undefined
                }
                {
                    room ? <Menu.SubMenu key="switch-layout" popupClassName="ads-menu-submenu" title={<span>
                        <HoIconFont className="icon-menu" type="icon-layout" />
                        <span className="description">切换布局</span>
                    </span>}>
                        <Menu.Item onClick={(e) => {
                            if (viewLayout !== 4) {
                                this.setState({
                                    viewLayout: 4,
                                })
                            }
                        }}>
                            <div className="left">
                                <HoIconFont className="icon-menu" type="icon-layout-4" />
                                <span className="description">多屏</span>
                            </div>
                            <div className="right">
                                {viewLayout === 4 ? <HoIconFont className="icon-menu" type="icon-duihao" /> : undefined}
                            </div>
                        </Menu.Item>
                        <Menu.Item onClick={(e) => {
                            if (viewLayout !== 1) {
                                this.setState({
                                    viewLayout: 1,
                                })
                            }
                        }}>
                            <div className="left">
                                <HoIconFont className="icon-menu" type="icon-layout-1" />
                                <span className="description">单屏</span>
                            </div>
                            <div className="right">
                                {viewLayout === 1 ? <HoIconFont className="icon-menu" type="icon-duihao" /> : undefined}
                            </div>
                        </Menu.Item>
                    </Menu.SubMenu> : undefined
                }
                {
                    room ? <div className="line"/> : undefined
                }
                <Menu.Item className={(sdkInit && !loading) ? "enable" : "disable"} onClick={e => {
                    if (!areaSelectMode) {
                        this.deviceDrawArea.clear()
                    }
                    this.setState({
                        areaSelectMode: true,
                        orgTilt: tilt,
                        tilt: 0,
                    }, () => {
                        this.map.setTilt(0)
                    })
                }}><HoIconFont className="icon-menu" type="icon-area-select" /><span className="description">框选设备入会</span></Menu.Item>
                <Menu.SubMenu key="device" popupClassName="ads-menu-submenu" title={<span>
                    <HoIconFont className="icon-menu" type="icon-menu-device" />
                    <span className="description">设备</span>
                </span>}>
                    <Menu.Item onClick={e => {
                        this.mainRefreshOnClick();
                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-refresh" />
                            <span className="description">刷新</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        this.setState({
                            selectDeviceVisible: true,
                            selectDeviceMode: "aim",
                            mapMenuStyle: this.hideMenuStyle(),
                        })

                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-local" />
                            <span className="description">定位</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        this.setState({
                            selectDeviceVisible: true,
                            selectDeviceMode: "put",
                            mapMenuStyle: this.hideMenuStyle(),
                        })
                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-center" />
                            <span className="description">放置</span>
                        </div>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="setting" popupClassName="ads-menu-submenu" title={<span>
                    <HoIconFont className="icon-menu" type="icon-view-setting" />
                    <span className="description">视角</span>
                </span>}>
                    <Menu.Item onClick={() => {
                        toggleFullscreen();
                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type={!isFullScreen ? "icon-full-screen" : "icon-full-screen-exit"} />
                            <span className="description">{!isFullScreen ? "全屏" : "退出全屏"}</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        if (window.localStorage) {
                            let lng = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapCenterLng")) || 113.97005752568613);
                            let lat = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapCenterLat")) || 22.589486651330265);
                            
                            this.map.setCenter(new window.BMapGL.Point(lng, lat), {
                                noAnimation: false,
                                callback: () => {
                                    let zoomLevel = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapZoomLevel")) || 14.00).toFixed(2);
                                    let tilt = parseFloat((window.localStorage && window.localStorage.getItem("mntn::mapTilt")) || 50.00).toFixed(2);
                                    this.setState({
                                        lng: lng,
                                        lat: lat,
                                        tilt: tilt,
                                        zoomLevel: zoomLevel,
                                        heading: 0,
                                    }, () => {
                                        this.map.setZoom(zoomLevel)
                                        this.map.setTilt(tilt);
                                        this.map.setHeading(0);
                                    })
                                }
                            })
                        }
                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-default" />
                            <span className="description">回到中心点</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        if (window.localStorage) {
                            window.localStorage.setItem("mntn::mapCenterLng", this.menuLatlng.lng);
                            window.localStorage.setItem("mntn::mapCenterLat", this.menuLatlng.lat);
                        }
                        this.map.setCenter(new window.BMapGL.Point(this.menuLatlng.lng, this.menuLatlng.lat), {
                            noAnimation: true,
                        })
                        message.success(`设置中心位置成功`)

                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-aim" />
                            <span className="description">设置当前位置为默认中心点</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        if (window.localStorage) {
                            window.localStorage.setItem("mntn::mapZoomLevel", this.state.zoomLevel);
                        }
                        message.success(`设置默认缩放级别成功`)

                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-zoom" />
                            <span className="description">设置当前缩放为默认级别</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={e => {
                        if (window.localStorage) {
                            window.localStorage.setItem("mntn::mapTilt", this.state.tilt);
                        }
                        message.success(`设置默认倾角成功`)
                    }}>
                        <div className="left">
                            <HoIconFont className="icon-menu" type="icon-tilt" />
                            <span className="description">设置当前视角为默认倾斜度</span>
                        </div>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item onClick={e => {
                    Modal.confirm({
                        title: `是否退出登录？`,
                        cancelText: "我再想想",
                        okText: "退出",
                        onOk: () => {
                            message.destroy();
                            this.props.history.push('/')
                        }
                    })
                }}>
                    <HoIconFont className="icon-menu" type="icon-exit" />
                    <span className="description">退出登录</span>
                </Menu.Item>
            </Menu>
        )

        let areaMembers = (members, start, end) => {
            // 计算出矩形框中满足要求的设备
            let arrays = [];
            let startLng = start?.lng || 0
            let startLat = start?.lat || 0
            let endLng = end?.lng || 0
            let endLat = end?.lat || 0
            if (startLng > endLng) {
                [startLng, endLng] = [endLng, startLng];
            }
            if (startLat < endLat) {
                [startLat, endLat] = [endLat, startLat];
            }
            members.forEach(m => {
                if (m.AppState !== 'online' || m.SipState !== 'online' || m.CallState !== 'idle'
                    || m.Lng < startLng || m.Lng > endLng
                    || m.Lat < endLat || m.Lat > startLat) {
                    return;
                }
                arrays.push(m);
            })

            return arrays;
        }
        
        let drawAreaMenuComponent = <Menu
            id="draw-area-menu"
            style={drawAreaMenuStyle}
            className="ads-menu"
            selectedKeys={[]}
            defaultSelectedKeys={[]}
            onClick={() => {
                this.setState({
                    drawAreaMenuStyle: this.hideMenuStyle(),
                })
            }}
        >
            {
                (!room && drawAreaEnd?.lng) ? <Menu.Item className={(sdkInit && !loading) ? "enable" : "disable"} onClick={() => {
                    this.deviceDrawArea.clear()
                    let members = areaMembers(records, drawAreaStart, drawAreaEnd);
                    if (members?.length > 0) {
                        this.map.setTilt(orgTilt)
                        this.setState({
                            drawAreaStart: {lng: 0, lat: 0},
                            drawAreaEnd: {lng: 0, lat: 0},
                            conferenceVisible: true,
                            areaSelectMode: false,
                            tilt: orgTilt,
                            viewLayout: this.defaultViewLayout,
                        }, () => {
                            this.deviceConference.createRoom(members);
                        })
                    } else {
                        this.setState({
                            drawAreaStart: {lng: 0, lat: 0},
                            drawAreaEnd: {lng: 0, lat: 0},
                        })
                        message.error("没有发现可以入会的设备")
                    }
                    
                }}><HoIconFont className="icon-menu" type="icon-create" /><span className="description">创建会议</span></Menu.Item>
                : undefined
            }
            {
                (room && drawAreaEnd?.lng) ? <Menu.Item onClick={() => {
                    this.deviceDrawArea.clear()
                    let members = areaMembers(records, drawAreaStart, drawAreaEnd);
                    if (members?.length > 0) {
                        this.map.setTilt(orgTilt)
                        this.setState({
                            drawAreaStart: {lng: 0, lat: 0},
                            drawAreaEnd: {lng: 0, lat: 0},
                            conferenceVisible: true,
                            areaSelectMode: false,
                            tilt: orgTilt
                        }, () => {
                            this.deviceConference.inviteMember(members);
                        })
                    } else {
                        this.setState({
                            drawAreaStart: {lng: 0, lat: 0},
                            drawAreaEnd: {lng: 0, lat: 0},
                        })
                        message.error("没有发现可以入会的设备")
                    }
                    
                }}><HoIconFont className="icon-menu" type="icon-invite" /><span className="description">邀请入会</span></Menu.Item>
                : undefined
            }
            <Menu.Item onClick={() => {
                this.deviceDrawArea.clear()
                this.map.setTilt(orgTilt)
                this.setState({
                    areaSelectMode: false,
                    tilt: orgTilt,
                })
            }}><HoIconFont className="icon-menu" type="icon-cuo" /><span className="description">退出框选</span></Menu.Item>
        </Menu>

        let footerComponent = <div className="footer">
            <div className="left">
                <Tooltip title="位置（经纬度）" className="tooltip">
                    <HoIconFont className="icon-menu" type="icon-local" /><div className="lnglat">{lng}, {lat}</div>
                </Tooltip>
                <Tooltip title="缩放级别" className="tooltip">
                    <HoIconFont className="icon-menu" type="icon-zoom" /><div className="zoom">{zoomLevel}</div>
                </Tooltip>
                <Tooltip title="视角倾斜度（3D）" className="tooltip">
                    <HoIconFont className="icon-menu" type="icon-tilt" /><div className="tilt">{tilt}°</div>
                </Tooltip>
                <Tooltip title="正北方偏移（顺时针）" className="tooltip">
                    <HoIconFont className="icon-menu" type="icon-heading" /><div className="heading">{heading}°</div>
                </Tooltip>
            </div>
            <div className="right">
                <span>{tips}</span>
            </div>
        </div>

        let drawRectangleComponent = <DrawRectangle
            className={"draw-rectangle " + (areaSelectMode ? "show" : "hidden")}
            ref={(element) => this.deviceDrawArea = element}
            width={width}
            height={height}
            onStart={(e) => {
                this.setState({
                    drawAreaStart: this.curLatlng,
                    drawAreaEnd: {lng: 0, lat: 0},
                    drawAreaMenuStyle: this.hideMenuStyle(),
                    lng: this.curLatlng.lng,
                    lat: this.curLatlng.lat,
                })
            }}
            onEnd={(e) => {
                // drawAreaEnd控制了draw-area-menu菜单的项，先设置，让菜单的项增加
                this.setState({
                    drawAreaEnd: this.curLatlng,
                    lng: this.curLatlng.lng,
                    lat: this.curLatlng.lat,
                }, () => {
                    // 再设置draw-area-menu菜单显示，这样高度计算就正确了
                    this.setState({
                        drawAreaMenuStyle: this.showMenuStyle(e, "draw-area-menu"),
                    })
                })
            }}
            onRightClick={(e) => {
                this.setState({
                    drawAreaMenuStyle: this.showMenuStyle(e, "draw-area-menu"),
                })
            }}
            onCancel={() => {
                this.setState({
                    drawAreaMenuStyle: this.hideMenuStyle(),
                    areaSelectMode: false,
                    tilt: orgTilt,
                })
                this.map.setTilt(orgTilt)
            }}
        />

        let conferenceComponent = <Conference
            ref={(element) => this.deviceConference = element}
            reqUserInfo={this.props.reqUserInfo}
            dispatch={this.props.dispatch}
            visible={conferenceVisible}
            viewLayout={viewLayout}
            onInitOk={(profile) => {
                this.setState({
                    sdkInit: true,
                    tips: <div>就绪</div>,
                    profile: profile,
                })
            }}
            onInitError={(errCode, errDesc) => {
                this.setState({
                    tips: <div><Spin size="small" />正在准备会议组件...</div>,
                    sdkInit: false,
                })
            }}
            onRoomOk={(room) => {
                message.success("会议创建成功", 3, "create_room");
                this.setState({
                    room: room,
                    loading: false,
                }, () => {
                    setTimeout(() => {
                        this.mainRefreshOnClick();
                    }, 500);
                })
            }}
            onRoomError={(errorCode, errorDesc) => {
                this.setState({
                    room: null,
                    conferenceVisible: false,
                })
            }}
            onRoomDestroy={() => {
                this.setState({
                    conferenceVisible: false,
                    room: null,
                }, () => {
                    setTimeout(() => {
                        this.mainRefreshOnClick();
                    }, 500);
                })
            }}
            onRoomChange={(room) => {
                this.setState({
                    room: room,
                })
            }}
            onMemberChange={(type, member, members) => {
                let { profile } = this.state;
                switch (type) {
                    case window.vmeeting.ACTION.MEMBER.JOINED:
                        if (profile.userId === member.userId) {
                            message.success("你已入会")
                        } else {
                            message.info(`${member.nickName}已入会`, 3, member.userId)
                        }
                        break;
                    case window.vmeeting.ACTION.MEMBER.LEAVED:
                        if (profile.userId === member.userId) {
                            message.info("你已离会")
                        } else {
                            message.info(`${member.nickName}已离会`, 3, member.userId)
                        }
                        break;
                    default:
                        break;
                }
                this.setState({
                    members: members,
                }, () => {
                    this.mainRefreshOnClick();
                })
            }}
            onViewChange={(view) => {
                this.setState({
                    view: view,
                })
            }}
        />

        return <div className="ads">
            {deviceMenuComponent}
            {deviceCtrlMenuComponent}
            {mapMenuComponent}
            {drawAreaMenuComponent}
            {drawRectangleComponent}
            {conferenceComponent}
            {footerComponent}
            <div className="map" id="map"/>
        </div>
    }

    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
        screenUnwatchFull((e) => this.screenOnChange(e));
        this.uninitMap();
    }

    componentDidMount() {
        window.ads = this;
        document.oncontextmenu = function () {
            return false;
        }

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            this.uninitMap();
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.uninitMap();
        }.bind(this)

        screenWatchFull((e) => this.screenOnChange(e));
        this.resize();
        this.initMap();

        setTimeout(() => {
            if (!this.state.isFullScreen) {
                message.info(<span>
                    <span>点击全屏以获得更好效果</span>
                    {/* <HoLinkButton style={{color: red[5]}} onClick={() => {
                        message.destroy();
                    }}>暂时不要</HoLinkButton> */}
                    <HoLinkButton onClick={() => {
                        message.destroy();
                        toggleFullscreen();
                    }}>全屏</HoLinkButton>
                </span>,
                   10,
                )
            }
        }, 3000)
    }

    render() {
        let adsComponent = this.mkAds();
        let selectDeviceComponent = this.mkSelectDevice();

        return (<div>
            {selectDeviceComponent}
            {adsComponent}
        </div>)
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(ADSCT);

