/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { PageHeader, Tabs, Divider, Collapse, Descriptions, Typography, Result, Tooltip, Spin, Button } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { 
    HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../util/tableComponent';
import { 
    actNetRequestClear, getNetRequestStatus, netRequestThunk2 
} from '../../util/netReqRD';

import { ACT_NET_CONFERENCE_ACTION_LIST, listConferenceActionData, dataListConferenceAction, listConferenceActionDataSuccessHd, dataQueryConference } from '../../pages/devops/conference/conferenceRD';
import { HoLinkButton } from '../../util/hoComponent';

class EventAction extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        //
        this.renderExtra = this.renderExtra.bind(this);
        
        //
        this.datalistConferenceActionDataSuccessHd = this.datalistConferenceActionDataSuccessHd.bind(this);

        //
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblCallidOnClick = this.tblCallidOnClick.bind(this);
        
        let actions = [
            { text: '创建会议', value: 'create' }, 
            { text: '释放会议', value: 'release' },
            { text: '邀请入会', value: 'invite' },
            { text: '成员踢出', value: 'kick' },
            { text: '成员选流', value: 'view' },
            { text: '成员禁音', value: 'mute' },
            { text: '解除禁音', value: 'unmute' },
            { text: '开始推流', value: 'pusher_start' },
            { text: '停止推流', value: 'pusher_stop' },

            { text: '邀请入会（批量）', value: 'multi_invite' },
            { text: '成员踢出（批量）', value: 'multi_kick' },
            { text: '成员选流（批量）', value: 'multi_view' },
            { text: '成员禁音（批量）', value: 'multi_mute' },
            { text: '解除禁音（批量）', value: 'multi_unmute' },

            { text: '成员入会', value: 'join' },
            { text: '成员离会', value: 'leave' },
        ]

        const columns = [
            hoTableColumn('序号', 'int', 'id', false, false, '7%', {
                render: (text, record) => { return record.id;}
            }),
            hoTableColumnTag('结果', 'result', 
                (text, record) => {
                    let tags = [];
                    switch(record.result){
                        case 'success': tags.push({color: green[3], tag:'成功'}); break;
                        case 'failed': tags.push({color: red[3], tag:'失败'}); break;
                        default: break;
                    }
                    return hoTableColumnTagButton(tags, record);
                }, 
            '8%', {nolight: true, filters: [{text:'成功', value: 'success'}, {text:'失败', value: 'failed'}]}),
            hoTableColumnTag('事件', 'action', 
                (text, record) => {
                    let act = actions.find(item => item.value === record.action);
                    return act?.text || record.action
                }, 
            '10%', {nolight: true, filters: actions}),
            hoTableColumnDate('触发时间', 'createTime', true, '15%'),
            hoTableColumn('详细信息', 'str', 'extra', false, false, '35%', {
                render: (text, record) => { return this.renderExtra(record);}
            }),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
        }
    }

    renderExtra(record){
        switch(record.action){
            case 'create': {
                const conference = record.extra;
                return <Descriptions size="small" column={1}>
                    <Descriptions.Item label="会议号">{conference.confId}</Descriptions.Item>
                    <Descriptions.Item label="会议描述">{conference.name}</Descriptions.Item>
                    <Descriptions.Item label="会议类型">{conference.type === "moderator" ? "主席模式" : "自由模式"}</Descriptions.Item>
                    <Descriptions.Item label="最大会议时长">{conference.expire ? `${conference.expire}秒` : `不限`}</Descriptions.Item>
                    <Descriptions.Item label="成员入会自动禁音">{conference.autoMute ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label="成员选视频流自动取消禁音">{conference.switchVideoWithAudio ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label="会议无成员自动释放会议">{conference.autoRelease ? "是" : "否"}</Descriptions.Item>
                </Descriptions>;
            }

            case 'release': {
                const conference = record.extra;
                return <Descriptions size="small" column={1}>
                    <Descriptions.Item label="会议号">{conference.confId}</Descriptions.Item>
                    <Descriptions.Item label="会议描述">{conference.name}</Descriptions.Item>
                    <Descriptions.Item label="会议类型">{conference.type === "moderator" ? "主席模式" : "自由模式"}</Descriptions.Item>
                    <Descriptions.Item label="会议无成员自动释放会议">{conference.autoRelease ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item label="释放控制">{conference.releaseCtrl === 'positive' ? "主动释放" : "被动释放"}</Descriptions.Item>
                    <Descriptions.Item label="释放原因">{conference.releaseReason}</Descriptions.Item>
                </Descriptions>;
            }

            case 'kick': 
                if (record.extra){
                    const reason = record.extra.reason;
                    const member = record.extra.member;
                    const flag = record.extra.flag;
                    if (!member){
                        return undefined;
                    }

                    let items = [];
                    if (record.result !== "success" && flag === 'all'){
                        items.push(<Descriptions.Item key="reason" label="失败原因">{reason}</Descriptions.Item>)
                        items.push(<Descriptions.Item key="sip" label="成员SIP号">所有成员</Descriptions.Item>)
                    }else if (record.result !== "success" && flag !== 'all'){
                        items.push(<Descriptions.Item key="reason" label="失败原因">{reason}</Descriptions.Item>)
                        items.push(<Descriptions.Item key="sip" label="成员SIP号">{member.sipNum}</Descriptions.Item>)
                    }else if (record.result === "success" && flag === 'all'){
                        items.push(<Descriptions.Item key="sip" label="成员SIP号">所有成员</Descriptions.Item>)
                    }else if (record.result === "success" && flag !== 'all'){
                        items.push(<Descriptions.Item key="sip" label="成员SIP号">{member.sipNum}</Descriptions.Item>)
                        items.push(<Descriptions.Item key="call_id" label="Call-Id">
                            {member.callid}
                            <Tooltip title="点击查看呼叫详情">
                                <HoLinkButton icon="paper-clip" onClick={(e) => this.tblCallidOnClick(e, member.callid)}/>
                            </Tooltip>
                        </Descriptions.Item>)
                        items.push(<Descriptions.Item key="moderator" label="召集者">{member.perm?.moderator ? "是" : "否"}</Descriptions.Item>)
                    }
                    
                    return <Descriptions size="small" column={1}>
                        {items} 
                    </Descriptions>;
                }
                return undefined;
            case 'invite':
            case 'join':
            case 'leave':
                if (record.extra){
                    const reason = record.extra.reason;
                    const member = record.extra.member;
                    if (!member){
                        return undefined;
                    }
                    
                    if (record.result === "success"){
                        return <Descriptions size="small" column={1}>
                            <Descriptions.Item label="成员SIP号">{member.sipNum}</Descriptions.Item>
                            {
                                member.callid ? <Descriptions.Item label="Call-Id">
                                    {member.callid}
                                    <Tooltip title="点击查看呼叫详情">
                                        <HoLinkButton icon="paper-clip" onClick={(e) => this.tblCallidOnClick(e, member.callid)}/>
                                    </Tooltip>
                                </Descriptions.Item> : undefined
                            }
                            <Descriptions.Item label="召集者">{member.perm?.moderator ? "是" : "否"}</Descriptions.Item>
                        </Descriptions>
                    }else {
                        return <Descriptions size="small" column={1}>
                            <Descriptions.Item label="失败原因">{reason}</Descriptions.Item>
                            <Descriptions.Item label="成员SIP号">{member.sipNum}</Descriptions.Item>
                        </Descriptions>
                    }
                }
                return undefined;
            case 'view':
                if (record.extra){
                    const {sipNum, views, reason, extra} = record.extra;

                    const viewDefinition = (definition) => {
                        let trans = undefined;
                        switch (definition) {
                            case 1: 
                            case 2: 
                            case 3: trans = "标清"; break;
                            case 4: trans = "高清"; break;
                            default: break;
                        }
                        return `${trans}(${definition})`;
                    }

                    const viewCompent = (viewList, className) => {
                        return viewList.map((item, index) => <div key={index} className={className}>
                            <Descriptions size="small" column={5}>
                                <Descriptions.Item label="SIP号" span={2}>{item.sipNum}</Descriptions.Item>
                                <Descriptions.Item label="流ID" span={1}>{item.streamId}</Descriptions.Item>
                                <Descriptions.Item label="分辨率" span={1}>{viewDefinition(item.definition)}</Descriptions.Item>
                            </Descriptions>
                        </div>) 
                    }
                    if (reason === 'SUCCESS') {
                        return <Descriptions size="small" column={1}>
                            <Descriptions.Item label="成员SIP号">{sipNum}</Descriptions.Item>
                            {views?.length ? <Descriptions.Item label="目标成员">{viewCompent(views, "ho-conference-action-view-member-do")}</Descriptions.Item> : undefined }
                            {views.same?.length ? <Descriptions.Item label="无变化的成员">{viewCompent(views.same, "ho-conference-action-view-member-same")}</Descriptions.Item> : undefined }
                            {views.new?.length ? <Descriptions.Item label="本次新增的成员">{viewCompent(views.new, "ho-conference-action-view-member-new")}</Descriptions.Item> : undefined }
                            {views.delete?.length ? <Descriptions.Item label="本次删除的成员">{viewCompent(views.delete, "ho-conference-action-view-member-delete")}</Descriptions.Item> : undefined }
                            {extra?.errSipNums?.length ? <Descriptions.Item label="失败的成员SIP号">{extra.errSipNums.map(item => <span>{item}</span>)}</Descriptions.Item> : undefined}
                        </Descriptions>
                    } else {
                        return <Descriptions size="small" column={1}>
                            <Descriptions.Item label="成员SIP号">{sipNum}</Descriptions.Item>
                            <Descriptions.Item label="原因">{reason}</Descriptions.Item>
                            {extra?.errSipNums?.length ? <Descriptions.Item label="失败的成员SIP号">{extra.errSipNums.map(item => <span>{item}</span>)}</Descriptions.Item> : undefined}
                        </Descriptions>
                    }
                    
                }
                return undefined;
            case 'mute': 
            case 'unmute': 
                if (record.extra) { 
                    const {sipNums, reason, errSipNums} = record.extra;
                    let component1 = undefined;
                    let component2 = undefined;
                    let component3 = undefined;
                    if (reason === 'SUCCESS') {
                        sipNums.map(sipNum => {
                            component1 = <div 
                                // className="ho-conference-action-view-member-new"
                                >
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item label="成员SIP号">{sipNum}</Descriptions.Item>
                                </Descriptions>
                            </div>
                            return component1;
                        })
                    } else {
                        component2 = <Descriptions size="small" column={1}>
                            <Descriptions.Item label="原因">{reason}</Descriptions.Item>
                        </Descriptions>
                    }
                    errSipNums.map(sipNum => {
                        component3 = <div 
                            // className="ho-conference-action-view-member-delete"
                            >
                            <Descriptions size="small" column={1}>
                                <Descriptions.Item label="成员SIP号">{sipNum}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        return component3;
                    })
                    return <div>
                        {component1}
                        {component2}
                        {component3}
                    </div>
                }
                break;
            case 'pusher_start': 
            case 'pusher_stop': 
                if (record.extra){
                    return <Descriptions size="small" column={1}>
                        <Descriptions.Item label="推流地址">{record.extra.url}</Descriptions.Item>
                        {record.extra.reason ? <Descriptions.Item label="失败原因">{record.extra.reason}</Descriptions.Item> : undefined}
                    </Descriptions>
                }
                return undefined;
            case 'recording_start': 
            case 'recording_stop': 
                if (record.extra){
                    return <Descriptions size="small" column={1}>
                        <Descriptions.Item label="录制地址">{record.extra.url}</Descriptions.Item>
                        {record.extra.reason ? <Descriptions.Item label="失败原因">{record.extra.reason}</Descriptions.Item> : undefined}
                    </Descriptions>
                }
                return undefined;
            case 'multi_invite':
                if (record.extra) {
                }
                return undefined;
            case 'multi_kick':
                if (record.extra) {
                }
                return undefined;
            case 'multi_view':
                if (record.extra) {
                }
                return undefined;
            case 'multi_mute':
                if (record.extra) {
                }
                return undefined;
            case 'multi_unmute':
                if (record.extra) {
                }
                return undefined;
            default: 
                return undefined;
        }
    }

    // '查看Call-ID'按钮被点击
    tblCallidOnClick(e, callid){
        e.stopPropagation();
        window.goToMenu("devops/analysis/channel/", `detail/${callid}`);
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
    }

    datalistConferenceActionDataSuccessHd(dispatch, rspBody, reqBody) {
        const {totalOnChange} = this.props;
        listConferenceActionDataSuccessHd(dispatch, rspBody, reqBody);

        if (totalOnChange) {
            totalOnChange(rspBody.pagination.total);
        }
    }

    mainRefreshOnClick(e) {
        if (e){
            e.stopPropagation();
        }
        if (this.state.conference) {
            dataListConferenceAction(this.props, this.state.paginationInfo, this.state.conference.id, this.datalistConferenceActionDataSuccessHd);
        }
        
        setTimeout(() => {
            this.setState({
                refresh: false,
            })
        }, 1000);
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataListConferenceAction(this.props, paginationInfo, this.state.conference.id, this.datalistConferenceActionDataSuccessHd);
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    mkTable() {
        let { columns } = this.state;
        const { data, getStatus, className, bordered, size, extra } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return (<div>
            {/* <HoLinkButton icon="sync" onClick={this.mainRefreshOnClick}>刷新</HoLinkButton> */}
            <div className={className}>
                <HoTable
                    extra={extra}
                    columns={columns}
                    data={records}
                    bordered={bordered}
                    size={size}
                    rowKey={'id'}
                    loading={getStatus === 1}
                    onColumnChange={this.tblColOnChange}
                    onRowClick={this.tblRowOnClick}
                    total={total}
                />
            </div>
        </div>);
    }

    // clear handle
    componentWillUnmount(){
        const {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_CONFERENCE_ACTION_LIST));
    }

    componentDidMount(){
        let that = this;
        const {conferenceSipNum} = this.props;
        dataQueryConference(this.props, conferenceSipNum, 
            // 拿sipNum查会议运维信息
            (dispatch, rspBody, reqBody) => {
                const conference = rspBody.conference;
                this.setState({
                    conference: conference,
                },)
                // 拿着会议运维信息的id号去查运维事件列表
                dataListConferenceAction(that.props, that.state.paginationInfo, conference.id, that.datalistConferenceActionDataSuccessHd);
            },
            // 失败了表示则没有任何的信息
            (dispatch,) => {
                listConferenceActionDataSuccessHd(dispatch, {pagination : {total: 0, records: []}}, {});
                return true;
            }
        );
    }

    render(){
        // 主页面
        if (this.props.refresh && !this.state.refresh) {
            this.mainRefreshOnClick()
            this.setState({
                refresh: true,
            })
        }
        return this.mkTable();
    }
}

const mapState = (state) => ({
    getStatus: getNetRequestStatus(state, ACT_NET_CONFERENCE_ACTION_LIST), 
    data: listConferenceActionData(state),
});


export default connect(
    mapState, 
    null
)(EventAction);

