import {message as Message} from "antd";

let timers = {

};

function process(fun, config) {
    // 没有设置key则默认为_default
    if (!config.key) {
        config.key = "_default";
    }

    let key = config.key;

    if (timers[key]) {
        clearTimeout(timers[key])
    }

    /* antd的message模块有时候弹出了消息一直不消失，点击消息之后（猜是获得了焦点）才按照配置的duration消失。
    这里做个定时器，防止这种情况 */
    let hideFun = fun(config);
    if (config.destroy) {
        timers[key] = setTimeout(() => {
            if (hideFun) {
                hideFun();
            }
        }, config.duration * 1000);
    }

    return hideFun;
}

export let message = {

}

message.open = function(config) {
    return process(Message.open, config);
}

message.success = function (content = "", duration = 3, key = "_default") {
    return process(Message.success, {content: content, duration: duration, key: key, destroy: true});
}

message.error = function (content = "", duration = 5, key = "_default") {
    return process(Message.error, {content: content, duration: duration, key: key, destroy: true});
}

message.info = function (content = "", duration = 3, key = "_default") {
    return process(Message.info, {content: content, duration: duration, key: key, destroy: true});
}

message.warning = function (content = "", duration = 5, key = "_default") {
    return process(Message.warning, {content: content, duration: duration, key: key, destroy: true});
}

message.warn = message.warning;

message.loading = function (content = "", duration = 60, key = "_default") {
    return process(Message.loading, {content: content, duration: duration, key: key, destroy: true});
}

message.destroy = function() {
    return Message.destroy();
}

Message.config({
    maxCount: 3,
});