/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import QueueAnim from 'rc-queue-anim';

import { Menu, Button, Input, Row, Col, Dropdown, Icon } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';
import { 
    listWarnData, ACT_NET_WARN_LIST, listWarnDataSuccessHd,
} from './warnRD';
import {
    dataWarnList,
} from '../../../api/warn';
// import {dataListGroup, dataListHost, dataListItem, dataListTemplate} from '../zabbix/zabbixRD';
import {dataListProblem, ZABBIX_HOST_IDS} from '../zabbix/zabbixRD'
import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import { 
    HoTable, HoTableExtend, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';
import { deviceTypeMap, warnStateMap } from '../../../defined/memberDevice';
import { dataListRoom, dataListCurRoom } from '../../room/roomRD';

import './warn.less';

class WarnCT extends Component{
    constructor(props){
        super(props);

        const zabbixColumns = [
            hoTableColumn('等级', 'severity', 'str', false, false, '10%' ,{
                render: (text, record) => {
                    let tags = [];
                    switch(record.severity){
                        case '0':
                           tags.push({color: grey[3], tag:'未分类'});
                           break;
                        case '1':
                           tags.push({color: 'green', tag:'信息'});
                           break;
                        case '2':
                            tags.push({color: 'cyan', tag:'告警'});
                            break;
                        case '3':
                            tags.push({color: 'orange', tag:'一般严重'});
                            break;
                        case '4':
                            tags.push({color: 'gold', tag:'严重'});
                            break;
                        case '5':
                            tags.push({color: 'red', tag:'灾难'});
                            break;
                       
                       default:
                           break;
                   }
                   
                   return hoTableColumnTagButton(tags, record);
                }, nolight: true,
            }),
            hoTableColumn('状态', 'r_clock', 'str', false, false, '10%', {
                render: (text, record) => {
                    if (record.r_clock === "0") {
                        return <span className="warn-state-norecorvery">未恢复</span>;
                    } else {
                        return <span className="warn-state-done">已解决</span>;
                    }
                }, nolight: true,
            }),
            hoTableColumn('告警时间', 'clock', 'str', false, false, '15%', {
                render: (text, record) => {
                    return moment(parseInt(record.clock) * 1000).format("YYYY-MM-DD HH:mm:ss")
                }, nolight: true,
            }),
            
            hoTableColumn('告警描述', 'name', 'str', false, false, '50%'),
        ];

        const columns = [
            hoTableColumn('状态', 'Cleared', 'int', false, false, '5%', {
                render: (text, record) => {
                    return warnStateMap.find(w => `${w.value}` === `${record.Cleared}`)?.title
                }
            }),
            hoTableColumn('设备(用户)名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('硬件版本', 'DeviceType', 'str', false, false, '7%'),
            hoTableColumn('SIP号（License ID）', 'Account', 'str', false, false, '15%'),
            hoTableColumn('会议号', 'ConferenceCode', 'str', false, false, '8%'),
            hoTableColumnDate('上报时间', 'ReportTime', 'str', false, false, '10%'),
            hoTableColumnDate('恢复时间', 'ClearedTime', 'str', false, false, '10%'),
            hoTableColumn('详情', 'Ip', 'str', false, false, '30%', {
                render: (text, record) => {
                    return <ul className="warn-column-detail">
                        <li><div className="key">IP</div><div className="value">{record.Ip}</div></li>
                        <li><div className="key">网络类型</div><div className="value">{record.NetType === 0 ? "未知" : (record.NetType === 1 ? "有线网络" : "无限网络")}</div></li>
                        <li><div className="key">音频</div>
                            <div className="value">
                                <ul className="warn-column-detail-sub">
                                    <li><div className="key">上行带宽</div><div className="value">{record.UpAudioBandw} bps</div></li>
                                    <li><div className="key">上行丢包带宽</div><div className="value">{record.UpAudioLostBandw} bps</div></li>
                                    <li><div className="key">下行带宽</div><div className="value">{record.DownAudioBandw} bps</div></li>
                                    <li><div className="key">下行丢包带宽</div><div className="value">{record.DownAudioLostBandw} bps</div></li>
                                </ul>
                            </div>
                        </li>
                        <li><div className="key">视频</div>
                            <div className="value">
                                <ul className="warn-column-detail-sub">
                                    <li><div className="key">上行带宽</div><div className="value">{record.UpVideoBandw} bps</div></li>
                                    <li><div className="key">上行丢包带宽</div><div className="value">{record.UpVideoLostBandw} bps</div></li>
                                    <li><div className="key">下行带宽</div><div className="value">{record.DownVideoBandw} bps</div></li>
                                    <li><div className="key">下行丢包带宽</div><div className="value">{record.DownVideoLostBandw} bps</div></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                }
            }),
        ]

        this.state = {
            zabbixColumns: zabbixColumns,
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
                warnType: 'ALL',
                warnStateMap: warnStateMap,
                deviceType: 'ALL',
                deviceTypeMap: deviceTypeMap,
            }
        }
    }

    searchOnChange(search, pag) {
        let process = (t, value) => {
            let confCodeFilters = [];
            switch (t) {
                case 1:
                    if (value) {
                        confCodeFilters.push(['ConfId', 'equal', value]);
                    }
                    break;
                case 2:
                    confCodeFilters.push(['Account', 'like', value]);
                    break;
                default:
                    break;
            }

            if (search.warnType !== 'ALL') {
                confCodeFilters.push(['Cleared', '=', search.warnType])
            }

            if (search.deviceType !== 'ALL') {
                confCodeFilters.push(['DeviceType', 'equal', search.deviceType])
            }

            let filters = [];
            if (confCodeFilters.length > 0) {
                filters.push(confCodeFilters)
            }

            let paginationInfo = JSON.parse(JSON.stringify(pag))
            paginationInfo.pagination.filters = filters;
            paginationInfo.pagination.sort = {
                field: 'ReportTime',
                order: 1
            }
            this.setState({
                search: search,
                loading: true,
            }, () => {
                dataWarnList(this.props, paginationInfo,
                    (_, rsp, req) => {
                        this.setState({
                            loading: false,
                            warns: rsp?.RecordList || [],
                            paginationInfo: hoTableInitPaginationInfo(req, rsp?.TotalNum || 0)
                        })
                    },
                    (_, status, statusMsg, req) => {
                        this.setState({
                            loading: false,
                            warns: [],
                            paginationInfo: hoTableInitPaginationInfo(req, 0)
                        })
                        return true;
                    }
                )
            })
        }

        if (search.text?.length > 0) {
            if (/^\d+$/.test(search.text)) {
                let p = hoTableInitPaginationInfo();
                p.pagination.filters = [[['ConferenceCode', 'equal', search.text]]]
                dataListRoom(this.props, p,
                    (_, rsp, req) => {
                        let confSipNum = rsp?.ConferenceList?.[0]?.SipCode;
                        if (!confSipNum) {
                            dataListCurRoom(this.props, p,
                                (_, rsp, req) => {
                                    process(1, rsp?.ConferenceList?.[0]?.SipCode);
                                },
                                () => {
                                    process(1)
                                    return true;
                                }
                            )
                        } else {
                            process(1, confSipNum)
                        }
                    },
                    () => {
                        process(1)
                        return true;
                    }
                )
            } else {
                process(2, search.text)
            }
        } else {
            process(3)
        }
    }

    // '刷新'按钮被点击
    mainZabbixRefreshOnClick(){
        dataListProblem(this.props, { hostids: ZABBIX_HOST_IDS }, listWarnDataSuccessHd);
    }

    mainWarnRefreshOnClick() {
        this.searchOnChange(this.state.search, this.state.paginationInfo)
    }

    mainGoTopologyPage() {
        window.goToMenu('devops/monitor/topology');
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "运维工具", url: "devops/tool"}, {title: "告警"}]}
        />
    }

    mkZabbixHeaderInfo(){
        const buttons = (<div>
            <Button type="primary" icon="global" onClick={(e) => this.mainGoTopologyPage(e)}>查看组网拓扑</Button>
            <HoRefreshButton onClick={(e) => this.mainZabbixRefreshOnClick(e)}/>
        </div>);
        return {title: <span>系统告警</span>, buttons: buttons}; 
    }

    mkZabbixTable(){
        let { zabbixColumns } = this.state;
        const { zabbixWarnData, getStatus } = this.props;
        const { records, paginationInfo } = zabbixWarnData || {};
        const { total } = paginationInfo ? paginationInfo.pagination : 0;

        // console.log("mkTable", data);

        return (<HoTableExtend
            className="ho-warn-table"
            columns={zabbixColumns}
            data={records}
            rowKey={'eventid'}
            loading={getStatus === 1}
            total={total}
        />);
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoRefreshButton onClick={(e) => this.mainWarnRefreshOnClick()}/>
        </div>);
        return {title: <span>业务告警</span>, buttons: buttons}; 
    }

    mkSearch() {
        let { search, paginationInfo } = this.state;

        // const deviceOptions = search.deviceTypeMap.find((e) => search.deviceType === e.value);
        // const deviceMenu = (
        //     <Menu onClick={(e) => {
        //         search.deviceType = e.key;
        //         this.searchOnChange(search, paginationInfo)
        //     }}>
        //         {search.deviceTypeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
        //     </Menu>
        // );

        const stateOptions = search.warnStateMap.find((e) => search.warnType === e.value);
        const stateMenu = (
            <Menu onClick={(e) => {
                search.warnType = e.key;
                this.searchOnChange(search, paginationInfo)
            }}>
                {search.warnStateMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        return <QueueAnim type={['left', 'right']}>
            <Row key="search" className="warn-search" type="flex">
                <Col span={12} style={{ display: 'flex' }}>
                    <Dropdown overlay={stateMenu}>
                        <Button className={"warn-search-btn-state-" + search.warnType} type="primary" size="large">
                            {stateOptions?.title} <Icon type="down" />
                        </Button>
                    </Dropdown>
                    {/* <Dropdown overlay={deviceMenu}>
                        <Button className="warn-search-btn-device" type="primary" size="large">
                            {deviceOptions?.title} <Icon type="down" />
                        </Button>
                    </Dropdown> */}
                    <Input.Search
                        size="large"
                        className="warn-search-input"
                        placeholder="搜索设备SIP号、设备License ID、会议号"
                        enterButton
                        allowClear={true}
                        onSearch={(text) => {
                            search.text = text;
                            this.searchOnChange(search, paginationInfo)
                        }}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                search.text = "";
                                this.searchOnChange(search, paginationInfo)
                            }
                        }}
                        />
                </Col>
                <Col span={12}>
                
                </Col>
            </Row>
        </QueueAnim>
    }

    mkTable(){
        let { columns, warns, search, paginationInfo, loading } = this.state;

        // console.log("mkTable", data);

        return (<HoTable
            className="warn-table"
            columns={columns}
            data={warns}
            rowKey={'Id'}
            loading={loading}
            onColumnChange={(pag) => {
                this.searchOnChange(search, pag)
            }}
            total={paginationInfo?.pagination?.total || 0}
        />);
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount(){
        const done = () => {
            this.mainZabbixRefreshOnClick();
            this.mainWarnRefreshOnClick();
        }

        let {dispatch, zabbixUserInfo} = this.props;
        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => done()))
        } else {
            done();
        }
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const zabbixHeaderInfo = this.mkZabbixHeaderInfo();
        const zabbixTableComponent = this.mkZabbixTable();

        const headerInfo = this.mkHeaderInfo();
        const searchComponent = this.mkSearch();
        const tableComponent = this.mkTable();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={zabbixHeaderInfo?.title} buttons={zabbixHeaderInfo?.buttons} >
                {zabbixTableComponent}
            </HoCard>

            <HoCard title={headerInfo?.title} buttons={headerInfo?.buttons} >
                <div className="warn">
                    {searchComponent}
                    {tableComponent}
                </div>
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_WARN_LIST), 
    zabbixWarnData: listWarnData(state),
    zabbixUserInfo: getLoginZabbixInfo(state),
});


export default connect(
    mapState, 
    null
)(WarnCT);

