/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { PageHeader, Tabs, Divider, Collapse, Descriptions, Typography, Result, Tooltip, Spin } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';

import {getLoginUserInfo} from '../../login/loginRD'
import { 
    listRegistrationData, ACT_NET_REGISTRATION_LIST, dataListRegistration, listRegistrationDataSuccessHd,
} from './registrationRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoBackButton, HoTag,
} from '../../../util/hoComponent';

import { 
    HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';

class RegistrationDetailCT extends Component{
    constructor(props){
        super(props);
        
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        this.renderExtra = this.renderExtra.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
      
        const columns = [
            hoTableColumnTag('事件', 'action', 
                (text, record) => {
                    let tags = [];
                    switch(record.action){
                        case 'register':
                            tags.push({color: blue[3], tag:'用户注册'});
                            break;
                        case 'unregister':
                            tags.push({color: grey[3], tag:'用户注销'});
                            break;
                        case 'forbidden':
                            tags.push({color: red[3], tag:'注册失败'});
                            break;
                        case 'refresh':
                            tags.push({color: blue[3], tag:'刷新注册'});
                            break;
                        case 'expire':
                            tags.push({color: red[3], tag:'超时注销'});
                            break;
                        default:
                            break;
                    }
                    
                    return hoTableColumnTagButton(tags, record);
                }, 
            '8%', {nolight: true, filters: [
                {text:'用户注册', value: 'register'}, 
                {text:'用户注销', value: 'unregister'}, 
                {text:'注册失败', value: 'forbidden'}, 
                {text:'刷新注册', value: 'refresh'}, 
                {text:'超时注销', value: 'expire'},
            ]}),
            hoTableColumnDate('触发时间', 'createTime', true, '15%'),
            hoTableColumn('额外参数', 'str', 'extra', false, false, '50%', {
                render: (text, record) => { return this.renderExtra(record);}
            }),
        ];

        const paginationFilter = ['sipNum', 'like', this.props.match.params.sipNum];
        const paginationCondition = {
            pagination: {
                sort: {},
                page: {},
                filters: [[paginationFilter]]
            }
        }

        this.state = {
            columns: columns,
            sipNum: this.props.match.params.sipNum,
            pagination: paginationCondition,
            paginationFilter: paginationFilter,
            paginationInfo: hoTableInitPaginationInfo(paginationCondition),
        }
    }

    renderExtra(record){

        switch(record.action){
            case 'register': 
            case 'unregister': 
            case 'forbidden': 
            case 'refresh': 
            case 'expire':
                const info = record.extra;
                return <Descriptions size="small" column={1}>
                    <Descriptions.Item label="超时时间">{info.expire}</Descriptions.Item>
                    <Descriptions.Item label="IP地址">{info.remoteIp}</Descriptions.Item>
                    <Descriptions.Item label="端口">{info.remotePort}</Descriptions.Item>
                    <Descriptions.Item label="user-agent">{info.userAgent}</Descriptions.Item>
                    <Descriptions.Item label="contact">{info.contact}</Descriptions.Item>
                </Descriptions>;
            default: 
                return undefined;
        }
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        const {paginationFilter} = this.state;
        paginationInfo.pagination.filters[0].push(paginationFilter);
        dataListRegistration(this.props, paginationInfo, listRegistrationDataSuccessHd);
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        const { paginationInfo } = this.state;
        dataListRegistration(this.props, paginationInfo, listRegistrationDataSuccessHd);
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "注册", url: "devops/analysis/registration"}, {title: "详情"}]}
        />
    }

    mkHeaderInfo(){
        const {sipNum} = this.state;
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("devops/analysis/registration")}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>详情 (sip号码：{sipNum})</span>, buttons: buttons}; 
    }

    mkTable(){
        let { columns } = this.state;
        const { data, getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        // console.log("mkTable", data);

        return (<HoTable
            columns={columns}
            data={records}
            rowKey={'id'}
            loading={getStatus === 1}
            onColumnChange={this.tblColOnChange}
            onRowClick={this.tblRowOnClick}
            total={total}
        />);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_REGISTRATION_LIST));
    }

    componentDidMount(){
        const { paginationInfo } = this.state;
        dataListRegistration(this.props, paginationInfo, listRegistrationDataSuccessHd);
    }

    render(){
        const {reqUserInfo} = this.props;
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_REGISTRATION_LIST), 
    data: listRegistrationData(state),
});


export default connect(
    mapState, 
    null
)(RegistrationDetailCT);

