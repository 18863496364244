/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import AnimatedRouter from 'react-animated-router';
import { getLoginUserInfo } from '../pages/login/loginRD';

import TestCT from '../pages/test/testCT';

import Page from '../pages/page';

import AccountCT from '../pages/account/accountCT';
import MemberDetailCT from '../pages/member/memberDetailCT';
import MemberCT from '../pages/member/memberCT';
import CameraCT from '../pages/camera/cameraCT';
import VmeetingSdkCT from '../pages/vmeetingSdk/vmeetingSdkCT';
import VmeetingSdkAccountCT from '../pages/vmeetingSdk/vmeetingSdkAccountCT';
import ManufacturerCT from '../pages/manufacturer/manufacturerCT';

import JizhenCT from '../pages/jizhen/jizhenCT';
import RoomDetailCT from '../pages/room/roomDetailCT';
import RoomCT from '../pages/room/roomCT';
import CdrCT from '../pages/bill/cdr/cdrCT';

import DevZabbixCT from '../pages/devops/zabbix/zabbixCT';
import DevChannelCT from '../pages/devops/channel/channelCT';
import DevChannelDetailCT from '../pages/devops/channel/channelDetailCT';
import DevConferenceCT from '../pages/devops/conference/conferenceCT';
import DevConferenceDetailCT from '../pages/devops/conference/conferenceDetailCT';
import DevRegistrationCT from '../pages/devops/registration/registrationCT';
import DevRegistrationDetailCT from '../pages/devops/registration/registrationDetailCT';
import DevAliossCT from '../pages/devops/alioss/aliossCT';
import DevWarnCT from '../pages/devops/warn/warnCT';
import DevServerCT from '../pages/devops/server/serverCT';
import DevTopologyCT from '../pages/devops/topology/topologyCT';
import DevBlacklistCT from '../pages/devops/blacklist/blacklistCT';
import DevPracticeCT from '../pages/devops/practice/practiceCT';
import DevSettingServerCT from '../pages/devops/setting/server/serverGroupCT';
import DevSettingSignalServerCT from '../pages/devops/setting/server/serverSignalCT';
import DevSettingMediaServerCT from '../pages/devops/setting/server/serverMediaCT';
import DevSettingMcuLicenseCT from '../pages/devops/setting/license/mcu/mcuCT';
import DevSettingTerminalLicenseCT from '../pages/devops/setting/license/terminal/terminalCT';
import DevSettingTerminalLicenseActivationCT from '../pages/devops/setting/license/terminal/activation/activationCT';
import DevSettingTerminalLicenseDetailCT from '../pages/devops/setting/license/terminal/detail/detailCT';

class MainRoute extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let { reqUserInfo } = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/test`} component={TestCT} />

                <Route path={`${this.props.match.path}/member/camera/manufacturer`} component={ManufacturerCT} />
                <Route path={`${this.props.match.path}/member/camera`} component={CameraCT} />

                <Route path={`${this.props.match.path}/member/detail/:id`} component={MemberDetailCT} />
                <Route path={`${this.props.match.path}/member/sdk/account/:id`} component={VmeetingSdkAccountCT} />
                <Route path={`${this.props.match.path}/member/sdk`} component={VmeetingSdkCT} />
                <Route path={`${this.props.match.path}/member/device`} component={MemberCT} />
                <Route path={`${this.props.match.path}/member/account`} component={AccountCT} />
                <Route path={`${this.props.match.path}/member`} component={Page} />
                
                <Route path={`${this.props.match.path}/ctrl/room/detail/:id`} component={RoomDetailCT} />
                <Route path={`${this.props.match.path}/ctrl/history-room`} component={RoomCT} />
                <Route path={`${this.props.match.path}/ctrl/room`} component={RoomCT} />
                <Route path={`${this.props.match.path}/ctrl`} component={Page} />
                
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/bill/cdr`} component={CdrCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/bill`} component={Page} /> : undefined
                }

                <Route path={`${this.props.match.path}/devops/monitor/zabbix`} component={DevZabbixCT} />
                <Route path={`${this.props.match.path}/devops/monitor/topology`} component={DevTopologyCT} />
                <Route path={`${this.props.match.path}/devops/monitor`} component={Page} />

                <Route path={`${this.props.match.path}/devops/tool/practice`} component={DevPracticeCT} />
                <Route path={`${this.props.match.path}/devops/tool/warn`} component={DevWarnCT} />
                <Route path={`${this.props.match.path}/devops/tool/blacklist`} component={DevBlacklistCT} />
                <Route path={`${this.props.match.path}/devops/tool/log`} component={DevAliossCT} />
                <Route path={`${this.props.match.path}/devops/tool/version`} component={DevAliossCT} />
                <Route path={`${this.props.match.path}/devops/tool`} component={Page} />

                <Route path={`${this.props.match.path}/devops/analysis/conference/detail/:name`} component={DevConferenceDetailCT} />
                <Route path={`${this.props.match.path}/devops/analysis/conference`} component={DevConferenceCT} />
                <Route path={`${this.props.match.path}/devops/analysis/channel/detail/:uuid`} component={DevChannelDetailCT} />
                <Route path={`${this.props.match.path}/devops/analysis/channel`} component={DevChannelCT} />
                <Route path={`${this.props.match.path}/devops/analysis/registration/detail/:sipNum`} component={DevRegistrationDetailCT} />
                <Route path={`${this.props.match.path}/devops/analysis/registration`} component={DevRegistrationCT} />
                <Route path={`${this.props.match.path}/devops/analysis/server`} component={DevServerCT} />
                <Route path={`${this.props.match.path}/devops/analysis`} component={Page} />
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/license/terminal/activation`} component={DevSettingTerminalLicenseActivationCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/license/terminal/detail/:id`} component={DevSettingTerminalLicenseDetailCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/license/terminal`} component={DevSettingTerminalLicenseCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/license/mcu`} component={DevSettingMcuLicenseCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/jizhen`} component={JizhenCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/server/group/signal/:groupId`} component={DevSettingSignalServerCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/server/group/media/:groupId`} component={DevSettingMediaServerCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting/server/group`} component={DevSettingServerCT} /> : undefined
                }
                {
                    reqUserInfo?.user?.role?.type === "superadmin" ? <Route path={`${this.props.match.path}/devops/setting`} component={Page} /> : undefined
                }

                <Route path={`${this.props.match.path}`} component={Page} />
            </Switch>
        )
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});

export default connect(
    mapState, 
    null
)(MainRoute);