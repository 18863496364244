/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../../util/tableComponent";
import { netRequestThunk } from '../../../util/netReqRD';

const ACT_LIST_CDR = 'ACT_LIST_CDR';
const ACT_CLEAN_CDR = 'ACT_CLEAN_CDR';

export const ACT_NET_CDR_LIST = 'listCdr';
export const ACT_NET_CDR_MOD = 'modCdr';
export const ACT_NET_CDR_NEW = 'newCdr';
export const ACT_NET_CDR_DISTRIBUTION = 'distributionCdr';
export const ACT_NET_CDR_DURATION = 'durationCdr';
export const ACT_NET_CDR_AREA = 'areaCdr';
export const ACT_NET_TOOL_IP_QUERY = 'queryIp';


// reducer handle
const cdrState = {
    cdrData: undefined, 
}

export const cdr_reducer = (state=cdrState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_CDR:{
            return {
                ...state,
                cdrData: action.cdrData
            };
        }

        case ACT_CLEAN_CDR:
            return {
                cdrData: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const listCdrData = (state) => {
    return state.cdr !== undefined ? state.cdr.cdrData : undefined;
}


export const actCdrClear = () => {
    return {
        type: ACT_CLEAN_CDR,
    }
}

export function listCdrDataSuccessHd(dispatch, rspBody, reqBody) {
    // console.log("listCdrDataSuccessHd", rspBody);
    dispatch({
        type: ACT_LIST_CDR, 
        cdrData: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataListCdr(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/cdr/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CDR_LIST, successHd, errorHd));
}

export function dataDistributionCdr(props, type, group, start = undefined, end = undefined, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/cdr/statistics/distribution'
    const msgBody = {
        userId: reqUserInfo.user.id,
        type: type,
        group: group,
        start: start,
        end: end,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CDR_DISTRIBUTION, successHd, errorHd));
}

export function dataDurationCdr(props, rangeType=undefined, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/cdr/statistics/duration'
    const msgBody = {
        userId: reqUserInfo.user.id,
        rangeType: rangeType,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CDR_DURATION, successHd, errorHd));
}

export function dataAreaCdr(props, type = 'all', group = 'region', successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/cdr/statistics/area'
    const msgBody = {
        userId: reqUserInfo.user.id,
        type: type,
        group: group,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CDR_AREA, successHd, errorHd));
}

export function dataQueryIp(props, ips, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/tool/ip/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ips: ips,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_TOOL_IP_QUERY, successHd, errorHd));
}



