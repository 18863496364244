/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import Reflv from 'reflv';

class FlvCT extends Component{
    constructor(props){
        super(props);

        let query = this.props.location.search;
        const url = query.substr(5);
        this.state = {
            url: url,
        }
    }

    componentDidMount() {
        document.title = "直播会场"
    }

    componentWillUnmount() {

    }

    render() {
        const {url} = this.state;
        return <div className="ho-preview-video-player-box">
            <div className="ho-preview-video-player-video">
                <Reflv
                    url={url}
                    type="flv"
                    isLive={true}
                    cors={false}
                    config={{
                        enableWorker: true,
                        enableStashBuffer: false,
                        stashInitialSize: 128,
                    }}
                />
            </div>
        </div>
    }
}

const mapState = (state) => ({
});


export default connect(
  mapState, 
  null
)(FlvCT);