/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Descriptions, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer, Form
} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk2 } from '../../../../util/netReqRD';
import { getLoginUserInfo } from '../../../login/loginRD'
import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoTooltipIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoDeleteAlertDialog, HoBackButton, HoIconFont,
} from '../../../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../util/tableComponent';
import { 
    dataListFrpServer, dataListServerStatus,
    dataListMediaServer, ACT_NET_MEDIA_SERVER_LIST, dataAddMediaServer, dataDelMediaServer, dataModMediaServer, dataListMediaServerGroup2, dataUpgradeMediaServer,
} from './serverRD';
import { dataListLicense } from '../license/mcu/mcuRD';

import { Weight, MediaForm } from './mediaForm';
import { Alioss } from '../../alioss/aliossClient';
import { getNetWorkDesc } from '../../../../util/logic';
import { version } from '../../../../util/version';

import './server.css';

class ServerMediaCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 数据处理回调
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowEditOnClick = this.tblRowEditOnClick.bind(this);
        this.tblRowDelOnClick = this.tblRowDelOnClick.bind(this);
        this.tblRowUpgradeOnClick = this.tblRowUpgradeOnClick.bind(this);
        this.tblRowEnableOnClick = this.tblRowEnableOnClick.bind(this);
        this.tblRowDisableOnClick = this.tblRowDisableOnClick.bind(this);
        
        // 主页面按钮
        this.mainAddOnClick = this.mainAddOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        const columns = [
            hoTableColumn('状态', 'IsActive', 'int', false, false, '5%', {
                render: (text, record) => hoTableColumnTagButton([{color: !record.IsActive ? red[5] : blue[5], tag: !record.IsActive ? '禁用' : '启用'}]),
            }),
            hoTableColumn('名称', 'MsName', 'str', false, false, '10%'),
            hoTableColumn('详情', 'EslAddr', 'str', false, false, '50%', {
                render: (text, record) => this.renderDetail(text, record)
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                actions.push({ title: '编辑', onClick: this.tblRowEditOnClick })
                if (record.IsActive) {
                    actions.push({ title: '禁用', onClick: this.tblRowDisableOnClick })
                } else {
                    actions.push({ title: '启用', onClick: this.tblRowEnableOnClick })
                }
                actions.push({ title: '升级', onClick: this.tblRowUpgradeOnClick })
                actions.push({ title: '删除', onClick: this.tblRowDelOnClick })

                return hoTableColumnActionButton(actions, record);
            }, '10%'),
        ];

        this.state = {
            ossClient: new Alioss('tv-android-version', this.props),
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            pageVisiable: false,
            data: undefined,
            objOrg: undefined,
            objCopy: undefined,
            upgradePageVisiable: false,
            upgradeData: undefined,
            upgradeVersions: [],
            upgradeVersionUrl: '',
        }
    }

    dataChanged(dst, src) {
        if (dst && src) {
            for (let key in dst) {
                if (src?.[key]?.value !== dst[key]?.value) {
                    return true;
                }
            }
        }
        return false;
    }

    trans2ApiData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = d[name] ? 1 : 0;
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            d[key] = data[key].value;
        }
        transBoolean(d, 'VoiceByPass');
        transInt(d, 'FrpId');
        transInt(d, 'Weight');
        return d;
    }

    trans2LocalData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = !!(d?.[name]?.value)
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = `${d[name].value}`;
            }
        }

        let d = {};
        if (data) {
            for (let key in data) {
                d[key] = { value: data[key] }
            }
            transBoolean(d, 'VoiceByPass');
            transInt(d, 'FrpId');
            transInt(d, 'Weight');
        } else {
            d = undefined;
        }
        return d;
    }

    renderDetail(text, record) {


        function getStatusComponent(status, value, type) {
            let component = undefined;
            if (status === undefined) {
                component = <Fragment><Spin className="loading" size="small" /><span className="loading-description">正在检查...</span></Fragment>
            } else {
                switch (type) {
                    case 1:
                        component = (value === 'up' && <Tag color={blue[5]}>正常</Tag>) || (value === 'down' && <Tag color={red[5]}>异常</Tag>) || <Tag color={grey[3]}>未知</Tag>
                        break;
                    case 2:
                        component = (value === 'up' && <Tag color={blue[5]}>已连接</Tag>) || (value === 'down' && <Tag color={red[5]}>未连接</Tag>) || <Tag color={grey[3]}>未知</Tag>
                        break;
                    default:
                        break;
                }
            }
            return !record.IsActive ? undefined : component;
        }
        let { frpData, group } = this.state;
        let licenseComponent = (!record?.license?.IsActive && group?.GroupType === 0) ? <Tag color={red[5]}>未激活</Tag> : undefined
        let frp = frpData.find(f => f.Id === record.FrpId);
        return <Descriptions column={2}>
            <Descriptions.Item label="ID">{record?.Id || "无"}</Descriptions.Item>
            <Descriptions.Item label="代理服务器">{frp?.FrpName || "无"}</Descriptions.Item>
            <Descriptions.Item label="版本">{record?.Version?.MsVersion || "无"}</Descriptions.Item>
            <Descriptions.Item label="控制信令地址">{record?.RpcAddr}:{record?.RpcPort}<span className="status">{getStatusComponent(record?.status, record?.status?.rpc?.status, 1)}</span></Descriptions.Item>
            <Descriptions.Item label="激活码">{record?.license?.Identity || "无"}<span className="status">{licenseComponent}</span></Descriptions.Item>
            <Descriptions.Item label="事件监听地址">{record?.EslAddr}:{record?.EslPort}<span className="status">{getStatusComponent(record?.status, record?.status?.esl?.status, 2)}</span></Descriptions.Item>
            <Descriptions.Item label="音频透传">{record?.VoiceByPass ? "启用" : "禁用"}</Descriptions.Item>
            <Descriptions.Item label="呼叫信令地址(内部)">{record?.LocalAddr}:{record?.InternalSignalPort}<span className="status">{getStatusComponent(record?.status, record?.status?.internal?.status, 1)}</span></Descriptions.Item>
            <Descriptions.Item label="权重">{Weight.getDescription(record?.Weight)}</Descriptions.Item>
            <Descriptions.Item label="呼叫信令地址(外部)">{record?.ProxyAddr}:{record?.ExternalSignalPort}<span className="status">{getStatusComponent(record?.status, record?.status?.external?.status, 1)}</span></Descriptions.Item>
            <Descriptions.Item label="当前会议数">{record?.conferenceCount || 0}</Descriptions.Item>
            <Descriptions.Item label="远程连接地址">{record?.ProxyAddr}:{record?.SshPort}</Descriptions.Item>
            <Descriptions.Item label="总容量(方)">{record?.MaxCall || "不限制"}</Descriptions.Item>
            <Descriptions.Item label="备注">{record?.Remark || "无"}</Descriptions.Item>
        </Descriptions>
    }

    dataListSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        let Identities = [];
        // eslint-disable-next-line no-unused-expressions
        rspBody?.RecordList?.forEach(m => {
            Identities.push(m.Identity)
        })
        // 查到服务器的license
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = Identities.map(identity => {
            return [['Identity', 'like', identity]]
        })
        dataListLicense(this.props, paginationInfo, (dispatch, rsp3, req) => {
            // eslint-disable-next-line no-unused-expressions
            rspBody?.RecordList?.map(m => {
                let ret = rsp3?.RecordList?.find(e => e.BindMsId === m.Id);
                if (ret) {
                    m.license = ret
                }
                return m;
            });
            this.setState({
                data: {
                    paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody?.TotalNum),
                    records: rspBody?.RecordList,
                }
            })
        })
        
        // 查到服务器的状态
        // eslint-disable-next-line no-unused-expressions
        rspBody?.RecordList?.forEach(m => {
            dataListServerStatus(this.props, [m.Id], [], (dispatch, rsp2, req) => { 
                // eslint-disable-next-line no-unused-expressions
                rspBody?.RecordList?.forEach(m => {
                    let ret = rsp2?.mediaServers?.find(e => e.Id === m.Id);
                    if (ret) {
                        m.conferenceCount = ret.conferenceCount
                        m.status = ret.status
                    }
                });
                this.setState({
                    data: {
                        records: rspBody?.RecordList,
                    }
                })
    
            }, (dispatch, rsp, req) => {
    
            })
        })
        
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo) {
        let { groupId } = this.state;
        paginationInfo.pagination.filters = [[['GroupId', '=', groupId]]];
        dataListMediaServer(this.props, paginationInfo, this.dataListSuccessHd)
    }

    // '新增'按钮被点击
    mainAddOnClick() {
        this.setState({
            pageVisiable: true,
            operation: 'add',
            objOrg: undefined,
            objCopy: undefined,
        })
    }

    // '编辑'按钮被点击
    tblRowEditOnClick(e, record) {
        // console.log('edit on click', record)
        e.stopPropagation();
        let data = this.trans2LocalData(record);
        this.setState({
            pageVisiable: true,
            operation: 'edit',
            objOrg: data,
            objCopy: data,
        })
    }

    // '删除'按钮被点击
    tblRowDelOnClick(e, record) {
        let that = this;
        let { groupId } = this.state;
        Modal.confirm({
            content: <div>是否删除<span className="warn">{record.GroupName}</span>？</div>,
            onOk() {
                dataDelMediaServer(that.props, groupId, record, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
    }
    // '升级'按钮被点击
    tblRowUpgradeOnClick(e, record) {
        let that = this;
        let { ossClient } = this.state;
        let environment = version.environment === "development" ? "test" : version.environment;
        ossClient.fileList(`mcu/${environment}/`, (prefix, versions) => {
            that.setState({
                upgradePageVisiable: true,
                upgradeData: record,
                upgradeVersions: versions,
                upgradeVersionUrl: versions?.[0]?.url || ''
            })
        }, () => {
            
        })
    }

    // '启用'按钮被点击
    tblRowEnableOnClick(e, record) {
        let that = this;
        let { groupId } = this.state;
        let info = Object.assign({}, record);
        info.IsActive = 1;
        dataModMediaServer(that.props, groupId, info, () => {
            that.mainRefreshOnClick();
        }, () => {
                
        })
    }

    // '禁用'按钮被点击
    tblRowDisableOnClick(e, record) {
        let that = this;
        Modal.confirm({
            content: <div>是否禁用<span className="warn">{record.MsName}</span>？</div>,
            onOk() {
                let { groupId } = that.state;
                let info = Object.assign({}, record);
                info.IsActive = 0;
                dataModMediaServer(that.props, groupId, info, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
        
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let that = this;
        let { group, groupId, paginationInfo } = this.state;
        paginationInfo.pagination.filters = [[['GroupId', '=', groupId]]];
        dataListMediaServer(this.props, paginationInfo, this.dataListSuccessHd);
        dataListFrpServer(this.props, (dispatch, rsp, req) => {
            that.setState({
                frpData: rsp?.FrpList,
            });
        }, (dispatch, rsp, req) => {

        });
        
        if (!group) {
            let paginationInfo2 = hoTableInitPaginationInfo();
            paginationInfo2.pagination.filters = [[['Id', '=', groupId]]]
            dataListMediaServerGroup2(this.props, paginationInfo2, (dispatch, rsp, req) => {
                that.setState({
                    group: rsp.RecordList?.[0],
                });
            })
        }
        
    }

    // 面包屑
    mkBreadcrumb(){
        let group = this.state.group || {};
        return <HoBreadcrumb
            items={[{title: "系统设置", url: "devops/setting"}, {title: "服务器" , url: "devops/setting/server/group"}, {title: group.GroupName}]}
        />
    }

    mkHeaderInfo() {
        let group = this.state.group || {};
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("devops/setting/server/group")}/>
            <HoNewButton onClick={this.mainAddOnClick}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return { title: <span>{group.GroupName}</span>, buttons: buttons };
    }

    mkPage() {
        let that = this;
        let { groupId, frpData, objOrg, objCopy, pageVisiable, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title={operation === 'add' ? "新增" : "编辑"}
            visible={pageVisiable}
            okText={operation === 'add' ? "确定" : "保存"}
            okButtonProps={{disabled: operation === 'edit' && !this.dataChanged(objCopy, objOrg)}}
            onOkClick={() => {
                if (operation === 'add') {
                    dataAddMediaServer(that.props, groupId, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                } else {
                    dataModMediaServer(that.props, groupId, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                }
            }}
            onCancelClick={() => {
                this.setState({
                    pageVisiable: false,
                })
            }}
        >
            <MediaForm
                fields={objCopy}
                frpData={frpData}
                onChange={(changedFields) => {
                    this.setState(({ objCopy }) => ({
                        objCopy: { ...objCopy, ...changedFields },
                    }));
                }}
            />
        </HoAlertDialog>
    }

    mkUpgradePage() {
        let that = this;
        let { upgradeData, upgradeVersionUrl, upgradeVersions, upgradePageVisiable } = this.state;
        let versionNoOrg = upgradeData?.Version?.MsVersion;
        let versionTimeOrg = upgradeData?.Version?.BuildTime;
        let versionHashOrg = upgradeData?.Version?.BuildHash;
        let index = upgradeVersions.findIndex(item => item.url === upgradeVersionUrl);
        let upgradeVersion = index !== -1 ? upgradeVersions[index] : undefined;
        let sps = upgradeVersion?.name?.split('-');
        let versionNo = sps?.[2] || '';
        let versionHash = sps?.[3] || '';
        let versionTime = undefined
        let reg = /(\d{4})-(\d{2})-(\d{2})_(\d{2})_(\d{2})_(\d{2})/.exec(sps?.slice(4).join('-'))
        if (reg) {
            versionTime = `${reg[1]}-${reg[2]}-${reg[3]} ${reg[4]}:${reg[5]}:${reg[6]}`;
        }
        let latest = index === 0 ? <Fragment><HoIconFont className="latest" type="icon-hot"/><Tag color="#f5222d" className="latest">最新</Tag></Fragment> : undefined;
        return <HoAlertDialog
            width="50%"
            title={`升级 - ${upgradeData?.MsName} `}
            visible={upgradePageVisiable}
            okText="确定"
            onOkClick={() => {
                dataUpgradeMediaServer(that.props, upgradeData.Id, upgradeVersionUrl, () => {
                    that.setState({
                        upgradePageVisiable: false,
                        upgradeData: undefined,
                        upgradeVersions: [],
                        upgradeVersionUrl: '',
                    })
                    hoMessage({type: "info", msg: "升级指令已下发，稍后请点击刷新按钮，查看升级结果", duration: 10})
                }, () => {
                
                })
            }}
            onCancelClick={() => {
                this.setState({
                    upgradePageVisiable: false,
                    upgradeData: undefined,
                    upgradeVersions: [],
                    upgradeVersionUrl: '',
                })
            }}
        >
            <Typography.Text className="server-version-title">当前版本</Typography.Text>
            <Form
                className="server-version"
                labelCol={{ xs: { span: 24 }, sm: { span: 4 }, }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 20 }, }}
            >
                <Form.Item label="版本号">{versionNoOrg}</Form.Item>
                <Form.Item label="发布时间">{versionTimeOrg}</Form.Item>
                <Form.Item label="编译哈希">{versionHashOrg}</Form.Item>
            </Form>
            <Typography.Text className="server-version-title">待升级版本</Typography.Text>
            <Form
                className="server-version"
                labelCol={{ xs: { span: 24 }, sm: { span: 4 }, }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 20 }, }}
            >
                <Form.Item label="升级包">
                    <Select
                        showSearch
                        value={upgradeVersionUrl}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                        this.setState({
                            upgradeVersionUrl: value
                        })
                    }}>
                        {
                            upgradeVersions.map((version, index) => {
                                return <Select.Option key={index} value={version?.url}>{version?.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="版本号">{versionNo}{latest}</Form.Item>
                <Form.Item label="包大小">{getNetWorkDesc(upgradeVersion?.size || 0, "B")}</Form.Item>
                <Form.Item label="发布时间">{versionTime}</Form.Item>
                <Form.Item label="编译哈希">{versionHash}</Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data } = this.state;
        const { getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return <div className="ho-server-media-table server">
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'Id'}
                loading={getStatus === 1}
                onColumnChange={this.tblColOnChange}
                // onRowDoubleClick={this.tblRowEditOnClick}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount(){
        this.props.dispatch(actNetRequestClear(ACT_NET_MEDIA_SERVER_LIST));
    }

    componentDidMount(){
        setTimeout(() => {
            let groupId = parseInt(this.props.match?.params?.groupId || 0)
            this.setState({
                groupId: groupId,
                group: this.props.location?.state,
            })
            this.mainRefreshOnClick()
        })
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const pageComponent = this.mkPage();
        const upgradePageComponent = this.mkUpgradePage();

        return (<div>
            {upgradePageComponent}
            {pageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_MEDIA_SERVER_LIST), 
});


export default connect(
    mapState, 
    null
)(ServerMediaCT);

