/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Descriptions, Input, Button, Radio,Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, Dropdown, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Drawer
} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk2 } from '../../../../util/netReqRD';
import { getLoginUserInfo } from '../../../login/loginRD'
import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoTooltipIcon, HoRefreshButton, HoNewButton, HoAlertDialog, HoDeleteAlertDialog, HoBackButton,
} from '../../../../util/hoComponent';
import { HoTableExtend, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../../../util/tableComponent';
import { 
    dataListFrpServer, dataListServerStatus,
    dataListSignalServer, ACT_NET_SIGNAL_SERVER_LIST, dataAddSignalServer, dataDelSignalServer, dataModSignalServer,
} from './serverRD';

import { SignalForm } from './signalForm';

import './server.css';

class ServerSignalCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 数据处理回调
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblRowEditOnClick = this.tblRowEditOnClick.bind(this);
        this.tblRowDelOnClick = this.tblRowDelOnClick.bind(this);
        this.tblRowEnableOnClick = this.tblRowEnableOnClick.bind(this);
        this.tblRowDisableOnClick = this.tblRowDisableOnClick.bind(this);

        // 主页面按钮
        this.mainAddOnClick = this.mainAddOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        const columns = [
            hoTableColumn('状态', 'IsActive', 'int', false, false, '5%', {
                render: (text, record) => hoTableColumnTagButton([{color: !record.IsActive ? red[5] : blue[5], tag: !record.IsActive ? '禁用' : '启用'}]),
            }),
            hoTableColumn('名称', 'SsName', 'str', false, false, '10%'),
            hoTableColumn('详情', 'EslAddr', 'str', false, false, '50%', {
                render: (text, record) => {
                    let rpcStatusComponent = (record?.status?.rpc?.status === 'up' && <Tag color={blue[5]}>正常</Tag>) || (record?.status?.rpc?.status === 'down' && <Tag color={red[5]}>异常</Tag>) || <Tag color={grey[3]}>未知</Tag>
                    let eslStatusComponent = (record?.status?.esl?.status === 'up' && <Tag color={blue[5]}>已连接</Tag>) || (record?.status?.esl?.status === 'down' && <Tag color={red[5]}>未连接</Tag>) || <Tag color={grey[3]}>未知</Tag>
                    let internalStatusComponent = (record?.status?.internal?.status === 'up' && <Tag color={blue[5]}>正常</Tag>) || (record?.status?.internal?.status === 'down' && <Tag color={red[5]}>异常</Tag>) || <Tag color={grey[3]}>未知</Tag>
                    let externalStatusComponent = (record?.status?.external?.status === 'up' && <Tag color={blue[5]}>正常</Tag>) || (record?.status?.external?.status === 'down' && <Tag color={red[5]}>异常</Tag>) || <Tag color={grey[3]}>未知</Tag>
                    return <Descriptions column={1}>
                        <Descriptions.Item label="ID">{record?.Id || "无"}</Descriptions.Item>
                        <Descriptions.Item label="控制信令地址">{record?.RpcAddr}:{record?.RpcPort}<span className="status">{rpcStatusComponent}</span></Descriptions.Item>
                        <Descriptions.Item label="事件监听地址">{record?.EslAddr}:{record?.EslPort}<span className="status">{eslStatusComponent}</span></Descriptions.Item>
                        <Descriptions.Item label="呼叫信令地址(内部)">{record?.LocalAddr}:{record?.InternalSignalPort}<span className="status">{internalStatusComponent}</span></Descriptions.Item>
                        <Descriptions.Item label="呼叫信令地址(外部)">{record?.PublicAddr}:{record?.ExternalSignalPort}<span className="status">{externalStatusComponent}</span></Descriptions.Item>
                        <Descriptions.Item label="总容量(方)">{record?.MaxCall || "不限制"}</Descriptions.Item>
                    </Descriptions>
                }
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                actions.push({ title: '编辑', onClick: this.tblRowEditOnClick })
                if (record.IsActive) {
                    actions.push({ title: '禁用', onClick: this.tblRowDisableOnClick })
                } else {
                    actions.push({ title: '启用', onClick: this.tblRowEnableOnClick })
                }
                actions.push({ title: '删除', onClick: this.tblRowDelOnClick })

                return hoTableColumnActionButton(actions, record);
            }, '10%'),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            pageVisiable: false,
            data: undefined,
            objOrg: undefined,
            objCopy: undefined,
        }
    }

    dataChanged(dst, src) {
        if (dst && src) {
            for (let key in dst) {
                if (src?.[key]?.value !== dst[key]?.value) {
                    return true;
                }
            }
        }
        return false;
    }

    trans2ApiData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = d[name] ? 1 : 0;
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            d[key] = data[key].value;
        }

        return d;
    }

    trans2LocalData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = !!(d?.[name]?.value)
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = `${d[name].value}`;
            }
        }

        let d = {};
        if (data) {
            for (let key in data) {
                d[key] = { value: data[key] }
            }

        } else {
            d = undefined;
        }
        return d;
    }

    dataListSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        let Identities = [];
        let ids = [];
        // eslint-disable-next-line no-unused-expressions
        rspBody.SsList?.forEach(m => {
            Identities.push(m.Identity)
            ids.push(m.Id);
        })
        
        // 查到服务器的状态
        dataListServerStatus(this.props, [], ids, (dispatch, rsp2, req) => { 
            rspBody.SsList.map(m => {
                let ret = rsp2?.signalServers?.find(e => e.Id === m.Id);
                if (ret) {
                    m.status = ret.status
                }
                return m;
            });
            this.setState({
                data: rspBody.SsList
            })

        }, (dispatch, rsp, req) => {

        })
    }

    // '启用'按钮被点击
    tblRowEnableOnClick(e, record) {
        let that = this;
        let info = Object.assign({}, record);
        info.IsActive = 1;
        dataModSignalServer(that.props, info, () => {
            that.mainRefreshOnClick();
        }, () => {
                
        })
    }

    // '禁用'按钮被点击
    tblRowDisableOnClick(e, record) {
        let that = this;
        Modal.confirm({
            content: <div>是否禁用<span className="warn">{record.SsName}</span>？</div>,
            onOk() {
                let info = Object.assign({}, record);
                info.IsActive = 0;
                dataModSignalServer(that.props, info, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
        
    }

    // '新增'按钮被点击
    mainAddOnClick() {
        this.setState({
            pageVisiable: true,
            operation: 'add',
            objOrg: undefined,
            objCopy: undefined,
        })
    }

    // '编辑'按钮被点击
    tblRowEditOnClick(e, record) {
        // console.log('edit on click', record)
        e.stopPropagation();
        let data = this.trans2LocalData(record);
        this.setState({
            pageVisiable: true,
            operation: 'edit',
            objOrg: data,
            objCopy: data,
        })
    }

    // '删除'按钮被点击
    tblRowDelOnClick(e, record) {
        let that = this;
        Modal.confirm({
            content: <div>是否删除<span className="warn">{record.GroupName}</span>？</div>,
            onOk() {
                dataDelSignalServer(that.props, record, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        dataListSignalServer(this.props, this.dataListSuccessHd);
    }

    // 面包屑
    mkBreadcrumb(){
        let group = this.props.location.state || {};
        return <HoBreadcrumb
            items={[{title: "系统设置", url: "devops/setting"}, {title: "服务器" , url: "devops/setting/server/group"}, {title: group.GroupName}]}
        />
    }

    mkHeaderInfo() {
        let group = this.props.location.state || {};
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("devops/setting/server/group")}/>
            <HoNewButton onClick={this.mainAddOnClick}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return { title: <span>{group.GroupName}</span>, buttons: buttons };
    }

    mkPage() {
        let that = this;
        let { frpData, objOrg, objCopy, pageVisiable, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title={operation === 'add' ? "新增" : "编辑"}
            visible={pageVisiable}
            okText={operation === 'add' ? "确定" : "保存"}
            okButtonProps={{disabled: operation === 'edit' && !this.dataChanged(objCopy, objOrg)}}
            onOkClick={() => {
                if (operation === 'add') {
                    dataAddSignalServer(that.props, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                } else {
                    dataModSignalServer(that.props, that.trans2ApiData(objCopy), () => {
                        that.setState({
                            pageVisiable: false,
                        })
                        that.mainRefreshOnClick()
                    }, () => {
                        
                    })
                }
            }}
            onCancelClick={() => {
                this.setState({
                    pageVisiable: false,
                })
            }}
        >
            <SignalForm
                fields={objCopy}
                frpData={frpData}
                onChange={(changedFields) => {
                    this.setState(({ objCopy }) => ({
                        objCopy: { ...objCopy, ...changedFields },
                    }));
                }}
            />
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data } = this.state;
        const { getStatus } = this.props;

        return <div className="ho-server-signal-table server">
            <HoTableExtend
                key="table"
                columns={columns}
                data={data}
                rowKey={'Id'}
                loading={getStatus === 1}
                // onRowDoubleClick={this.tblRowEditOnClick}
            />
        </div>
    }

    // clear handle
    componentWillUnmount(){
        this.props.dispatch(actNetRequestClear(ACT_NET_SIGNAL_SERVER_LIST));
    }

    componentDidMount(){
        setTimeout(() => {
            let groupId = parseInt(this.props.match?.params?.groupId || 0)
            this.setState({
                groupId: groupId,
            })
            this.mainRefreshOnClick()
        })
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const pageComponent = this.mkPage();

        return (<div>
            {pageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_SIGNAL_SERVER_LIST), 
});


export default connect(
    mapState, 
    null
)(ServerSignalCT);

