/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../util/tableComponent";
import { encrypt } from '../../util/logic';
import { netRequestThunk } from '../../util/netReqRD';

const ACT_LIST_USER = 'ACT_LIST_USER';
const ACT_MOD_USER = 'ACT_MOD_USER';
const ACT_CLEAN_USER = 'ACT_CLEAN_USER';


export const ACT_NET_USER_LIST = 'listUser';
export const ACT_NET_USER_MOD = 'modUser';
export const ACT_NET_USER_NEW = 'newUser';


// reducer handle
const userState = {
    userData: undefined, 
}

export const user_reducer = (state=userState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_USER:{
            return {
                ...state,
                userData: action.userData
            };
        }

        case ACT_CLEAN_USER:
            return {
                userData: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const listUserData = (state) => {
    return state.user !== undefined ? state.user.userData : undefined;
}

export const actUserClear = () => {
    return {
        type: ACT_CLEAN_USER,
    }
}

export function listUserDataSuccessHd(dispatch, rspBody, reqBody) {
    // console.log("listUserDataSuccessHd", rspBody);
    dispatch({
        type: ACT_LIST_USER, 
        userData: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataListUser(props, paginationInfo, successHd = undefined, errorHd = undefined,){
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/user/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    dispatch(netRequestThunk(path, msgBody, undefined, ACT_NET_USER_LIST, successHd, errorHd));
}

export function dataNewUser(props, userInfo, successHd = undefined, errorHd = undefined){
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/user/create'

    // 用户名密码加密
    userInfo.user.username = encrypt(userInfo.user.username)
    userInfo.user.password = encrypt(userInfo.user.password)

    const msgBody = {
        userId: reqUserInfo.user.id,
        ...userInfo,
    };
    dispatch(netRequestThunk(path, msgBody, undefined, ACT_NET_USER_NEW, successHd, errorHd));
}





