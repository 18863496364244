/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../../../../util/tableComponent";
import { netRequestThunk, netRequestThunk2 } from '../../../../util/netReqRD';
import { loginNoauthHd } from "../../../login/loginRD";

export const ACT_NET_FRP_SERVER_LIST = 'listSignalServer';
export const ACT_NET_SERVER_STATUS_LIST = 'listServerStatus';

export const ACT_NET_SIGNAL_SERVER_ADD = 'addSignalServer';
export const ACT_NET_SIGNAL_SERVER_MOD = 'modSignalServer';
export const ACT_NET_SIGNAL_SERVER_DEL = 'delSignalServer';
export const ACT_NET_SIGNAL_SERVER_LIST = 'listSignalServer';

export const ACT_NET_MEDIA_SERVER_GROUP_ADD = 'addMediaServerGroup';
export const ACT_NET_MEDIA_SERVER_GROUP_MOD = 'modMediaServerGroup';
export const ACT_NET_MEDIA_SERVER_GROUP_DEL = 'delMediaServerGroup';
export const ACT_NET_MEDIA_SERVER_GROUP_LIST = 'listMediaServerGroup';

export const ACT_NET_MEDIA_SERVER_LIST = 'listMediaServer';
export const ACT_NET_MEDIA_SERVER_ADD = 'addMediaServer';
export const ACT_NET_MEDIA_SERVER_MOD = 'modMediaServer';
export const ACT_NET_MEDIA_SERVER_DEL = 'delMediaServer';
export const ACT_NET_MEDIA_SERVER_UP = 'upMediaServer';


export const dataListServerStatus = (props, mediaServerIds, signalServerIds, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/mcu/server/status/query'
    const msgBody = {
        "mediaServerIds": mediaServerIds,
        "signalServerIds": signalServerIds,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_SERVER_STATUS_LIST, successFun, errorFun));
}

export const dataListFrpServer = (props, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/frp/list'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_FRP_SERVER_LIST, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, undefined, header, reqUserInfo, ACT_NET_FRP_SERVER_LIST, successFun, errorFun))
    );
}

export const dataAddSignalServer = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ss/add'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, info, header, ACT_NET_SIGNAL_SERVER_ADD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, info, header, reqUserInfo, ACT_NET_SIGNAL_SERVER_ADD, successFun, errorFun))
    );
}

export const dataDelSignalServer = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ss/del'
    const body = {
        SsId: info.SsId,
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_SIGNAL_SERVER_DEL, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_SIGNAL_SERVER_DEL, successFun, errorFun))
    );
}

export const dataModSignalServer = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ss/mod'
    const body = {
        ...info,
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_SIGNAL_SERVER_MOD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_SIGNAL_SERVER_MOD, successFun, errorFun))
    );
}

export const dataListSignalServer = (props, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ss/list'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_SIGNAL_SERVER_LIST, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, undefined, header, reqUserInfo, ACT_NET_SIGNAL_SERVER_LIST, successFun, errorFun))
    );
}

export const dataAddMediaServerGroup = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/msGroup/add'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, info, header, ACT_NET_MEDIA_SERVER_GROUP_ADD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, info, header, reqUserInfo, ACT_NET_MEDIA_SERVER_GROUP_ADD, successFun, errorFun))
    );
}

export const dataDelMediaServerGroup = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/msGroup/del'
    const body = {
        ...info,
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_GROUP_DEL, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_GROUP_DEL, successFun, errorFun))
    );
}

export const dataModMediaServerGroup = (props, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/msGroup/mod'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, info, header, ACT_NET_MEDIA_SERVER_GROUP_MOD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, info, header, reqUserInfo, ACT_NET_MEDIA_SERVER_GROUP_MOD, successFun, errorFun))
    );
}

export const dataListMediaServerGroup = (props, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/msGroup/list'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, undefined, header, ACT_NET_MEDIA_SERVER_GROUP_LIST, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, undefined, header, reqUserInfo, ACT_NET_MEDIA_SERVER_GROUP_LIST, successFun, errorFun))
    );
}

export const dataListMediaServerGroup2 = (props, paginationInfo, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/msGroup/list2'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    const body = {
        ...hoTableTransPaginationInfo(paginationInfo),
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_GROUP_LIST, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_GROUP_LIST, successFun, errorFun))
    );
}

export const dataListMediaServer = (props, paginationInfo, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/list'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    const body = {
        ...hoTableTransPaginationInfo(paginationInfo),
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_LIST, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_LIST, successFun, errorFun))
    );
}

export const dataAddMediaServer = (props, groupId, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/inGroup/add'
    const body = {
        GroupId: groupId,
        ...info
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_ADD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_ADD, successFun, errorFun))
    );
}

export const dataDelMediaServer = (props, groupId, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/inGroup/del'
    const body = {
        GroupId: groupId,
        MsId: info.Id,
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_DEL, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_DEL, successFun, errorFun))
    );
}

export const dataModMediaServer = (props, groupId, info, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/inGroup/mod'
    const body = {
        GroupId: groupId,
        MsId: info.Id,
        ...info
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_MOD, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_MOD, successFun, errorFun))
    );
}

export const dataUpgradeMediaServer = (props, id, url, successFun = undefined, errorFun = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/upgrade'
    const body = {
        MsId: id,
        DownloadUrl: url,
    }
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_MEDIA_SERVER_UP, successFun, errorFun,
        () => loginNoauthHd(dispatch, path, body, header, reqUserInfo, ACT_NET_MEDIA_SERVER_UP, successFun, errorFun))
    );
}






