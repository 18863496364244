/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Form, Input, Select } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { ArrowRightOutlined } from '@ant-design/icons';
import { dataTerminalLicenseForbidden, dataTerminalLicensePenewal } from '../../../../../api/terminalLicence';
import { HoAlertDialog, hoMessage } from '../../../../../util/hoComponent';

export function remainingDate(endTime) {
    let expire = false;
    let diff = moment(endTime).startOf('day').diff(moment().startOf('day'), 'd');
    if (diff >= 0) {
        expire = false;
    } else {
        expire = true;
        diff = -diff
    }

    let duration = moment.duration(diff, 'd')
    let years = duration.years();
    let months = duration.months();
    let days = duration.days();
    if (years || months || expire) {
        return <div style={{ color: expire ? red[5] : undefined, fontWeight: expire ? 600 : undefined }}>
            {(expire ? "已超期" : "") + (years ? `${years}年` : "") + (months ? `${months}个月` : "") + (days ? `${days}天` : "")}
        </div>
    } else {
        return <div style={{color: gold[5], fontWeight: 600}}>{days}天（即将超期）</div>
    }
}

export function durationDate(day) {
    let duration = moment.duration(day || 0, 'd')
    let years = duration.years();
    let months = duration.months();
    let days = duration.days();
    return (years ? `${years}年` : "") + (months ? `${months}个月` : "") + (days ? `${days}天` : "")
}

export function operationActive(record, active, successHd) {
    Modal.confirm({
        content: <div>{`是否${active ? "启用" : "禁用"}`}<span className="warn">{record.LicenseId}</span>？</div>,
        onOk: () => {
            dataTerminalLicenseForbidden(this.props, [record.LicenseId], !active,
                (_, rsp, req) => {
                    hoMessage({ type: 'success', msg: `${active ? "启用" : "禁用"}成功` });
                    successHd && successHd(rsp)
                },
                () => {
                    hoMessage({type: 'error', msg: `${active ? "启用" : "禁用"}失败`});
                    return true;
                }
            )
        },
        okText: "是的",
        cancelText: "我再想想",
        cancelButtonProps: { type: "danger" },
    })
}


export function penewalPageDom() {
    let { penewalPageVisible, penewalData, penewalDuration } = this.state;

    let year = parseInt(penewalDuration / 365);
    if ((penewalDuration % 365 !== 0) || ((1 > year || year > 10) && year !== 100)) {
        year = 0
    }
    return <HoAlertDialog
        className="penewal-modal"
        title="续期"
        visible={penewalPageVisible}
        onOkClick={() => {
            dataTerminalLicensePenewal(this.props, [penewalData?.LicenseId], penewalDuration,
                () => {
                    this.setState({
                        penewalPageVisible: false,
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                }
            );
        }}
        onCancelClick={() => {
            this.setState({
                penewalPageVisible: false,
            });
        }}
    >
        <Form className="penewal-modal-form">
            <Form.Item label="剩余时间" className="penewal-modal-form-item-remaining">
                <div className="penewal-modal-form-item-remaining-content">
                    <div className="now">{remainingDate(penewalData?.EndTime)}</div>
                    <ArrowRightOutlined className="sp"/>
                    <div className="future">{remainingDate(moment(penewalData?.EndTime).add(penewalDuration, 'd').format("YYYY-MM-DD"))}</div>
                </div>
            </Form.Item>
            <Form.Item label="续期时间">
                <Input className="duration-input" value={penewalDuration}
                    addonAfter="天"
                    addonBefore={<Select style={{ width: '100%' }} defaultValue={1} value={year} onChange={(key) => {
                        this.setState({
                            penewalDuration: 365 * key
                        })
                    }}>
                        <Select.Option disabled={true} key={0} value={0}></Select.Option>
                            {Array.from({ length: 10 }).map((e, index) => {
                                return <Select.Option key={index + 1} value={index + 1}>{index + 1}年</Select.Option>
                            })}
                        <Select.Option key={100} value={100}>永久（100年）</Select.Option>
                    </Select>}
                    onChange={(e) => {
                        this.setState({
                            penewalDuration: parseInt(e.target.value) || 0
                        })
                    }
                } />
            </Form.Item>
            
        </Form>
    </HoAlertDialog>
}

export function penewalPageShow(e, record) {
    this.setState({
        penewalPageVisible: true,
        penewalData: record,
        penewalDuration: 365,
    })
}
