/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../util/tableComponent";
import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export const dataWarnList = (props, paginationInfo, successHd = undefined, errorHd = undefined) => {
    const { reqUserInfo, dispatch } = props;
    const path = '/cs/v1/mntn/netstat/alarm/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "warnList", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "warnList", successHd, successHd))
    );
}

