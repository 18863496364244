/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';

import { PageHeader, Menu, Dropdown, Button, Descriptions, Typography, Input, Modal, Form, Tag } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, HoRefreshButton,HoButton, HoBackButton
} from '../../util/hoComponent';

import { 
    actNetRequestClear, getNetRequestStatus, netRequestThunk2 
} from '../../util/netReqRD';
import {
    getLoginUserInfo
} from '../login/loginRD';
import {
    getMemberDataSuccessHd, dataGetMember, ACT_NET_MEMBER_GET, getMemberData,
} from './memberRD';
import { deviceTypeMap } from '../../defined/memberDevice';
import { hoTableColumnTagButton } from '../../util/tableComponent';
import { callStateTags } from '../../component/common/common';
import { Fragment } from 'react';
import { formatValidTime } from '../../util/logic';
import { apiMemberSetLocation } from '../../api/member';


class MemberDetailCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 数据回调
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面回调
        
        this.state = {
            memberInfo: {
                memberId: parseInt(this.props.match.params.id)
            },
            prepage: this.props.location.state,
        }
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e){
        dataGetMember(this.props, this.state.memberInfo, getMemberDataSuccessHd);
    }

    // '设置位置'
    mainSetLocationOnClick(e) {
        e.stopPropagation();
        this.setState({
            modPageVisible: true,
            modMemberInfo: Object.assign({}, this.props.memberInfo),
        });
    }

    mkModModal(){

        const {modPageVisible, modMemberInfo} = this.state;

        return <Modal
            title="编辑设备"
            visible={modPageVisible}
            width="50%"
            okText="确定"
            onOk={() => {
                apiMemberSetLocation(this.props, modMemberInfo,
                    () => {
                        this.setState({
                            modPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick()
                        })
                    },
                    () => {
                        this.setState({
                            modPageVisible: false,
                        })
                    }
                )
            }}
            cancelText="我再想想"
            onCancel={() => {
                this.setState({
                    modPageVisible: false,
                })
            }} 
            destroyOnClose={true}
            >
            <Form>
                <Form.Item label="经度">
                    <Input 
                        autoFocus={true}
                        allowClear={true} 
                        value={modMemberInfo?.Lng}
                        onChange={(e) => {
                            modMemberInfo.Lng = e.target.value
                            this.setState({
                                modMemberInfo
                            })
                        }} 
                    />
                </Form.Item>
                <Form.Item label="纬度">
                    <Input 
                        allowClear={true} 
                        value={modMemberInfo?.Lat}
                        onChange={(e) => {
                            modMemberInfo.Lat = e.target.value
                            this.setState({
                                modMemberInfo
                            })
                        }} 
                    />
                </Form.Item>
            </Form>
        </Modal>
    }


    // 面包屑
    mkBreadcrumb() {
        let { prepage } = this.state;
        switch (prepage) {
            case 'member/camera':
                return <HoBreadcrumb
                    items={[{title: "用户管理", url: "member"}, {title: "摄像头", url: prepage}, {title: "详情"}]}
                />
            case 'bill/cdr':
            case 'member/device':
            default:
                return <HoBreadcrumb
                    items={[{title: "用户管理", url: "member"}, {title: "设备用户", url: prepage}, {title: "详情"}]}
                />
        }
        
    }

    mkHeaderInfo() {
        let { prepage } = this.state;
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu(prepage)}/>
            <HoButton icon="environment" onClick={(e) => this.mainSetLocationOnClick(e)}>设置位置</HoButton>
            <HoRefreshButton key="refresh" onClick={this.mainRefreshOnClick}/>
        </div>);
        
        return {title: <span>详情</span>, buttons: buttons}; 
    }

    mkBase(){
        const {memberInfo} = this.props;
        if (!memberInfo){
            return undefined;
        }

        let tag = hoTableColumnTagButton(callStateTags("", memberInfo));
        const mainContent = <div></div>;

        const extraContent = <div>
            <Descriptions size="small" layout="vertical" column={4}>
                <Fragment>
                    <Descriptions.Item label="设备(用户)名称">
                        <Typography.Paragraph>{memberInfo.NickName}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="状态" className="descriptions-state">
                        {tag}
                    </Descriptions.Item>
                    <Descriptions.Item label="类型">
                        <Typography.Paragraph>{deviceTypeMap.find(item => item.value === memberInfo.DeviceType)?.title || memberInfo.DeviceType}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="版本号"><Typography.Paragraph>{memberInfo.Version}</Typography.Paragraph></Descriptions.Item>
                </Fragment>

                <Fragment>
                    <Descriptions.Item label="SIP号">
                        <Typography.Paragraph copyable={!!memberInfo.SipNum} style={{ color: geekblue[3] }}>{memberInfo.SipNum}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="SIP密码">
                        <Typography.Paragraph copyable={!!memberInfo.SipPassword}>{memberInfo.SipPassword}</Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="位置" span={2}>
                        <Typography.Paragraph>{memberInfo.Lng} , {memberInfo.Lat}</Typography.Paragraph>
                    </Descriptions.Item>
                </Fragment>

                <Fragment>
                    <Descriptions.Item label="创建时间"><Typography.Paragraph>{formatValidTime(memberInfo.CreateTime)}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="最近在线时间" span={3}><Typography.Paragraph>{formatValidTime(memberInfo.OnlineTime)}</Typography.Paragraph></Descriptions.Item>
                </Fragment>

                {
                    memberInfo.DeviceType === "CAMERA-28181" ? <Fragment>
                        <Descriptions.Item label="发行状态">
                            <Typography.Paragraph>{memberInfo.IssuedInfo?.IsIssued ? <Tag color={blue[5]}>已发行</Tag> : <Tag color={grey[3]}>未发行</Tag>}</Typography.Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label="发行时间">
                            <Typography.Paragraph >{memberInfo.IssuedInfo?.IssuedTime}</Typography.Paragraph>
                        </Descriptions.Item>
                        <Descriptions.Item label="发行备注" span={2}>
                            <Typography.Paragraph >{memberInfo.IssuedInfo?.Remark}</Typography.Paragraph>
                        </Descriptions.Item>
                    </Fragment> : undefined
                }

                {
                    memberInfo.SipState === 'online' ?
                        <Fragment>
                            <Descriptions.Item label="注册协议"><Typography.Paragraph>{memberInfo.Protocol}</Typography.Paragraph></Descriptions.Item>
                            <Descriptions.Item label="注册IP">
                                <Typography.Paragraph copyable={!!memberInfo.SipContactIp}>{memberInfo.SipContactIp}</Typography.Paragraph>
                            </Descriptions.Item>
                            <Descriptions.Item label="注册端口" span={2}>
                                <Typography.Paragraph copyable={!!memberInfo.SipContactPort}>{memberInfo.SipContactPort}</Typography.Paragraph>
                            </Descriptions.Item>
                        </Fragment> : undefined
                }
            </Descriptions>
        </div>;

        return <div>
            <PageHeader className="ho-pageheader">
                <div className="content">
                    <div className="main">{mainContent}</div>
                    <div className="extra">{extraContent}</div>
                </div>
            </PageHeader>
        </div>
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_MEMBER_GET));
    }

    componentDidMount(){
        dataGetMember(this.props, this.state.memberInfo, getMemberDataSuccessHd);
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const baseComponent = this.mkBase();
        const modComponent = this.mkModModal();

        return (<div>
            {modComponent}
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {baseComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_MEMBER_GET), 
    memberInfo: getMemberData(state),
});


export default connect(
    mapState, 
    null
)(MemberDetailCT);

