/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import G6 from '@antv/g6';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'
import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import { 
    dataListProblem, dataListHistory, dataListGroup, dataListHost, dataListItem,
    ZABBIX_HOST_IDS, 
    ZABBIX_CM_CPU, ZABBIX_CM_MEMORY, ZABBIX_CM_PORT,
    ZABBIX_FREESWITCH_CPU, ZABBIX_FREESWITCH_MEMORY, ZABBIX_FREESWITCH_PORT,
    ZABBIX_CS_CPU, ZABBIX_CS_MEMORY, ZABBIX_CS_PORT,
    ZABBIX_FLV_CPU, ZABBIX_FLV_MEMORY, ZABBIX_FLV_PORT,
    ZABBIX_RTSP_CPU, ZABBIX_RTSP_MEMORY, ZABBIX_RTSP_PORT,
    ZABBIX_SPEEDER_CPU, ZABBIX_SPEEDER_MEMORY, ZABBIX_SPEEDER_PORT,
    ZABBIX_MYSQL_CPU, ZABBIX_MYSQL_MEMORY, ZABBIX_MYSQL_PORT,
    ZABBIX_REDIS_CPU, ZABBIX_REDIS_MEMORY, ZABBIX_REDIS_PORT,
} from '../zabbix/zabbixRD';

import { getIconSrc } from '../../../resource/resource';


class TopologyCT extends Component{
    constructor(props){
        super(props);

        this.dataListTimerSuccessHd = this.dataListTimerSuccessHd.bind(this);
        this.dataWarnSuccessHd = this.dataWarnSuccessHd.bind(this);
        
        let nets = ['cm', 'cs', 'freeswitch', 'redis', 'mysql', 'speeder', 'rtsp', 'flv'];
        let netStatus = {};
        nets.forEach(net => {
            netStatus[`${net}PortStatus`] = 1;
            netStatus[`${net}WarnStatus`] = 0;
        })

        this.state = {
            // 网元id
            nets: nets,
            // 告警数据
            warnData: undefined,
            ...netStatus,
        }
        
    }

    getLogIcon(netId, status = 'normal') {
        let text = undefined;
        switch(netId) {
            case 'cm':
            case 'cs':
            case 'freeswitch': 
                text = 'server'; 
                break;
            case 'redis': 
                text = 'redis'; 
                break;
            case 'mysql': 
                text = 'database'; 
                break;
            default:
                text = 'component'; 
                break;
        }

        return getIconSrc(`${text}-${(status === 'normal' && 'green') || (status === 'warning' && 'orange') || 'red'}`);
    }

    updateCpu(netId, lastValue) {
        let state = this.state;
        let stateKey = `${netId}CpuValue`;
        let value = `${parseFloat(lastValue).toFixed(2)}%`;
        if (state[stateKey] !== value) {
            state.graph.update(`${netId}_cpu`, {
                label: value,
            })
            state[stateKey] = value;
            this.setState({
                ...state,
            })
        }
    }

    updateMemory(netId, lastValue) {
        let state = this.state;
        let stateKey = `${netId}MemoryValue`;
        let value = `${(parseInt(lastValue) / (1024 * 1024)).toFixed(2)}M`;
        if (Math.floor(parseInt(lastValue) / (1024 * 1024 * 1024)) >= 1) {
            value = `${(parseInt(lastValue) / (1024 * 1024 * 1024)).toFixed(2)}G`;
        }
        if (state[stateKey] !== value) {
            state.graph.update(`${netId}_memory`, {
                label: value,
            })
            state[stateKey] = value;
            this.setState({
                ...state,
            })
        }
    }

    updateState(netId, lastValue) {
        // console.log("updateState", netId, lastValue)
        let state = this.state;
        let stateKey = `${netId}PortStatus`;
        let value = parseInt(lastValue);
        if (state[stateKey] !== value) {
            state.graph.update(netId, {
                style: {
                    fill: value ? blue[0] : red[3],
                },
                labelCfg: {
                    style: {
                        fill: value ? blue[3] : red[5],
                    },
                },
                descriptionCfg: {
                    style: {
                        fill: value ? grey[0] : red[5],
                    }
                },
                logoIcon: {
                    img: this.getLogIcon(netId, value ? 'normal' : 'error'),
                },
                preRect: {
                    fill: value ? blue[5] : red[5],
                }
            })
            let edgeItems = state.graph.findAll("edge", (edge) => {
                return edge.defaultCfg && (edge.defaultCfg.model.source === netId || edge.defaultCfg.model.target === netId);
            });
            edgeItems.forEach(function(edge) {
                if (!value) {
                    return state.graph.setItemState(edge, 'running', false);
                } else {
                    if ((edge.defaultCfg.model.source === netId && state[`${edge.defaultCfg.model.target}PortStatus`]) 
                        || (edge.defaultCfg.model.target === netId && state[`${edge.defaultCfg.model.source}PortStatus`])) {
                        return state.graph.setItemState(edge, 'running', true);
                    } 
                }
            });

            state[stateKey] = value;
            this.setState({
                ...state,
            })
        }
    }

    updateWarn(warns) {
        let state = this.state;

        state.nets.forEach(net => {
            let warnKey = `${net}WarnStatus`;
            let portKey = `${net}PortStatus`;
            let value = warns[warnKey];
            if (value !== state[warnKey] && state[portKey]) {
                state.graph.update(net, {
                    style: {
                        fill: !value ? blue[0] : orange[3],
                    },
                    labelCfg: {
                        style: {
                            fill: !value ? blue[3] : orange[5],
                        },
                    },
                    descriptionCfg: {
                        style: {
                            fill: !value ? grey[0] : orange[5],
                        }
                    },
                    logoIcon: {
                        img: this.getLogIcon(net, value ? 'warning' : 'normal'),
                    },
                    preRect: {
                        fill: !value ? blue[5] : orange[5],
                    }
                })
            }
        })

        this.setState({
            ...warns,
        })

    }

    dataWarnSuccessHd(dispatch, rspBody, reqBody) {

        let warnData = rspBody.result;
        let warns = {};

        this.state.nets.forEach(net => {
            warns[`${net}WarnStatus`] = 0;
        })
        
        if (warnData && warnData.length > 0) {
            warnData.forEach(item => {
                if (item.tags && item.tags.length > 0) {
                    item.tags.forEach(tag => {
                        if (tag.tag === 'type') {
                            warns[`${tag.value}WarnStatus`] = 1;
                        }
                    })
                }
            })
        }

        this.updateWarn(warns);

        this.setState({
            warnData: warnData,
        })
    }


    dataListTimerSuccessHd(dispatch, rspBody, reqBody) {
        let result = rspBody.result;
        if (!result || result.length === 0) {
            return true;
        }
        let value = result[0].value;

        switch(reqBody.params.itemids) {
            case ZABBIX_CM_CPU: this.updateCpu('cm', value); break;
            case ZABBIX_CM_MEMORY: this.updateMemory('cm', value); break;
            case ZABBIX_CM_PORT: this.updateState('cm', value); break;
            case ZABBIX_FREESWITCH_CPU: this.updateCpu('freeswitch', value); break;
            case ZABBIX_FREESWITCH_MEMORY: this.updateMemory('freeswitch', value); break;
            case ZABBIX_FREESWITCH_PORT: this.updateState('freeswitch', value); break;
            case ZABBIX_CS_CPU: this.updateCpu('cs', value); break;
            case ZABBIX_CS_MEMORY: this.updateMemory('cs', value); break;
            case ZABBIX_CS_PORT: this.updateState('cs', value); break;
            case ZABBIX_FLV_CPU: this.updateCpu('flv', value); break;
            case ZABBIX_FLV_MEMORY: this.updateMemory('flv', value); break;
            case ZABBIX_FLV_PORT: this.updateState('flv', value); break;
            case ZABBIX_RTSP_CPU: this.updateCpu('rtsp', value); break;
            case ZABBIX_RTSP_MEMORY: this.updateMemory('rtsp', value); break;
            case ZABBIX_RTSP_PORT: this.updateState('rtsp', value); break;
            case ZABBIX_SPEEDER_CPU: this.updateCpu('speeder', value); break;
            case ZABBIX_SPEEDER_MEMORY: this.updateMemory('speeder', value); break;
            case ZABBIX_SPEEDER_PORT: this.updateState('speeder', value); break;
            case ZABBIX_MYSQL_CPU: this.updateCpu('mysql', value); break;
            case ZABBIX_MYSQL_MEMORY: this.updateMemory('mysql', value); break;
            case ZABBIX_MYSQL_PORT: this.updateState('mysql', value); break;
            case ZABBIX_REDIS_CPU: this.updateCpu('redis', value); break;
            case ZABBIX_REDIS_MEMORY: this.updateMemory('redis', value); break;
            case ZABBIX_REDIS_PORT: this.updateState('redis', value); break;
            default: break;
        }
    }

    onTimer1() {
        let that = this;
        let { dispatch, zabbixUserInfo } = this.props;

        function dataZabbix() {
            let cpuIds = [ZABBIX_CM_CPU, ZABBIX_FREESWITCH_CPU, ZABBIX_CS_CPU, ZABBIX_FLV_CPU, ZABBIX_RTSP_CPU, ZABBIX_SPEEDER_CPU, ZABBIX_MYSQL_CPU, ZABBIX_REDIS_CPU];
            let memoryIds = [ZABBIX_CM_MEMORY, ZABBIX_FREESWITCH_MEMORY, ZABBIX_CS_MEMORY, ZABBIX_FLV_MEMORY, ZABBIX_RTSP_MEMORY, ZABBIX_SPEEDER_MEMORY, ZABBIX_MYSQL_MEMORY, ZABBIX_REDIS_MEMORY];
            let params = {
                hostids: ZABBIX_HOST_IDS, 
                history: 3, 
                limit: 1,
            }

            cpuIds.forEach(itemId => {
                dataListHistory(that.props, {...params, itemids: itemId, history: 0}, that.dataListTimerSuccessHd, () => {return true;})
            })
            memoryIds.forEach(itemId => {
                dataListHistory(that.props, {...params, itemids: itemId}, that.dataListTimerSuccessHd, () => {return true;})
            })

            dataListProblem(that.props, {hostids: ZABBIX_HOST_IDS}, that.dataWarnSuccessHd);

        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    onTimer2() {
        let that = this;
        let { dispatch, zabbixUserInfo } = this.props;

        function dataZabbix() {
            let portIds = [ZABBIX_CM_PORT, ZABBIX_FREESWITCH_PORT, ZABBIX_CS_PORT, ZABBIX_FLV_PORT, ZABBIX_RTSP_PORT, ZABBIX_SPEEDER_PORT, ZABBIX_MYSQL_PORT, ZABBIX_REDIS_PORT];

            let params = {
                hostids: ZABBIX_HOST_IDS, 
                history: 3, 
                limit: 1,
            }
            portIds.forEach(itemId => {
                dataListHistory(that.props, {...params, itemids: itemId}, that.dataListTimerSuccessHd, () => {return true;})
            })
        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "运维监控", url: "devops/monitor"}, {title: "组网拓扑"}]}
        />
    }

    // 定义边缘动画
    defineEdgeAnimate() {
        // lineDash 的差值，可以在后面提供 util 方法自动计算
        let dashArray = [[0, 1], [0, 2], [1, 2], [0, 1, 1, 2]].concat(Array.from({length: 10}, (v,k)=> {return [k, 2, 1, 2]}));
        let lineDash = [10, 2, 1, 2];
        let interval = 14;
        G6.registerEdge('can-running', {
            setState: function setState(name, value, item) {
                let shape = item.get('keyShape');
                if (name === 'running') {
                    if (value) {
                        let length = shape.getTotalLength(); // 后续 G 增加 totalLength 的接口
                        let totalArray = [];
                        for (let i = 0; i < length; i += interval) {
                            totalArray = totalArray.concat(lineDash);
                        }
                        let index = 0;
                        shape.animate({
                            onFrame: function onFrame(ratio) {
                                let cfg = {
                                    lineDash: dashArray[index].concat(totalArray),
                                    strokeStyle: green[3],
                                };
                                index = (index + 1) % interval;
                                return cfg;
                            },
                            repeat: true
                        }, 3000);
                    } else {
                        shape.stopAnimate();
                        shape.attr('lineDash', null);
                        shape.attr('strokeStyle', red[3]);
                    }
                }
            }
        }, 'cubic-horizontal');
    }

    newGraph(nodes) {
        let that = this;
        let graph = new G6.Graph({
            container: 'mountNode',
            width: window.innerWidth,
            height: window.innerHeight - 192,
            defaultEdge: {
                style: {
                    stroke: green[3],
                }
            },
            modes: {
                default: [{
                    type: 'tooltip', // 提示框
                    formatText(model) {
                        // console.log(model)
                        let text = undefined;
                        switch(model.type) {
                            case 'modelRect': {
                                text = model.label + model.description + '</br>';
                                let data = that.state.warnData;
                                if (data && data.length > 0) {
                                    data.forEach(item => {
                                        if (item.tags && item.tags.length > 0) {
                                            item.tags.forEach(tag => {
                                                if (tag.tag === 'type' && tag.value === model.id) {
                                                    text += `<span className="ant-badge-status-dot ant-badge-status-failed"/><span style="color: ${red[5]}">${item.name}</span></br>`;
                                                }
                                            })
                                        }
                                    })
                                }
                                break;
                            }
                                
                            case 'image':
                                let sp = model.id.split('_')
                                let context = undefined;
                                if (sp[1] === 'memory') {
                                    context = '内存使用';
                                } else if (sp[1] === 'cpu') {
                                    context = 'CPU使用率';
                                }
                                text = `${sp[0]} <span>${context}: <span/> ${model.label}`;
                                break;
                            default:
                                break;
                        }
                        // 提示框文本内容
                        
                        return text;
                    }
                }]
            },
        });
        let edges = nodes.map(x => x.edges).flat(1).filter((value) => value)
        graph.data({
            nodes: nodes.map(x => x.nodes).flat(1).filter((value) => value),
            edges: edges,
        });
        graph.render();
        graph.fitView();
        
        // graph.on('node:mouseenter', function(ev) {
        //     let node = ev.item;
        //     let edges = node.getEdges();
        //     edges.forEach(function(edge) {
        //         return graph.setItemState(edge, 'running', true);
        //     });
        // });

        // graph.on('node:mouseleave', function(ev) {
        //     let node = ev.item;
        //     let edges = node.getEdges();
        //     edges.forEach(function(edge) {
        //         return graph.setItemState(edge, 'running', false);
        //     });
        // });

        // 让连接线动起来
        let edgeItems = graph.findAll("edge", (edge) => {return true});
        edgeItems.forEach(function(edge) {
            return graph.setItemState(edge, 'running', true);
        });

        return graph;
    }

    // createNode(id, x = 500, y = 300, size = 20) {
    //     let node = {
    //         id: id,
    //         x: x + 128,
    //         y: y + 256,
    //         type: 'center-node',
    //         size: size,
    //     };
    //     return {node: node, edges: [undefined]};
    // }

    createNode(id, x = 500, y = 300, label = "", description = "", edges = [], linkPoints={left: true, right: true,}) {
        let node = {
            id: id,
            type: 'modelRect',
            x: x + 128,
            y: y + 256,
            size: [75, 40],
            style: {
                fill: blue[0],
                lineWidth: 0,
                radius: 1,
            },
            label: label,
            labelCfg: {
                position: 'left',
                offset: 12,
                style: {
                    fill: blue[3],
                    fontSize: 10,
                }
            },
            description: description,
            descriptionCfg: {
                position: 'top',
                offset: 0,
                style: {
                    fill: grey[0],
                    fontSize: 6,
                }
            },
            preRect: {
                // 设置为false，则不显示
                show: true,
                fill: blue[5],
                width: 2,
                radius: 0,
            },
            anchorPoints: [[0.5, 0], [1, 0.5], [0.5, 1], [0, 0.5]],
            linkPoints: {
                top: linkPoints.top,
                bottom: linkPoints.bottom,
                left: linkPoints.left,
                right: linkPoints.right,
                size: 2,
                fill: '#fff'
            },
            logoIcon: {
                show: true,
                x: -30,
                y: -13,
                img: this.getLogIcon(id),
                width: 8,
                height: 8,
                offset: 0,
            },
            // 节点中表示状态的icon配置
            stateIcon: {
                show: false,
            }
        };

        let node2 = {
            id: id + '_cpu',
            type: 'image',
            img: getIconSrc('cpu'),
            x: x - 34 + 128,
            y: y + 25 + 256,
            size: 6,
            style: {
                fill: grey[0] + "00",
                lineWidth: 0,
                radius: 0,
            },
            label: '-',
            labelCfg: {
                position: 'right',
                style: {
                    fill: grey[1],
                    fontSize: 6,
                }
            },
        }

        let node3 = {
            id: id + '_memory',
            type: 'image',
            img: getIconSrc('memory'),
            x: x + 6 + 128,
            y: y + 25 + 256,
            size: 6,
            style: {
                fill: grey[0] + "00",
                lineWidth: 0,
                radius: 0,
            },
            label: '-',
            labelCfg: {
                position: 'right',
                style: {
                    fill: grey[1],
                    fontSize: 6,
                }
            },
        }
        return {nodes: [node, node2, node3], edges: edges};
    }

    createLeafNode(id, x = 250, y = 300, edgeIds = ['center']) {
        let node = {
            id: id,
            x: x + 128,
            y: y + 256,
            type: 'leaf-node'
        };
        let edges = edgeIds.map(edgeTargetId => {
            return {
                source: id,
                target: edgeTargetId,
                type: 'can-running',
            }
        });
        return {nodes: [node], edges: edges};
    }

    initNodes() {

        this.defineEdgeAnimate();

        let nodes = []
        let y = window.innerWidth / 2;
        nodes.push(this.createNode('redis', 25, y - 75, 'redis', '数据库', [], {right: true}));
        nodes.push(this.createNode('mysql', 150, y - 75, 'mysql', '数据库', [], {left: true, right: true}));
        nodes.push(this.createNode('cs', 0, y + 25, 'cs', '会议业务服务器', 
            [{
                source: 'cs',
                sourceAnchor: 1,
                target: 'cm',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'cs',
                sourceAnchor: 1,
                target: 'mysql',
                targetAnchor: 3,
                type: 'can-running',
            }], {right:true}));
        nodes.push(this.createNode('cm', 100, y + 25, 'cm', '会议控制服务器', 
            [{
                source: 'cm',
                sourceAnchor: 1,
                target: 'freeswitch',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'cm',
                sourceAnchor: 0,
                target: 'mysql',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'cm',
                sourceAnchor: 0,
                target: 'redis',
                targetAnchor: 1,
                type: 'can-running',
            }], {left: true, right: true, top: true}));
        nodes.push(this.createNode('freeswitch', 200, y + 25, 'freeswitch', '会议服务器', 
            [{
                source: 'freeswitch',
                sourceAnchor: 1,
                target: 'speeder',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'freeswitch',
                sourceAnchor: 1,
                target: 'rtsp',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'freeswitch',
                sourceAnchor: 1,
                target: 'flv',
                targetAnchor: 3,
                type: 'can-running',
            }, {
                source: 'freeswitch',
                sourceAnchor: 0,
                target: 'mysql',
                targetAnchor: 1,
                type: 'can-running',
            }], {left: true, right: true, top: true}));
        nodes.push(this.createNode('speeder', 300, y - 100, 'speeder', '媒体服务器', [], {left:true}));
        nodes.push(this.createNode('rtsp', 300, y - 25, 'rtsp', '直播服务器', [], {left:true}));
        nodes.push(this.createNode('flv', 300, y + 75, 'flv', '直播服务器', [], {left:true}));
        
        let graph = this.newGraph(nodes);

        this.setState({
            graph: graph,
        })
    }

    // clear handle
    componentWillUnmount(){
        clearInterval(this.timer1);
        clearInterval(this.timer2);
    }

    componentDidMount(){
        this.initNodes();
        this.onTimer1();
        this.onTimer2();
        this.timer1 = setInterval(() => {this.onTimer1()}, 30000);
        this.timer2 = setInterval(() => {this.onTimer2()}, 5000);
    }

    render(){
        // 主页面
        let breadcrumbComponent = this.mkBreadcrumb();

        return (<div>
            {breadcrumbComponent}
            <div id="mountNode" className="ho-devops-topology"/>
        </div>);
    }
}

let mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    zabbixUserInfo: getLoginZabbixInfo(state),
});


export default connect(
    mapState, 
    null
)(TopologyCT);

