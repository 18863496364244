/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo, hoTableTransPaginationInfo } from "../../util/tableComponent";
import { netRequestThunk2 } from "../../util/netReqRD";
import {loginNoauthHd} from "../login/loginRD";

const ACT_LIST_MEMBER = 'ACT_LIST_MEMBER';
const ACT_MOD_MEMBER = 'ACT_MOD_MEMBER';
const ACT_GET_MEMBER = 'ACT_GET_MEMBER';
const ACT_CLEAN_MEMBER = 'ACT_CLEAN_MEMBER';


export const ACT_NET_MEMBER_LIST = 'listMember';
export const ACT_NET_MEMBER_LIST2 = 'list2Member';
export const ACT_NET_MEMBER_MOD = 'modMember';
export const ACT_NET_MEMBER_PULL_LOG = 'pullLogMember';
export const ACT_NET_MEMBER_GET_LOG = 'getLogMember';
export const ACT_NET_MEMBER_NEW = 'newMember';
export const ACT_NET_MEMBER_GET = 'getMember';
export const ACT_NET_MEMBER_BASEINFO_GET = 'getMemberBaseInfo';
export const ACT_NET_MEMBER_ACCOUNT_PUBLISH = 'accountPublish';
export const ACT_NET_MEMBER_CAMERA_CONTROL_START = 'cameraControlStart';
export const ACT_NET_MEMBER_CAMERA_CONTROL_STOP = 'cameraControlStop';
export const ACT_NET_MEMBER_CAMERA_ACCOUNT_GRN = 'cameraAccountGen';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_LIST = 'vmeetingSdkAppList';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_ADD = 'vmeetingSdkAppAdd';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_DEL = 'vmeetingSdkAppDel';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_MOD = 'vmeetingSdkAppMod';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_RESET_SECERT = 'vmeetingSdkAppResetAppSecert';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_LIST = 'vmeetingSdkAppAccountList';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_GEN = 'vmeetingSdkAppAccountGen';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_DEL = 'vmeetingSdkAppAccountDel';
export const ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_MOD = 'vmeetingSdkAppAccountMod';

// reducer handle
const memberState = {
    memberList: undefined, 
    memberInfo: undefined,
}

export const member_reducer = (state=memberState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_MEMBER:{
            return {
                ...state,
                memberList: action.memberList,
            };
        }

        case ACT_GET_MEMBER: {
            return {
                ...state,
                memberInfo: action.memberInfo,
            };
        }

        case ACT_CLEAN_MEMBER:
            return {
                memberList: undefined,
            };

        default:
            return state;
    }
}

export const actMemberClear = () => {
    return {
        type: ACT_CLEAN_MEMBER,
    }
}

export const listMemberData = (state) => {
    return state.member !== undefined ? state.member.memberList : undefined;
}

export function listMemberDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_MEMBER, 
        memberList: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
            records: rspBody.UserList,
        }, 
    })
}

export function dataListMember(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_LIST, successHd, errorHd))
    );
}

export const getMemberData = (state) => {
    return state.member !== undefined ? state.member.memberInfo : undefined;
}

export function getMemberDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_GET_MEMBER, 
        memberInfo: rspBody.UserInfo, 
    })
}

export function dataModMember(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/setNickName'
    const msgBody = {
        DestUserId: member.UserId,
        NickName: member.NickName,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_MOD, successHd, errorHd))
    );
}

export function dataPullMemberLog(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/pullLog'
    const msgBody = {
        SipNum: member.SipNum,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_PULL_LOG, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_PULL_LOG, successHd, errorHd))
    );
}

export function dataGetMemberLog(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/pullLogState'
    const msgBody = {
        SipNum: member.SipNum,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_GET_LOG, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_GET_LOG, successHd, errorHd))
    );
}

export function dataGetMember(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/detail'
    const msgBody = {
        UserId: member.memberId,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_GET, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_GET, successHd, errorHd))
    );
}

export function dataGetMemberBaseInfo(props, SipNums, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/baseInfos'
    const msgBody = {
        SipNums: SipNums,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_BASEINFO_GET, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_BASEINFO_GET, successHd, errorHd))
    );
}

export function dataListMember2(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/sipNum/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_LIST2, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_LIST2, successHd, errorHd))
    );
}


export function apiMemberAccountPublish(props, data, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/account/issue'
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    const msgBody = {
        SipNum: data?.SipNum,
        Remark: data?.PublishRemark,
    };
    
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_ACCOUNT_PUBLISH, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_ACCOUNT_PUBLISH, successHd, errorHd))
    );
}


export function apiCameraContrlStart(props, sipNum, action, speed, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/camera/ctrl/start`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let msgBody = {
        SipNum: sipNum,
        Action: action,
        Speed: speed,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_CAMERA_CONTROL_START, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_CAMERA_CONTROL_START, successHd, errorHd))
    );}

export function apiCameraContrlStop(props, sipNum, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/camera/ctrl/stop`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let msgBody = {
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_CAMERA_CONTROL_STOP, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_CAMERA_CONTROL_STOP, successHd, errorHd))
    );
}

export function apiCameraAccountGen(props, number, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/account/gen`;
    let header = {
        Token: reqUserInfo?.auth?.token,
    }
    let msgBody = {
        UserType: 25, 
        AccountNum: number,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_CAMERA_ACCOUNT_GRN, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_CAMERA_ACCOUNT_GRN, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppList(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/list`;
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_LIST, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppAdd(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/create`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_ADD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_ADD, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppMod(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/mod`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_MOD, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppDel(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/del`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_DEL, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_DEL, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppResetAppSecert(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/resetAppCode`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_RESET_SECERT, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_RESET_SECERT, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppAccountList(props, id, paginationInfo, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/account/list`;
    const msgBody = {
        Id: id,
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_LIST, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppAccountGen(props, id, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/account/gen`;
    const msgBody = {
        ...info,
        Id: id,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_GEN, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_GEN, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppAccountMod(props, id, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/account/mod`;
    const msgBody = {
        ...info,
        Id: id,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_MOD, successHd, errorHd))
    );
}

export function apiVmeetingSdkAppAccountDel(props, id, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/user/sdkAccess/app/account/del`;
    const msgBody = {
        ...info,
        Id: id,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_DEL, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MEMBER_VMEETING_SDK_APP_ACCOUNT_DEL, successHd, errorHd))
    );
}


