/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import {
    Form,
    Input,
    Select,
} from 'antd';

let GroupTypeList = [
    { key: "0", description: "私有组(托管)" },
    { key: "1", description: "公共组" },
    { key: "2", description: "私有组(内网)" },
]
let GroupType = {
    list: GroupTypeList,
    getDescription(key) {
        return GroupTypeList.find(e => e.key === key)?.description;
    }
}

const CustomizedForm = Form.create({
    name: 'media_group_form',
    onFieldsChange(props, changedFields) {
      props.onChange(changedFields);
    },
    mapPropsToFields(props) {
      return {
        SsId: Form.createFormField({
            ...props.SsId,
            value: props.SsId?.value,
        }),
        GroupName: Form.createFormField({
          ...props.GroupName,
          value: props.GroupName?.value,
        }),
        GroupType: Form.createFormField({
            ...props.GroupType,
            value: props.GroupType?.value,
        }),
        Remark: Form.createFormField({
            ...props.Remark,
            value: props.Remark?.value,
        }),
      };
    },
  })(props => {
    const { getFieldDecorator } = props.form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 4 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 20 },
            sm: { span: 20 },
        },
    };
    
    return (
        <Form>
            <Form.Item label="组名">
                {getFieldDecorator('GroupName', {
                    initialValue: '媒体服务器组_',
                    rules: [{ required: true, message: '请输入名称', whitespace: false }],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="所属网络类型">
                {getFieldDecorator('GroupType', {
                    initialValue: '0',
                    rules: [{ required: true, message: '请选择网络类型', whitespace: false }],
                })(<Select style={{ width: '100%' }}>
                    {GroupType.list.map(e => <Select.Option key={e.key} value={e.key}>{e.description}</Select.Option>)}
                </Select>,
                )}
            </Form.Item>
            <Form.Item label="备注">
                {getFieldDecorator('Remark')(<Input.TextArea rows={10}/>)}
            </Form.Item>
        </Form>
    );
  });

class OMediaGroupForm extends Component {
    render() {
        const { fields, onChange } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} onChange={onChange}/>
            </div>
        );
    }
}

let MediaGroupForm = Form.create({ name: 'mediaGroup' })(OMediaGroupForm);

export { MediaGroupForm, GroupType }