/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, Input, Select, Checkbox } from 'antd';

import './account.less';

const CustomizedForm = Form.create({
    name: '_gen_account_form',
    onFieldsChange(props, changedFields, allFields) {
        props.onChange && props.onChange(changedFields);
    },
    onValuesChange(props, changedValues, allValues) {

    },
    mapPropsToFields(props) {
      return {
        Account: Form.createFormField({
            ...props.Account,
            value: props.Account?.value,
        }),
        NickName: Form.createFormField({
            ...props.NickName,
            value: props.NickName?.value,
        }),
        AccountPassword: Form.createFormField({
            ...props.AccountPassword,
            value: props.AccountPassword?.value,
        }),
        ConfirmAccountPassword: Form.createFormField({
            ...props.ConfirmAccountPassword,
            value: props.ConfirmAccountPassword?.value,
        }),
      };
    },
})(props => {
    const { form, operation } = props;
    const { getFieldDecorator } = form;

    return (
        <Form>
             <Form.Item label="账号">
                {getFieldDecorator('Account', {
                    initialValue: '',
                    rules: [
                        { required: true, message: '请输入账号' }
                    ],
                })(<Input disabled={operation === 'edit'} placeholder="账号" />)}
            </Form.Item>
            {
                operation === 'add' ? <Form.Item label="密码">
                    {getFieldDecorator('AccountPassword', {
                        initialValue: '',
                        rules: [
                            { required: true, message: '请输入密码' }
                        ],
                    })(<Input placeholder="密码" />)}
                </Form.Item> : undefined
            }
            {
                operation === 'add' ? <Form.Item label="密码确认">
                    {getFieldDecorator('ConfirmAccountPassword', {
                        initialValue: '',
                        rules: [
                            { type: 'string', message: '两次密码不同，请重新输入', asyncValidator: (rule, value) => {
                                return new Promise((resolve, reject) => {
                                    if (value !== props.AccountPassword.value && value) {
                                        reject('invalid');
                                    } else {
                                        resolve();
                                    }
                                })
                            }},
                            { required: true, message: '请输入密码' }
                        ],
                    })(<Input placeholder="密码确认" />)}
                </Form.Item> : undefined
            }
            <Form.Item label="昵称">
                {getFieldDecorator('NickName', {
                    initialValue: '',
                    rules: [
                        { required: true, message: '请输入昵称' }
                    ],
                })(<Input placeholder="昵称" />)}
            </Form.Item>
        </Form>
    );
});

class OGenForm extends Component {
    render() {
        const { fields, operation, onChange } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} operation={operation} onChange={onChange}/>
            </div>
        );
    }
}


const CustomizedRForm = Form.create({
    name: '_reset_account_form',
    onFieldsChange(props, changedFields, allFields) {
        props.onChange && props.onChange(changedFields);
    },
    onValuesChange(props, changedValues, allValues) {

    },
    mapPropsToFields(props) {
      return {
        Account: Form.createFormField({
            ...props.Account,
            value: props.Account?.value,
        }),
        AccountPassword: Form.createFormField({
            ...props.AccountPassword,
            value: props.AccountPassword?.value,
        }),
        ConfirmAccountPassword: Form.createFormField({
            ...props.ConfirmAccountPassword,
            value: props.ConfirmAccountPassword?.value,
        }),
      };
    },
})(props => {
    const { form, operation } = props;
    const { getFieldDecorator } = form;

    return (
        <Form>
             <Form.Item label="账号">
                {getFieldDecorator('Account', {
                    initialValue: '',
                    rules: [
                        { required: true, message: '请输入账号' }
                    ],
                })(<Input disabled={operation === 'reset'} placeholder="账号" />)}
            </Form.Item>
            <Form.Item label="新密码">
                {getFieldDecorator('AccountPassword', {
                    initialValue: '',
                    rules: [
                        { required: true, message: '请输入密码' }
                    ],
                })(<Input placeholder="新密码" />)}
            </Form.Item>
            <Form.Item label="密码确认">
                {getFieldDecorator('ConfirmAccountPassword', {
                    initialValue: '',
                    rules: [
                        { type: 'string', message: '两次密码不同，请重新输入', asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                if (value !== props.AccountPassword.value && value) {
                                    reject('invalid');
                                } else {
                                    resolve();
                                }
                            })
                        }},
                        { required: true, message: '请输入密码' }
                    ],
                })(<Input placeholder="密码确认" />)}
            </Form.Item>
        </Form>
    );
});

class RGenForm extends Component {
    render() {
        const { fields, operation, onChange } = this.props;
        return (
            <div>
                <CustomizedRForm {...fields} operation={operation} onChange={onChange}/>
            </div>
        );
    }
}

let GenForm = Form.create({ name: 'gen_account_form' })(OGenForm);
let ResetForm = Form.create({ name: 'reset_account_form' })(RGenForm);

export { GenForm, ResetForm }