/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { 
    HoTableExtend, hoTableColumnTagButton, hoTableExtendColumn,
} from '../../util/tableComponent';

import { sipStateTags, confStateTags } from '../common/common';

class UnlockViewMember extends Component{

    constructor(props){
        super(props);
    }


    // clear handle
    componentWillUnmount(){

    }

    componentDidMount(){
    }

    render(){
        const {selectedRowKeys, members, className, style, selectOnChange} = this.props;

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: selectOnChange,
        };
        const columns = [
            hoTableExtendColumn('状态', 'ConfState', 'str', false, false, '45%', {
                render: (text, record) => hoTableColumnTagButton(sipStateTags(text, record).concat(confStateTags(text, record))),
            }),
            hoTableExtendColumn('设备名称', 'NickName', 'str', false, false, '45%'),
        ];
        
        return (<HoTableExtend
            className={className}
            style={style}
            columns={columns}
            data={members}
            bordered={false}
            size={'small'}
            rowKey={'UserId'}
            rowSelection={rowSelection}
        />);
        
    }
}

const mapState = (state) => ({
});


export default connect(
    mapState, 
    null
)(UnlockViewMember);

