/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Layout } from 'antd'
import { curEnvTrans } from '../util/version';

class Footer extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return <Layout.Footer className="footer">
            <div className="env">{curEnvTrans()}</div>
            <div>深圳陆地桥科技有限公司 copyright©2021</div>
        </Layout.Footer>
    }
}

export default connect(
    null, 
    null
)(Footer);