/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';

import {Button, Modal, Select} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus } from '../../../util/netReqRD';
import { 
    dataListBlacklist, listBlacklistData, ACT_NET_BLACKLIST_LIST, listBlacklistDataSuccessHd, dataListBlacklistDelete,
} from './blacklistRD';
import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import { 
    HoTableExtend, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';
import { dataListMediaServerGroup, dataListSignalServer } from '../setting/server/serverRD';

import './blacklist.css';

class BlacklistCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblDelOnClick = this.tblDelOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkMain = this.mkMain.bind(this);
      
        const columns = [
            hoTableColumn('IP地址', 'ip', 'str', false, false, '15%'),
            hoTableColumn('位置', 'country', 'str', false, false, '15%', {
                render: (text, record) => {
                    let addr = `${record.country}-${record.region}-${record.city}`;
                    if (record.city === 'XX') {
                        addr = `${record.country}-${record.region}`
                    } 
                    if (record.region === 'XX') {
                        addr = `${record.country}`
                    }
                    if (record.country === 'XX') {
                        addr = ""
                    }
                    return addr;
                }, nolight: true,
            }),
            
            hoTableColumn('丢弃字节数', 'bytes', 'str', false, false, '10%'),
            hoTableColumn('丢弃包数', 'packets', 'str', false, false, '10%'),
            hoTableColumn('备注', 'comment', 'str', false, false, '30%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '删除', onClick: this.tblDelOnClick}
            ], '10%'),

        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            serverType: 'signal',
            serverId: undefined,
            signalServer: [],
            mediaServer: [],
        }
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
    }

    // 删除被点击
    tblDelOnClick(e, record) {
        e.stopPropagation();
        let that = this;

        Modal.confirm({
            content: <div>是否删除地址为<span className="warn">{record.ip}</span>的黑名单？</div>,
            onOk() {
                let { serverType, serverId } = that.state; 
                dataListBlacklistDelete(that.props, serverType, parseInt(serverId), record.id, 
                    () => {
                        that.mainRefreshOnClick();
                    },
                    () => {
                        that.mainRefreshOnClick();
                    },
                )
            },
        })

        
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let that = this;
        
        dataListSignalServer(this.props, (dispatch, rsp, req) => {
            let serverType = 'signal';
            let serverId = `${rsp?.SsList?.[0]?.Id}`
            that.setState({
                serverType: serverType,
                serverId: serverId,
                signalServer: rsp?.SsList
            })
            dataListMediaServerGroup(that.props, (dispatch, rsp2, req) => {
                let ms = [];
                // eslint-disable-next-line no-unused-expressions
                rsp2?.GroupList?.forEach(group => {
                    ms = ms.concat(group.MsList);
                });
                that.setState({
                    mediaServer: ms
                })
                dataListBlacklist(that.props, serverType, serverId && parseInt(serverId), listBlacklistDataSuccessHd);
            })
        })
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{ title: "运维工具", url: "devops/tool"}, {title: "IP黑名单"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>IP黑名单</span>, buttons: buttons}; 
    }

    mkMain() {
        let that = this;
        let { columns, signalServer, mediaServer, serverType, serverId, } = this.state;
        const { data, getStatus } = this.props;
        const { records } = data || {};
        let servers = serverType === 'signal' ? signalServer : mediaServer;
        servers.forEach(s => { s.Id = s.Id || s.MsId })
        const nameKey = serverType === 'signal' ? 'SsName' : 'MsName';
        let newData = [];
        // eslint-disable-next-line no-unused-expressions
        records?.forEach(element => {
            newData = newData.concat(element.list);
        });
        
        return (<Fragment>
            <div className="ho-blacklist-header">
                <Select className="ho-blacklist-server-type-select" disabled={getStatus === 1} loading={getStatus === 1} value={serverType} onChange={(key) => {
                    let serverId = key === 'signal' ? `${signalServer?.[0]?.Id}` : `${mediaServer?.[0]?.MsId}`;
                    this.setState({
                        serverType: key,
                        serverId: serverId,
                    })
                    dataListBlacklist(that.props, key, serverId && parseInt(serverId), listBlacklistDataSuccessHd);
                }}>
                    <Select.Option key="signal">信令服务器</Select.Option>
                    <Select.Option key="media">媒体服务器</Select.Option>
                </Select>
                <Select className="ho-blacklist-server-select" disabled={getStatus === 1} loading={getStatus === 1} value={serverId} onChange={(key) => {
                    this.setState({
                        serverId: key
                    }, () => {
                        dataListBlacklist(that.props, serverType, parseInt(key), listBlacklistDataSuccessHd);
                    })
                }}>
                    {
                        servers.map(server => {
                            return <Select.Option disabled={!server.IsActive} key={`${server.Id}`}>{server[nameKey]}</Select.Option>
                        })
                    }
                </Select>
            </div>
            <HoTableExtend
                className="ho-blacklist-table"
                columns={columns}
                data={newData}
                rowKey={'id'}
                // pagination={false}
                loading={getStatus === 1}
                onRowDoubleClick={this.tblRowOnClick}
            />
        </Fragment>);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_BLACKLIST_LIST));
    }

    componentDidMount(){
        this.mainRefreshOnClick();
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const mainComponent = this.mkMain();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {mainComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_BLACKLIST_LIST), 
    data: listBlacklistData(state),
});


export default connect(
    mapState, 
    null
)(BlacklistCT);

