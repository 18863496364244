/* eslint-disable no-unused-vars */
let version = {
    software: "v1.1.6-beta17", // ATTENTION: 切勿修改v1.1.6-beta17这个值，在gitlab构建时，会自动替换成对应版本
    environment: process.env.REACT_APP_ENV,
    // environment: "test",

    isProduction() {
        return this.environment === "production";
    },

    isInternal() {
        return this.environment === "internal";
    }
}

let config = {
    alioss: {
        region: 'oss-cn-shenzhen',
        logBucket: 'tv-client-log',
        versionBucket: 'tv-android-version',
    },

    host: {
        ws: 'wss://ludiqiao.com',
        as: 'https://ludiqiao.com',
        cm: 'https://ludiqiao.com',
        cs: 'https://ludiqiao.com',
        zabbix: 'https://test.ludiqiao.com:8052/zabbix',
    },
}

function updateHostEnv(host) {

    if (host.cs?.ip && host.cs?.port) {
        config.host.cs = `https://${host.cs.ip}:${host.cs.port}`;
        window.localStorage && window.localStorage.setItem("mntn::csIp", host.cs.ip);
        window.localStorage && window.localStorage.setItem("mntn::csPort", host.cs.port);
    }
    if (host.ws?.ip && host.ws?.port) {
        config.host.ws = `wss://${host.ws.ip}:${host.ws.port}`;
        window.localStorage && window.localStorage.setItem("mntn::wsIp", host.ws.ip);
        window.localStorage && window.localStorage.setItem("mntn::wsPort", host.ws.port);
    }
    if (host.as?.ip && host.as?.port) {
        config.host.as = `https://${host.as.ip}:${host.as.port}`;
        window.localStorage && window.localStorage.setItem("mntn::asIp", host.as.ip);
        window.localStorage && window.localStorage.setItem("mntn::asPort", host.as.port);
    }
    if (host.cm?.ip && host.cm?.port) {
        config.host.cm = `https://${host.cm.ip}:${host.cm.port}`;
        window.localStorage && window.localStorage.setItem("mntn::cmIp", host.cm.ip);
        window.localStorage && window.localStorage.setItem("mntn::cmPort", host.cm.port);
    }
    if (host.zabbix?.ip && host.zabbix?.port) {
        config.host.zabbix = `https://${host.zabbix.ip}:${host.zabbix.port}/zabbix`;
        window.localStorage && window.localStorage.setItem("mntn::zabbixIp", host.cm.ip);
        window.localStorage && window.localStorage.setItem("mntn::zabbixPort", host.cm.port);
    }
}

function curEnvTrans() {
    let title = "开发环境"
    switch (version.environment) {
        case "internal":
            title = "内网环境";
            break;
        case "development":
            title = "开发环境";
            break;
        case "production":
            title = "正式环境";
            break;
        case "show":
            title = "展厅环境";
            break;
        case "test":
            title = "测试环境";
            break;
        default:
            break;
    }

    return title;
}

function switchEnvironment(evn, force) {
    version.environment = evn;
    let host = {

    }
    switch (version.environment) {
        case "internal":
            let csIp = window.location.hostname;
            let csPort = "6443";
            let wsIp = window.location.hostname;
            let wsPort = "6443";
            let asIp = window.location.hostname;
            let asPort = "6443";
            let cmIp = window.location.hostname;
            let cmPort = "6443";
            let zabbixIp = window.location.hostname;
            let zabbixPort = "6443";
            if (window.localStorage) {
                csIp = window.localStorage.getItem("mntn::csIp") || csIp;
                csPort = window.localStorage.getItem("mntn::csPort") || csPort;
                wsIp = window.localStorage.getItem("mntn::wsIp") || wsIp;
                wsPort = window.localStorage.getItem("mntn::wsPort") || wsPort;
                asIp = window.localStorage.getItem("mntn::asIp") || asIp;
                asPort = window.localStorage.getItem("mntn::asPort") || asPort;
                cmIp = window.localStorage.getItem("mntn::cmIp") || cmIp;
                cmPort = window.localStorage.getItem("mntn::cmPort") || cmPort;
                zabbixIp = window.localStorage.getItem("mntn::zabbixIp") || zabbixIp;
                zabbixPort = window.localStorage.getItem("mntn::zabbixPort") || zabbixPort;
            }
            host = {
                as: {
                    ip: asIp,
                    port: asPort
                },
                ws: {
                    ip: wsIp,
                    port: wsPort
                },
                cs: {
                    ip: csIp,
                    port: csPort
                },
                cm: {
                    ip: cmIp,
                    port: cmPort
                },
                zabbix: {
                    ip: zabbixIp,
                    port: zabbixPort
                },
            }
            break;
        
        case "development":
            host = {
                ws: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                as: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                cm: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                cs: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
            }
            break;
        
        case "production":
            host = {
                ws: {
                    ip: "ludiqiao.com",
                    port: 443
                },
                as: {
                    ip: "ludiqiao.com",
                    port: 443
                },
                cm: {
                    ip: "ludiqiao.com",
                    port: 443
                },
                cs: {
                    ip: "ludiqiao.com",
                    port: 443
                },
            }
            break;
        
        case "show":
            host = {
                ws: {
                    ip: "show.ludiqiao.com",
                    port: 443
                },
                as: {
                    ip: "show.ludiqiao.com",
                    port: 443
                },
                cm: {
                    ip: "show.ludiqiao.com",
                    port: 443
                },
                cs: {
                    ip: "show.ludiqiao.com",
                    port: 443
                },
            }
            break;
        
        case "test":
            host = {
                ws: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                as: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                cm: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
                cs: {
                    ip: "test.ludiqiao.com",
                    port: 443
                },
            }
            break;
        default:
            break;
    }

    if (window.localStorage && !force) {
        let csIp = window.localStorage.getItem("mntn::csIp");
        let csPort = window.localStorage.getItem("mntn::csPort");
        let cmIp = window.localStorage.getItem("mntn::cmIp");
        let cmPort = window.localStorage.getItem("mntn::cmPort");
        let asIp = window.localStorage.getItem("mntn::asIp");
        let asPort = window.localStorage.getItem("mntn::asPort");
        let wsIp = window.localStorage.getItem("mntn::wsIp");
        let wsPort = window.localStorage.getItem("mntn::wsPort");
        let zabbixIp = window.localStorage.getItem("mntn::zabbixIp");
        let zabbixPort = window.localStorage.getItem("mntn::zabbixPort");

        if (csIp && cmIp) {
            host = {
                ws: {
                    ip: wsIp,
                    port: wsPort
                },
                as: {
                    ip: asIp,
                    port: asPort
                },
                cm: {
                    ip: cmIp,
                    port: cmPort
                },
                cs: {
                    ip: csIp,
                    port: csPort
                },
            }
        }
    }

    updateHostEnv(host)
}

switchEnvironment(version.environment)

export { config, version, updateHostEnv, curEnvTrans, switchEnvironment };