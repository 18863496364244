/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { PageHeader, Tabs, Divider, Collapse, Descriptions, Typography, Result, Tooltip, Spin } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import {getLoginUserInfo} from '../../login/loginRD'
import { 
    dataQueryConference
} from './conferenceRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoBackButton, HoTag, HoLinkButton, HoIcon, HoButton,
} from '../../../util/hoComponent';
import { 
    HoTable, hoTableInitPaginationInfo, 
} from '../../../util/tableComponent';
import EventAction from '../../../component/subpages/eventAction';
import { dataListSignalServer, dataListMediaServer } from '../setting/server/serverRD';

class ConferenceDetailCT extends Component{
    constructor(props){
        super(props);
        
        // 主页面数据回调
        this.dataQueryConferenceSuccessHd = this.dataQueryConferenceSuccessHd.bind(this);

        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkMain = this.mkMain.bind(this);
        this.mkTable = this.mkTable.bind(this);

        this.state = {
            paginationInfo: hoTableInitPaginationInfo(),
            conferenceName: this.props.match.params.name,
            conference: this.props.location?.state?.conference,
        }
    }

    dataQueryConferenceSuccessHd(dispatch, rspBody, reqBody) {
        let that = this;
        let paginationInfo = hoTableInitPaginationInfo();
        paginationInfo.pagination.filters = [[['Id', '=', rspBody.conference?.extra?.mediaServer?.Id]]];
        dataListMediaServer(that.props, paginationInfo, (dispatch, rsp1, req) => {
            dataListSignalServer(that.props, (dispatch, rsp2, req) => {
                let ret1 = rsp1?.RecordList?.find(e => rspBody.conference?.extra?.mediaServer?.Id === e.Id)
                let ret2 = rsp2?.SsList?.find(e => rspBody.conference?.extra?.signalServer?.Id === e.Id)
                this.setState({
                    conference: {
                        ...rspBody.conference,
                        mediaServerName: ret1?.MsName || "",
                        mediaServerGroupId: ret1?.GroupId,
                        signalServerName: ret2?.SsName || "",
                        signalServerGroupId: 0,
                    },
                })
            })
        });
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        const {conferenceName} = this.state;
        dataQueryConference(this.props, conferenceName, this.dataQueryConferenceSuccessHd);
        this.setState({
            refreshEvent: true,
        })
        setTimeout(() => {
            this.setState({
                refreshEvent: false,
            })
        }, 500)
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "会议", url: "devops/analysis/conference"}, {title: "详情"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("devops/analysis/conference")}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>详情</span>, buttons: buttons}; 
    }

    mkTable(){
        if (!this.state.conference) {
            return undefined;
        }
        return (<div className="ho-conference-detail-table">
            <div style={{color: '#000000d9', fontWeight: 500, fontSize: '16px', padding: '24px'}}>事件{this.state.total ? `(${this.state.total})`: undefined}</div>
                <div style={{padding: '0 24px'}}>
                    <EventAction
                        reqUserInfo={this.props.reqUserInfo}
                        history={this.props.history}
                        conferenceSipNum={this.state.conferenceName}
                        refresh={this.state.refreshEvent}
                        bordered={false}
                        size={'small'}
                        totalOnChange={(total) => {
                            this.setState({
                                total: total,
                            })
                        }}
                    />
                </div>
        </div>);
    }

    // 主页面
    mkMain(){
        const {conference} = this.state;
        if (!conference){
            return undefined; 
        }
        // console.log(conference);
        let tag = [];
        switch(conference.state){
            case 'idle':
                tag.push(<HoTag key="idle" color={blue[3]} className='ho-pageheader-state'>空闲</HoTag>);
                break;
            case 'meeting':
                tag.push(<HoTag key="meeting" color={green[3]} className='ho-pageheader-state'>与会中</HoTag>);
                break;
            case 'wait_release':
                tag.push(<HoTag key="wait_release" color={gold[3]} className='ho-pageheader-state'>等待释放</HoTag>);
                break;
            case 'completed':
                tag.push(<HoTag key="completed" color={grey[3]} className='ho-pageheader-state'>已结束</HoTag>);
                break;
            default:
                break;
        }

        const mainContent = (
            <div>
                <span>{tag}</span>
            </div>
        );

        let extraContent = (
            <div style={{marginTop: '1rem'}}>
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="会议号"><Typography.Paragraph copyable>{conference.extra?.code}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="信令服务器"><Typography.Paragraph>
                        {conference.signalServerName}
                        <Tooltip title="点击查看服务器详情">
                            <HoLinkButton icon="paper-clip" style={{ marginLeft: '0.5rem', padding: 0, height: 'auto' }} onClick={(e) => {
                                window.goToMenu('devops/setting/server/group/', `signal/${conference.signalServerGroupId}`, {
                                    GroupName: "信令组",
                                    GroupType: 1,
                                    Id: 0,
                                    Remark: "信令组",
                                })
                            }} />
                        </Tooltip>
                    </Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="SIP号"><Typography.Paragraph copyable >{conference.name}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="媒体服务器"><Typography.Paragraph>
                        {conference.mediaServerName}
                        <Tooltip title="点击查看服务器详情">
                            <HoLinkButton icon="paper-clip" style={{marginLeft: '0.5rem', padding: 0, height: 'auto'}} onClick={(e) => {
                                window.goToMenu('devops/setting/server/group/', `media/${conference.mediaServerGroupId}`)
                            }} />
                        </Tooltip>
                    </Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="会议类型">
                        <Typography.Paragraph>
                            {conference.type === "moderator" ? <Tooltip title="召集者（主席）可以控制结束会议">主席模式</Tooltip> : <Tooltip title="会议成员之间可以随意互相切换视频源">自由模式</Tooltip>}
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="会议描述"><Typography.Paragraph>{conference.description}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="最大会议时长"><Typography.Paragraph>{conference.expire ? `${conference.expire}秒` : `不限`}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="创建时间"><Typography.Paragraph>{conference.createTime}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label="推流地址"><Typography.Paragraph copyable>{conference.extra?.pusher?.url || "无"}</Typography.Paragraph></Descriptions.Item>
                </Descriptions>
            </div>
        );

        return <div>
            <PageHeader className="ho-pageheader">
                <div className="content">
                    <div className="main">{mainContent}</div>
                    <div className="extra">{extraContent}</div>
                </div>
            </PageHeader>
        </div>
    }

    // clear handle
    componentWillUnmount(){
        // let {dispatch} = this.props;
        // dispatch(actNetRequestClear(ACT_NET_CONFERENCE_LIST));
    }

    componentDidMount(){
        this.mainRefreshOnClick();
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const mainComponent = this.mkMain();
        const tableComponent = this.mkTable();

        return <div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {mainComponent}
            </HoCard>
            <Divider style={{background: '#f0f0f0', margin: 0}}></Divider>
            {tableComponent}
        </div>
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(ConferenceDetailCT);

