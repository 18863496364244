/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import './decoration1.css';
import './decoration2.css';

class Decoration1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <div className="decoration1">
            <div class="bar"/>
        </div>
    }
}

class Decoration2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let { number, className } = this.props;
        return <div className={"decoration2 " + className}>
            {
                Array.from({ length: 2 }, (v, k) => k).map(row => {
                    return <div key={row} className="row">
                        {
                            Array.from({ length: number }, (v, k) => k).map(k => {
                                return <div key={k} className={`square-animation${parseInt(Math.random()*10)}`} />
                            })
                        }
                    </div>
                })
            }
        </div>
    }
}
Decoration2.defaultProps = {
    number: 20,
}

export { Decoration1, Decoration2 };