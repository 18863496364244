/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';

import { Modal, Pagination, Button, Row, Col, Card, Tooltip, Result, Dropdown, Spin, Menu, Icon, Input } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { InfoCircleOutlined, PoweroffOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk2 } from '../../util/netReqRD';
import { 
    dataListRoom, dataNewRoom, dataDelRoom, 
    listRoomDataSuccessHd, listRoomData, ACT_NET_ROOM_LIST, dataStartRoom, dataEndRoom, actRoomClear,
} from './roomRD';
import {getLoginUserInfo} from '../login/loginRD'
import {
    HoForm, HoFormRowColInput, HoFormRowColInputNumber, HoFormRowColSelect, HoSelectOption, HoFormRowColTextArea,
    hoValidateEmptyFormat, hoValidateCustomFormat, HoFormRowColDate, HoFormRowColCommon,
} from '../../util/formComponent';
import { 
    HoRow, HoCol, HoTag,
    HoCard, HoBreadcrumb, hoMessage, HoBackButton, HoRefreshButton, HoNewButton, HoHistoryButton, HoAlertDialog, HoDeleteAlertDialog,
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../util/tableComponent';

import SelectAppointmentMember from '../../component/subpages/selectAppointmentMember';
import { getIconSrc } from '../../resource/resource';
import { version } from '../../util/version';
import { dataListMember, dataListMember2 } from '../member/memberRD';
import { dataListMediaServer } from '../devops/setting/server/serverRD';

import "./room.css";

class RoomCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 额外操作
        this.initData = this.initData.bind(this);
        this.initPracticeData = this.initPracticeData.bind(this);
        this.goToDetail = this.goToDetail.bind(this);
        
        // 数据处理回调
        this.dataCommonSuccessHd = this.dataCommonSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.listPaginationOnChange = this.listPaginationOnChange.bind(this);
        this.mainSearchOnChange = this.mainSearchOnChange.bind(this);
        this.listItemDetailOnClick = this.listItemDetailOnClick.bind(this);
        this.listItemEndOnClick = this.listItemEndOnClick.bind(this);
        this.listItemDelOnClick = this.listItemDelOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainNewOnClick = this.mainNewOnClick.bind(this);
        this.mainSearchOnClick = this.mainSearchOnClick.bind(this);
        

        /********************************** 子页面(新增) ***********************************/
        // 子页面回调
        this.newPageMemberPageOnChange = this.newPageMemberPageOnChange.bind(this);
        
        // 子页面按钮
        this.newPageOkOnClick = this.newPageOkOnClick.bind(this);
        this.newPageCancelOnClick = this.newPageCancelOnClick.bind(this);

        // 子页面数据处理回调
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);
        this.dataNewSuccessHd = this.dataNewSuccessHd.bind(this);

        // 子页面
        this.mkNewModal = this.mkNewModal.bind(this);

        this.state = {
            path: this.props.match.path,
            historyRoom: this.props.match.path !== "/conference/ctrl/room",
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
            },
            ...this.initData(),
        }
    }

    codeFix(code) {
        return ("00000000" + code).slice(-9)
    }

    initData(){
        return {
            newPageVisible: false,
            newPageMode: "normal",
            newRoomInfo: {
                Title: '',
                ExpectStartTime: undefined,
                ExpectEndTime: undefined,
                MediaServerId: 0,
                MemberNumber: 0,
                Members: [],
            },
            newRoomValidate:{
                Title: {validateStatus:"error", help:"会议主题不能为空"},
                Members: { validateStatus: "error", help: "与会设备不能为空" },
                MediaServerId: { validateStatus: "success", help: "" },
            },
        };
    }

    initPracticeData(state) {
        let paginationInfo2 = hoTableInitPaginationInfo();
        switch (version.environment) {
            case "show":
                paginationInfo2.pagination.filters = [[["Id", "=", 67]]];
                break;
        
            default:
                break;
        }
        dataListMediaServer(this.props, paginationInfo2, (dispatch, rspBody1, reqBody) => {
            let paginationInfo = hoTableInitPaginationInfo();
            paginationInfo.pagination.page.size = 1000;
            paginationInfo.pagination.filters = [[["UserType", "=", 3]]];
            dataListMember(this.props, paginationInfo, (dispatch, rspBody, reqBody) => {
                let members = [];
                let cnt = 0;
                // eslint-disable-next-line no-unused-expressions
                rspBody.UserList?.forEach(item => {
                    if (item.SipState === "online" && cnt < state.MemberNumber) {
                        cnt++;
                        members.push(item);
                    }
                });
                this.setState({
                    ...state,
                    newRoomInfo: {
                        Title: '演示会议',
                        ExpectStartTime: undefined,
                        ExpectEndTime: undefined,
                        MediaServerId: rspBody1.RecordList?.[0]?.Id || 0,
                        MemberNumber: cnt,
                        Members: members,
                    },
                    newRoomValidate: {
                        Title: {validateStatus:"success", help:""},
                        Members: { validateStatus: "success", help: "" },
                        MediaServerId: { validateStatus: "success", help: "" },
                    },
                    mediaServers: [{Id: 0, MsName: "默认"}, ...rspBody1.RecordList]
                });
            })
        })
    }

    goToDetail(record){
        window.goToMenu('ctrl/room', `detail/${record.ConferenceId}`, record)
    }

    dataListSuccessHd(dispatch, rspBody, reqBody) {
        let { paginationInfo } = this.state;
        if (rspBody?.TotalNum > 0 && !rspBody?.ConferenceList?.length) {
            paginationInfo.pagination.page.number = 1;
            paginationInfo.pagination.sort = {
                field: 'CreateTime',
                order: 1
            }
            this.setState({ paginationInfo }, () => {
                dataListRoom(this.props, paginationInfo, this.dataListSuccessHd)
            });

        } else {
            paginationInfo.pagination.total = rspBody?.TotalNum
            this.setState({ paginationInfo }, () => {
                listRoomDataSuccessHd(dispatch, rspBody, reqBody);
            });
        }
    }

    dataCommonSuccessHd(dispatch, rspBody, reqBody)  {
        hoMessage({ type: 'success', msg: '操作成功' });
        let { paginationInfo } = this.state;
        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        dataListRoom(this.props, paginationInfo, this.dataListSuccessHd)
    }

    dataNewSuccessHd(dispatch, rspBody, reqBody){
        this.dataCommonSuccessHd(dispatch, rspBody, reqBody);
        this.setState({
            ...this.initData(),
        })
        this.goToDetail({ConferenceId: rspBody.ConferenceId})
    }

    // 分页发生变化
    listPaginationOnChange(page, pageSize){
        let { paginationInfo } = this.state;

        paginationInfo.pagination.page.number = page;
        paginationInfo.pagination.page.size = pageSize;
        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }

        dataListRoom(this.props, paginationInfo, this.dataListSuccessHd)
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    // '详情'按钮被点击
    listItemDetailOnClick(e, record){
        e.stopPropagation();
        this.goToDetail(record);
    }

    // '结束'按钮被点击
    listItemEndOnClick(e, record){
        e.stopPropagation();

        const that = this;
        Modal.confirm({
            title: <span>你确定结束<span style={{color: 'red'}}>{record.Title}</span>会议？</span>,
            content: <span>会议结束后，所有入会设备将离会</span>,
            onOk() {
                dataEndRoom(that.props, record, that.dataCommonSuccessHd);
            },
            onCancel() {
                
            },
        });
    }

    // '取消'按钮被点击
    listItemDelOnClick(e, record){
        e.stopPropagation();

        const that = this;
        Modal.confirm({
            title: <span>你确定取消<span style={{color: 'red'}}>{record.Title}</span>的预约？</span>,
            content: <span>取消会议预约，将会使之前的配置失效</span>,
            onOk() {
                dataDelRoom(that.props, record, that.dataCommonSuccessHd);
            },
            onCancel() {
                
            },
        });
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        this.dataCommonSuccessHd()
    }

    // '新增'按钮被点击
    mainNewOnClick(mode) {
        switch (mode) {
            case "practice":
                this.initPracticeData({
                    newPageVisible: true,
                    newPageMode: mode,
                    MemberNumber: 100,
                })
                break;
           
            default:
                this.setState({
                    ...this.initData(),
                    newPageMode: mode,
                    newPageVisible: true,
                });
                break;
        }
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text){
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    searchOnChange(search, pag) {
        let filters1 = [];
        let filters2 = [];
        if (search.text?.length > 0) {
            filters1.push(['ConferenceCode', 'like', search.text]);
            filters2.push(['SipCode', 'like', search.text]);
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = [
            filters1,
            filters2,
        ]
        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
        })

        dataListRoom(this.props, paginationInfo, this.dataListSuccessHd)
    }

    // 生成页面'取消'按钮被点击
    newPageCancelOnClick(){
        this.setState({
            ...this.initData(),
        });
    }

    // 生成页面'确定'按钮被点击
    newPageOkOnClick(){
        const { newRoomValidate } = this.state;
        
        // 点击确定前再次检查是否有字段不合法
        if (newRoomValidate.Title.validateStatus !== "success" || 
            newRoomValidate.Members.validateStatus !== "success" || 
            newRoomValidate.MediaServerId.validateStatus !== "success"){
            hoMessage({type:'error', msg:'输入有误，请检查'});
            return;
        }

        this.setState({
            newPageVisible: false,
        });
        dataNewRoom(this.props, this.state.newRoomInfo, this.dataNewSuccessHd);
    }


    // 新页面参数变化通知函数
    newPageParamOnChange(e, param){
        // console.log("newPageParamOnChange", e, param);
        let state = this.state;
        switch(param){
            case 'Title':
                state['newRoomInfo'][param] = e.target.value;
                break;
            case 'MemberNumber':
                this.initPracticeData({
                    MemberNumber: e || 1,
                })
            // eslint-disable-next-line no-fallthrough
            case 'MediaServerId':
            case 'ExpectStartTime':
            case 'ExpectEndTime':
                state['newRoomInfo'][param] = e;
                break;
                
            default:
                break;
        }

        // 校验会议主题是否为空
        state.newRoomValidate.Title = hoValidateEmptyFormat(
            state.newRoomInfo.Title, 
            "会议主题不能为空"
        )

        // 校验媒体服务器是否为空
        state.newRoomValidate.MediaServerId = hoValidateEmptyFormat(
            state.newRoomInfo.MediaServerId, 
            "媒体服务器不能为空"
        )

        this.setState({
            ...state,
        })
    }

    newPageMemberPageOnChange(action, member){
        let state = this.state;

        // console.log("newPageMemberPageOnChange", action, member)

        const add = (state, member) => {
            if (state.newRoomInfo.Members.find((e) => {return e.UserId === member.UserId}) === undefined){
                state.newRoomInfo.Members.push(member);
                state.newRoomValidate.Members = hoValidateEmptyFormat(
                    state.newRoomInfo.Members, "与会设备不能为空"
                )
            }
            return state;
        }

        const del = (state, member) => {
            const pos = state.newRoomInfo.Members.findIndex((e) => {return e.UserId === member.UserId})
            if (pos !== -1){
                state.newRoomInfo.Members.splice(pos, 1)
            }
            state.newRoomValidate.Members = hoValidateEmptyFormat(
                state.newRoomInfo.Members, "与会设备不能为空"
            )
            return state;
        }

        switch(action){

            case 'add':
                state = add(state, member);
                break;
            
            case 'group.add':
                member.forEach(item => {
                    state = add(state, item);
                })
                break;

            case 'del':
                state = del(state, member);
                break;

            case 'group.del':
                member.forEach(item => {
                    state = del(state, item);
                })
                break;

            default:
                break;
        }

        this.setState({
            ...state,
        })
    }

    // 面包屑
    mkBreadcrumb(){
        let title = "会议室"
        if (this.state.historyRoom){
            title = "历史会议"
        }
        return <HoBreadcrumb
            items={[{title: "会议管理", url: "ctrl"}, {title: title}]}
        />
    }

    mkHeaderInfo(){
        let title = "会议室";
        let buttons = undefined;
        
        switch (version.environment) {
            case "development":
            case "show":
            case "test":
                buttons = <div>
                    <Dropdown
                        overlay={<Menu onClick={(e) => this.mainNewOnClick(e.key)}>
                            <Menu.Item key="normal"><Icon type="plus" />普通预约</Menu.Item>
                            <Menu.Item key="practice"><Icon type="build" />演示预约</Menu.Item>
                        </Menu>}
                    >
                        <HoNewButton onClick={() => this.mainNewOnClick("normal")}>预约</HoNewButton>
                    </Dropdown>
                    <HoRefreshButton onClick={this.mainRefreshOnClick}/>
                </div>
                break;
            case "production":
                buttons = <div>
                    <HoNewButton onClick={(e) => this.mainNewOnClick("normal")}>预约</HoNewButton>
                    <HoRefreshButton onClick={this.mainRefreshOnClick}/>
                </div>
                break;
            default:
                break;
        }
        
        if (this.state.historyRoom){
            title = "历史会议"
            buttons = (<div>
                <HoBackButton onClick={(e) => {
                    window.goToMenu('ctrl/room');
                }} icon="coffee">查看会议室</HoBackButton>
                <HoRefreshButton onClick={this.mainRefreshOnClick}/>
            </div>);
        }
        return {title: <span>{title}</span>, buttons: buttons}; 
    }

    mkNewModal(){

        const {reqUserInfo} = this.props;
        const {newPageVisible, newRoomInfo, newRoomValidate, newPageMode, mediaServers} = this.state;

        // console.log("mkNewModal", newRoomInfo);

        return <HoAlertDialog
            width="80%"
            title="预约会议"
            visible={newPageVisible}
            onCancelClick={this.newPageCancelOnClick}
            footer={[
                <Button key="plus" type="primary" icon="plus" onClick={this.newPageOkOnClick}>
                    预约
                </Button>,
            ]}
        >
            <HoForm >
                <HoFormRowColInput
                    label="会议主题"
                    autoFocus={true}
                    placeholder="请填写会议主题(例如: XX会议)"
                    colExtra={{ span:24 }}
                    inputExtra={{value: newRoomInfo?.Title}}
                    onChange={(e) => this.newPageParamOnChange(e, 'Title')}
                    validate={newRoomValidate.Title}
                />
                <HoFormRowColDate
                    label="预计开始时间"
                    placeholder="请选择预计开始时间"
                    showTime={true}
                    colExtra={{ span: 24 }}
                    onChange={(e) => this.newPageParamOnChange(e, 'ExpectStartTime')}
                    value={newRoomInfo?.ExpectStartTime}
                />
                <HoFormRowColDate
                    label="预计结束时间"
                    placeholder="请选择预计结束时间"
                    showTime={true}
                    colExtra={{ span: 24 }}
                    onChange={(e) => this.newPageParamOnChange(e, 'ExpectEndTime')}
                    value={newRoomInfo?.ExpectEndTime}
                />
                {
                    newPageMode === "practice" ? <Fragment>
                        <HoFormRowColInputNumber
                            label="演示用户方数"
                            placeholder="请填写演示用户方数"
                            colExtra={{ span:24 }}
                            inputExtra={{min: 1, max: 200, step: 20, value: newRoomInfo?.MemberNumber, style:{width:'100%'}}}
                            onChange={(e) => this.newPageParamOnChange(e, 'MemberNumber')}
                        />
                        <HoFormRowColSelect
                            label="媒体服务器"
                            placeholder="请选择媒体服务器"
                            colExtra={{ span: 24 }}
                            selectExtra={{ value: `${newRoomInfo?.MediaServerId}` }}
                            onChange={(e) => this.newPageParamOnChange(e, 'MediaServerId')}
                            content={mediaServers?.map(m => {
                                let display = `${m.MsName}`;
                                if (m.GroupType === 1) {
                                    display = `${m.MsName} (公网IP: ${m.ProxyAddr})`;
                                } else if (m.GroupType === 0) {
                                    display = `${m.MsName} (本机IP: ${m.LocalAddr})`;
                                }
                                return HoSelectOption({ display: display, value: `${m.Id}` })
                            })}
                            validate={newRoomValidate.MediaServerId}
                        />
                    </Fragment> : undefined
                }
                <HoFormRowColCommon
                    label="与会设备"
                    colExtra={{ span: 24 }}
                    validate={newRoomValidate.Members}
                >
                    <SelectAppointmentMember
                        reqUserInfo={reqUserInfo}
                        selectedMembers={newRoomInfo ? newRoomInfo.Members : undefined}
                        memberOnChange={this.newPageMemberPageOnChange}
                    />
                </HoFormRowColCommon>
                <div style={{ textAlign: 'left' }}>
                    <p style={{ color: "white" }}>*</p>
                </div>
            </HoForm>
        </HoAlertDialog>
    
    }

    mkMain() {
        let { maxRow, historyRoom, paginationInfo } = this.state;
        let { getStatus, data } = this.props;
        let { records } = data || {};
        let { total, page } = paginationInfo?.pagination;

        let searchComponent = <QueueAnim type={['left', 'right']}>
            <Row key="search" className="search" type="flex">
                <Col span={12} style={{display: 'flex'}}>
                    <Input.Search
                        size="large"
                        className="input"
                        placeholder="搜索会议号或会议SIP号"
                        enterButton
                        allowClear={true}
                        onSearch={this.mainSearchOnClick}
                        onChange={this.mainSearchOnChange}
                        /> 
                </Col>
                <Col span={12}>
                
                </Col>
            </Row>
        </QueueAnim>

        let roomComponent = <QueueAnim interval={0} component="ul" type={['top', 'bottom']} leaveReverse>
            {
                records ? records.map((record, index) => {
                    let tag = undefined;
                    let actions = [
                        <Tooltip title="详情"><InfoCircleOutlined onClick={(e) => this.listItemDetailOnClick(e, record)}/></Tooltip>,
                    ]
                    switch(record.State){
                        case 'tostart':
                            tag = (<HoTag key="setup" color={gold[3]}>已预约</HoTag>);
                            actions.push(<Tooltip title="取消预约"><CloseCircleOutlined onClick={(e) => this.listItemDelOnClick(e, record)} /></Tooltip>)
                            break;
                        case 'inprogress':
                            tag = (<HoTag key="stable" color={blue[3]}>与会中</HoTag>);
                            actions.push(<Tooltip title="结束会议"><PoweroffOutlined onClick={(e) => this.listItemEndOnClick(e, record)} /></Tooltip>)
                            break;
                        case 'finished':
                            tag = (<HoTag key="completed" color={grey[3]}>已结束</HoTag>);
                            break;
                        default:
                            break;
                    }
                    return <li key={index}>
                        <Card
                            className="room"
                            style={{margin: '1rem 1rem 1rem 0'}}
                            cover={
                                <Tooltip title="点击详情">
                                    { record.State === 'inprogress' ? <Spin style={{position: 'absolute', left: 8, top: 8}} size="small"/> : undefined}
                                    <img onClick={(e) => this.listItemDetailOnClick(e, record)} alt="" style={{padding: '1rem', width: '16rem', height: '14rem'}} src={getIconSrc('room')}/>
                                </Tooltip>
                            }
                            actions={actions}
                        >
                            <Card.Meta 
                                title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span>{!record.ConferenceCode || record.ConferenceCode.length === 0 ? "(无号码)" : this.codeFix(record.ConferenceCode)}</span>
                                    <span>{tag}</span>
                                </div>} 
                                description={<div>
                                    <span>{!record.Title || record.Title.length === 0 ? "(无主题)" : record.Title}</span>
                                </div>} 
                            />
                        </Card>
                    </li>;
                }) : null
            }
        </QueueAnim>

        return <div id="ho-room" className="ho-room">
            {searchComponent}
            <Row className="rooms" justify="space-around">
                {
                    records ? roomComponent : (getStatus === 2 ? <Result 
                        key="result"
                        status="404" 
                        title={<QueueAnim interval={0} type={['top', 'bottom']} leaveReverse><span key="title">没有正在召开的会议</span></QueueAnim>}
                        subTitle={<QueueAnim interval={0} type={['top', 'bottom']} leaveReverse><span key="sub-title">你可以选择</span></QueueAnim>}
                        extra={<QueueAnim interval={0} type={['bottom', 'top']} leaveReverse><div key="extra" className="extra">
                            <HoNewButton onClick={(e) => this.mainNewOnClick("normal")}>预约</HoNewButton>
                            <HoHistoryButton onClick={(e) => window.goToMenu('ctrl/history-room')}>查看历史</HoHistoryButton>
                        </div></QueueAnim>}
                        >
                    </Result> : undefined)
                }
            </Row>
            {
                records ? <div className="pagination">
                    <Pagination 
                        current={page ? page.number : 1} 
                        pageSize={page ? page.size : 12} 
                        showSizeChanger 
                        showQuickJumper 
                        total={total} 
                        defaultCurrent={1}
                        defaultPageSize={12}
                        showTotal={(total, range) => {
                            return <span>显示总共 {total} 条记录中的 {range[0]} 至 {range[1]} 条</span>
                        }}
                        pageSizeOptions={Array.from({length: 5}).map((i, index) => (Math.pow(2, index) * maxRow * 2).toString())}
                        onChange={this.listPaginationOnChange} 
                        onShowSizeChange={this.listPaginationOnChange} />
                </div> : undefined
            }
        </div>
    }

    // clear handle
    componentWillUnmount(){
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        let that = this;
        function resize() {
            let container = document.getElementById('ho-room');
            let maxRow = container ? parseInt((container.clientWidth - 16) / (16 * (1 + 16))) : 6;
            that.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
                maxRow: Math.max(maxRow, 1),
            })

            return maxRow;
        }
        window.addEventListener('resize', resize);
        let maxRow = resize();
        let { paginationInfo } = this.state;
        paginationInfo.pagination.page.size = maxRow * 2;
        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        dataListRoom(this.props, paginationInfo, this.dataListSuccessHd)
    }

    componentWillReceiveProps(nextProps){
        // 路由变化则去刷新页面
        let { paginationInfo } = this.state;
        if (this.state.path !== nextProps.match.path) {
            paginationInfo.pagination.page.number = 1;
            paginationInfo.pagination.sort = {
                field: 'CreateTime',
                order: 1
            }
            this.setState({
                path: nextProps.match.path,
                historyRoom: nextProps.match.path !== "/conference/ctrl/room",
                paginationInfo: paginationInfo,
            })
            dataListRoom(nextProps, paginationInfo, this.dataListSuccessHd)
        }
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const mainComponent = this.mkMain();
        const newComponent = this.mkNewModal();

        return (<div>
            {newComponent}
            {breadcrumbComponent}
            {/* {statisticsComponent} */}
            <HoCard title={title} buttons={buttons} >
                {mainComponent}
                {/* {tableComponent} */}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_ROOM_LIST), 
    data: listRoomData(state),
});


export default connect(
    mapState, 
    null
)(RoomCT);

