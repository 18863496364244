/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import moment from 'moment';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { Spin, Tag, Button, Collapse, Descriptions, Modal, Col, Tabs, Timeline, Icon } from 'antd';
import { actions, ScriptStatus } from './pipline';
import { HoLinkButton } from '../../../util/hoComponent';
import { printObject } from '../../../util/logic';

import './practice.css'

class DetailHeader extends Component {

    render() {

        let { config, pipline } = this.props;

        let header = undefined;
        if (pipline && pipline.isStart) {
            header = <div className="header">
                <Spin className="loading" size="small"/><Tag className="status" color={blue[3]}>正在演习</Tag><span className="title">{config.title}</span>
            </div>
        } else {
            header = <div className="header">
                <Tag className="status" color={grey[3]}>已停止</Tag><span className="title">{config.title}</span>
            </div>
        }

        return <div className="detail">
            <div className="base">
                {header}
                <Descriptions className="content" size="small" column={1}>
                    <Descriptions.Item label="会议数"><span>{config.conferences[0]}</span><span>~</span><span>{config.conferences[1]}</span></Descriptions.Item>
                    <Descriptions.Item label="成员数"><span>{config.members[0]}</span><span>~</span><span>{config.members[1]}</span></Descriptions.Item>
                    <Descriptions.Item label="执行脚本">
                        {
                            config.actions.map(action => {
                                return <span key={action} style={{ display: 'inline-block' }}>
                                    <Tag>
                                        {actions.map(action)}
                                    </Tag>
                                </span>
                            })
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="执行脚本次数"><span>{config.scripts[0]}</span><span>~</span><span>{config.scripts[1]}</span></Descriptions.Item>
                    <Descriptions.Item label="操作间隔(秒)"><span>{config.operationInterval[0]}</span><span>~</span><span>{config.operationInterval[1]}</span></Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    }
}


class DetailPipLine extends Component {

    constructor(props){
        super(props);

        this.detailOnClick = this.detailOnClick.bind(this)

        this.state = {
            pageVisible: false,
        }
    }

    formatTime(t) {
        return moment(t * 1000).format('YYYY-MM-DD HH:mm:ss')
    }

    formatRangeTime(t1, t2) {
        return moment(t1 * 1000).format('YYYY-MM-DD HH:mm:ss') + " ~ " + moment(t2 * 1000).format('HH:mm:ss')
    }

    statisticalFieldNumber(arr) {
        return arr.reduce(function (prev, next) {
            prev[next.status] = (prev[next.status] + 1) || 1;
            return prev;
        }, {});
    }

    detailOnClick(e, apis) {
        e.stopPropagation();
        this.setState({
            pageVisible: true,
            apis: apis,
        })
    }

    mkDetail() {
        let { pageVisible, apis } = this.state;
        return <Modal
            title={"详情"} 
            visible={pageVisible} 
            width={"90%"}
            onOk={() => {
                this.setState({
                    pageVisible: false,
                })
            }} 
            onCancel={() => {
                this.setState({
                    pageVisible: false,
                })
            }} 
            footer={null}
            destroyOnClose={true}
        >
            <Collapse className="apis">
                {
                    apis?.map((api, index) => {
                        return <Collapse.Panel className="api" key={index} header={api.req?.path}>
                            <pre className="req-body">{"请求\r\n"}{printObject(api.req?.body)}</pre>
                            <pre className="rsp-body">{`响应(耗时: ${api.rsp?.elapse || 0}秒)\r\n`}{printObject(api.rsp?.body)}</pre>
                        </Collapse.Panel>
                    })
                }
            </Collapse>
        </Modal>
    }

    mkTab(isHistory = false) {
        let { config, pipline } = this.props;
        let summaryComponent = undefined;
        let detailComponent = undefined;
        if (pipline) {
            let jobs = isHistory ? pipline.jobHistories : pipline.jobs;
            
            let scriptTotal = 0;
            let errorScriptTotal = 0;
            let warnScriptTotal = 0;
            detailComponent = jobs.length > 0 ? <Collapse className="content">
                {
                    jobs.map(job => {
                        let scriptCnt = job.scripts ? job.scripts.length : 0;
                        let statistScriptRet = this.statisticalFieldNumber(job.scripts);
                        
                        scriptTotal += scriptCnt;
                        errorScriptTotal += statistScriptRet[ScriptStatus.ERROR] || 0;
                        warnScriptTotal += statistScriptRet[ScriptStatus.WARN] || 0;

                        let headerComponent = <div className="header">
                            <span className="title">任务#{job.seq}@{job.flagIndex}</span>
                            <Tag className="status" color={isHistory ? grey[3] : blue[3]}>进度:{((job.runScriptIndex) / scriptCnt * 100).toFixed(2)}%</Tag>
                            <Tag className="status" color={isHistory ? grey[3] : purple[3]}>运行时间:{(job.endTime !== 0 ? job.endTime : moment().unix()) - job.startTime}秒</Tag>
                            {isHistory ? <Tag className="status" color={grey[3]}>脚本总数:{scriptCnt}</Tag>: undefined}
                            {statistScriptRet[ScriptStatus.ERROR] ? <Tag className="status" color={red[3]}>出错脚本:{statistScriptRet[ScriptStatus.ERROR]}个</Tag> : undefined}
                            {statistScriptRet[ScriptStatus.WARN] ? <Tag className="status" color={volcano[3]}>告警脚本:{statistScriptRet[ScriptStatus.WARN]}个</Tag> : undefined}
                            {
                                isHistory ? undefined : <HoLinkButton className="btn" size="small" icon="stop" onClick={(e) => {
                                    e.stopPropagation()
                                    pipline.stop(job.uuid);
                                }}>停止</HoLinkButton>
                            }
                        </div>

                        let descriptionComponent = <Descriptions className="description" size="small" column={1} >
                            <Descriptions.Item label="任务序号"><span>#{job.seq}@{job.flagIndex}</span></Descriptions.Item>
                            <Descriptions.Item label="任务标识"><span>{job.uuid}</span></Descriptions.Item>
                            <Descriptions.Item label="会议SIP号"><span>{job.data?.conference?.SipCode}</span></Descriptions.Item>
                            <Descriptions.Item label="会议号"><span>{job.data?.conference?.ConferenceCode}</span></Descriptions.Item>
                            <Descriptions.Item label="会议成员数"><span>{job.members}</span></Descriptions.Item>
                            <Descriptions.Item label="执行脚本总数"><span>{scriptCnt}</span></Descriptions.Item>
                            <Descriptions.Item label="当前执行脚本ID"><span>{job.runScriptIndex} ({((job.runScriptIndex) / scriptCnt * 100).toFixed(2)}%)</span></Descriptions.Item>
                            <Descriptions.Item label="开始时间"><span>{this.formatTime(job.startTime)}</span></Descriptions.Item>
                            {
                                job.endTime !== 0 ? <Descriptions.Item label="结束时间"><span>{this.formatTime(job.endTime)}</span></Descriptions.Item> : undefined
                            }
                            <Descriptions.Item label="运行时间"><span>{(job.endTime !== 0 ? job.endTime : moment().unix()) - job.startTime}秒</span></Descriptions.Item>
                        </Descriptions>

                        let timelineComponent = <Collapse className="timeline">
                            <Collapse.Panel header="详情">
                                <Timeline>
                                    {
                                        job.scripts.map((script, index) => {
                                            let color = grey[3];
                                            let dot = undefined;
                                            let content = undefined;
                                            switch(script.status) {
                                                case ScriptStatus.DOING: {
                                                    // 正在执行的脚本
                                                    color = blue[3];
                                                    dot = <Icon type="loading" style={{ fontSize: '16px', color: blue[3] }}/>;
                                                    content = <span className="row"><Tag>{actions.map(script.key)}</Tag><span>执行中...</span></span>
                                                    break;
                                                }
                                                case ScriptStatus.NONE: {
                                                    // 还没执行的脚本
                                                    if (isHistory) {
                                                        color = grey[3];
                                                        dot = <Icon type="stop" style={{ fontSize: '16px', color: grey[3] }}/>
                                                        content = <span className="row"><Tag>{actions.map(script.key)}</Tag><span>已取消</span></span>
                                                    } else {
                                                        if (job.runScriptIndex === index) {
                                                            color = blue[3];
                                                            dot = <Icon type="clock-circle" style={{ fontSize: '16px', color: blue[3] }}/>
                                                            content = <span className="row"><Tag>{actions.map(script.key)}</Tag><span>准备中...</span></span>
                                                        } else {
                                                            color = gold[3];
                                                            dot = <Icon type="pause-circle" style={{ fontSize: '16px', color: gold[3] }}/>
                                                            content = <span className="row"><Tag>{actions.map(script.key)}</Tag><span>等待中...</span></span>
                                                        }
                                                    }
                                                    break;
                                                }
                                                case ScriptStatus.SUCCESS: 
                                                case ScriptStatus.WARN: 
                                                case ScriptStatus.ERROR: {
                                                    // 执行完成的脚本
                                                    if (script.status === ScriptStatus.SUCCESS) {
                                                        color = green[3];
                                                        dot = <Icon type="check-circle" style={{ fontSize: '16px', color: green[3]}}/>
                                                    } else if (script.status === ScriptStatus.ERROR) {
                                                        color = red[3];
                                                        dot = <Icon type="close-circle" style={{ fontSize: '16px', color: red[3]}}/>
                                                    } else if (script.status === ScriptStatus.WARN) {
                                                        color = volcano[3];
                                                        dot = <Icon type="exclamation-circle" style={{ fontSize: '16px', color: volcano[3]}}/>
                                                    }
                                                    content = <span className="row">
                                                        <Tag className="tag"color={color}>{actions.map(script.key)}</Tag>
                                                        <span className="time">{this.formatRangeTime(script.startTime, script.endTime)}</span>
                                                        {script.reason && script.reason.length > 0 ? <span className="reason">{script.reason}</span> : undefined}
                                                        <span className="description">{script.description}</span>
                                                    </span>
                                                    break;
                                                }
                                                default:
                                                    break;
                                            }

                                            return <Timeline.Item onDoubleClick={(e) => this.detailOnClick(e, script.apis)} key={index} color={color} dot={dot}>{content}</Timeline.Item>
                                        })
                                    }
                                </Timeline>
                            </Collapse.Panel>
                        </Collapse>

                        return <Collapse.Panel header={headerComponent} key={job.uuid}>
                            {descriptionComponent}
                            {timelineComponent}
                        </Collapse.Panel>
                    })
                }
            </Collapse> : undefined;
            summaryComponent = jobs.length > 0 ? <Descriptions className="summary" column={1} style={{backgroundColor: !isHistory ? blue[2] : grey[2]}}>
                <Descriptions.Item label="会议数">{jobs.length}</Descriptions.Item>
                <Descriptions.Item label="脚本总数">{scriptTotal}</Descriptions.Item>
                <Descriptions.Item label="告警脚本数">{warnScriptTotal}</Descriptions.Item>
                <Descriptions.Item label="出错脚本数">{errorScriptTotal}</Descriptions.Item>
            </Descriptions> : undefined;
        }

        return <div>
            {summaryComponent}
            {detailComponent}
        </div>;
    }

    render() {
        let { config, pipline } = this.props;
        let detailComponent = this.mkDetail();
        return <div className="piplines">
            {detailComponent}
            <Tabs type="card" defaultActiveKey="doing" className="detail-tabs">
                <Tabs.TabPane className="tabpane" tab={`执行中(${pipline ? pipline.jobs.length : 0})`} key="doing">
                    {this.mkTab()}
                </Tabs.TabPane>
                <Tabs.TabPane className="tabpane" tab={`已结束(${pipline ? pipline.jobHistories.length : 0})`} key="done">
                    {this.mkTab(true)}
                </Tabs.TabPane>
            </Tabs>
        </div>
    }
}

export {DetailHeader, DetailPipLine};