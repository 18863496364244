
import { version } from '../util/version';

export let billMenu = {
    key: "bill", icon: "icon-qian", title: "结算管理",
    children: [{
        key: "bill/cdr", icon: "icon-qianyuejilu", title: "话单",
    }]
}

export let devopsMonitorMenu = {
    key: "devops/monitor", icon: "icon-monitor", title: "运维监控",
    children: [{
        key: "devops/monitor/fullscreen", icon: "icon-dapingzhanshi", title: "大屏",
    }, {
        key: "devops/monitor/topology", icon: "icon-network", title: "组网拓扑",
    }, {
        key: "devops/monitor/zabbix", icon: "icon-monitor", title: "zabbix",
    }]
}

export let devopsToolMenu = {
    key: "devops/tool", icon: "icon-tool", title: "运维工具",
    children: [
        {
            key: "devops/tool/log", icon: "icon-bug", title: "用户日志",
        }, {
            key: "devops/tool/version", icon: "icon-banben", title: "版本",
        }, {
            key: "devops/tool/warn", icon: "icon-alert-fill", title: "告警",
        }, {
            key: "devops/tool/blacklist", icon: "icon-heimingdan", title: "IP黑名单",
        }
    ]
}

export let deviceMainMenu = { 
    key: "member", icon: "icon-user", title: "用户管理",
    children: [{
        key: "member/account", icon: "icon-id", title: "账户",
    }, {
        key: "member/device", icon: "icon-shebei", title: "设备用户",
    }, {
        key: "member/camera", icon: "icon-shebei1", title: "摄像头",
    }, {
        key: "member/sdk", icon: "icon-api", title: "SDK用户",
    }]
}

export let devopsAnalysisMenu = {
    key: "devops/analysis", icon: "icon-analysis", title: "数据分析",
    children: [{
        key: "devops/analysis/server", icon: "icon-fuwuqi", title: "服务器",
    }, {
        key: "devops/analysis/conference", icon: "icon-huiyishi", title: "会议",
    }, {
        key: "devops/analysis/channel", icon: "icon-hujiaotiaodu", title: "呼叫",
    }, {
        key: "devops/analysis/registration", icon: "icon-register", title: "注册",
    }]
}

export let devopsSettingMenu = {
    key: "devops/setting", icon: "icon-setting", title: "配置管理",
    children: [{
        key: "devops/setting/jizhen", icon: "icon-sanxiangpinghengfenxi", title: "极真组",
    }, {
        key: "devops/setting/server/group", icon: "icon-fuwuqi", title: "服务器",
    },{
        key: "devops/setting/license", icon: "icon-id", title: "License",
        children: [{
            key: "devops/setting/license/mcu", icon: "icon-fuwuqi1", title: "MCU",
        },{
            key: "devops/setting/license/terminal", icon: "icon-shebei", title: "终端",
        }]
    }]
}

export let roomMenu = {
    key: "ctrl", icon: "icon-huiyi", title: "会议管理",
    children: [{
        key: "ctrl/room", icon: "icon-huiyishi", title: "会议室",
    }, {
        key: "ctrl/ads", icon: "icon-tiaodu", title: "会议调度",
    }, {
        key: "ctrl/history-room", icon: "icon-qianyuejilu", title: "历史会议",
    }]
}

export let menus = [];
switch(version.environment) {
    // 正式环境
    case "production":
        // menus = [billMenu, devopsMonitorMenu, devopsAnalysisMenu];
        // break;
    // 展厅环境、开发环境、测试环境
    // eslint-disable-next-line no-fallthrough
    case "show":
    case "development":
    case "test":
        devopsToolMenu.children.push({
            key: "devops/tool/practice", icon: "icon-moni", title: "模拟演习",
        })
        break;
    default:
        break;
}

export function initMenu(roleType) {
    switch (roleType) {
        case "superadmin":
            menus = [deviceMainMenu, roomMenu, billMenu, devopsMonitorMenu, devopsToolMenu, devopsAnalysisMenu, devopsSettingMenu]
            break;
        
        case "test":
            menus = [deviceMainMenu, roomMenu, devopsMonitorMenu, devopsToolMenu, devopsAnalysisMenu]
            break;
        
        default:
            break;
    }

    return menus;
}