/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import { Menu, Input, Button, Descriptions, Row, Col, Switch, Dropdown, Form, Modal } from 'antd';

import { apiManufacturerAdd, apiManufacturerDel, apiManufacturerMod, apiManufacturerList } from '../../api/manufacturer';
import { getLoginUserInfo } from '../login/loginRD'

import {
    hoValidateEmptyFormat, 
} from '../../util/formComponent';
import { 
    HoCard, HoBreadcrumb, HoBackButton, HoRefreshButton, HoNewButton, HoAlertDialog, HoButton, 
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTagButton, hoTableColumnActionButton
} from '../../util/tableComponent';

import './manufacturer.less'

class ManufacturerCT extends Component {
    constructor(props){
        super(props);

        const columns = [
            hoTableColumn('名称', 'DisManufacturer', 'str', false, false, '15%'),
            hoTableColumn('标识', 'Manufacturer', 'str', false, false, '15%'),
            hoTableColumn('设备型号', 'Model', 'str', false, false, '15%'),
            hoTableColumn('能力', 'SupportPtz', 'int', false, false, '15%', {
                render: (text, record) => {
                    return <Descriptions className="manufacturer-table-col-attr-descriptions">
                        <Descriptions.Item label="云台控制">{record.SupportPtz ? "支持" : "不支持"}</Descriptions.Item>
                    </Descriptions>
                }
            }),
            hoTableColumnAction('操作', 'operation', [
                {
                    title: '编辑', onClick: (e, record) => {
                        e.stopPropagation();
                        this.setState({
                            modPageVisible: true,
                            data: Object.assign({}, record),
                        });
                    }
                },
                {
                    title: '删除', onClick: (e, record) => {
                        e.stopPropagation();
                        Modal.confirm({
                            title: "删除",
                            content: `是否删除${record?.DisManufacturer}？`,
                            onOk: () => {
                                apiManufacturerDel(this.props, record,
                                    () => {
                                        this.mainRefreshOnClick();
                                    }
                                );
                            }
                        })
                    }
                },
            ], '20%')
        ];

        let pag = hoTableInitPaginationInfo();
        pag.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        
        this.state = {
            columns: columns,
            paginationInfo: pag,
            search: {
                text: '',
            },
            ...this.initData(),
        }
    }

    initData() {
        return {
            data: {
                DisManufacturer: '',
                Manufacturer: '',
                Model: '',
                SupportPtz: 0,
            },
        }
    }

    searchOnChange(search, pag) {
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        if (search.text?.length > 0) {
            paginationInfo.pagination.filters = [
                [['DisManufacturer', 'like', search.text]],
                [['Manufacturer', 'like', search.text]],
                [['Model', 'like', search.text]],
            ];
        } else {
            paginationInfo.pagination.filters = []
        }

        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            status: 1,
        }, () => {
            apiManufacturerList(this.props, paginationInfo,
                (_, rsp, req) => {
                    this.setState({
                        manufacturers: rsp.RecordList,
                        total: rsp.TotalNum,
                        status: 2,
                    })
                },
                () => {
                    this.setState({
                        status: 3,
                    })
                }
            )
        })
        
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }


    // 面包屑
    mkBreadcrumb() {
        return <HoBreadcrumb 
            items={[{title: "用户管理", url: "member"}, {title: "摄像头", url:"member/camera"}, {title: "厂商能力"}]}
        />
    }

    mkHeaderInfo() {
        let title = "厂商能力"
        let buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("member/camera")} />
            <HoNewButton onClick={() => {
                this.setState({
                    newPageVisible: true,
                    ...this.initData(),
                })
            }}/>
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick} />
        </div>);
        return { title: <span>{title}</span>, buttons: buttons};
    }

    mkModModal(){
        let { modPageVisible, data } = this.state;

        return <HoAlertDialog
            title="编辑设备"
            visible={modPageVisible}
            onOkClick={() => {
                apiManufacturerMod(this.props, this.state.data, (dispatch, rspBody, reqBody) => {
                    this.setState({
                        modPageVisible: false,
                        ...this.initData(),
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                });
            }}
            onCancelClick={() => {
                this.setState({
                    modPageVisible: false,
                });
            }}
        >
            <Form className="manufacturer-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="名称" {...hoValidateEmptyFormat(data.DisManufacturer, "名称不能为空")}>
                    <Input value={data.DisManufacturer} onChange={(e) => {
                        data.DisManufacturer = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="厂商标识" {...hoValidateEmptyFormat(data.Manufacturer, "厂商标识不能为空")}>
                    <Input value={data.Manufacturer} onChange={(e) => {
                        data.Manufacturer = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="设备型号" {...hoValidateEmptyFormat(data.Model, "设备型号不能为空")}>
                    <Input value={data.Model} onChange={(e) => {
                        data.Model = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="支持云台">
                    <Switch checked={!!data.SupportPtz} onChange={(e) => {
                        data.SupportPtz = e ? 1 : 0;
                        this.setState({
                            data: data,
                        })
                    }}></Switch>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    
    }

    mkNewModal(){
        const { newPageVisible, data } = this.state;

        return <HoAlertDialog
            className="member-modal"
            title="新增厂商能力"
            visible={newPageVisible}
            onOkClick={() => {
                apiManufacturerAdd(this.props, data,
                    (dispatch, rspBody, reqBody) => {
                        this.setState({
                            newPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick()
                        });
                    }
                )
            }}
            onCancelClick={() => {
                this.setState({
                    newPageVisible: false,
                });
            }}
        >
            <Form className="manufacturer-modal-form" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Form.Item label="名称" {...hoValidateEmptyFormat(data.DisManufacturer, "名称不能为空")}>
                    <Input onChange={(e) => {
                        data.DisManufacturer = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="厂商标识" {...hoValidateEmptyFormat(data.Manufacturer, "厂商标识不能为空")}>
                    <Input onChange={(e) => {
                        data.Manufacturer = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="设备型号" {...hoValidateEmptyFormat(data.Model, "设备型号不能为空")}>
                    <Input onChange={(e) => {
                        data.Model = e.target.value;
                        this.setState({
                            data: data,
                        })
                    }}></Input>
                </Form.Item>
                <Form.Item label="支持云台">
                    <Switch checked={!!data.SupportPtz} onChange={(e) => {
                        data.SupportPtz = e ? 1 : 0;
                        this.setState({
                            data: data,
                        })
                    }}></Switch>
                </Form.Item>
            </Form>
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, search, manufacturers, total, status } = this.state;

        // console.log("mkTable", search);

        return <div className="ho-member">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{ display: 'flex' }}>
                        <Input.Search
                            size="large"
                            placeholder="搜索厂商名称、厂商标识或设备型号"
                            enterButton
                            allowClear={true}
                            onSearch={(text) => {
                                let {search, paginationInfo} = this.state;
                                search.text = text;
                                this.searchOnChange(search, paginationInfo)
                            }}
                            onChange={(e) => {
                                if (e.target?.value?.length === 0) {
                                    let {search, paginationInfo} = this.state;
                                    search.text = "";
                                    this.searchOnChange(search, paginationInfo)
                                }
                            }}
                            /> 
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={manufacturers}
                rowKey='Id'
                loading={status === 1}
                onColumnChange={(paginationInfo) => {
                    this.searchOnChange(this.state.search, paginationInfo)
                }}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount(){
    }

    componentDidMount() {
        this.mainRefreshOnClick()
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const { title, buttons } = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const modComponent = this.mkModModal();
        const newComponent = this.mkNewModal();
        
        return (<div>
            {modComponent}
            {newComponent}
            {breadcrumbComponent}
            {/* {statisticsComponent} */}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(ManufacturerCT);

