/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Dropdown, Button, Descriptions, Typography, Icon, Modal, Spin } from 'antd';

import { 
    HoTable, hoTableColumn, hoTableColumnAction, hoTableInitPaginationInfo, hoTableColumnTagButton,
} from '../../util/tableComponent';
import { 
    actNetRequestClear, getNetRequestStatus 
} from '../../util/netReqRD';

import { hoMessage, HoLinkButton } from '../../util/hoComponent';
import { confStateTags2,} from '../common/common';
import { ACT_NET_ROOM_GET2, getRoomData2, getRoomDataSuccessHd2, dataGetRoom2 } from '../../pages/room/roomRD';

class SelectJoinedMember extends Component {
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 待选
        this.mainAddOnClick = this.mainAddOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblAddOnClick = this.tblAddOnClick.bind(this);
        this.tblSelectOnClick = this.tblSelectOnClick.bind(this);
        this.tblSelectOnChange = this.tblSelectOnChange.bind(this);

        this.state = {
            debug: false,
            selectedRowKeys: [],
            selectedRows: [],
            paginationInfo: hoTableInitPaginationInfo(),
        }
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e){
        const {paginationInfo} = this.state;
        const {roomInfo} = this.props;

        dataGetRoom2(this.props, roomInfo, paginationInfo, getRoomDataSuccessHd2);
    }

    // '批量添加'按钮被点击
    mainAddOnClick(e) {
        const {selectedRows} = this.state;
        const {memberOnChange} = this.props;

        if (selectedRows.length === 0){
            hoMessage({type: 'warning', msg: `请至少勾选一个设备`});
        } else {
            if (memberOnChange){
                // console.log("tblAddOnClick add member", record);
                memberOnChange('group.add', selectedRows);
            }
            this.setState({
                selectedRowKeys: [],
                selectedRows: [],
            });
        }
    }

    // '添加'按钮被点击
    tblAddOnClick(e, record){
        e.stopPropagation();
        const {memberOnChange} = this.props;
        if (memberOnChange){
            // console.log("tblAddOnClick add member", record);
            memberOnChange('add', record);
        }
    }

    // '选择'按钮被点击
    tblSelectOnClick(e, record) {
        // console.log(e, record);
        const {memberOnSelect} = this.props;
        
        if (memberOnSelect) {
            memberOnSelect(record);
        }
    }

    tblSelectOnChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        const {roomInfo} = this.props;
        dataGetRoom2(this.props, roomInfo, paginationInfo, getRoomDataSuccessHd2);
        this.setState({
            paginationInfo: paginationInfo,
            selectedRowKeys: [],
            selectedRows: [],
        });
    }

    // 单行被点击
    tblRowOnClick(e, record) {
        const {memberOnSelect} = this.props;
        if (memberOnSelect) {
            memberOnSelect(record);
        }
    }

    mkWillTable(){
        let { selectedRowKeys } = this.state;
        const { data, getStatus, memberOnSelect } = this.props;
        const { TotalMemberNum, ConfMebmbers } = data || {};
        if (!data){
            return undefined;
        }

        let rowSelection = undefined;
        let operations = undefined;
        if (memberOnSelect) {
            rowSelection = undefined;
            operations = [{title: '选择', onClick: this.tblSelectOnClick}];
        } else {
            rowSelection = {
                selectedRowKeys: selectedRowKeys,
                onChange: this.tblSelectOnChange,
            };
            operations = [{title: '添加', onClick: this.tblAddOnClick}];
        }

        const columns = [
            hoTableColumn('在线状态', 'AppState', 'str', false, false, '40%', {
                render: (text, record) => hoTableColumnTagButton(confStateTags2(text, record)),
            }),
            hoTableColumn('设备名称', 'NickName', 'str', false, false, '40%'),
            hoTableColumnAction('操作', 'operation', operations, '10%'),
        ];

        return (<HoTable
            className="ho-select-member-will-table"
            columns={columns}
            data={ConfMebmbers}
            bordered={false}
            size={'small'}
            rowKey={'UserId'}
            loading={getStatus === 1}
            onRowClick={this.tblRowOnClick}
            onColumnChange={this.tblColOnChange}
            total={TotalMemberNum}
            rowSelection={rowSelection}
        />);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_ROOM_GET2));
    }

    componentDidMount(){
        const {paginationInfo} = this.state;
        const {roomInfo} = this.props;

        dataGetRoom2(this.props, roomInfo, paginationInfo, getRoomDataSuccessHd2);
    }

    render() {
        const {selectedRowKeys} = this.state;
        const {memberOnSelect, className} = this.props;
        // 主页面
        const tableComponent = this.mkWillTable();

        const addDisable = selectedRowKeys.length === 0;
        if (!memberOnSelect) {
            return <div className={className}>
                <span style={{fontWeight: 500, fontSize: '16px'}}>待选</span>
                <div style={{margin: '0.5rem 0'}}>
                    <HoLinkButton disabled={addDisable} icon="plus" style={{padding: 0}} onClick={this.mainAddOnClick}>批量添加</HoLinkButton>
                    <HoLinkButton icon="sync" style={{float: 'right', padding: 0}} onClick={this.mainRefreshOnClick}>刷新</HoLinkButton>
                </div>
                {tableComponent}
            </div>;
        } else {
            return <div className={className}>
                {tableComponent}
            </div>;
        }
        
    }
}

const mapState = (state) => ({
    getStatus: getNetRequestStatus(state, ACT_NET_ROOM_GET2), 
    data: getRoomData2(state),
});


export default connect(
    mapState, 
    null
)(SelectJoinedMember);

