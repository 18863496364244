/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Dropdown, Button, Descriptions, Typography, Icon, Modal, Spin } from 'antd';

import { 
    HoTable, hoTableColumn, hoTableColumnAction, hoTableInitPaginationInfo, hoTableColumnTagButton, hoTableColumnDate,
} from '../../util/tableComponent';
import { 
    actNetRequestClear, getNetRequestStatus 
} from '../../util/netReqRD';

import { ACT_NET_MEMBER_LIST2, dataListMember2 } from '../../pages/member/memberRD';
import { hoMessage } from '../../util/hoComponent';

class JizhenMember extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        this.dataListMemberSuccess = this.dataListMemberSuccess.bind(this);

        // 已选
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);

        const columns = [
            // hoTableColumn('用户类型', 'UserType', 'str', false, false, '10%', ),
            // hoTableColumn('设备类型', 'DeviceType', 'str', false, false, '10%', ),
            hoTableColumn('设备名称', 'NickName', 'str', false, true, '20%'),
            hoTableColumn('SIP号', 'SipNum', 'str', false, true, '20%'),
            hoTableColumnDate('创建时间', 'CreateTime', false, '20%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '选择', onClick: this.tblRowOnClick},
            ], '10%'),
        ];

        this.state = {
            columns: columns,
            records: undefined,
            paginationInfo: hoTableInitPaginationInfo(),

        }
    }

    dataListMemberSuccess(dispatch, rspBody, reqBody) {
        this.setState({
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.TotalNum),
            records: rspBody.RecordList,
        })
    }

     // '刷新'按钮被点击
    mainRefreshOnClick(e) {
        let { paginationInfo } = this.state;
        dataListMember2(this.props, paginationInfo, this.dataListMemberSuccess)
    }

    tblRowOnClick(e, record){
        let { onClick } = this.props;
        e && e.stopPropagation();
        onClick && onClick(record);
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataListMember2(this.props, paginationInfo, this.dataListMemberSuccess)
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    mkTable() {
        let { columns, records, paginationInfo } = this.state;
        const { getStatus } = this.props;
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        return (<HoTable
            key="table"
            columns={columns}
            data={records}
            rowKey={'Id'}
            loading={getStatus === 1}
            onColumnChange={this.tblColOnChange}
            onRowDoubleClick={this.tblRowOnClick}
            total={total}
        />);
    }


    // clear handle
    componentWillUnmount() {
        actNetRequestClear(ACT_NET_MEMBER_LIST2)
    }

    componentDidMount() {
        this.mainRefreshOnClick()
    }

    render() {
        const tableComponent = this.mkTable();
        return (<div>
            {tableComponent}
        </div>);
    }
}

const mapState = (state) => ({
    getStatus: getNetRequestStatus(state, ACT_NET_MEMBER_LIST2), 
});


export default connect(
    mapState, 
    null
)(JizhenMember);

