/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';

import {getLoginUserInfo} from '../../login/loginRD'
import { config } from '../../../util/version';


class ZabbixCT extends Component{
    constructor(props){
        super(props);
        
        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkMain = this.mkMain.bind(this);

        this.state = {
            iFrameHeight: '2400px',
            url: config.host.zabbix + "/zabbix.php?action=dashboard.view&ddreset=1",
        }

        window.open(config.host.zabbix + "/zabbix.php?action=dashboard.view&ddreset=1", "_blank")
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "运维工具", url: "devops/tool"}, {title: "Zabbix"}]}
        />
    }

    mkHeaderInfo(){
        return {title: <span>Zabbix</span>, buttons: undefined}; 
    }

    mkMain(){
        return <iframe 
            title="zabbix"
            style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
            // onLoad={() => {
            //     const obj = ReactDOM.findDOMNode(this);
            //     this.setState({
            //         iFrameHeight: obj.contentWindow.document.body.scrollHeight + 'px'
            //     });
            // }} 
            ref="iframe" 
            src={this.state.url}
            width="100%" 
            height={this.state.iFrameHeight} 
            scrolling="no" 
            frameBorder="0"
        />
    }

    // clear handle
    componentWillUnmount(){
    }

    componentDidMount(){
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const mainComponent = this.mkMain();

        // return (<div>
        //     {breadcrumbComponent}
        //     <HoCard title={title} buttons={buttons} >
                
        //     </HoCard>
        // </div>);
        // return mainComponent;
        return null;
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(ZabbixCT);

