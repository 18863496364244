/* eslint-disable no-unused-vars */
import { config } from './version';

export const sendRequest = (path, body, headers={}, method='POST') => {
    let request = {
        method: method, 
        // mode: 'no-cors', 
        headers: Object.assign({
            'Accept': '*/*',
            'Content-Type': 'application/json',
        }, headers), 
        body: JSON.stringify(body)
    };
    
    let url = path;
    if (path.indexOf("/cm") !== -1) {
        url = config.host.cm + path;
    } else if (path.indexOf("/cs") !== -1) {
        url = config.host.cs + path;
    }

    return fetch(url, request)
        .then((response) => {
            // console.log("then response", response)
            const contentType = response.headers.get('content-type');
            if (response.status === 200) {
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    // console.log("then json", response, contentType)
                    return response.json();
                }
                return response.text();
            }else{
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json().then(r => Promise.reject(r));
                }
                return response.text().then(r => Promise.reject(r));
            }
        })
        .then(
            ((json) => {
                // console.log("then then json", json)
                return json;
            }), 
            ((text) => {
                // console.log("then then text", text)
                return text;
            }), 
            ((error) => {
                // console.log("then then error", error)
                return error;
            })
        )
        .catch(
            (error) => {
                // console.log('catch error', error);
                return undefined;
            }
        )
}


export const sendRequestZabbix = (body, headers={}, method='POST') => {
    let request = {
        method: method, 
        // mode: 'no-cors', 
        headers: Object.assign({
            'Content-Type': 'application/json',
        }, headers), 
        body: JSON.stringify(body)
    };

    return fetch(config.host.zabbix + "/api_jsonrpc.php", request)
        .then((response) => {
            // console.log("then response", response)
            const contentType = response.headers.get('content-type');
            if (response.status === 200) {
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json();
                }
                return response.text();
            }else{
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json().then(r => Promise.reject(r));
                }
                return response.text().then(r => Promise.reject(r));
            }
        })
        .then(
            ((json) => {
                // console.log("then then json", json)
                return json;
            }), 
            ((text) => {
                // console.log("then then text", text)
                return text;
            }), 
            ((error) => {
                // console.log("then then error", error)
                return error;
            })
        )
        .catch(
            (error) => {
                // console.log('catch error', error);
                return undefined;
            }
        )
}





