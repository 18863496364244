/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Card, Tooltip, Select } from 'antd';

import './jizhen.less';

let RoleList = [
    { key: "Mid", description: "主屏", value: 1 },
    { key: "Left", description: "左屏", value: 2 },
    { key: "Right", description: "右屏", value: 3 },
    { key: "Extra1", description: "扩展屏1", value: 100 },
    { key: "Extra2", description: "扩展屏2", value: 101 },
    { key: "Extra3", description: "扩展屏3", value: 102 },
    { key: "Extra4", description: "扩展屏4", value: 103 },
    { key: "Extra5", description: "扩展屏5", value: 104 },
    { key: "Extra6", description: "扩展屏6", value: 105 },
]
let Role = {
    list() {
        return RoleList;
    },
    keys() {
        return RoleList.map(role => role.key);
    },
    getDescription(key) {
        return RoleList.find(e => `${e.key}` === `${key}`)?.description || key;
    },
    getDescription2(value) {
        return RoleList.find(e => `${e.value}` === `${value}`)?.description || value;
    },
    getValue(key) {
        return RoleList.find(e => `${e.key}` === `${key}`)?.value || key;
    },
    getKey(value) {
        return RoleList.find(e => `${e.value}` === `${value}`)?.key || value;
    }
}

let TypeList = [
    { key: "default", description: "普通版", value: 0 },
    { key: "plus", description: "豪华版", value: 1 },
]
let Type = {
    list() {
        return TypeList;
    },
    keys() {
        return TypeList.map(role => role.key);
    },
    getDescription(key) {
        return TypeList.find(e => `${e.key}` === `${key}`)?.description || key;
    },
    getDescription2(value) {
        return TypeList.find(e => `${e.value}` === `${value}`)?.description || value;
    },
    getValue(key) {
        return TypeList.find(e => `${e.key}` === `${key}`)?.value || key;
    },
    getKey(value) {
        return TypeList.find(e => `${e.value}` === `${value}`)?.key || value;
    }
}

const CustomizedForm = Form.create({
    name: 'jizhen_form',
    onFieldsChange(props, changedFields) {
      props.onChange(changedFields);
    },
    mapPropsToFields(props) {
      return {
        Id: Form.createFormField({
            ...props.Id,
            value: props.Id?.value,
        }),
        Name: Form.createFormField({
            ...props.Name,
            value: props.Name?.value,
        }),
        RcType: Form.createFormField({
            ...props.RcType,
            value: props.RcType?.value,
        }),
        ClientCode: Form.createFormField({
            ...props.ClientCode,
            value: props.ClientCode?.value,
        }),
        AuthCode: Form.createFormField({
            ...props.AuthCode,
            value: props.AuthCode?.value,
        }),
        Left: Form.createFormField({
            ...props.Left,
            value: props.Left?.value,
        }),
        Mid: Form.createFormField({
            ...props.Mid,
            value: props.Mid?.value,
        }),
        Right: Form.createFormField({
            ...props.Right,
            value: props.Right?.value,
        }),
        Others: Form.createFormField({
            ...props.Others,
            value: props.Others?.value,
        }),
        Remark: Form.createFormField({
            ...props.Remark,
            value: props.Remark?.value,
        }),
      };
    },
})(props => {
    const { form } = props;
    const { getFieldDecorator } = form;

    return (
        <Form>
            <Form.Item label="名称">
                {getFieldDecorator('Name', {
                    initialValue: '',
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请输入名称', whitespace: false }],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="类型">
                {getFieldDecorator('RcType', {
                    rules: [{ required: true, message: '请选择类型', whitespace: false }],
                })(<Select>
                    {
                        Type.list().map(t => <Select.Option key={t.value}>{t.description}</Select.Option>)
                    }
                </Select>)}
            </Form.Item>
            <Form.Item label="识别码">
                {getFieldDecorator('ClientCode', {
                    initialValue: '',
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请输入识别码', whitespace: false }],
                })(<Input />)}
            </Form.Item >
            <Form.Item label="授权码">
                {getFieldDecorator('AuthCode', {
                    initialValue: '',
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请输入授权码', whitespace: false }],
                })(<Input.Password />)}
            </Form.Item >
            <Form.Item label="屏" className="screen">
                <Row gutter={16}>
                    {
                        Role.list().map((role) => {
                            let nickName = props[role.key]?.value?.NickName;
                            let sipNum = props[role.key]?.value?.SipNum;
                            let actions = [];

                            if (`${props.RcType?.value}` === "0" && role.value >= 100) {
                                return undefined;
                            }
                            if (nickName) {
                                actions.push(<Button key="edit" icon="edit" type="link" onClick={(e) => {
                                    props.deviceEditOnClick && props.deviceEditOnClick(role.key)
                                }}>编辑</Button>);
                            } else {
                                actions.push(<Button key="plus" icon="plus" type="link" onClick={(e) => {
                                    props.deviceEditOnClick && props.deviceEditOnClick(role.key)
                                }}>添加</Button>);
                            }
                            
                            if (sipNum) {
                                actions.push(<Button key="delete" icon="delete" type="link" onClick={(e) => {
                                    props.deviceDelOnClick && props.deviceDelOnClick(role.key)
                                }}>删除</Button>)
                            }
                            return <div key={role.key} className="item">
                                <div className="left">
                                    <div className="role">{role.description}</div>
                                    <div className="nickname">{nickName}</div>
                                    <div className="sipnum">{sipNum}</div>
                                </div>
                                <div className="actions">{actions}</div>
                            </div>
                        })
                    }
                </Row>
            </Form.Item>
            <Form.Item label="备注">
                {getFieldDecorator('Remark')(<Input.TextArea rows={3}/>)}
            </Form.Item>
        </Form>
    );
});

class OJizhenForm extends Component {
    render() {
        const { fields, onChange, deviceEditOnClick, deviceDelOnClick } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} onChange={onChange} deviceEditOnClick={deviceEditOnClick} deviceDelOnClick={deviceDelOnClick}/>
            </div>
        );
    }
}

let JizhenForm = Form.create({ name: 'jizhen' })(OJizhenForm);

export { JizhenForm, Role, Type }