/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Form, Input, Slider, Row, Col, Select } from 'antd';
import { actions } from './pipline';
import { hoMessage } from '../../../util/hoComponent';


class SliderInput extends Component {

    render() {
        let { min, max, marks, values, onChange } = this.props;

        return <Row justify="space-between" align="middle">
            <Col span={12} style={{paddingRight: '1rem'}}>
                <Slider range min={min} max={max} marks ={marks} value={values} onChange={(e) => {
                    if (onChange) {
                        onChange(e)
                    }
                }}/>
            </Col>
            <Col span={12}>
                <Input.Group compact>
                    <Input style={{ width: 50, textAlign: 'center' }} placeholder="最小值" value={values[0]} onChange={(e) => {
                        values[0] = parseInt(e.target.value.replace(/\D/g, ''));
                        if (onChange) {
                            onChange(values)
                        }
                    }}/>
                    <Input
                        style={{ width: 30, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff', }}
                        placeholder="~"
                        disabled
                    />
                    <Input style={{ width: 50, textAlign: 'center', borderLeft: 0 }} placeholder="最大值" value={values[1]} onChange={(e) => {
                        values[1] = parseInt(e.target.value.replace(/\D/g, ''));
                        if (onChange) {
                            onChange(values)
                        }
                    }}/>
                </Input.Group>
            </Col>
        </Row>
    }
}

class NewForm extends Component {

    render() {
        let { info, onChange } = this.props;
        let formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 20 },
            },
        };
        return <Form>
            <Form.Item label="标题">
                <Input placeholder="" value={info.title} allowClear onChange={(e) => {
                    info.title = e.target.value;
                    if (onChange) {
                        onChange(info)
                    }
                }} />
            </Form.Item>
            <Form.Item label="会议数">
                <SliderInput
                    min={2}
                    max={100}
                    marks={{ 2: '2',  100: '100'}}
                    values={info.conferences}
                    onChange={(values) => {
                        info.conferences = values;
                        if (values[1] * info.members[1] > 1000) {
                            hoMessage({type: 'error', msg: '会议数太大'})
                        } else {
                            if (onChange) {
                                onChange(info)
                            }
                        }
                        
                    }}
                />
            </Form.Item>
            <Form.Item label="成员数">
                <SliderInput
                    min={2}
                    max={20}
                    marks={{ 2: '2',  20: '20'}}
                    values={info.members}
                    onChange={(values) => {
                        info.members = values;
                        if (values[1] * info.conferences[1] > 1000) {
                            hoMessage({type: 'error', msg: '成员数太大'})
                        } else {
                            if (onChange) {
                                onChange(info)
                            }
                        }
                    }}
                />
            </Form.Item>
            <Form.Item label="执行脚本">
                <Select mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="请选择执行的脚本"
                    value={info.actions}
                    onChange={(e) => {
                        info.actions = e;
                        if (onChange) {
                            onChange(info)
                        }
                    }}>
                        {
                            actions.list.map((action, index) => {
                                return <Select.OptGroup key={index} label={`---${action.group}---`}>
                                    {
                                        action.children.map(a => <Select.Option key={a.key}>{a.value}</Select.Option>)
                                    }
                                </Select.OptGroup>
                            })
                        }
                </Select>
            </Form.Item>
            <Form.Item label="执行脚本次数">
                <SliderInput
                    min={20}
                    max={200}
                    marks={{ 80: '80',  160: '160'}}
                    values={info.scripts}
                    onChange={(values) => {
                        info.scripts = values;
                        if (onChange) {
                            onChange(info)
                        }
                    }}
                />
            </Form.Item>
            <Form.Item label="操作间隔（秒）">
                <SliderInput
                    min={5}
                    max={30}
                    marks={{ 5: '5',  30: '30'}}
                    values={info.operationInterval}
                    onChange={(values) => {
                        info.operationInterval = values;
                        if (onChange) {
                            onChange(info)
                        }
                    }}
                />
            </Form.Item>
        </Form>
    }
}

NewForm = Form.create({ name: 'practice' })(NewForm)
export default NewForm;