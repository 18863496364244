/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Menu, Input, Button, Descriptions, Row, Col, Icon, Dropdown, Collapse, Form, Modal } from 'antd';

import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../util/tableComponent';
import { callStateTags } from '../../component/common/common';
import { deviceTypeMap, stateMap } from '../../defined/memberDevice';

class MemberCT extends Component{
    constructor(props){
        super(props);
        this.tblRowSelectOnClick = this.tblRowSelectOnClick.bind(this);
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainSearchOnClick = this.mainSearchOnClick.bind(this);
        this.mainSearchOnChange = this.mainSearchOnChange.bind(this);
        this.mainDeviceTypeOnClick = this.mainDeviceTypeOnClick.bind(this);
        this.mainStateTypeOnClick = this.mainStateTypeOnClick.bind(this);
        
        let pag = hoTableInitPaginationInfo();
        pag.pagination.sort = {
            field: 'UserId',
            order: 1
        }
        
        this.state = {
            paginationInfo: pag,
            search: {
                text: '',
                deviceType: 'ALL',
                deviceTypeMap: deviceTypeMap,
                stateType: 'ALL',
                stateMap: stateMap,
            },
        }
    }

    tblRowSelectOnClick(e, record) {
        let { onSelect } = this.props;
        onSelect && onSelect(record)
    }

    searchOnChange(search, pag) {
        let { mode, apiAddressBook } = this.props;
        let nickNameFilters = [];
        let sipNumFilters = [];

        if (search.text?.length > 0) {
            nickNameFilters.push(['NickName', 'like', search.text]);
            sipNumFilters.push(['SipNum', 'like', search.text]);
            if (mode === 'aim') {
                nickNameFilters.push(['Lng', '>', 0])
                nickNameFilters.push(['Lat', '>', 0])

                sipNumFilters.push(['Lng', '>', 0])
                sipNumFilters.push(['Lat', '>', 0])
            }

            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
                sipNumFilters.push(['AppState', 'equal', search.stateType])
            }

            if (search.deviceType !== 'ALL') {
                nickNameFilters.push(['DeviceType', 'equal', search.deviceType])
                sipNumFilters.push(['DeviceType', 'equal', search.deviceType])
            }
            
        } else {
            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
            }

            if (search.deviceType !== 'ALL') {
                nickNameFilters.push(['DeviceType', 'equal', search.deviceType])
            }

            if (mode === 'aim') {
                nickNameFilters.push(['Lng', '>', 0])
                nickNameFilters.push(['Lat', '>', 0])
            }
        }

        let filters = [];
        if (nickNameFilters.length > 0) {
            filters.push(nickNameFilters)
        }
        if (sipNumFilters.length > 0) {
            filters.push(sipNumFilters)
        }

        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = filters;
        paginationInfo.pagination.sort = {
            field: 'UserId',
            order: 1
        }
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            loading: true,
        }, () => {
            apiAddressBook(paginationInfo.pagination,
                (rsp) => {
                    this.setState({
                        total: rsp.TotalNum,
                        records: rsp.RecordList,
                        loading: false,
                    })
                },
                () => {
                    this.setState({
                        total: 0,
                        records: [],
                        loading: false,
                    })
                }
            )
        })
        
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e) {
        e.stopPropagation();
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text){
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '设备类型'切换
    mainDeviceTypeOnClick(e) {
        let {search, paginationInfo} = this.state;
        search.deviceType = e;
        this.searchOnChange(search, paginationInfo)
    }

    // '在线状态'切换
    mainStateTypeOnClick(e) {
        let {search, paginationInfo} = this.state;
        search.stateType = e;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let { search, paginationInfo } = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    mkSearch() {
        let { search } = this.state;

        const deviceOptions = search.deviceTypeMap.find((e) => search.deviceType === e.value);
        const deviceMenu = (
            <Menu onClick={(e) => this.mainDeviceTypeOnClick(e.key)}>
                {search.deviceTypeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        const stateOptions = search.stateMap.find((e) => search.stateType === e.value);
        const stateMenu = (
            <Menu onClick={(e) => this.mainStateTypeOnClick(e.key)}>
                {search.stateMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        return <Row key="search" className="search" type="flex">
            <Col span={18} style={{ display: 'flex' }}>
                <Dropdown overlay={stateMenu}>
                    <Button className={"type1 " + search.stateType} type="primary" size="large">
                        {stateOptions?.title} <Icon type="down" />
                    </Button>
                </Dropdown>
                <Dropdown overlay={deviceMenu}>
                    <Button className="type2" type="primary" size="large">
                        {deviceOptions?.title} <Icon type="down" />
                    </Button>
                </Dropdown>
                <Input.Search
                    size="large"
                    className="input"
                    placeholder="搜索设备名称、SIP号、License ID"
                    enterButton
                    allowClear={true}
                    onSearch={this.mainSearchOnClick}
                    onChange={this.mainSearchOnChange}
                    />
            </Col>
            <Col span={6}>

            </Col>
        </Row>
    }

    mkTable(){
        let { search, loading, records, paginationInfo, total } = this.state;
        let { mode } = this.props;
        const columns = [
            hoTableColumn('状态', 'AppState', 'str', false, false, '12%', {
                render: (text, record) => hoTableColumnTagButton(callStateTags(text, record)),
            }),
            hoTableColumn('设备(用户)名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('类型', 'DeviceType', 'str', false, false, '7%', {
                render: (text, record) => deviceTypeMap.find(item => item.value === text)?.title || text,
            }),
            hoTableColumn('SIP号（License ID）', 'SipNum', 'str', false, false, '15%'),
            hoTableColumnAction('操作', 'operation', [{ title: mode === 'put' ? '放置' : '定位', onClick: this.tblRowSelectOnClick }], '15%')
        ];

        return <HoTable
            key="table"
            columns={columns}
            data={records}
            rowKey={'UserId'}
            loading={loading}
            onColumnChange={(pag) => {
                this.searchOnChange(search, pag)
            }}
            total={total}
            // bordered={false}
            // extra={{size: 'small'}}
        />
    }

    // clear handle
    componentWillUnmount() {
        // 防止内存溢出
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    render() {
        let { className } = this.props;
        let searchComponent = this.mkSearch();
        let tableComponent = this.mkTable();
        return (<div className={"ho-member " + className}>
            {searchComponent}
            {tableComponent}
        </div>);
    }
}

const mapState = (state) => ({
});

export default connect(
    mapState, 
    null
)(MemberCT);

