/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Menu} from 'antd';
import { HoIconFont } from '../util/hoComponent';
import { config, switchEnvironment, version } from '../util/version';
import { initMenu, menus } from './menu';
import { getUrlParam } from '../util/logic';

import './layout.less'

class HeaderNav extends Component {
    constructor(props){
        super(props);

        this.menuOnClick = this.menuOnClick.bind(this);
        this.menuOnChange = this.menuOnChange.bind(this);

        this.subMenuComponent = this.subMenuComponent.bind(this);

        this.state = {

        }

    }

    menuOnChange(keyOrUrl = undefined, urlParam = "", state = undefined, inner = true, blank = false) {

        let { history } = this.props;
        if (keyOrUrl === undefined) {
            history.push('/')
            return true;
        }

        if (inner) {
            if (keyOrUrl.startsWith("/")){
                keyOrUrl = keyOrUrl.substr(1)
            } 
            if (keyOrUrl.endsWith("/")) {
                keyOrUrl = keyOrUrl.substr(0, keyOrUrl.length - 1)
            }
            if (urlParam.startsWith("/")) {
                urlParam = urlParam.substr(1)
            }
            let path = `/conference/${keyOrUrl}/${urlParam}`
            if (!keyOrUrl) {
                path = "/conference/"
            }
            if (!blank) { 
                history.push({
                    pathname: path,
                    state: state
                })
                let selectedKeys = [];
                menus.forEach(menu => {
                    // eslint-disable-next-line no-unused-expressions
                    menu?.children?.forEach(child => {
                        if (keyOrUrl === child.key) {
                            selectedKeys.push(keyOrUrl)
                        } else if (keyOrUrl.indexOf(child.key) === 0) {
                            selectedKeys.push(child.key)
                        }
                    })
                })
                if (selectedKeys.length === 0) {
                    selectedKeys.push(keyOrUrl)
                }

                this.setState({
                    selectedKeys: selectedKeys,
                })
            } else {
                window.open(path, "_blank");
            }
        } else {
            if (!blank) {
                window.open(keyOrUrl, "_self")
            } else {
                window.open(keyOrUrl, "_blank")
            }
        }
        
    }

    menuOnClick({key, keyPath, item, domEvent}){
        if (key === "devops/monitor/zabbix") {
            window.open(config.host.zabbix + "/index.php", "_blank")
        } else if (key === "devops/monitor/fullscreen") {
            window.open(`/conference/monitor?env=${version.environment}`, "_blank")
        } else if (key === "ctrl/ads") {
            window.open(`/conference/ads?env=${version.environment}`, "_blank")
        } else {
            window.goToMenu(key);
        }
    }

    componentDidMount() {

        let env = getUrlParam("env", window.location?.search) || window.localStorage?.getItem("environment") || (version.isInternal() ? "internal" : "production");
        switchEnvironment(env)

        let menuKey = 'member';
        let pathname = window.location.pathname;
        let menus = initMenu(this.props.reqUserInfo?.user.role.type);

        window.goToMenu = this.menuOnChange;

        if (pathname.startsWith('/conference')) {
            menuKey = pathname.substr('/conference'.length)
        }
        if (menuKey.startsWith("/")){
            menuKey = menuKey.substr(1)
        } 
        if (menuKey.endsWith('/')) {
            menuKey = menuKey.substr(0, menuKey.length - 1)
        }


        let menuKeys = menuKey.split('/');
        let matchLength = 0
        menus.forEach(menu => {
            menu.children.forEach(item => {
                let keys = item.key.split('/')
                // console.log(item.title, keys.length, keys, menuKeys)
                if (keys.slice(0, keys.length).toString() === menuKeys.slice(0, keys.length).toString() && keys.length > matchLength) {
                    matchLength = keys.length
                    menuKey = keys.join('/')
                }
            })
        })

        this.setState({
            selectedKeys: [menuKey],
        })
    }

    subMenuComponent(menus) {
        return menus.map(menu => {
            return <Menu.SubMenu
                key={menu.key}
                title={<div onClick={(e) => this.menuOnClick({ key: menu.key })}>
                    <HoIconFont type={menu.icon} /><span className="menu-title">{menu.title}</span>
                </div>}>
                {
                    menu.children?.map(item => {
                        if (item.children) {
                            return this.subMenuComponent([item])
                        } else {
                            return <Menu.Item key={item.key} >
                                <HoIconFont style={{marginBottom: '1px'}} type={item.icon}/><span>{item.title}</span>
                            </Menu.Item>
                        }
                    })
                }
            </Menu.SubMenu>
        })
    }

    render(){

        // const menuComponent = this.mkMenu();
        
        return <Menu
            className="menu"
            theme="dark"
            mode="horizontal"
            onClick={this.menuOnClick}
            defaultSelectedKeys={this.state.selectedKeys}
            selectedKeys={this.state.selectedKeys}
            >
                {this.subMenuComponent(menus)}
        </Menu>

    }
}

export default connect(
    null, 
    null
)(HeaderNav);