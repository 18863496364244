/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import './loading1.css';
import './loading2.css';

class Loading1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
        }
    }

    render() {
        return <div className="loading1">
            <span></span>
            <span></span>
            <span></span>
        </div>
    }
}

class Loading2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: null,
        }
    }

    render() {
        return <div class="loading2">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    }
}

export {Loading1, Loading2};