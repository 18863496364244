/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../util/tableComponent";
import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export function apiMemberSetLocation(props, member, successHd = undefined, errorHd = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/user/setLocation'
    const msgBody = {
        SipNum: member.SipNum,
        Lng: parseFloat(member.Lng),
        Lat: parseFloat(member.Lat),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "setLocation", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "setLocation", successHd, errorHd))
    );
}