/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { netRequestThunk } from '../../../util/netReqRD';

const ACT_LIST_BLACKLIST = 'ACT_LIST_BLACKLIST';
const ACT_CLEAN_BLACKLIST = 'ACT_CLEAN_BLACKLIST';

export const ACT_NET_BLACKLIST_LIST = 'listBlacklist';
export const ACT_NET_BLACKLIST_ADD = 'addBlacklist';
export const ACT_NET_BLACKLIST_DELETE = 'deleteBlacklist';
export const ACT_NET_BLACKLIST_QUERY = 'queryBlacklist';

// reducer handle
const blacklistState = {
    data: undefined, 
}

export const blacklist_reducer = (state=blacklistState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_BLACKLIST:{
            return {
                ...state,
                data: action.data
            };
        }

        case ACT_CLEAN_BLACKLIST:
            return {
                data: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const listBlacklistData = (state) => {
    return state.blacklist !== undefined ? state.blacklist.data : undefined;
}


export const actBlacklistClear = () => {
    return {
        type: ACT_CLEAN_BLACKLIST,
    }
}

export function listBlacklistDataSuccessHd(dispatch, rspBody, reqBody) {
    // console.log(rspBody);
    dispatch({
        type: ACT_LIST_BLACKLIST, 
        data: {
            records: rspBody.records,
        }, 
    })
}

export function dataListBlacklist(props, serverType, serverId, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/blacklist/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        serverType: serverType,
        serverId: serverId,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_BLACKLIST_LIST, successFun, errorFun));
}

export function dataListBlacklistDelete(props, serverType, serverId, _id, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/blacklist/delete'
    const msgBody = {
        userId: reqUserInfo.user.id,
        serverType: serverType,
        serverId: serverId,
        id: _id,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_BLACKLIST_DELETE, successFun, errorFun));
}
