/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Layout } from 'antd';

import MainRoute from '../router/mainRoute'
import { getUrlParam } from '../util/logic';

class Content extends Component {
    constructor(props){
        super(props)

        this.state = {
            hideHeader: parseInt(getUrlParam("hideHeader", window.location.search)) || 0,
        }
    }

    render(){
        return (<Layout.Content className="ho-content">
            <MainRoute {...this.props}/>
        </Layout.Content>);
    }
}

export default connect(
    null, 
    null
)(Content);