
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React,{Component} from "react";
import moment from 'moment';

export default class Clock extends Component{
    // state 固定的名字 状态 
    constructor(props){
        super(props);
        this.state = {
            data: moment(),
        }
    }
    
    // componentDidMount声明周期函数   表示组件渲染完成后
    componentDidMount(){
        this.timer = setInterval(() => {
            this.setState({//  this.setState固定 更改state中的data值
                 data: moment(),
           })  
        }, 1000);
    }

    // 为了防止内存泄漏  清除定时器
    componentWillUnmount(){
        clearInterval(this.timer);
    }

    render(){
        return(
            <span className={this.props.className} onDoubleClick={this.props.onDoubleClick}>
                {this.state.data.format('YYYY-MM-DD HH:mm:ss')}
            </span>
        )
    }
}