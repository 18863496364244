/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import JSEncrypt from "jsencrypt";

// 使用命令生成公钥、私钥
// openssl genrsa -out rsa_private_key.pem 1024
// openssl rsa -in rsa_private_key.pem -pubout -out rsa_public_key.pem
// 公钥
const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7qY7rpCBeN18I0GwuHQZFGVxOB+nw6AwokOhU5+t5EHwWEfN6hWmvA7NRNFH85ncSS3+Z25gk878It3cD1dI0Wgh1iR1nJt0n38JQAGArsGR3a4VkhRVEuz5ygjq6DzWxJcGpqiQsmCr41oFiCbwZjhn+NyyjahuJzzUdlGmukwIDAQAB-----END PUBLIC KEY-----';

// 获取当前时间显示信息
export const getDisplayTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const result = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return result;
}

// 获取指定时间显示信息
export const getFixedDisplayTime = (fixedTime) => {
    const date = new Date(fixedTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const result = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return result;
}

export function getDateDiff(dateTimeStamp) {
    //若你得到的时间格式不是时间戳，可以使用下面的JavaScript函数把字符串转换为时间戳, 本函数的功能相当于JS版的strtotime：
    if (dateTimeStamp === undefined){
        return "";
    }

    let idata = Date.parse(dateTimeStamp.replace(/-/gi,"/"));  //js函数代码：字符串转换为时间
    let now = new Date().getTime();
    let diffValue = now - idata;
    let result = dateTimeStamp + " ";
    if (diffValue >= 0) {
        const monthC = diffValue / (7 * 1000 * 60 * 60 * 24 * 30);
        const weekC = diffValue / (7 * 1000 * 60 * 60 * 24);
        const dayC = diffValue / (1000 * 60 * 60 * 24);
        const hourC = diffValue / (1000 * 60 * 60);
        const minC = diffValue / (1000 * 60);
        
        if (monthC >= 1) {
            result += "(" + parseInt(monthC) + "个月前)";
        }else if (weekC >= 1) {
            result += "(" + parseInt(weekC) + "周前)";
        }else if (dayC >= 1) {
            result += "(" + parseInt(dayC) + "天前)";
        }else if (hourC >= 1) {
            result += "(" + parseInt(hourC) + "小时前)";
        }else if (minC >= 1) {
            result += "(" + parseInt(minC) + "分钟前)";
        }else{
            result += "(刚刚)";
        } 
    }
     
    return result;
}

export function getTimeDesc(value, base = 'second') {
    let day = undefined;
    let hour = undefined;
    let minute = undefined;
    let second = undefined;
    switch(base){
        case 'second':
            day = Math.floor(value / (3600 * 24));
            hour = Math.floor((value - day * 3600 * 24) / 3600);
            minute = Math.floor((value - day * 3600 * 24 - hour * 3600) / 60);
            second = value % 60;
            break;
        case 'minute':
            day = Math.floor(value / (60 * 24));
            hour = Math.floor((value - day * 60 * 24) / 60);
            minute = Math.floor(value - day * 60 * 24 - hour * 60);
            break;
        default:
            break;
    }
    
    const content = (day ? `${day}天` : "") + (hour ? `${hour}小时` : "") + (minute ? `${minute}分` : "") + (second ? `${second}秒` : "")
    return content || "无";
}

export function getTimeRangeDesc(from, to) {
    if ((from === "2200-01-01 00:00:00" || from === "") && (to === "2200-01-01 00:00:00" || to === "")) {
        return "无"
    }else if ((from === "2200-01-01 00:00:00" || from === "") && to !== "2200-01-01 00:00:00") {
        return `- 至 ${to}`
    }else if (from !== "2200-01-01 00:00:00" && (to === "2200-01-01 00:00:00" || to === "")) {
        return `${from} 至 -`
    }else{
        return `${from} 至 ${to}`
    }
}

export function getNetWorkDesc(value, unit="bps") {
    let gbps = value / (1024 * 1024 * 1024)
    let mbps = value / (1024 * 1024)
    let kbps = value / (1024)
    if (gbps >= 1) {
        return `${gbps.toFixed(2)} G${unit}`
    } else if (mbps >= 1) {
        return `${mbps.toFixed(2)} M${unit}`
    } else if (kbps >= 1) {
        return `${kbps.toFixed(2)} K${unit}`
    } else {
        return `${value} ${unit}`
    }
}

export function getDateRemaining(dateTimeStamp) {

    let idata = Date.parse(dateTimeStamp.replace(/-/gi,"/"));  //js函数代码：字符串转换为时间
    let now = new Date().getTime();

    let diff = idata - now;
    let flag = diff >= 0;
    let day = parseInt((flag ? diff : -diff) / (1000 * 60 * 60 * 24));
    day+=1
    let date = flag ? `剩余${day}天` : `已超期${day}天`;
    
    return {date: date, flag: flag};
}

export function formatValidTime(t, validValue=""){
    return (t === undefined || t === '1970-01-01 00:00:00' || t === '1000-01-01 00:00:00' || t === '2200-01-01 00:00:00') ? validValue : t;
}

export function isValidTime(t){
    return (t === undefined || t === '1970-01-01 00:00:00' || t === '1000-01-01 00:00:00' || t === '2200-01-01 00:00:00');
}


export function encrypt(str){
    //使用公钥加密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.encrypt(str) || "";
}

export function decrypt(str){
    //使用公钥解密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.decrypt(str) || "";
}


export function getUrlParam(name, str) {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = (str && str.substr(1).match(reg)) || null;
    if (r != null) return  decodeURIComponent(r[2]); return null;
}

export function randomString(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZ2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = chars.length;
    let str = '';
    for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}

export function randomNumber(len) {
    len = len || 32;
    let chars = '0123456789';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = chars.length;
    let str = '';
    for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}

export function randomFind(array, compFun) {
    let newArray = []
    array.forEach(element => {
        if (compFun(element)) {
            newArray.push(element)
        }
    });
    return newArray[Math.floor(Math.random() * newArray.length)];
}


// 工具方法
export function formatJson(json, options) {
    var reg = null,
        formatted = '',
        pad = 0,
        PADDING = '    '; // one can also use '\t' or a different number of spaces
    if (!json) {
        return "";
    }
    // optional settings
    options = options || {};
    // remove newline where '{' or '[' follows ':'
    options.newlineAfterColonIfBeforeBraceOrBracket = (options.newlineAfterColonIfBeforeBraceOrBracket === true) ? true : false;
    // use a space after a colon
    options.spaceAfterColon = (options.spaceAfterColon === false) ? false : true;

    // begin formatting...

    // make sure we start with the JSON as a string
    if (typeof json !== 'string') {
        json = JSON.stringify(json);
    }
    // parse and stringify in order to remove extra whitespace
    json = JSON.parse(json);
    json = JSON.stringify(json);

    // add newline before and after curly braces
    reg = /([\{\}])/g;
    json = json.replace(reg, '\r\n$1\r\n');

    // add newline before and after square brackets
    reg = /([\[\]])/g;
    json = json.replace(reg, '\r\n$1\r\n');

    // add newline after comma
    reg = /(\,)/g;
    json = json.replace(reg, '$1\r\n');

    // remove multiple newlines
    reg = /(\r\n\r\n)/g;
    json = json.replace(reg, '\r\n');

    // remove newlines before commas
    reg = /\r\n\,/g;
    json = json.replace(reg, ',');

    // optional formatting...
    if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
        reg = /\:\r\n\{/g;
        json = json.replace(reg, ':{');
        reg = /\:\r\n\[/g;
        json = json.replace(reg, ':[');
    }
    if (options.spaceAfterColon) {
        reg = /\:/g;
        json = json.replace(reg, ': ');
    }

    json.split('\r\n').forEach(function(node, index) {
        var i = 0,
            indent = 0,
            padding = '';

        if (node.match(/\{$/) || node.match(/\[$/)) {
            indent = 1;
        } else if (node.match(/\}/) || node.match(/\]/)) {
            if (pad !== 0) {
                pad -= 1;
            }
        } else {
            indent = 0;
        }

        for (i = 0; i < pad; i++) {
            padding += PADDING;
        }
        formatted += padding + node + '\r\n';
        pad += indent;
    });
    return formatted;
};

export function printObject(o, sp = '\r\n', root = '', indent = 0) {
    return formatJson(o);
}
