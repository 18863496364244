/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Result} from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';

import { actNetRequestClear, getNetRequestStatus, netRequestThunk } from '../../../util/netReqRD';
import { 
    listChannelData, ACT_NET_CHANNEL_LIST, dataListChannel, listChannelDataSuccessHd,
} from './channelRD';
import {getLoginUserInfo} from '../../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoRefreshButton,
} from '../../../util/hoComponent';
import { 
    HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton 
} from '../../../util/tableComponent';


class ChannelCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblRowDetailOnClick = this.tblRowDetailOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
      
        const columns = [
            hoTableColumnTag('状态', 'state', 
                (text, record) => {
                    let tags = [];
                    switch(record.state){
                        case 'setup':
                            tags.push({color: gold[3], tag:'正在呼叫'});
                            break;
                        case 'stable':
                            tags.push({color: blue[3], tag:'通话中'});
                            break;
                        case 'completed':
                        case 'completed2': // 此为强制结束（被后台任务与freeswitch数据库对比得出的不正常呼叫）
                            if (!record.answerInfo || !record.answerInfo.answer) {
                                tags.push({color: red[3], tag: '没有接通'});
                                tags.push({color: red[3], tag: `${record.hangupInfo.hangup.cause}(${record.hangupInfo.sip.termCause})`});
                            }else{
                                tags.push({color: grey[3], tag:'已结束'});
                            }
                            break;
                        default:
                            break;
                    }
                    
                    return hoTableColumnTagButton(tags, record);
                }, 
            '8%', {nolight: true, filters: [{text:'正在呼叫', value: 'setup'}, {text:'通话中', value: 'stable'}, {text:'已结束', value: 'completed'}]}),
            hoTableColumn('UUID', 'uuid', 'str', false, true, '25%'),
            hoTableColumn('主叫SIP号', 'fromNumber', 'str', false, true, '10%'),
            hoTableColumn('被叫SIP号', 'toNumber', 'str', false, true, '10%'),
            hoTableColumnDate('呼叫时间', 'createTime', true, '15%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '详情', onClick: this.tblRowDetailOnClick},
            ], '12%'),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
        }
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        dataListChannel(this.props, paginationInfo, listChannelDataSuccessHd);
        this.setState({
            paginationInfo: paginationInfo,
        });
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
        this.tblRowDetailOnClick(e, record)
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record){
        e.stopPropagation();
        window.goToMenu('devops/analysis/channel', `detail/${record.uuid}`, {channel: record})
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(){
        const { paginationInfo } = this.state;
        dataListChannel(this.props, paginationInfo, listChannelDataSuccessHd);
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb
            items={[{title: "数据分析", url: "devops/analysis"}, {title: "呼叫"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </div>);
        return {title: <span>呼叫</span>, buttons: buttons}; 
    }

    mkTable(){
        let { columns } = this.state;
        const { data, getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        // console.log("mkTable", data);

        return (<HoTable
            columns={columns}
            data={records}
            rowKey={'uuid'}
            loading={getStatus === 1}
            onColumnChange={this.tblColOnChange}
            onRowDoubleClick={this.tblRowOnClick}
            total={total}
        />);
    }

    // clear handle
    componentWillUnmount(){
        let {dispatch} = this.props;
        dispatch(actNetRequestClear(ACT_NET_CHANNEL_LIST));
    }

    componentDidMount(){
        const { paginationInfo } = this.state;
        dataListChannel(this.props, paginationInfo, listChannelDataSuccessHd);
    }

    render(){
        const {reqUserInfo} = this.props;

        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();

        return (<div>
            {breadcrumbComponent}
            <HoCard title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_CHANNEL_LIST), 
    data: listChannelData(state),

});


export default connect(
    mapState, 
    null
)(ChannelCT);

