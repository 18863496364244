/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import * as echarts from 'echarts';
import moment from 'moment';
import { Menu, Input, Button, Descriptions, Row, Col, Icon, Dropdown, Collapse, Form, Modal } from 'antd';

import { getNetRequestStatus } from '../../util/netReqRD';
import { 
    dataListMember, dataModMember,
    listMemberDataSuccessHd, listMemberData, ACT_NET_MEMBER_LIST, dataPullMemberLog, dataGetMemberLog,
} from './memberRD';
import {getLoginUserInfo} from '../login/loginRD'
import {
    hoValidateEmptyFormat, 
} from '../../util/formComponent';
import { 
    HoCard, HoBreadcrumb, hoMessage, HoRefreshButton, HoAlertDialog, HoIconFont,
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../util/tableComponent';
import { callStateTags } from '../../component/common/common';
import { formatValidTime } from '../../util/logic';
import { deviceTypeMap, stateMap } from '../../defined/memberDevice';
import { dataTerminalLicenseCtrlList } from '../../api/terminalLicence';
import { remainingDate } from '../devops/setting/license/terminal/common';
import { dataDauList2 } from '../../api/statistics';

import './member.less'

class MemberCT extends Component{
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 额外操作
        this.initData = this.initData.bind(this);
        this.goToDetail = this.goToDetail.bind(this);
        
        // 数据处理回调
        this.dataCommonSuccessHd = this.dataCommonSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblRowOnClick = this.tblRowOnClick.bind(this);
        this.tblRowModOnClick = this.tblRowModOnClick.bind(this);
        this.tblRowPullLogOnClick = this.tblRowPullLogOnClick.bind(this);
        this.tblRowLicenseOnClick = this.tblRowLicenseOnClick.bind(this);
        this.tblRowDetailOnClick = this.tblRowDetailOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainSearchOnClick = this.mainSearchOnClick.bind(this);
        this.mainSearchOnChange = this.mainSearchOnChange.bind(this);
        this.mainDeviceTypeOnClick = this.mainDeviceTypeOnClick.bind(this);
        this.mainStateTypeOnClick = this.mainStateTypeOnClick.bind(this);
        
        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);
        
        /********************************** 子页面(修改) ***********************************/
        // 子页面回调
        this.pageParamOnChange = this.pageParamOnChange.bind(this);
        
        // 子页面按钮
        this.modPageOkOnClick = this.modPageOkOnClick.bind(this);
        this.pageCancelOnClick = this.pageCancelOnClick.bind(this);

        // 子页面
        this.mkModModal = this.mkModModal.bind(this);
        
        const columns = [
            hoTableColumn('状态', 'AppState', 'str', false, false, '12%', {
                render: (text, record) => hoTableColumnTagButton(callStateTags(text, record)),
            }),
            hoTableColumn('设备(用户)名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('类型', 'DeviceType', 'str', false, false, '7%', {
                render: (text, record) => deviceTypeMap.find(item => item.value === text)?.title || text,
            }),
            hoTableColumn('License剩余时间', 'License', 'str', false, false, '10%', {
                render: (text, record) => {
                    if (!record.License?.EndTime) {
                        return "无"
                    } else {
                        return remainingDate(record.License?.EndTime)
                    }
                },
            }),
            hoTableColumn('SIP号（License ID）', 'SipNum', 'str', false, false, '15%'),
            hoTableColumn('版本', 'Version', 'str', false, false, '5%'),
            hoTableColumnDate('最近在线时间', 'OnlineTime', false, '10%', {
                render: (text, record) => {
                    if (record.AppState === 'online' && record.SipState === 'online') {
                        return moment().format("YYYY-MM-DD HH:mm:ss")
                    } else {
                        return formatValidTime(record.OnlineTime);
                    }
                },
            }),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [{ title: '编辑', onClick: this.tblRowModOnClick }];
                if (deviceTypeMap.find(item => item?.caps?.pullLog && item.value === record.DeviceType)) {
                    actions.push({ title: '获取日志', onClick: this.tblRowPullLogOnClick });
                }
                if (record.License && this.props.reqUserInfo?.user?.role?.type === "superadmin") {
                    actions.push({ title: '查看License', onClick: this.tblRowLicenseOnClick });
                }

                actions.push({ title: '详情', onClick: this.tblRowDetailOnClick });
                return hoTableColumnActionButton(actions, record);
            }, '15%')
        ];

        let pag = hoTableInitPaginationInfo();
        pag.pagination.sort = {
            field: 'UserId',
            order: 1
        }
        
        this.state = {
            columns: columns,
            paginationInfo: pag,
            search: {
                text: '',
                deviceType: 'ALL',
                deviceTypeMap: deviceTypeMap,
                stateType: 'online',
                stateMap: stateMap,
            },
            collapseActiveKey: "",
            ...this.initData(),
        }
    }

    initData() {
        return {
            modPageVisible: false,
            modMemberInfo: {
                NickName: '',
            },
            pageValidate:{
                NickName: {validateStatus:"success"},
            },
        }
    }

    chartShowLoading(chart) {
        let opt = {
            text: '加载中...',
            color: '#c23531',
            textColor: '#fff',
            maskColor: 'rgba(255, 255, 255, 0)'
        }
        chart.showLoading('default', opt);
    }

    chartHideLoading(chart) {
        chart.hideLoading();
    }

    initDeviceStatusChart(func) {
        let { chartDeviceStatus } = this.state;
        let dom = document.getElementById('summary-device-status');
        if (!dom || chartDeviceStatus) {
            return;
        }
        chartDeviceStatus = echarts.init(dom, 'light');
        let option = {
            title: {
                text: '设备在线率',
                left: 'center',
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                top: 128,
                inactiveColor: '#d9d9d9',
            },
            color: ['#3ba272', '#5470c6', '#fac858', '#ee6666', '#73c0de', '#fc8452', '#91cc75', '#9a60b4', '#ea7ccc'],
            series: [{
                type: 'pie',
                startAngle: 135,
                radius: ['0px', '60px'],
                center: ['50%', '50%'],
                top: 0,
                label: {
                    show: true,
                    position: 'outer',
                    bleedMargin: 5,
                    formatter: '{b} {c} ({d}%)',
                },
                labelLine: {
                    show: true
                },
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.6875)'
                    }
                }
            }]
        };
        chartDeviceStatus.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartDeviceStatus.resize();
            })
        });

        this.setState({
            chartDeviceStatus: chartDeviceStatus,
        }, () => {
            func && func();
        })

        return chartDeviceStatus;
    }

    initDeviceTypeChart(func) {
        let { chartDeviceType } = this.state;
        let dom = document.getElementById('summary-device-type');
        if (!dom || chartDeviceType) {
            return;
        }
        chartDeviceType = echarts.init(dom, 'light');
        let option = {
            title: {
                text: '设备类型分布',
                left: 'center',
            },
            legend: {
                orient: 'vertical',
                top: 48,
                left: 'left',
                inactiveColor: '#d9d9d9',
            },
            color: ['#91cc75', '#5470c6', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
            series: [{
                type: 'pie',
                startAngle: 135,
                radius: ['0px', '60px'],
                center: ['50%', '50%'],
                label: {
                    show: true,
                    position: 'outer',
                    bleedMargin: 5,
                    formatter: '{b} {c} ({d}%)',
                },
                labelLine: {
                    show: true
                },
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.6875)'
                    }
                }
            }]
        };
        chartDeviceType.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartDeviceType.resize();
            })
        });

        this.setState({
            chartDeviceType: chartDeviceType,
        }, () => {
            func && func()
        })

        return chartDeviceType;
    }

    initDeviceDauChart(func) {
        let { chartDeviceDau } = this.state;
        let dom = document.getElementById('summary-device-dau');
        if (!dom || chartDeviceDau) {
            return;
        }
        chartDeviceDau = echarts.init(dom, 'light');
        let option = {
            title: {
                text: '设备日活',
                left: 'center',
            },
            color: ['#5470c6', '#3ba272', '#fac858', '#ee6666', '#73c0de', '#fc8452', '#91cc75', '#9a60b4', '#ea7ccc'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        alignWithLabel: true,
                    },
                    minorTick: {
                        splitNumber: 7,
                    },
                    axisLabel: {
                        interval: 0,
                        // rotate: 45
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '活跃用户数',
                    type: 'bar',
                    barWidth: '60%',
                    data: [],
                    itemStyle: {
                        normal: {
                            label: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#808080'
                                }
                            }
                        }
                    },
                }
            ]
        };
        chartDeviceDau.setOption(option);
        window.addEventListener("resize",function (){
            setTimeout(() => {
                chartDeviceDau.resize();
            })
        });

        this.setState({
            chartDeviceDau: chartDeviceDau,
        }, () => {
            func && func();
        })

        return chartDeviceDau;
    }

    initChart() {
        this.initDeviceStatusChart(() => {
            this.monitorOnTimer()
        });
        this.initDeviceTypeChart(() => {
            this.monitorOnTimer()
        });
        this.initDeviceDauChart(() => {
            this.monitorOnTimer()
        });
    }

    monitorOnTimer() {
        let { chartDeviceStatus, chartDeviceType, chartDeviceDau } = this.state;

        chartDeviceStatus && this.chartShowLoading(chartDeviceStatus);
        chartDeviceType && this.chartShowLoading(chartDeviceType);
        chartDeviceDau && this.chartShowLoading(chartDeviceDau);

        function weekday(day) {
            switch (day) {
                case 0: return "星期一"
                case 1: return "星期二"
                case 2: return "星期三"
                case 3: return "星期四"
                case 4: return "星期五"
                case 5: return "星期六"
                case 6: return "星期天"
                default: return `未知(${day})`
            }
        }

        dataDauList2(this.props,moment().add(-6, 'd').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),
            (_, rsp, req) => {
                if (chartDeviceDau) {
                    let option = chartDeviceDau.getOption();
                    option.xAxis[0].data = rsp.dau.map(d => {
                        let day = moment(d.day);
                        let today = moment();
                        return {
                            value: day.format("YYYY-MM-DD").slice(5) + `\n${weekday(day.weekday())}`,
                            textStyle: {
                                color: today.format("YYYY-MM-DD") === day.format("YYYY-MM-DD") ? '#a90000' : '#000000'
                            }
                        }
                    })
                    option.series[0].data = rsp.dau.map(d => {
                        return {
                            value: d.amount,
                            itemStyle: {
                                color: moment().format("YYYY-MM-DD") === d.day ? '#a90000' : '#5470c6'
                            }
                        }
                    })
                    this.chartHideLoading(chartDeviceDau);
                    chartDeviceDau.setOption(option)
                }
            }
        )

        let pag = hoTableInitPaginationInfo();
        pag.pagination.filters = [[['DeviceType', 'not equal', 'sipp']]];
        pag.pagination.page.size = 1;
        dataListMember(this.props, pag,
            (_, rsp) => {
                pag.pagination.filters = [[['AppState', 'equal', 'online'], ['DeviceType', 'not equal', 'sipp']]];
                dataListMember(this.props, pag,
                    (_, rsp2) => {
                        if (chartDeviceStatus) {
                            let option = chartDeviceStatus.getOption();
                            option.series[0].data = [
                                {value: rsp2.TotalNum, name: '在线'},
                                {value: rsp.TotalNum - rsp2.TotalNum, name: '离线'},
                            ]
                            this.chartHideLoading(chartDeviceStatus);
                            chartDeviceStatus.setOption(option)
                        }
                    }
                )
                
                function getDeviceType(start, end, option, completeHd) {
                    if (start < end) {
                        let item = deviceTypeMap[start]
                        pag.pagination.filters = [[['DeviceType', 'equal', item.value]]];
                        dataListMember(this.props, pag,
                            (_, rsp3) => {
                                option.series[0].data.push({value: rsp3.TotalNum, name: item.title})
                                getDeviceType.bind(this)(start + 1, end, option, completeHd)
                            }
                        )
                    } else {
                        completeHd && completeHd()
                    }
                }

                if (chartDeviceType) {
                    let option = chartDeviceType.getOption();
                    option.series[0].data = []
                    getDeviceType.bind(this)(1, deviceTypeMap.length - 1, option, () => {
                        this.chartHideLoading(chartDeviceType);
                        chartDeviceType.setOption(option)
                    })
                }

            }
        )
    }

    goToDetail(record){
        window.goToMenu('member', `detail/${record.UserId}`, 'member/device')
    }

    searchOnChange(search, pag) {
        let nickNameFilters = [];
        let sipNumFilters = [];

        if (search.text?.length > 0) {
            nickNameFilters.push(['NickName', 'like', search.text]);
            sipNumFilters.push(['SipNum', 'like', search.text]);

            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
                sipNumFilters.push(['AppState', 'equal', search.stateType])
            }

            if (search.deviceType !== 'ALL') {
                nickNameFilters.push(['DeviceType', 'equal', search.deviceType])
                sipNumFilters.push(['DeviceType', 'equal', search.deviceType])
            }
            
        } else {
            if (search.stateType !== 'ALL') {
                nickNameFilters.push(['AppState', 'equal', search.stateType])
            }

            if (search.deviceType !== 'ALL') {
                nickNameFilters.push(['DeviceType', 'equal', search.deviceType])
            }
        }

        let filters = [];
        if (nickNameFilters.length > 0) {
            filters.push(nickNameFilters)
        }
        if (sipNumFilters.length > 0) {
            filters.push(sipNumFilters)
        }

        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = filters;
        paginationInfo.pagination.sort = {
            field: 'UserId',
            order: 1
        }
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
        })
        dataListMember(this.props, paginationInfo,
            (dispatch, rsp, req) => {

                let paginationInfo2 = JSON.parse(JSON.stringify(paginationInfo))
                paginationInfo2.pagination.filters = rsp.UserList?.map(u => [['LicenseId', 'equal', u.SipNum]])
                paginationInfo2.pagination.sort = {}
                paginationInfo2.pagination.page.number = 1;
                dataTerminalLicenseCtrlList(this.props, paginationInfo2,
                    (_, rsp2, req2) => {
                        // eslint-disable-next-line no-unused-expressions
                        rsp.UserList?.forEach(u => {
                            u.License = rsp2.RecordList?.find(l => l.LicenseId === u.SipNum);
                        })
                        listMemberDataSuccessHd(dispatch, rsp, req);
                    },
                    () => {
                        listMemberDataSuccessHd(dispatch, rsp, req);
                    }
                )
            }
        )
    }

    dataCommonSuccessHd(dispatch, rspBody, reqBody) {
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    // '编辑'按钮被点击
    tblRowModOnClick(e, record){
        e.stopPropagation();
        this.setState({
            modPageVisible: true,
            modMemberInfo: Object.assign({}, record),
        });
    }

    // '拉取日志'按钮被点击
    tblRowPullLogOnClick(e, record){
        e.stopPropagation();
        dataGetMemberLog(this.props, record, (dispatch, rsp, req) => {
            this.setState({
                pullLogPageVisible: true,
                pullLogMemberInfo: Object.assign(rsp, record),
            });
        })
    }

    // '查看License'按钮被点击
    tblRowLicenseOnClick(e, record) {
        window.goToMenu('devops/setting/license/terminal', `detail/${record.SipNum}`, 'member/device')
    }

    // 单行被点击
    tblRowOnClick(e, record){
        e.stopPropagation();
        this.goToDetail(record);
    }

    // '详情'按钮被点击
    tblRowDetailOnClick(e, record){
        e.stopPropagation();
        this.goToDetail(record);
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e) {
        e.stopPropagation();
        this.monitorOnTimer();
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    
    // '搜索'按钮被点击
    mainSearchOnClick(text){
        let {search, paginationInfo} = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '设备类型'切换
    mainDeviceTypeOnClick(e) {
        let {search, paginationInfo} = this.state;
        search.deviceType = e;
        this.searchOnChange(search, paginationInfo)
    }

    // '在线状态'切换
    mainStateTypeOnClick(e) {
        let {search, paginationInfo} = this.state;
        search.stateType = e;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    // 生成页面'取消'按钮被点击
    pageCancelOnClick(){
        this.setState({
            modPageVisible: false,
        });
    }

    // 生成页面'确定'按钮被点击
    modPageOkOnClick(){
        const { pageValidate } = this.state;

        // 点击确定前再次检查是否有字段不合法
        if (pageValidate.NickName.validateStatus !== "success"){
            hoMessage({type:'error', msg:'输入有误，请检查'});
            return;
        }

        this.setState({
            modPageVisible: false,
        });
        dataModMember(this.props, this.state.modMemberInfo, (dispatch, rspBody, reqBody) => {
            this.dataCommonSuccessHd(dispatch, rspBody, reqBody);
            this.setState({
                ...this.initData(),
            })
        });
    }


    // 新页面参数变化通知函数
    pageParamOnChange(e, param){
        // console.log("pageParamOnChange", e, param);
        let state = this.state;
        switch(param){
            case 'NickName':
                console.log(e.target.value);
                state['modMemberInfo'][param] = e.target.value;
                break;
                
            default:
                break;
        }

        // 校验设备名称是否为空
        state['pageValidate']['NickName'] = hoValidateEmptyFormat(
            state['modMemberInfo']['NickName'], 
            "设备名称不能为空"
        )

        this.setState({
            ...state,
        })
    }

    // 面包屑
    mkBreadcrumb() {
        return <HoBreadcrumb 
            items={[{title: "用户管理", url: "member"}, {title: "设备用户"}]}
        />
    }

    mkHeaderInfo() {
        let { reqUserInfo } = this.props;
        let title = "设备用户";
        let buttons = (<div>
            { reqUserInfo?.user?.role?.type === "superadmin" ? <Button type="primary" onClick={() => {
                let { collapseActiveKey } = this.state;
                this.setState({
                    collapseActiveKey: collapseActiveKey === "summary" ? "" : "summary"
                }, () => {
                    if (collapseActiveKey !== "summary") {
                        setTimeout(() => {
                            this.initChart();
                        }, 500)
                    }
                })
            }}><HoIconFont type="icon-fenxi" />统计数据</Button> : undefined}
            <HoRefreshButton onClick={this.mainRefreshOnClick} />
        </div>);
        return { title: <span>{title}</span>, buttons: buttons};
    }

    mkModModal(){

        const {modPageVisible, modMemberInfo, pageValidate} = this.state;

        return <Modal
            title="编辑设备"
            visible={modPageVisible}
            width="50%"
            okText="确定"
            onOk={this.modPageOkOnClick}
            cancelText="我再想想"
            onCancel={this.pageCancelOnClick} 
            destroyOnClose={true}
            >
            <Form>
                <Form.Item label="设备名称" {...pageValidate.NickName}>
                    <Input 
                        autoFocus={true}
                        allowClear={true} 
                        placeholder="请填写设备名称(例如: XX会议设备)"
                        value={modMemberInfo?.NickName}
                        onChange={(e) => this.pageParamOnChange(e, 'NickName')} 
                    />
                </Form.Item>
            </Form>
        </Modal>
    }

    mkPullLogModal(){

        const { pullLogPageVisible, pullLogMemberInfo } = this.state;

        let pullState = "无"
        let okText = "获取"
        let okAndLinkText = "获取并跳转"
        switch (pullLogMemberInfo?.PullState) {
            case 0:
                pullState = "未执行"
                break;
            case 1:
                pullState = "正在执行中"
                break;
            case 2:
                pullState = "已完成"
                okText = "再次获取"
                okAndLinkText = "再次获取并跳转"
                break;
            default:
                break;
        }

        return <HoAlertDialog
            title="获取日志"
            visible={pullLogPageVisible}
                footer={[
                <Button key="cancel" type="default" onClick={() => {
                    this.setState({
                        pullLogPageVisible: false,
                    })
                }}>我再想想</Button>,
                <Button key="ok" type="primary" onClick={() => {
                    dataPullMemberLog(this.props, pullLogMemberInfo)
                    this.setState({
                        pullLogPageVisible: false,
                    })
                }}>{okText}</Button>,
                <Button key="ok_and_redirect" type="primary" onClick={() => {
                    dataPullMemberLog(this.props, pullLogMemberInfo)
                    this.setState({
                        pullLogPageVisible: false,
                    })
                    window.goToMenu("devops/tool/log/");
                }}>{okAndLinkText}</Button>,
            ]}
            onCancelClick={() => {
                this.setState({
                    pullLogPageVisible: false,
                })
            }}
        >
            <Descriptions column={1}>
                <Descriptions.Item label="状态">{pullState}</Descriptions.Item>
                <Descriptions.Item label="最近一次执行时间">{formatValidTime(pullLogMemberInfo?.PullTime, "无")}</Descriptions.Item>
            </Descriptions>
        </HoAlertDialog>
    }

    mkSummary() {
        let { collapseActiveKey } = this.state;

        return <Collapse className="summary" activeKey={collapseActiveKey}>
            <Collapse.Panel showArrow={false} key="summary">
                <Row>
                    <Col span={6}><div id="summary-device-status" className="summary-device-status"/></Col>
                    <Col span={10}><div id="summary-device-type" className="summary-device-type"/></Col>
                    <Col span={8}><div id="summary-device-dau" className="summary-device-dau"/></Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    }

    mkSearch() {
        let { search } = this.state;

        const deviceOptions = search.deviceTypeMap.find((e) => search.deviceType === e.value);
        const deviceMenu = (
            <Menu onClick={(e) => this.mainDeviceTypeOnClick(e.key)}>
                {search.deviceTypeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        const stateOptions = search.stateMap.find((e) => search.stateType === e.value);
        const stateMenu = (
            <Menu onClick={(e) => this.mainStateTypeOnClick(e.key)}>
                {search.stateMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        return <QueueAnim type={['left', 'right']}>
            <Row key="search" className="search" type="flex">
                <Col span={12} style={{ display: 'flex' }}>
                    <Dropdown overlay={stateMenu}>
                        <Button className={"type1 " + search.stateType} type="primary" size="large">
                            {stateOptions?.title} <Icon type="down" />
                        </Button>
                    </Dropdown>
                    <Dropdown overlay={deviceMenu}>
                        <Button className="type2" type="primary" size="large">
                            {deviceOptions?.title} <Icon type="down" />
                        </Button>
                    </Dropdown>
                    <Input.Search
                        size="large"
                        className="input"
                        placeholder="搜索设备名称、SIP号、License ID"
                        enterButton
                        allowClear={true}
                        onSearch={this.mainSearchOnClick}
                        onChange={this.mainSearchOnChange}
                        />
                </Col>
                <Col span={12}>
                
                </Col>
            </Row>
        </QueueAnim>
    }

    mkTable(){
        let { search, columns } = this.state;
        const { data, getStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = paginationInfo?.pagination || 0;

        return <HoTable
            key="table"
            columns={columns}
            data={records}
            rowKey={'UserId'}
            loading={getStatus === 1}
            onColumnChange={(pag) => {
                this.searchOnChange(search, pag)
            }}
            total={total}
        />
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount() {
        this.initChart();
        this.searchOnChange(this.state.search, this.state.paginationInfo);
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const { title, buttons } = this.mkHeaderInfo();
        const summaryComponent = this.mkSummary();
        const searchComponent = this.mkSearch();
        const tableComponent = this.mkTable();
        const modComponent = this.mkModModal();
        const pullLogComponent = this.mkPullLogModal();
        
        return (<div>
            {modComponent}
            {pullLogComponent}
            {breadcrumbComponent}
            {/* {statisticsComponent} */}
            <HoCard key="card" title={title} buttons={buttons} >
                <div className="ho-member">
                    {summaryComponent}
                    {searchComponent}
                    {tableComponent}
                </div>
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    getStatus: getNetRequestStatus(state, ACT_NET_MEMBER_LIST), 
    data: listMemberData(state),
});


export default connect(
    mapState, 
    null
)(MemberCT);

