/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { 
    apiVmeetingSdkAppAccountList, apiVmeetingSdkAppAccountGen, apiVmeetingSdkAppAccountDel, apiVmeetingSdkAppAccountMod
} from '../member/memberRD';
import {getLoginUserInfo} from '../login/loginRD'
import {
    HoForm, HoFormRowColInput, HoFormRowColInputNumber
} from '../../util/formComponent';
import { 
    HoCard, HoBreadcrumb, hoMessage, HoBackButton, HoRefreshButton, HoGenButton, HoAlertDialog, HoDeleteAlertDialog,
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../util/tableComponent';

import './vmeetingSdk.less'

class VmeetingSdkAccountCT extends Component{
    constructor(props){
        super(props);

        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainGenOnClick = this.mainGenOnClick.bind(this);

        this.tblRowDelOnClick = this.tblRowDelOnClick.bind(this);
        this.tblRowModOnClick = this.tblRowModOnClick.bind(this);
        this.tblRowActiveOnClick = this.tblRowActiveOnClick.bind(this);
        this.tblRowForbiddenOnClick = this.tblRowForbiddenOnClick.bind(this);

        let pag = hoTableInitPaginationInfo();
        pag.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }

        const columns = [
            hoTableColumn('状态', 'IsActive', 'int', false, false, '7%', {
                render: (text, record) => {
                    let tags = [];
                    switch(record.IsActive){
                        case 1: 
                            tags.push({color: blue[3], tag:'启用'});
                            break;
                        case 0: 
                            tags.push({color: red[3], tag:'禁用'});
                            break;
                        default: 
                            break;
                    }
                    return hoTableColumnTagButton(tags);
                }
            }),
            hoTableColumn('账号', 'Account', 'str', false, false, '15%', {
                render: (text, record) => {
                    return <span><img className="app-account-avatar" alt={record.NickName} src={record.AvatarUrl} /><span>{record.Account}</span></span>
                }
            }),
            hoTableColumn('名称', 'NickName', 'str', false, false, '15%'),
            hoTableColumnDate('创建时间', 'CreateTime', false, '15%'),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [
                    // { title: "编辑", onClick: this.tblRowModOnClick },
                ];
                if (this.props.reqUserInfo?.user?.role?.type === "superadmin") {
                    actions.push({ title: "删除", onClick: this.tblRowDelOnClick })
                }
                if (!record.IsActive) {
                    actions.unshift({ title: "启用", onClick: this.tblRowActiveOnClick })
                } else {
                    actions.unshift({ title: "禁用", onClick: this.tblRowForbiddenOnClick })
                }

                return hoTableColumnActionButton(actions, record);
            }, '15%')
        ];

        this.state = {
            columns: columns,
            paginationInfo: pag,
            id: parseInt(this.props.match.params.id),
            app: this.props.location.state,
        }
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        this.mainRefreshOnClick(paginationInfo)
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(paginationInfo) {
        let { id } = this.state;
        paginationInfo = paginationInfo || this.state.paginationInfo
        this.setState({
            status: 1,
        })
        apiVmeetingSdkAppAccountList(this.props, id, paginationInfo,
            (dispatch, rsp, req) => {
                this.setState({
                    accounts: rsp?.AccountList,
                    total: rsp?.TotalNum,
                    status: 2,
                })
            }
        );
    }

    // '新增'按钮被点击
    mainGenOnClick() {
        this.setState({
            newPageVisible: true,
            data: {
                NickName: '',
            },
        })
    }

    // '编辑'按钮被点击
    tblRowModOnClick(e, record) {
        this.setState({
            modPageVisible: true,
            data: Object.assign({}, record),
        })
    }

    // '删除'按钮被点击
    tblRowDelOnClick(e, record) {
        let { id } = this.state;
        Modal.confirm({
            title: "删除账号",
            content: `是否删除${record.NickName}？`,
            onOk: () => {
                apiVmeetingSdkAppAccountDel(this.props, id, record, () => {
                    this.mainRefreshOnClick();
                })
            },
            okText: "是的",
            cancelText: "我再想想",
            cancelButtonProps: { type: "danger" },
            maskClosable: true,
        })
    }

    // '启用'按钮被点击
    tblRowActiveOnClick(e, record) {
        let { id } = this.state;
        Modal.confirm({
            title: "启用账号",
            content: `是否启用${record.NickName}？`,
            onOk: () => {
                let newRecord = Object.assign({}, record);
                newRecord.IsActive = 1;
                apiVmeetingSdkAppAccountMod(this.props, id, newRecord, () => {
                    this.mainRefreshOnClick();
                })
            },
            okText: "是的",
            cancelText: "我再想想",
            cancelButtonProps: { type: "danger" },
            maskClosable: true,
        })
    }

    // '禁用'按钮被点击
    tblRowForbiddenOnClick(e, record) {
        let { id } = this.state;
        Modal.confirm({
            title: "禁用账号",
            content: `是否禁用${record.NickName}？`,
            onOk: () => {
                let newRecord = Object.assign({}, record);
                newRecord.IsActive = 0;
                apiVmeetingSdkAppAccountMod(this.props, id, newRecord, () => {
                    this.mainRefreshOnClick();
                })
            },
            okText: "是的",
            cancelText: "我再想想",
            cancelButtonProps: { type: "danger" },
            maskClosable: true,
        })
    }

    // 面包屑
    mkBreadcrumb() {
        let { app } = this.state;
        return <HoBreadcrumb 
            items={[{ title: "用户管理", url: "member" }, { title: "SDK用户", url: "member/sdk" }, { title: `${app?.AppName}` }, { title: "账户" }]}
        />
    }

    mkHeaderInfo() {
        let { reqUserInfo } = this.props;
        let title = "账户";
        let buttons = (<div>
            <HoBackButton onClick={(e) => window.goToMenu("member/sdk")} />
            {reqUserInfo?.user?.role?.type === "superadmin" ? <HoGenButton onClick={this.mainGenOnClick}>生成账户</HoGenButton> : undefined}
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick()} />
        </div>);
        return { title: <span>{title}</span>, buttons: buttons};
    }

    mkNewModal(){
        const { id, newPageVisible, data } = this.state;

        return <HoAlertDialog
            title="生成账户"
            visible={newPageVisible}
            onOkClick={() => {
                apiVmeetingSdkAppAccountGen(this.props, id, data,
                    () => {
                        this.setState({
                            newPageVisible: false,
                        }, () => {
                            this.mainRefreshOnClick();
                        })
                    }
                )
            }}
            onCancelClick={() => {
                this.setState({
                    newPageVisible: false,
                })
            }}
        >
            <HoForm >
                <HoFormRowColInputNumber
                    label="账户数"
                    colExtra={{ span: 24 }}
                    inputExtra={{min: 1, max: 1000, step: 100, value: data?.AccountNum, style:{width:'100%'}}}
                    onChange={(e) => {
                        data.AccountNum = e
                        this.setState({
                            data
                        })
                    }}
                />
                <div style={{ textAlign: 'left' }}>
                    <p style={{ color: "white" }}>*</p>
                </div>
            </HoForm>
        </HoAlertDialog>
    
    }
    
    mkModModal(){

        let { modPageVisible, data } = this.state;

        return <HoAlertDialog
            title="编辑App"
            visible={modPageVisible}
            onOkClick={() => {
                apiVmeetingSdkAppAccountMod(this.props, data, () => {
                    this.setState({
                        modPageVisible: false,
                    }, () => {
                        this.mainRefreshOnClick();
                    })
                })
            }}
            onCancelClick={() => {
                this.setState({
                    modPageVisible: false,
                })
            }}
        >
            <HoForm >
                <HoFormRowColInput
                    label="名称"
                    placeholder=""
                    colExtra={{ span:24 }}
                    inputExtra={{value: data?.NickName}}
                    onChange={(e) => {
                        data.NickName = e.target.value
                        this.setState({
                            data
                        })
                    }}
                />
                <HoFormRowColInputNumber
                    label="最大账户数"
                    colExtra={{ span: 24 }}
                    inputExtra={{min: 1, max: 1000, step: 100, value: data?.MaxAccount, style:{width:'100%'}}}
                    onChange={(e) => {
                        data.MaxAccount = e
                        this.setState({
                            data
                        })
                    }}
                />
                <HoFormRowColInputNumber
                    label="最大呼叫数"
                    colExtra={{ span: 24 }}
                    inputExtra={{min: 1, max: 100, step: 10, value: data?.MaxCall, style:{width:'100%'}}}
                    onChange={(e) => {
                        data.MaxCall = e
                        this.setState({
                            data
                        })
                    }}
                />
                <div style={{ textAlign: 'left' }}>
                    <p style={{ color: "white" }}>*</p>
                </div>
            </HoForm>
        </HoAlertDialog>
    
    }

    mkTable() {
        let { columns, accounts, status, total } = this.state;
        return <HoTable
            key="table"
            columns={columns}
            data={accounts}
            rowKey={'Id'}
            loading={status === 1}
            onColumnChange={this.tblColOnChange}
            total={total}
        />
    }

    componentWillUnmount() {
        
    }

    componentDidMount() {
        this.mainRefreshOnClick();
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const { title, buttons } = this.mkHeaderInfo();
        const tableComponent = this.mkTable();

        const newComponent = this.mkNewModal();
        const modComponent = this.mkModModal();

        return (<div>
            {newComponent}
            {modComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(VmeetingSdkAccountCT);

