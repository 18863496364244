/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import {
    Form,
    Input,
    Select,
    Switch,
    Button
} from 'antd';

import './form.css';

let WeightList = [
    { key: "100", description: "极高" },
    { key: "80", description: "高" },
    { key: "60", description: "中" },
    { key: "20", description: "低" },
    { key: "0", description: "极低" },
]
let Weight = {
    list: WeightList,
    getDescription(key) {
        return WeightList.find(e => `${e.key}` === `${key}`)?.description || key;
    }
}

const CustomizedForm = Form.create({
    name: 'signal_form',
    onFieldsChange(props, changedFields) {
      props.onChange(changedFields);
    },
    mapPropsToFields(props) {
      return {
        MsId: Form.createFormField({
            ...props.MsId,
            value: props.MsId?.value,
        }),
        VoiceByPass: Form.createFormField({
            ...props.VoiceByPass,
            value: props.VoiceByPass?.value,
        }),
        MsName: Form.createFormField({
            ...props.MsName,
            value: props.MsName?.value,
        }),
        FrpId: Form.createFormField({
            ...props.FrpId,
            value: props.FrpId?.value,
        }),
        RpcAddr: Form.createFormField({
            ...props.RpcAddr,
            value: props.RpcAddr?.value,
        }),
        RpcPort: Form.createFormField({
            ...props.RpcPort,
            value: props.RpcPort?.value,
        }),
        EslAddr: Form.createFormField({
            ...props.EslAddr,
            value: props.EslAddr?.value,
        }),
        EslPort: Form.createFormField({
            ...props.EslPort,
            value: props.EslPort?.value,
        }),
        LocalAddr: Form.createFormField({
            ...props.LocalAddr,
            value: props.LocalAddr?.value,
        }),
        InternalSignalPort: Form.createFormField({
            ...props.InternalSignalPort,
            value: props.InternalSignalPort?.value,
        }),
        ProxyAddr: Form.createFormField({
            ...props.ProxyAddr,
            value: props.ProxyAddr?.value,
        }),
        ExternalSignalPort: Form.createFormField({
            ...props.ExternalSignalPort,
            value: props.ExternalSignalPort?.value,
        }),
        MaxCall: Form.createFormField({
            ...props.MaxCall,
            value: props.MaxCall?.value,
        }),
        Weight: Form.createFormField({
            ...props.Weight,
            value: props.Weight?.value,
        }),
        Remark: Form.createFormField({
            ...props.Remark,
            value: props.Remark?.value,
        }),
      };
    },
})(props => {
    const { form, frpData } = props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 4 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 20 },
            sm: { span: 20 },
        },
    };

    return (
        <Form>
            <Form.Item label="名称">
                {getFieldDecorator('MsName', {
                    initialValue: '媒体服务器_',
                    rules: [{ required: true, message: '请输入名称', whitespace: false }],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="代理服务器">
                {getFieldDecorator('FrpId', {
                    initialValue: '0',
                    rules: [{ message: '请选择代理服务器', whitespace: false }],
                })(<Select style={{ width: '100%' }}>
                    <Select.Option key="0" value="0">无</Select.Option>
                    {
                        frpData?.map(e => <Select.Option key={`${e.Id}`} value={`${e.Id}`}>{e.FrpName}</Select.Option>)
                    }
                </Select>,
                )}
            </Form.Item>
            <Form.Item className="form-item-addr" required label="控制信令地址">
                <Form.Item className="form-item-addr-ip">
                    {getFieldDecorator('RpcAddr', {
                        rules: [
                            // { pattern: new RegExp(/^([a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?)$/), message: 'ip地址或域名无效' },
                            { required: true, message: '请输入ip地址或域名' }
                        ],
                    })(<Input placeholder="ip地址或域名" />)}
                </Form.Item>
                <Form.Item className="form-item-addr-port">
                    {getFieldDecorator('RpcPort', {
                        normalize: (value) => value ? parseInt(value) : "",
                        initialValue: '8022',
                        rules: [
                            { pattern: new RegExp(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/), message: '端口号无效'},
                            { required: true, message: '端口号无效' }
                        ],
                    })(<Input placeholder="端口号"/>)}
                </Form.Item>
            </Form.Item>
            <Form.Item className="form-item-addr" required label="事件监听地址">
                <Form.Item className="form-item-addr-ip">
                    {getFieldDecorator('EslAddr', {
                        rules: [
                            // { pattern: new RegExp(/^([a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?)$/), message: 'ip地址或域名无效' },
                            { required: true, message: '请输入ip地址或域名' }
                        ],
                    })(<Input placeholder="ip地址或域名" />)}
                </Form.Item>
                <Form.Item className="form-item-addr-port">
                    {getFieldDecorator('EslPort', {
                        normalize: (value) => value ? parseInt(value) : "",
                        initialValue: '8021',
                        rules: [
                            { pattern: new RegExp(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/), message: '端口号无效'},
                            { required: true, message: '端口号无效' }
                        ],
                    })(<Input placeholder="端口号"/>)}
                </Form.Item>
            </Form.Item>
            <Form.Item className="form-item-addr" required label="呼叫信令地址(内部)">
                <Form.Item className="form-item-addr-ip">
                    {getFieldDecorator('LocalAddr', {
                        rules: [
                            // { pattern: new RegExp(/^([a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?)$/), message: 'ip地址或域名无效'},
                            { required: true, message: '请输入ip地址或域名'}
                        ],
                    })(<Input placeholder="ip地址或域名"/>)}
                </Form.Item>
                <Form.Item className="form-item-addr-port">
                    {getFieldDecorator('InternalSignalPort', {
                        normalize: (value) => value ? parseInt(value) : "",
                        initialValue: '6560',
                        rules: [
                            { pattern: new RegExp(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/), message: '端口号无效'},
                            { required: true, message: '端口号无效' }
                        ],
                    })(<Input placeholder="端口号"/>)}
                </Form.Item>
            </Form.Item>
            <Form.Item className="form-item-addr" required label="呼叫信令地址(外部)">
                <Form.Item className="form-item-addr-ip">
                    {getFieldDecorator('ProxyAddr', {
                        rules: [
                            // { pattern: new RegExp(/^([a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?)$/), message: 'ip地址或域名无效' },
                            { required: true, message: '请输入ip地址或域名' }
                        ],
                    })(<Input placeholder="ip地址或域名" />)}
                </Form.Item>
                <Form.Item
                    className="form-item-addr-port">
                    {getFieldDecorator('ExternalSignalPort', {
                        normalize: (value) => value ? parseInt(value) : "",
                        initialValue: '6560',
                        rules: [
                            { pattern: new RegExp(/^([1-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/), message: '端口号无效'},
                            { required: true, message: '端口号无效' }
                        ],
                    })(<Input placeholder="端口号"/>)}
                </Form.Item>
            </Form.Item>
            <Form.Item label="音频透传">
                {getFieldDecorator('VoiceByPass', {
                    valuePropName: 'checked',
                    rules: [
                        { type: 'boolean', message: '', whitespace: false }
                    ],
                })(<Switch />)}
            </Form.Item>
            <Form.Item label="权重">
                {getFieldDecorator('Weight', {
                    initialValue: '0',
                    rules: [{ message: '请选择权重', whitespace: false }],
                })(<Select style={{ width: '100%' }}>
                    {Weight.list.map(e => <Select.Option key={e.key} value={e.key}>{e.description}</Select.Option>)}
                </Select>,
                )}
            </Form.Item>
            <Form.Item label="总容量(方)">
                {getFieldDecorator('MaxCall', {
                    normalize: (value) => value ? parseInt(value) : "",
                    initialValue: '0',
                })(<Input placeholder="0代表不限制"/>)}
            </Form.Item>
            <Form.Item label="备注">
                {getFieldDecorator('Remark')(<Input.TextArea rows={3}/>)}
            </Form.Item>
        </Form>
    );
  });

class OMediaForm extends Component {
    render() {
        const { fields, onChange, frpData } = this.props;
        return (
            <div>
                <CustomizedForm {...fields} frpData={frpData} onChange={onChange}/>
            </div>
        );
    }
}

let MediaForm = Form.create({ name: 'media' })(OMediaForm);

export { MediaForm, Weight }