/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../util/tableComponent";
import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export const dataTerminalLicenseList = (props, paginationInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/fileList'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseList", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseList", successHd, successHd))
    );
}

export const dataTerminalLicenseCtrlList = (props, paginationInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/ctrl/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseCtrlList", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseCtrlList", successHd, successHd))
    );
}

export const dataTerminalLicenseMod = (props, license, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/license/mod'
    const msgBody = {
        ...license,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseMod", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseMod", successHd, successHd))
    );
}

export const dataTerminalLicenseGen = (props, license, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/genLicense'
    const msgBody = {
        ...license,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseGen", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseGen", successHd, successHd))
    );
}

export const dataTerminalLicensePublish = (props, licenseIds, remark, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/issue'
    const msgBody = {
        LicenseIds: licenseIds,
        Remark: remark,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicensePublish", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicensePublish", successHd, successHd))
    );
}

export const dataTerminalLicenseForbidden = (props, licenseIds, forbidden, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/ctrl/forbidden'
    const msgBody = {
        LicenseIds: licenseIds,
        IsForbidden: forbidden ? 1 : 0,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseForbidden", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseForbidden", successHd, successHd))
    );
}

export const dataTerminalLicensePenewal = (props, licenseIds, duration, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/ctrl/duration'
    const msgBody = {
        LicenseIds: licenseIds,
        Duration: duration,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicensePenewal", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicensePenewal", successHd, successHd))
    );
}

export const dataTerminalLicenseDownloadTimesAdd = (props, licenseIds, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/addDownloadTimes'
    const msgBody = {
        LicenseIds: licenseIds,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "terminalLicenseDownloadTimesAdd", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "terminalLicenseDownloadTimesAdd", successHd, successHd))
    );
}

export const dataTerminalLicenseDownload = (props, licenseIds, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/license/batchDownload'
    const msgBody = {
        LicenseIds: licenseIds,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "dataTerminalLicenseDownload", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "dataTerminalLicenseDownload", successHd, successHd))
    );
}
