/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import moment from 'moment';

import { Form, Input, TimePicker, DatePicker, Typography, Modal, Table, Card, Breadcrumb, 
    Row, Col, Icon, InputNumber, Tag, Divider, List, Avatar, Timeline, Collapse,
    message, Select, AutoComplete, Popover, Alert, Tooltip, Pagination, BackTop, Empty, Spin,
    Layout, Upload, Carousel, Switch
} from 'antd';
import { HoRemoteSelect, HoUploadPictureWall, HoUploadAvatar } from './hoComponent';


export function HoForm(props){
    let {layout, children, onSubmit, extra, className, style} = props;
    return (<Form 
        style={style}
        className={className}
        layout={layout} 
        onSubmit={onSubmit}
        {...extra}>
        {children}
    </Form>);
}
HoForm.defaultProps = {
    // layout: "horizontal",
    onSubmit: undefined,
};

export function HoFormItem(props){
    let {children, extra} = props;
    return (<Form.Item {...extra}>{children}</Form.Item>);
}
HoFormItem.defaultProps = {
    extra: {},
};

// 字段校验
export const hoValidateEmptyFormat = (field, help, type="error") => {
    if (field === undefined || field.length === 0){
        return {validateStatus:type, help: help};
    }else{
        return {validateStatus:"success", help: ""};
    }
}

export const hoValidateZeroFormat = (field, help, type="error") => {
    if (field === undefined || field === 0){
        return {validateStatus:type, help: help};
    }else{
        return {validateStatus:"success", help: ""};
    }
}

export const hoValidateCustomFormat = (customFun, help, type="error") => {
    if (customFun()){
        return {validateStatus:type, help: help};
    }else{
        return {validateStatus:"success", help: ""};
    }
}

export function HoFormRowColCommon(props){
    let {label, colExtra, itemExtra, validate, children} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                {children}
            </Form.Item>
        </Col>
    );       
}

HoFormRowColCommon.defaultProps = {
    prefix: undefined,
    colExtra: {span:12}, 
    inputExtra: {}, 
    validate: {validateStatus:"success", help:""},
};

export function HoFormRowColText(props){
    let { label, children, colExtra, itemExtra, textExtra } = props;
    return (
        <Col {...colExtra}>
            <Form.Item  label={label} {...itemExtra}>
                <Typography.Text style={{textAlign:'left'}} {...textExtra}>{children}</Typography.Text>
            </Form.Item>
        </Col>
    );
}
HoFormRowColText.defaultProps = {
    colExtra: {span:12}, 
    textExtra: {}, 
    itemExtra: {  
         labelCol: { xs: { span: 4}, sm: { span: 4}},
        wrapperCol: { xs: { span: 20}, sm: { span: 20}},
        style: {marginBottom:0},
    }
};

export function HoFormRowColParagraph(props) {
    let { label, children, colExtra, itemExtra, paragraphExtra } = props;
    return (
        <Col {...colExtra} >
            <Form.Item style={{marginBottom:0}} label={label} {...itemExtra} >
                <Typography.Paragraph {...paragraphExtra} >{children}</Typography.Paragraph>
            </Form.Item>
        </Col>
    );
}
HoFormRowColParagraph.defaultProps = {
    colExtra: {span:12}, 
    
    paragraphExtra: {copyable:true}
}

export function HoFormRowColInput(props){
    let {id, label, placeholder, autoFocus, allowClear, onChange, colExtra, itemExtra, inputExtra, validate} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <Input 
                    autoFocus={autoFocus}
                    allowClear={allowClear} 
                    placeholder={placeholder} 
                    onChange={onChange} 
                    {...inputExtra} 
                />
            </Form.Item>
        </Col>
    );       
}

HoFormRowColInput.defaultProps = {
    allowClear: true,
    prefix: undefined,
    colExtra: {span:12}, 
    inputExtra: {}, 
    
    validate: {validateStatus:"success", help:""},
    // validate: {validateStatus:"error", help:"SIP号前缀必须是4位纯数字"},
};


export function HoFormRowColSwitch(props){
    let {id, checked, label, autoFocus, onChange, colExtra, itemExtra, switchExtra, validate} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <Switch
                    checked={checked}
                    autoFocus={autoFocus}
                    onChange={onChange} 
                    {...switchExtra} 
                />
            </Form.Item>
        </Col>
    );       
}

HoFormRowColSwitch.defaultProps = {
    prefix: undefined,
    colExtra: {span:12}, 
    inputExtra: {}, 
    
    validate: {validateStatus:"success", help:""},
};

export function HoFormRowColInputPassword(props){
    let {label, placeholder, prefix, onChange, colExtra, itemExtra, inputExtra, validate} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <Input.Password placeholder={placeholder} onChange={onChange} {...inputExtra}/>
            </Form.Item>
        </Col>
    );       
}

HoFormRowColInputPassword.defaultProps = {
    prefix: undefined,
    colExtra: {span:12}, 
    inputExtra: {}, 
    
    validate: {validateStatus:"success", help:""},
    // validate: {validateStatus:"error", help:"密码必须大于4位"},
};


export function HoFormRowColInputNumber(props) {
    let {label, precision, onChange, colExtra, itemExtra, inputExtra} = props;
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra}>
                <InputNumber
                    precision={precision}
                    onChange={onChange}
                    {...inputExtra}
                />
            </Form.Item>
        </Col>
    );
}
HoFormRowColInputNumber.defaultProps = {
    precision: 0,
    onChange: undefined,
    colExtra: {span:12}, 
    inputExtra: {min: 0, step: 1, width: 150},
     
}


export function HoFormRowColDate(props){
    let {label, className, value, showToday, showTime, defaultValue, defaultPickerValue, placeholder, onChange, colExtra, itemExtra, dateExtra, validate} = props;
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <DatePicker 
                    style={{width: '100%'}}
                    className={className}
                    showToday={showToday}
                    showTime={showTime}
                    placeholder={placeholder} 
                    value={value}
                    defaultValue={defaultValue}
                    defaultPickerValue={defaultPickerValue}
                    onChange={onChange}
                    // onOk={(e)=>console.log("ok")}
                    {...dateExtra}>
                </DatePicker>   
            </Form.Item>
        </Col>
    );
}
HoFormRowColDate.defaultProps = {
    placeholder: "", 
    showToday: true,
    showTime: false,
    colExtra: {span:12}, 
    
    dateExtra: {}, 
    validate: {validateStatus:"success", help:""},
};

export function HoFormRowRangeDate(props){
    let {label, className, value, defaultValue, placeholder, onChange, colExtra, itemExtra, dateExtra, validate} = props;
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <DatePicker.RangePicker 
                    className={className}
                    placeholder={placeholder} 
                    value={value}
                    onChange={onChange}
                    {...dateExtra}>
                </DatePicker.RangePicker>   
            </Form.Item>
        </Col>
    );
}
HoFormRowRangeDate.defaultProps = {
    placeholder: ['开始时间','结束时间'], 
    colExtra: {span:12}, 
    
    dateExtra: {}, 
    validate: {validateStatus:"success", help:""},
};


export function HoFormRowColTime(props){
    let {label, className, format, minuteStep, value, defaultValue, defaultOpenValue, placeholder, onChange, colExtra, itemExtra, timeExtra, validate} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <TimePicker
                    inputReadOnly={true}
                    className={className}
                    minuteStep={minuteStep} 
                    format={format}
                    placeholder={placeholder} 
                    value={value}
                    defaultOpenValue={defaultOpenValue}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    {...timeExtra}>
                </TimePicker>   
            </Form.Item>
        </Col>
    );
}
HoFormRowColTime.defaultProps = {
    placeholder: "", 
    format: "HH:mm",
    defaultOpenValue: moment("09:00", "HH:mm"),
    minuteStep: 5,
    colExtra: {span:12}, 
    
    timeExtra: {}, 
    validate: {validateStatus:"success", help:""},
};

export function HoFormRowColTextArea(props){
    let {label, placeholder, onChange, colExtra, itemExtra, inputExtra, validate} = props;

    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <Input.TextArea placeholder={placeholder} onChange={onChange} {...inputExtra}/>
            </Form.Item>
        </Col>
    );
}
HoFormRowColTextArea.defaultProps = {
    colExtra: {span:12}, 
    inputExtra: {}, 
    
    validate: {validateStatus:"success", help:""},

};

export function HoSelectOption(props){
    const {value, display} = props;
    const v = value !== undefined ? value : display;

    // console.log("HoSelectOption", display, v, props);

    return (<Select.Option key={v} value={v}>{display}</Select.Option>);
}

export function HoFormRowColSelect(props){
    let {label, content, placeholder, onChange, mode, colExtra, itemExtra, selectExtra, validate, children} = props;
    const disContent = content ? content.map(item => item) : undefined;
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra} {...validate}>
                <Select 
                    showSearch
                    mode={mode} 
                    placeholder={placeholder} 
                    onChange={onChange}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return option.props.children.indexOf(input) >= 0
                    }}
                    {...selectExtra}>
                    {disContent}
                    {children}
                </Select>   
            </Form.Item>
        </Col>
    );
}
HoFormRowColSelect.defaultProps = {
    placeholder: "", 
    mode: "default", 
    colExtra: {span:12}, 
    
    selectExtra: {}, 
    validate: {validateStatus:"success", help:""},
};

export function HoFormRowColRemoteSelect(props){
    let {label, mode, data, placeholder, loading, onSearch, onChange, colExtra, itemExtra, selectExtra} = props;
    
    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra}>
                <HoRemoteSelect 
                    mode={mode}
                    data={data}
                    placeholder={placeholder} 
                    loading={loading}
                    onSearch={onSearch}
                    onChange={onChange} 
                    {...selectExtra}/>
            </Form.Item>
        </Col>
    );       
}
HoFormRowColRemoteSelect.defaultProps = {
    colExtra: {span:24}, 
    
    mode: "default",
    data: [],
    placeholder: "搜索",
    loading: false, // 是否正在请求数据
    onSearch: undefined, // 搜索触发的回调
    onChange: undefined, // 选择变化的回调
    selectExtra: undefined,
};

export function HoFormRowColUploadPictureWall(props){
    let {label, onChange, maxPicture, colExtra, itemExtra, fileList, pictureWallExtra} = props;

    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra}>
                <HoUploadPictureWall 
                    fileList={fileList}
                    maxPicture={maxPicture} 
                    onChange={onChange} 
                    {...pictureWallExtra}
                    />
            </Form.Item>
        </Col>
    );
}
HoFormRowColUploadPictureWall.defaultProps = {
    maxPicture: 1,
    onChange: undefined,
    fileList: [],
    colExtra: {span:12}, 
    pictureWallExtra: {}, 
    itemExtra: {  
         labelCol: { xs: { span: 4}, sm: { span: 4}},
        wrapperCol: { xs: { span: 20}, sm: { span: 20}},
        style: {marginBottom:0},
    }
};

export function HoFormRowColUploadAvatar(props){
    let {label, onChange, colExtra, itemExtra, imageUrl, avatarExtra} = props;

    return (
        <Col {...colExtra}>
            <Form.Item label={label} {...itemExtra}>
                <HoUploadAvatar
                    imageUrl={imageUrl}
                    onChange={onChange} 
                    {...avatarExtra}
                    />
            </Form.Item>
        </Col>
    );
}
HoFormRowColUploadAvatar.defaultProps = {
    maxPicture: 1,
    onChange: undefined,
    imageUrl: undefined,
    colExtra: {span:12}, 
    avatarExtra: {}, 
    itemExtra: {  
         labelCol: { xs: { span: 4}, sm: { span: 4}},
        wrapperCol: { xs: { span: 20}, sm: { span: 20}},
        style: {marginBottom:0},
    }
};









