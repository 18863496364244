/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';


export function sipStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.AppState){
        case 'online': 
            tags.push({color: green[3], tag:'在线'});
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'离线'});
            break;
        default: 
            break;
    }
    return tags;
}

export function confStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.ConfState){
        case 'joined': 
            tags.push({color: green[3], tag:'已入会'});
            break;
        case 'notjoin': 
            tags.push({color: grey[3], tag:'未入会'});
            if (record.AppState === 'online'){
                switch(record.CallState){
                    case 'idle': tags.push({color: green[3], tag:'空闲'});break;
                    case 'occupy': tags.push({color: red[3], tag:'被占用'});break;
                    default:break;
                }
                // tags.push({color: grey[3], tag:'未入会'});
            }
            break;
        case 'oncejoined': 
            tags.push({color: gold[3], tag:'曾入会'});
            break;
        default: 
            break;
    }
    return tags;
}

export function confStateTags2(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.ConfState){
        case 'joined': 
            tags.push({color: blue[3], tag:'已入会'});
            break;
        case 'notjoin': 
            tags.push({color: grey[3], tag:'未入会'});
            tags = tags.concat(sipStateTags(text, record));
            if (record.AppState === 'online'){
                switch(record.CallState){
                    case 'idle': tags.push({color: green[3], tag:'空闲'});break;
                    case 'occupy': tags.push({color: red[3], tag:'被占用'});break;
                    default:break;
                }
                // tags.push({color: grey[3], tag:'未入会'});
            }
            break;
        case 'oncejoined': 
            tags.push({color: gold[3], tag:'曾入会'});
            break;
        case 'inviting': 
            tags.push({color: gold[3], tag:'正在邀请'});
            break;
        default: 
            break;
    }
    return tags;
}


export function callStateTags(text, record, confState = 'tostart') {
    let tags = [];
    switch(record.AppState){
        case 'online': 
            tags.push({color: green[3], tag:'在线'});
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'离线'});
            break;
        default: 
            break;
    }

    switch(record.SipState){
        case 'online': 
            tags.push({color: green[3], tag:'已注册'});
            switch(record.CallState){
                case 'idle': 
                    tags.push({color: green[3], tag:'空闲'});
                    break;
                case 'occupy': 
                    tags.push({color: red[3], tag:'通话中'});
                    break;
                default: 
                    break;
            }
            break;
        case 'offline': 
            tags.push({color: grey[3], tag:'未注册'});
            break;
        default: 
            break;
    }
    
    return tags;
}


