import { netRequestThunk } from "../util/netReqRD";

export function dataDauList(props, rangeType="week", successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/dau/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        rangeType: rangeType,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, "dau", successFun, errorFun));
}

export function dataDauList2(props, startDate, endDate, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/dau/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        startDate: startDate,
        endDate: endDate,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, "dau2", successFun, errorFun));
}