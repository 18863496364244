/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { netRequestZabbix } from '../../../util/netReqRD';
import { version } from '../../../util/version';

// 正式环境
export let ZABBIX_HOST_IDS = ["10264", "10266"]
export let ZABBIX_CALL_COUNT_IDS = "28829"
export let ZABBIX_CONFERENCE_COUNT_IDS = "28830"
export let ZABBIX_CUP_IDEL_IDS = "28768"
export let ZABBIX_FILESYSTEM_TOTAL_IDS = "28841"
export let ZABBIX_FILESYSTEM_FREE_IDS = "28839"
export let ZABBIX_MEMORY_TOTAL_IDS = "28786"
export let ZABBIX_MEMORY_FREE_IDS = "28785"
export let ZABBIX_NETWORK_INCOMING = "28836"
export let ZABBIX_NETWORK_OUTGOING = "28837"

export let ZABBIX_CM_PORT = "29247"
export let ZABBIX_CM_CPU = "29248"
export let ZABBIX_CM_MEMORY = "29249"

export let ZABBIX_FREESWITCH_PORT = "28833"
export let ZABBIX_FREESWITCH_CPU = "29010"
export let ZABBIX_FREESWITCH_MEMORY = "29014"

export let ZABBIX_CS_PORT = "29250"
export let ZABBIX_CS_CPU = "29251"
export let ZABBIX_CS_MEMORY = "29252"

export let ZABBIX_MYSQL_PORT = "29056"
export let ZABBIX_MYSQL_CPU = "29057"
export let ZABBIX_MYSQL_MEMORY = "29058"

export let ZABBIX_REDIS_PORT = "29059"
export let ZABBIX_REDIS_CPU = "29060"
export let ZABBIX_REDIS_MEMORY = "29061"

// TODO: SPEEDER没有在正式环境部署
export let ZABBIX_SPEEDER_PORT = "29040"
export let ZABBIX_SPEEDER_CPU = "29041"
export let ZABBIX_SPEEDER_MEMORY = "29042"

export let ZABBIX_FLV_PORT = "29028"
export let ZABBIX_FLV_CPU = "29030"
export let ZABBIX_FLV_MEMORY = "29031"

export let ZABBIX_RTSP_PORT = "29047"
export let ZABBIX_RTSP_CPU = "29048"
export let ZABBIX_RTSP_MEMORY = "29049"

switch (version.environment) {
    case "development":
    case "test": {
        ZABBIX_HOST_IDS = ["10264", "10268"]
        ZABBIX_CALL_COUNT_IDS = "28803"
        ZABBIX_CONFERENCE_COUNT_IDS = "28804"
        ZABBIX_CUP_IDEL_IDS = "28725"
        ZABBIX_FILESYSTEM_TOTAL_IDS = "28827"
        ZABBIX_FILESYSTEM_FREE_IDS = "28825"
        ZABBIX_MEMORY_TOTAL_IDS = "28743"
        ZABBIX_MEMORY_FREE_IDS = "28742"
        ZABBIX_NETWORK_INCOMING = "28813"
        ZABBIX_NETWORK_OUTGOING = "28820"

        ZABBIX_CM_PORT = "29254"
        ZABBIX_CM_CPU = "29255"
        ZABBIX_CM_MEMORY = "29256"

        ZABBIX_FREESWITCH_PORT = "28807"
        ZABBIX_FREESWITCH_CPU = "29009"
        ZABBIX_FREESWITCH_MEMORY = "29013"

        ZABBIX_CS_PORT = "29257"
        ZABBIX_CS_CPU = "29258"
        ZABBIX_CS_MEMORY = "29259"

        ZABBIX_MYSQL_PORT = "29056"
        ZABBIX_MYSQL_CPU = "29057"
        ZABBIX_MYSQL_MEMORY = "29058"

        ZABBIX_REDIS_PORT = "29059"
        ZABBIX_REDIS_CPU = "29060"
        ZABBIX_REDIS_MEMORY = "29061"

        // 下面进程没有部署，使用的是测试环境的
        ZABBIX_FLV_PORT = "29028"
        ZABBIX_FLV_CPU = "29030"
        ZABBIX_FLV_MEMORY = "29031"

        ZABBIX_RTSP_PORT = "29047"
        ZABBIX_RTSP_CPU = "29048"
        ZABBIX_RTSP_MEMORY = "29049"

        ZABBIX_SPEEDER_PORT = "29040"
        ZABBIX_SPEEDER_CPU = "29041"
        ZABBIX_SPEEDER_MEMORY = "29042"

        break
    }
    case "show": {
        ZABBIX_HOST_IDS = ["10264", "10281"]
        ZABBIX_CALL_COUNT_IDS = "29342" // FREESWITCH: Calls count
        ZABBIX_CONFERENCE_COUNT_IDS = "29343" // FREESWITCH: Conference count
        ZABBIX_CUP_IDEL_IDS = "29284" //CPU: CPU idle time
        ZABBIX_FILESYSTEM_TOTAL_IDS = "28827" // Filesystems: Total disk space on /
        ZABBIX_FILESYSTEM_FREE_IDS = "28825" // Filesystems: Free disk space on /
        ZABBIX_MEMORY_TOTAL_IDS = "29308" // Memory: Total memory
        ZABBIX_MEMORY_FREE_IDS = "29307" // Memory: Available memory
        ZABBIX_NETWORK_INCOMING = "29357" // Network interfaces: Incoming network traffic on eth0
        ZABBIX_NETWORK_OUTGOING = "29361" // Network interfaces: Outgoing network traffic on eth0

        ZABBIX_CM_PORT = "29339"
        ZABBIX_CM_CPU = "29340"
        ZABBIX_CM_MEMORY = "29341"

        ZABBIX_FREESWITCH_PORT = "29346"
        ZABBIX_FREESWITCH_CPU = "29349"
        ZABBIX_FREESWITCH_MEMORY = "29350"

        ZABBIX_CS_PORT = "29351"
        ZABBIX_CS_CPU = "29352"
        ZABBIX_CS_MEMORY = "29353"

        ZABBIX_MYSQL_PORT = "29367"
        ZABBIX_MYSQL_CPU = "29368"
        ZABBIX_MYSQL_MEMORY = "29369"

        ZABBIX_REDIS_PORT = "29370"
        ZABBIX_REDIS_CPU = "29371"
        ZABBIX_REDIS_MEMORY = "29372"

        // 下面进程没有部署，使用的是测试环境的
        ZABBIX_FLV_PORT = "29028"
        ZABBIX_FLV_CPU = "29030"
        ZABBIX_FLV_MEMORY = "29031"

        ZABBIX_RTSP_PORT = "29047"
        ZABBIX_RTSP_CPU = "29048"
        ZABBIX_RTSP_MEMORY = "29049"

        ZABBIX_SPEEDER_PORT = "29040"
        ZABBIX_SPEEDER_CPU = "29041"
        ZABBIX_SPEEDER_MEMORY = "29042"

        break
    }
    default:
        break
        
}

export const ACT_NET_WARN_LIST = 'listWarn';
export const ACT_NET_PROBLEM_LIST = 'listProblem';
export const ACT_NET_HISTORY_LIST = 'listHistory';
export const ACT_NET_GROUP_LIST = 'listGroup';
export const ACT_NET_HOST_LIST = 'listHost';
export const ACT_NET_ITEM_LIST = 'listItem';
export const ACT_NET_TEMPLATE_LIST = 'listTemplate';


export const dataListGroup = (props, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"hostgroup.get",
        "params": {
            "output":["groupid","name"],
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_GROUP_LIST, successFun, errorFun));
}

export const dataListHost = (props, groupids, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"host.get",
        "params": {
            "output":["groupid", "hostid", "name"],
            "groupids": groupids,
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_HOST_LIST, successFun, errorFun));
}

export const dataListItem = (props, hostids, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"item.get",
        "params": {
            // "output": ["hostid", "itemid", "key_", "name", "templateid", ],
            "hostids": hostids,
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_ITEM_LIST, successFun, errorFun));
}

export const dataListTemplate = (props, hosts, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"template.get",
        "params": {
            // "output": ["host", "templateid", "name", ],
            // "filter" : {
            //     "host": hosts,
            // }
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_TEMPLATE_LIST, successFun, errorFun));
}

export const dataListWarn = (props, params, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"trigger.get",
        "params": {
            "filter": {
                "value": 1,
            },
            "sortfield": "priority",
            "sortorder": "DESC",
            "min_severity": 4,
            "skipDependent": 1,
            "monitored": 1,
            "active": 1,
            "expandDescription": 1,
            "selectHosts": ['host'],
            "selectGroups": ['name'],
            "only_true": 1,
            ...params,
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_WARN_LIST, successFun, errorFun));
}

export const dataListProblem = (props, params, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"problem.get",
        "params": {
            // "output": "extend",
            "selectAcknowledges": "extend",
            "selectTags": "extend",
            "selectSuppressionData": "extend",
            "sortfield": ["eventid"],
            "sortorder": "DESC",
            "tags": [{"tag": "host", "value": version.isProduction() ? 'release' : version.environment}],
            ...params,
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_PROBLEM_LIST, successFun, errorFun));
}

export const dataListHistory = (props, params, successFun = undefined, errorFun = undefined) => {
    const {zabbixUserInfo, dispatch, } = props;
    const msgBody = {
        "jsonrpc":"2.0",
        "method":"history.get",
        "params": {
            "output": "extend",
            // "hostids": hostids,
            // "itemids": itemids,
            "sortfield": "clock",
            "sortorder": "DESC",
            // "limit": 10,
            ...params,
        },
        "auth": zabbixUserInfo?.authID,
        "id": Math.floor(Math.random() * 1000000),
    };
    dispatch(netRequestZabbix(msgBody, undefined, ACT_NET_HISTORY_LIST, successFun, errorFun));
}





