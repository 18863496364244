/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Dropdown, Button, Descriptions, Typography, Icon, Modal, Spin } from 'antd';

import { 
    HoTableExtend, hoTableExtendColumn, hoTableExtendColumnAction, hoTableColumnTagButton,
} from '../../util/tableComponent';

import { hoMessage, HoLinkButton } from '../../util/hoComponent';
import { sipStateTags, confStateTags } from '../common/common';
import SelectAllMember from './selectAllMember';
import SelectJoinedMember from './selectJoinedMember';

class SelectAppointmentMember extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/
        // 回调
        this.memberOnChange = this.memberOnChange.bind(this);

        // 已选
        this.mainDeleteOnClick = this.mainDeleteOnClick.bind(this);
        this.tblDeleteOnClick = this.tblDeleteOnClick.bind(this);
        this.tblSelectOnChange = this.tblSelectOnChange.bind(this);

        const columns = [
            hoTableExtendColumn('在线状态', 'AppState', 'str', false, false, '40%', {
                render: (text, record) => hoTableColumnTagButton(sipStateTags(text, record).concat(confStateTags(text, record))),
            }),
            hoTableExtendColumn('设备名称', 'NickName', 'str', false, false, '40%'),
            hoTableExtendColumnAction('操作', 'operation', [
                {title: '删除', onClick: this.tblDeleteOnClick},
            ], '10%'),
        ];

        this.state = {
            columns: columns,
            selectedRowKeys: [],
            selectedRows: [],
            flag: true,
        }
    }

    memberOnChange(action, record) {
        let {memberOnChange} = this.props;
        if (memberOnChange){
            memberOnChange(action,record);
        }
        this.setState({
            flag: !this.state.flag,
        })
    }

    // '批量删除'按钮被点击
    mainDeleteOnClick(e) {
        const {selectedRows} = this.state;
        if (selectedRows.length === 0){
            hoMessage({type: 'warning', msg: `请至少勾选一个设备`});
        } else {
            selectedRows.forEach(element => {
                this.tblDeleteOnClick(e, element);
            });
            this.setState({
                selectedRowKeys: [],
                selectedRows: [],
            });
        }
    }

    tblDeleteOnClick(e, record){
        e.stopPropagation();
        let {memberOnChange} = this.props;
        if (memberOnChange){
            memberOnChange('del',record);
        }
        this.setState({
            flag: !this.state.flag,
        })
    }

    tblSelectOnChange(selectedRowKeys, selectedRows) {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    mkTable(){
        let { columns, selectedRowKeys} = this.state;
        const {selectedMembers } = this.props;
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: this.tblSelectOnChange,
        };
        // console.log("mkTable", selectedMembers);

        return (<HoTableExtend
            className="ho-select-member-done-table"
            columns={columns}
            data={selectedMembers}
            bordered={false}
            size={'small'}
            rowKey={'UserId'}
            rowSelection={rowSelection}
        />);
    }


    // clear handle
    componentWillUnmount(){
    }

    componentDidMount(){
    }

    render() {
        const {selectedRowKeys} = this.state;
        const {style, mode} = this.props;
        // 主页面
        const tableComponent = this.mkTable();

        const deleteDisable = selectedRowKeys.length === 0;
        return (<div style={style}>
            <Row>
                <Col span={11} style={{border: 'solid 1px #e8e8e8', borderRadius: '2px', padding: '1rem', textAlign: 'left'}}>
                    <span style={{fontWeight: 500, fontSize: '16px'}}>已选</span>
                    <div style={{margin: '0.5rem 0'}}>
                        <HoLinkButton disabled={deleteDisable} icon="delete" style={{padding: 0}} onClick={this.mainDeleteOnClick}>批量删除</HoLinkButton>
                    </div>
                    {tableComponent}
                </Col>
                <Col span={2}>
                </Col>
                <Col span={11} style={{border: 'solid 1px #e8e8e8', borderRadius: '2px', padding: '1rem', textAlign: 'left'}}>
                    {mode !== "join" ? 
                        <SelectAllMember
                            reqUserInfo={this.props.reqUserInfo}
                            memberOnChange={this.memberOnChange}
                        /> 
                    :
                        <SelectJoinedMember
                            reqUserInfo={this.props.reqUserInfo}
                            roomInfo={this.props.roomInfo}
                            memberOnChange={this.memberOnChange}
                        />
                    }
                </Col>
            </Row>
            
        </div>);
    }
}

const mapState = (state) => ({
    
});


export default connect(
    mapState, 
    null
)(SelectAppointmentMember);

