/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../../util/tableComponent";
import { netRequestThunk } from '../../../util/netReqRD';

const ACT_GROUP_LIST_REGISTRATION = 'ACT_GROUP_LIST_REGISTRATION';
const ACT_LIST_REGISTRATION = 'ACT_LIST_REGISTRATION';
const ACT_CLEAN_REGISTRATION = 'ACT_CLEAN_REGISTRATION';

export const ACT_NET_REGISTRATION_GROUP_LIST = 'groupListRegistration';
export const ACT_NET_REGISTRATION_LIST = 'listRegistration';
export const ACT_NET_REGISTRATION_QUERY = 'queryRegistration';

// reducer handle
const registrationState = {
    groupData: undefined,
    data: undefined,
}

export const registration_reducer = (state=registrationState, action) => {
    switch(action.type){
        // 列表
        case ACT_GROUP_LIST_REGISTRATION:{
            return {
                ...state,
                groupData: action.groupData
            };
        }

        case ACT_LIST_REGISTRATION:{
            return {
                ...state,
                data: action.data
            };
        }

        case ACT_CLEAN_REGISTRATION:
            return {
                groupData: undefined,
                data: undefined,
            };

        default:
            return state;
    }
}


export const actRegistrationClear = () => {
    return {
        type: ACT_CLEAN_REGISTRATION,
    }
}

// 数据
export const groupListRegistrationData = (state) => {
    return state.registration !== undefined ? state.registration.groupData : undefined;
}

export function groupListRegistrationDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_GROUP_LIST_REGISTRATION, 
        groupData: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataGroupListRegistration(props, paginationInfo, successFun = undefined, errorFun = undefined){
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/registration/group/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_REGISTRATION_GROUP_LIST, successFun, errorFun));
}


export const listRegistrationData = (state) => {
    return state.registration !== undefined ? state.registration.data : undefined;
}

export function listRegistrationDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_REGISTRATION, 
        data: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataListRegistration(props, paginationInfo, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/registration/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_REGISTRATION_LIST, successFun, errorFun));
}

export function dataQueryRegistration(props, uuid, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/registration/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        registration: {
            uuid: uuid,
        }
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_REGISTRATION_QUERY, successFun, errorFun));
}





