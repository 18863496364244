/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import { Menu, Input, Button, Dropdown, Modal, Card, Row, Col, Icon, Tooltip } from 'antd';
import { actNetRequestClear, getNetRequestStatus } from '../../util/netReqRD';
import { 
    listJizhenDataSuccessHd, listJihenData,
    dataAddJizhenGroup, dataDelJizhenGroup, dataModJizhenGroup, dataListJizhenGroup, ACT_NET_JIZHEN_GROUP_LIST, 
    dataAddJizhenMember, dataDelJizhenMember, dataModJizhenMember, dataListJizhenMember, ACT_NET_JIZHEN_MEMBER_LIST,
} from './jizhenRD';
import {getLoginUserInfo} from '../login/loginRD'
import { HoCard, HoBreadcrumb, HoRefreshButton, HoNewButton, HoAlertDialog, } from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, hoTableColumn, hoTableColumnDate, hoTableColumnAction, } from '../../util/tableComponent';
import { JizhenForm, Role, Type } from './jizhenForm';
import JizhenMember from '../../component/subpages/jizhenMember';
import { randomString, randomNumber, encrypt } from '../../util/logic';

import './jizhen.less'

class JizhenCT extends Component {
    constructor(props){
        super(props);

        /********************************** 公共操作 ***********************************/
        // 数据处理回调
        this.dataListSuccessHd = this.dataListSuccessHd.bind(this);

        /********************************** 主页面 ***********************************/
        // 主页面回调
        this.tblColOnChange = this.tblColOnChange.bind(this);
        this.tblRowModOnClick = this.tblRowModOnClick.bind(this);
        this.tblRowDelOnClick = this.tblRowDelOnClick.bind(this);
        
        // 主页面按钮
        this.mainRefreshOnClick = this.mainRefreshOnClick.bind(this);
        this.mainNewOnClick = this.mainNewOnClick.bind(this);
        this.mainSearchOnClick = this.mainSearchOnClick.bind(this);
        this.mainSearchOnChange = this.mainSearchOnChange.bind(this);
        this.mainUserTypeOnClick = this.mainUserTypeOnClick.bind(this);

        // 主页面
        this.mkBreadcrumb = this.mkBreadcrumb.bind(this);
        this.mkHeaderInfo = this.mkHeaderInfo.bind(this);
        this.mkTable = this.mkTable.bind(this);

        // 子页面
        this.mkEditModal = this.mkEditModal.bind(this);

        const columns = [
            hoTableColumn('名称', 'Name', 'str', false, false, '10%'),
            hoTableColumn('类型', 'RcType', 'str', false, false, '7%', {
                render: (text, record) => Type.getDescription2(record.RcType)
            }),
            hoTableColumn('识别码', 'ClientCode', 'str', false, false, '7%'),
            hoTableColumn('授权码', 'AuthCode', 'str', false, false, '13%', {
                render: (text, recotd) => {
                    return <Input.Password className="password" value={text} readOnly="readonly" autoComplete="new-password" />
                }
            }),
            hoTableColumn('终端', 'Remark', 'str', false, false, '40%', {
                render: (text, record) => {
                    let ms = record.MemberList?.sort((a,b) => a.Role - b.Role) || []
                    return <Row className="screen" gutter={16}>
                        {
                            ms.map((member, index) => {
                                let nickName = member?.NickName || "无";
                                let sipNum = member?.SipNum || "无";
                                return <div key={index} className="item">
                                    <div className="left">
                                        <div className="role">{Role.getDescription2(member.Role)}</div>
                                        <div className="nickname">{nickName}</div>
                                        <div className="sipnum">{sipNum}</div>
                                    </div>
                                </div>
                            })
                        }
                    </Row>
                },
                nolight: true,
            }),
            hoTableColumnDate('创建时间', 'CreateTime', false, '10%'),
            hoTableColumnAction('操作', 'operation', [
                {title: '编辑', onClick: this.tblRowModOnClick},
                {title: '删除', onClick: this.tblRowDelOnClick},
            ], '10%'),
        ];

        this.state = {
            columns: columns,
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
                userType: 1,
                userTypeMap: [{
                    value: 0,
                    title: "极真终端",
                }, {
                    value: 1,
                    title: "极真组",
                }]
            },
            editPageVisible: false,
            editOperation: '',
            editOrg: undefined,
            editCopy: undefined,
        }
    }

    dataChanged(dst, src) {
        if (dst && src) {
            for (let key in dst) {
                if (Role.keys().find(k => k === key)) {
                    if (src?.[key]?.value?.SipNum !== dst[key]?.value?.SipNum) {
                        return true;
                    }
                } else if (src?.[key]?.value !== dst[key]?.value) {
                    return true;
                }
            }
        }
        return false;
    }

    trans2ApiData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = d[name] ? 1 : 0;
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        function transSecurity(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = encrypt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            d[key] = data[key].value;
        }
        transInt(d, 'Id');
        transInt(d, 'RcType');
        transSecurity(d, 'AuthCode');
        return d;
    }

    trans2LocalData(data) {

        function transBoolean(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = !!(d?.[name]?.value)
            }
        }

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name].value = `${d[name].value}`;
            }
        }

        let d = {};
        if (data) {
            for (let key in data) {
                d[key] = { value: data[key] }
            }
            transInt(d, 'Id');
            transInt(d, 'RcType');
        } else {
            d = undefined;
        }
        return d;
    }

    searchOnChange(search, pag) {
        let filters1 = [];
        let filters2 = [];
        let fun = undefined;
        switch (search.userType) {
            case 0:
                fun = dataListJizhenMember;
                if (search.text.length > 0) {
                    filters1.push(['NickName', 'like', search.text]);
                    filters2.push(['SipNum', 'like', search.text]);
                }
                break;
            case 1:
                fun = dataListJizhenGroup;
                if (search.text.length > 0) {
                    filters1.push(['Name', 'like', search.text]);
                    filters2.push(['ClientCode', 'like', search.text])
                }
                break;
            default:
                break;
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
        })
        if (fun) {
            if (filters1.length > 0) {
                paginationInfo.pagination.filters = [
                    filters1,
                    filters2,
                ]
            } else {
                paginationInfo.pagination.filters = [];
            }
            fun(this.props, paginationInfo, this.dataListSuccessHd);
        }
    }

    dataListSuccessHd(dispatch, rspBody, reqBody){
        listJizhenDataSuccessHd(dispatch, rspBody, reqBody)
    }

    // 过滤、排序、分页发生变化
    tblColOnChange(paginationInfo){
        this.searchOnChange(this.state.search, paginationInfo)
    }

    // '编辑'按钮被点击
    tblRowModOnClick(e, record){
        e && e.stopPropagation();
        let data = this.trans2LocalData(record);
        // eslint-disable-next-line no-unused-expressions
        record.MemberList?.forEach(member => {
            data[Role.getKey(member.Role)] = {
                value: member
            }
        })
        this.setState({
            editPageVisible: true,
            editOperation: 'edit',
            editOrg: Object.assign({}, data),
            editCopy: data,
        });
    }

    // '删除'按钮被点击
    tblRowDelOnClick(e, record){
        e && e.stopPropagation();
        let that = this;
        Modal.confirm({
            content: <div>是否删除<span className="warn">{record.Name}</span>？</div>,
            onOk() {
                dataDelJizhenGroup(that.props, record, () => {
                    that.mainRefreshOnClick();
                }, () => {
                        
                })
            },
        })
    }

    // '新增'按钮被点击
    mainNewOnClick(e) {
        let clientCode = randomString(8);
        let authCode = randomNumber(8);
        this.setState({
            editPageVisible: true,
            editOperation: 'add',
            editOrg: undefined,
            editCopy: this.trans2LocalData({ClientCode: clientCode, AuthCode: authCode, RcType: "0"}),
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick(e) {
        e && e.stopPropagation();
        let { search, paginationInfo } = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索'按钮被点击
    mainSearchOnClick(text){
        let { search, paginationInfo } = this.state;
        search.text = text;
        this.searchOnChange(search, paginationInfo)
    }

    // '用户类型'切换
    mainUserTypeOnClick(e) {
        let { search, paginationInfo } = this.state;
        search.userType = parseInt(e);
        this.searchOnChange(search, paginationInfo)
    }

    // '搜索输入框'文本发生变化
    mainSearchOnChange(e) {
        if (e.target.value === "") {
            let {search, paginationInfo} = this.state;
            search.text = "";
            this.searchOnChange(search, paginationInfo)
        }
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[{title: "配置管理", url: "devops/setting"}, {title: "极真组"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<Fragment>
            <HoNewButton onClick={this.mainNewOnClick}/>
            <HoRefreshButton onClick={this.mainRefreshOnClick}/>
        </Fragment>);
        return {title: <span>极真组</span>, buttons: buttons}; 
    }

    mkEditModal(){
        let that = this;
        const { flag, editPageVisible, editCopy, editOrg, editOperation } = this.state;

        return <HoAlertDialog
            width="50%"
            title={editOperation === 'add' ? "新增" : "编辑"}
            visible={editPageVisible}
            okText={editOperation === 'add' ? "确定" : "保存"}
            okButtonProps={{disabled: editOperation === 'edit' && !this.dataChanged(editCopy, editOrg)}}
            onOkClick={() => {
                if (editOperation === 'add') {
                    dataAddJizhenGroup(that.props, that.trans2ApiData(editCopy), (dispatch, rsp, req) => {
                        that.setState({
                            editPageVisible: false,
                        })
                        for (let role of Role.keys()) {
                            let sipNum = editCopy[role]?.value?.SipNum;
                            if (sipNum) {
                                let member = {
                                    SipNum: sipNum,
                                    Role: Role.getValue(role)
                                }
                                dataAddJizhenMember(that.props, rsp.Id, member, () => {
                                    
                                })
                            }
                        }
                        setTimeout(() => {
                            that.mainRefreshOnClick()
                        }, 1000)
                    }, () => {
                        
                    })
                } else {
                    dataModJizhenGroup(that.props, that.trans2ApiData(editCopy), () => {
                        that.setState({
                            editPageVisible: false,
                        })
                        Role.keys().forEach(role => {
                            let editMember = editCopy?.[role]?.value
                            let ret = editOrg.MemberList?.value?.find(member => member.Role === Role.getValue(role))
                            if (ret) {
                                if (editMember) {
                                    if (editMember.SipNum !== ret.SipNum) {
                                        // 修改了终端
                                        console.log(`mod ${role} ${ret.SipNum} -> ${editMember.SipNum}`)
                                        dataDelJizhenMember(that.props, ret);
                                        dataAddJizhenMember(that.props, parseInt(editCopy?.Id?.value || 0), { ...editMember, Role: Role.getValue(role) });
                                    } else {
                                        // 无变化
                                    }
                                } else {
                                    // 删除了终端
                                    console.log(`del ${role} ${ret.SipNum}`)
                                    dataDelJizhenMember(that.props, ret)
                                }
                            } else {
                                if (editMember) {
                                    // 新增了终端
                                    console.log(`add ${role} ${editMember.SipNum}@${editCopy?.Id?.value}`)
                                    dataAddJizhenMember(that.props, parseInt(editCopy?.Id?.value || 0), { ...editMember, Role: Role.getValue(role) })
                                } else {
                                    // 无变化
                                }
                            }
                        })
                        setTimeout(() => {
                            that.mainRefreshOnClick()
                        }, 1000)
                    }, () => {
                        
                    })
                }
            }}
            onCancelClick={() => {
                this.setState({
                    editPageVisible: false,
                })
            }}
        >
            <JizhenForm
                fields={editCopy}
                flag={flag}
                deviceEditOnClick={(field) => {
                    this.setState(({ flag }) => ({
                        memberPageVisible: true,
                        memberRole: field,
                        flag: !flag,
                    }))
                }}
                deviceDelOnClick={(field) => {
                    editCopy[field] = {
                        value: undefined
                    }
                    this.setState(({ flag }) => ({
                        editCopy: editCopy,
                        flag: !flag,
                    }));
                }}
                onChange={(changedFields) => {
                    let { editCopy, flag } = this.state;
                    let newEditCopy = { ...editCopy, ...changedFields };
                    if (newEditCopy.RcType?.value === "0") {
                        newEditCopy['Extra1'] = newEditCopy['Extra2'] = newEditCopy['Extra3'] = newEditCopy['Extra4'] = newEditCopy['Extra5'] = newEditCopy['Extra6'] = { value: undefined }
                    }
                    this.setState({
                        editCopy: newEditCopy,
                        flag: !flag,
                    });
                }}
            />
        </HoAlertDialog>
    }

    mkMemberModal() {
        const { memberPageVisible, memberRole } = this.state;

        return <HoAlertDialog
            width="80%"
            title={`选择${Role.getDescription(memberRole)}设备`}
            visible={memberPageVisible}
            footer={null}
            onCancelClick={() => {
                this.setState({
                    memberPageVisible: false,
                })
            }}
        >
            <JizhenMember
                reqUserInfo={this.props.reqUserInfo}
                onClick={(record) => {
                    let { editCopy, memberRole } = this.state;
                    if (editCopy[memberRole]) {
                        editCopy[memberRole] = { value: record } 
                    } else {
                        editCopy = Object.assign(editCopy, {
                            [memberRole]: { value: record }
                        })
                    }
                    // 防止一个终端被选择多次
                    Role.keys().filter(item => item !== memberRole).forEach(role => {
                        if (editCopy[role]?.value?.SipNum === editCopy[memberRole]?.value?.SipNum) {
                            editCopy[role] = { value: undefined }
                        }
                    })
                    this.setState({
                        memberPageVisible: false,
                        editCopy: editCopy
                    })
                }}
            />
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, search } = this.state;
        const { data, getGroupStatus, getMemberStatus } = this.props;
        const { records, paginationInfo } = data || {};
        const { total } = (paginationInfo && paginationInfo.pagination) || 0;

        // console.log("mkTable", search.userType);
        const options = search.userTypeMap.find((e) => search.userType === e.value);
        const menu = (
            <Menu onClick={(e) => this.mainUserTypeOnClick(e.key)}>
                {search.userTypeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
            </Menu>
        );

        return <div className="ho-jizhen-table">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{display: 'flex'}}>
                        <Dropdown overlay={menu}>
                            <Button className="type" type="primary" size="large">
                                {options.title} <Icon type="down" />
                            </Button>
                        </Dropdown>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder={options.value === 1 ? "搜索极真组名称或极真组识别码": "搜索极真终端名称或极真终端SIP号"}
                            enterButton
                            allowClear={true}
                            onSearch={this.mainSearchOnClick}
                            onChange={this.mainSearchOnChange}
                            /> 
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={records}
                rowKey={'Id'}
                loading={getGroupStatus === 1 || getMemberStatus === 1}
                onColumnChange={this.tblColOnChange}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount() {
        actNetRequestClear(ACT_NET_JIZHEN_GROUP_LIST);
        actNetRequestClear(ACT_NET_JIZHEN_MEMBER_LIST);
    }

    componentDidMount() {
        this.mainRefreshOnClick()
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const {title, buttons} = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const newComponent = this.mkEditModal();
        const memberComponent = this.mkMemberModal();
        

        return (<div>
            {newComponent}
            {memberComponent}
            {breadcrumbComponent}
            {/* {statisticsComponent} */}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    data: listJihenData(state),
    getGroupStatus: getNetRequestStatus(state, ACT_NET_JIZHEN_GROUP_LIST), 
    getMemberStatus: getNetRequestStatus(state, ACT_NET_JIZHEN_MEMBER_LIST), 
});


export default connect(
    mapState, 
    null
)(JizhenCT);

