/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../../util/tableComponent";
import { netRequestThunk } from '../../../util/netReqRD';

const ACT_LIST_CONFERENCE = 'ACT_LIST_CONFERENCE';
const ACT_LIST_CONFERENCE_ACTION = 'ACT_LIST_CONFERENCE_ACTION';
const ACT_CLEAN_CONFERENCE = 'ACT_CLEAN_CONFERENCE';


export const ACT_NET_CONFERENCE_LIST = 'listConference';
export const ACT_NET_CONFERENCE_ACTION_LIST = 'listConferenceAction';
export const ACT_NET_CONFERENCE_QUERY = 'queryConference';

// reducer handle
const conferenceState = {
    conferences: undefined, 
    actions: undefined,
}

export const conference_reducer = (state=conferenceState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_CONFERENCE:{
            return {
                ...state,
                conferences: action.data
            };
        }

        // 列表
        case ACT_LIST_CONFERENCE_ACTION:{
            return {
                ...state,
                actions: action.data
            };
        }

        case ACT_CLEAN_CONFERENCE:
            return {
                conferences: undefined,
                actions: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const listConferenceData = (state) => {
    return state.conference !== undefined ? state.conference.conferences : undefined;
}

// 数据
export const listConferenceActionData = (state) => {
    return state.conference !== undefined ? state.conference.actions : undefined;
}


export const actConferenceClear = () => {
    return {
        type: ACT_CLEAN_CONFERENCE,
    }
}

export function listConferenceDataSuccessHd(dispatch, rspBody, reqBody) {
    dispatch({
        type: ACT_LIST_CONFERENCE, 
        data: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataListConference(props, paginationInfo, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/conference/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CONFERENCE_LIST, successFun, errorFun));
}

export function dataQueryConference(props, name, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/conference/query'
    const msgBody = {
        userId: reqUserInfo.user.id,
        conference: {
            name: name,
        }
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CONFERENCE_QUERY, successFun, errorFun));
}


export function listConferenceActionDataSuccessHd(dispatch, rspBody, reqBody){
    dispatch({
        type: ACT_LIST_CONFERENCE_ACTION, 
        data: {
            paginationInfo: hoTableInitPaginationInfo(reqBody, rspBody.pagination.total),
            records: rspBody.pagination.records,
        }, 
    })
}

export function dataListConferenceAction(props, paginationInfo, conferenceId, successFun = undefined, errorFun = undefined) {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cm/api/v1/manager/devops/conference/action/list'
    const msgBody = {
        userId: reqUserInfo.user.id,
        conference: {
            id: conferenceId,
        },
        ...paginationInfo,
    };
    const header = {
        'P-Access-AppId': reqUserInfo.company.appid,
    }
    dispatch(netRequestThunk(path, msgBody, header, ACT_NET_CONFERENCE_ACTION_LIST, successFun, errorFun));
}





