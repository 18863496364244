/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableInitPaginationInfo } from "../../../util/tableComponent";
import { netRequestZabbix } from '../../../util/netReqRD';

const ACT_LIST_WARN = 'ACT_LIST_WARN';
const ACT_CLEAN_WARN = 'ACT_CLEAN_WARN';


export const ACT_NET_WARN_LIST = 'listWarn';
export const ACT_NET_WARN_QUERY = 'queryWarn';

// reducer handle
const warnState = {
    data: undefined, 
}

export const warn_reducer = (state=warnState, action) => {
    switch(action.type){
        // 列表
        case ACT_LIST_WARN:{
            return {
                ...state,
                data: action.data
            };
        }

        case ACT_CLEAN_WARN:
            return {
                data: undefined,
            };

        default:
            return state;
    }
}


// 数据
export const listWarnData = (state) => {
    return state.warn !== undefined ? state.warn.data : undefined;
}


export const actWarnClear = () => {
    return {
        type: ACT_CLEAN_WARN,
    }
}

export function listWarnDataSuccessHd(dispatch, rspBody, reqBody) {
    // console.log(rspBody);
    dispatch({
        type: ACT_LIST_WARN, 
        data: {
            records: rspBody.result,
        }, 
    })
}

