/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../util/tableComponent";
import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export const ACT_NET_MANUFACTURER_ADD = 'addMember';
export const ACT_NET_MANUFACTURER_DEL = 'delMember';
export const ACT_NET_MANUFACTURER_MOD = 'modMember';
export const ACT_NET_MANUFACTURER_LIST = 'listMember';

export function apiManufacturerAdd(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/device/camera/profile/add`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MANUFACTURER_ADD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MANUFACTURER_ADD, successHd, errorHd))
    );
}

export function apiManufacturerDel(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/device/camera/profile/del`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MANUFACTURER_DEL, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MANUFACTURER_DEL, successHd, errorHd))
    );
}

export function apiManufacturerMod(props, info, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/device/camera/profile/mod`;
    const msgBody = {
        ...info,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MANUFACTURER_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MANUFACTURER_MOD, successHd, errorHd))
    );
}

export function apiManufacturerList(props, paginationInfo, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/device/camera/profile/list`;
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_MANUFACTURER_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_MANUFACTURER_LIST, successHd, errorHd))
    );
}