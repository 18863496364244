/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../util/tableComponent";
import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export const dataAccountList = (props, paginationInfo, successHd = undefined, errorHd = undefined) => {
    const { reqUserInfo, dispatch } = props;
    const path = '/cs/v1/mntn/user/internal/account/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "accountList", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "accountList", successHd, successHd))
    );
}


export const dataAccountNew = (props, data, successHd = undefined, errorHd = undefined) => {
    const { reqUserInfo, dispatch } = props;
    const path = '/cs/v1/mntn/user/internal/account/add'
    const msgBody = {
        ...data,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "accountNew", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "accountNew", successHd, successHd))
    );
}


export const dataAccountMod = (props, data, successHd = undefined, errorHd = undefined) => {
    const { reqUserInfo, dispatch } = props;
    const path = '/cs/v1/mntn/user/internal/account/mod'
    const msgBody = {
        ...data,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, "accountMod", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, "accountMod", successHd, successHd))
    );
}

