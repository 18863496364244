import { netRequestThunk2 } from "../util/netReqRD";
import { loginNoauthHd } from "../pages/login/loginRD";

export function apiAliOssStsToken(props, successHd = undefined, errorHd = undefined) {
    const { dispatch, reqUserInfo } = props;
    let path = `/cs/v1/mntn/general/ali/tmpToken`;
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, {}, header, "apiAliOssStsToken", successHd, errorHd,
        () => loginNoauthHd(dispatch, path, {}, header, reqUserInfo, "apiAliOssStsToken", successHd, errorHd))
    );
}
