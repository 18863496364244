/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import QueueAnim from 'rc-queue-anim';

import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, orange, } from '@ant-design/colors';
import { Result, Collapse, Button, BackTop, Menu, Dropdown, Row, Col, Icon, Tooltip, Card, Tabs, Radio, Select} from 'antd';
import { ChartCard, Field, MiniArea, Bar, MiniBar, MiniProgress, Pie, Gauge } from 'ant-design-pro/lib/Charts';
import { G2, Chart, Geom, Axis, Tooltip as BZTooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util,} from "bizcharts";

import {getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo} from '../../login/loginRD'
import { 
    dataListProblem, dataListHistory, dataListGroup, dataListHost, dataListItem,
    ZABBIX_HOST_IDS, ZABBIX_CALL_COUNT_IDS, ZABBIX_CONFERENCE_COUNT_IDS, ZABBIX_CUP_IDEL_IDS, ZABBIX_FILESYSTEM_FREE_IDS, ZABBIX_FILESYSTEM_TOTAL_IDS,
    ZABBIX_MEMORY_FREE_IDS, ZABBIX_MEMORY_TOTAL_IDS, ZABBIX_NETWORK_INCOMING, ZABBIX_NETWORK_OUTGOING, 
} from '../zabbix/zabbixRD';
import { 
    HoCard, HoBreadcrumb, HoRefreshButton, HoIconFont,
} from '../../../util/hoComponent';

import './server.css';

class MemoryCT extends Component{
    constructor(props){
        super(props);

        /********************************** 主页面 ***********************************/

        // 数据回调
        this.dataListCallSuccessHd = this.dataListCallSuccessHd.bind(this);
        this.dataListCallTimerSuccessHd = this.dataListCallTimerSuccessHd.bind(this);
        this.dataListServerSuccessHd = this.dataListServerSuccessHd.bind(this);
        this.dataListServerTimerSuccessHd = this.dataListServerTimerSuccessHd.bind(this);

        // 
        this.monitorOnTimer = this.monitorOnTimer.bind(this);
        this.monitorInitData = this.monitorInitData.bind(this);
        this.mainTimeOnChange = this.mainTimeOnChange.bind(this);


        this.state = {
            timerInterval: 30000,
            server: {
                timeFormat: (clock) => parseInt(clock) * 1000,
                getTitle: (m = [], t = "") => { 
                    const item = m.find((e) => e.value === t);
                    return item ? item.title : "无";
                },
                memory: {
                    type: 'today.this',
                    typeMap: [
                        {value:"today.hour.1", title: "最近1小时"},
                        {value:"today.this", title: "今天"},
                        {value:"today.ago.1", title: "昨天"},
                        {value:"today.ago.2", title: "前天"},
                    ],
                    total: 0,
                    list: [],
                    format: (value) => Math.floor(parseInt(value) / (1024 * 1024 * 1024) * 100),
                },
                call: {
                    list: [],
                    max: null,
                    min: null,
                },
            }
        }
    }

    dataListCallTimerSuccessHd(dispatch, rspBody, reqBody) {
        let server = this.state.server;
        let result = rspBody.result;

        let tagetItem = server.call.list.find((e) => e.clock === result[0].clock);
        if (tagetItem) {
            return true;
        }

        // 数据是按时间降序排的，这里删除最后一个即为最老的数据，新增数据在最前头
        server.call.list.pop();
        server.call.list.unshift(result[0])

        this.setState({
            server: server,
        })

        let params = {
            hostids: ZABBIX_HOST_IDS, 
            itemids: ZABBIX_MEMORY_FREE_IDS,
            history: 3, 
            limit: 1, 
        }

        dataListHistory(this.props, params, this.dataListServerTimerSuccessHd, () => {return true;})
        
    }

    dataListCallSuccessHd(dispatch, rspBody, reqBody) {
        let server = this.state.server;
        server.call.list = rspBody.result;
        server.call.last = rspBody.result?.[0]?.value || 0;

        this.setState({
            server: server,
        })

        let content = {
            hostids: ZABBIX_HOST_IDS, 
            history: 3, 
            time_from: reqBody.params.time_from,
            time_till:reqBody.params.time_till,
        }

        dataListHistory(this.props, {...content, itemids: ZABBIX_MEMORY_TOTAL_IDS, limit: 1, }, this.dataListServerSuccessHd, () => {return true;})
        dataListHistory(this.props, {...content, itemids: ZABBIX_MEMORY_FREE_IDS, }, this.dataListServerSuccessHd, () => {return true;})
        
    }

    dataListServerSuccessHd(dispatch, rspBody, reqBody) {
        // console.log(reqBody.params.itemids, rspBody.result[0].value)
        if (!rspBody.result) {
            return true;
        }
        let itemid = reqBody.params.itemids;
        let server = this.state.server;
        let result = rspBody.result;
        
        switch(itemid){
            case ZABBIX_MEMORY_TOTAL_IDS:
                server.memory.total = server.memory.format(result[0].value);
                break;
            case ZABBIX_MEMORY_FREE_IDS:
                server.memory.list = result.map(item => {
                    let callItem = server.call.list.find((e) => Math.abs(e.clock - item.clock) <= 30);

                    if (callItem && callItem.value > server.call.max) {
                        server.call.max = callItem.value;
                    }
                    if (callItem && callItem.value < server.call.min) {
                        server.call.min = callItem.value;
                    }
                    return {
                        time: server.timeFormat(item.clock), 
                        call: callItem ? callItem.value : null,
                        memory: server.memory.total ? server.memory.total - server.memory.format(item.value) : 0,
                    };
                })
                break;
            default:
                break;
        }
        this.setState({
            server: server,
        })
    }

    dataListServerTimerSuccessHd(dispatch, rspBody, reqBody) {
        if (!rspBody.result) {
            return true;
        }
        let server = this.state.server;
        let result = rspBody.result;
        let tagetItem = server.memory.list.find((e) => e.time === parseInt(result[0].clock) * 1000);
        if (tagetItem) {
            return true;
        }

        let callItem = server.call.list.find((e) => Math.abs(e.clock - result[0].clock) <= 30);
        let newItem = {
            time: server.timeFormat(result[0].clock),
            memory: server.memory.total ? server.memory.total - server.memory.format(result[0].value) : 0,
            call: callItem ? callItem.value : null,
        }

        // 数据是按时间降序排的，这里删除最后一个即为最老的数据，新增数据在最前头
        server.memory.list.pop();
        server.memory.list.unshift(newItem);

        this.setState({
            server: server,
        })

    }

    monitorOnTimer() {
        let that = this;
        const { dispatch, zabbixUserInfo } = this.props;
        let server = this.state.server;

        if (server.memory.type.startsWith("today.ago")) {
            return true;
        }

        function dataZabbix() {
            const params = {
                hostids: ZABBIX_HOST_IDS, 
                itemids: ZABBIX_CALL_COUNT_IDS, 
                history: 3, 
                limit: 1,
            }
            dataListHistory(that.props, params, that.dataListCallTimerSuccessHd, () => {return true;})
        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    monitorInitData() {
        let that = this;
        const { dispatch, zabbixUserInfo } = this.props;

        function dataZabbix() {
            const params = {
                hostids: ZABBIX_HOST_IDS, 
                itemids: ZABBIX_CALL_COUNT_IDS, 
                history: 3, 
                time_from: moment().startOf('day').unix(),
                time_till: moment().endOf('day').unix(),
            }
            dataListHistory(that.props, params, that.dataListCallSuccessHd, () => {return true;})
        }

        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => {dataZabbix()}))
        } else {
            dataZabbix()
        }
    }

    mainTimeOnChange(value) {
        let server = this.state.server;
        server.memory.type = value;
        this.setState({
            server: server,
        })

        let timeFrom = undefined;
        let timeTill = undefined;

        switch(value) {
            case 'today.hour.1':
                timeTill = moment().unix();
                timeFrom = timeTill - 1 * 3600;
                break;
            case 'today.this':
                timeFrom = moment().startOf('day').unix()
                timeTill = moment().endOf('day').unix()
                break;
            case 'today.ago.1':
                timeFrom = moment().add('day', -1).startOf('day').unix()
                timeTill = moment().add('day', -1).endOf('day').unix()
                break;
            case 'today.ago.2':
                timeFrom = moment().add('day', -2).startOf('day').unix()
                timeTill = moment().add('day', -2).endOf('day').unix()
                break;
            default:
                break;             
        }

        const params = {
            hostids: ZABBIX_HOST_IDS, 
            itemids: ZABBIX_CALL_COUNT_IDS, 
            history: 3, 
            time_from: timeFrom,
            time_till: timeTill,
        }
        dataListHistory(this.props, params, this.dataListCallSuccessHd, () => {return true;})
    }

    mkMemory() {
        let that = this;
        const { server } = this.state
        let scale = {
            time: {
                type: 'time',
                alias: '时刻',
                tickCount: 12,
                mask: 'YYYY-MM-DD HH:mm:ss',
            },
            memory: {
                type: 'linear',
                alias: '内存使用',
            },
            call: {
                min: 0,
                type: 'linear',
                alias: '入会方数',
            }
        }

        if (server.call.max < 100) {
            scale.call.ticks = Array.from({length:11}, (v,k) => 10 * k);
        } else if (server.call.max < 500) {
            scale.call.ticks = Array.from({length:11}, (v,k) => 50 * k);
        } else {
            scale.call.ticks = Array.from({length:11}, (v,k) => 100 * k);
        }

        // 计算出离2的幂次方最近的值，来生成坐标轴
        let base = Math.pow(2, Math.ceil(Math.log2(server.memory.total / 100)) - 3)
        scale.memory.ticks = Array.from({length:9}, (v,k) => base * 100 * k);

        return <ChartCard
            key="memory-card"
            bordered={false}
            title={<Tooltip 
                placement="right"
                openClassName="tooltip-wrapper"
                title={<div className="tooltip" style={{width: '260px'}}>
                    <Row>
                        <span className="title">{server.memory.list[0] ? moment(server.memory.list[0].time).format("YYYY-MM-DD HH:mm:ss") : undefined}</span>
                    </Row>
                    <Row >
                        <Col span={2}><span className="ant-badge-status-dot" style={{backgroundColor: '#3182bd'}}></span></Col><Col span={14}>入会方数</Col><Col span={8}>{server.call.list[0] ? server.call.list[0].value : 0}</Col>
                    </Row>
                    <Row >
                        <Col span={2}><span className="ant-badge-status-dot" style={{backgroundColor: '#ffae6b'}}></span></Col><Col span={14}>内存使用</Col><Col span={8}>{server.memory.list[0] ? (server.memory.list[0].memory / 100).toFixed(2) : 0}G</Col>
                    </Row>
                </div>}>
                {/* <QueueAnim type={['left', 'right']} delay={100}> */}
                    <span key="memory-title" className="title">
                        <HoIconFont type="icon-memory-grey" className="icon"/>
                        <span className="description">内存</span>
                    </span>
                {/* </QueueAnim> */}
            </Tooltip>}
            className="memory-card"
            action={
                <Dropdown overlay={
                    <Menu onClick={(e) => this.mainTimeOnChange(e.key)}>
                        {server.memory.typeMap.map(item => <Menu.Item key={item.value}>{item.title}</Menu.Item>)}
                    </Menu>}
                >
                    <Button type="primary">
                        {server.getTitle(server.memory.typeMap, server.memory.type)}
                        <Icon type="down"/>
                    </Button>
                </Dropdown>
            }
            contentHeight={404}
        >
            <QueueAnim type={['bottom', 'top']}>
                <Chart key="memory-chart" scale={scale} height={404} data={server.memory.list} forceFit padding={[48, 48, 72, 48]} onGetG2Instance={chart => {that.chartIns = chart;}}>
                    <Axis name="time" title={false} 
                        label={{
                            offset: 25,
                            formatter: (val) => {
                                const timesp = val.split(' ');
                                if (timesp[0] === moment().format("YYYY-MM-DD")) {
                                    return timesp[1];
                                } else if (timesp[0] === moment().subtract(1, 'days').format("YYYY-MM-DD")){ 
                                    return "昨天\n" + timesp[1];
                                } else {
                                    return `${timesp[0]}\n${timesp[1]}`;
                                }
                            }}
                        }/>
                    <Axis name="memory" label={{formatter: (val) => `${(val / 100).toFixed(0)} G`, textStyle: {fill: "#ffae6b"}}}/>
                    <Axis name="call" grid={null} label={{ textStyle: { fill: "#3182bd" } }}/>
                    <Legend
                        custom={true}
                        position="top-right"
                        offsetY={-10}
                        allowAllCanceled={true}
                        itemFormatter={val => {
                            switch(val) {
                                case 'call': return "入会方数";
                                case 'memory': return "内存使用";
                                default: return "";
                            }
                        }}
                        items={[{
                            value: "call",
                            marker: { symbol: "hyphen", stroke: "#3182bd", radius: 5, lineWidth: 3 }
                        }, {
                            value: "memory",
                            marker: { symbol: "hyphen", stroke: "#ffae6b", radius: 5, lineWidth: 3 }
                        }]}
                        onClick={ev => {
                            const item = ev.item;
                            const value = item.value;
                            const checked = ev.checked;
                            const geoms = that.chartIns.getAllGeoms();
            
                            for (let i = 0; i < geoms.length; i++) {
                            const geom = geoms[i];
                            if (geom.getYScale().field === value) {
                                    if (checked) {
                                        geom.show();
                                    } else {
                                        geom.hide();
                                    }
                            }
                            }
                        }}
                    />
                    <BZTooltip showTitle={true}/>
                    <Geom type="line" position="time*memory" color="#fdae6b" size={1} shape="dotSmooth"
                        tooltip={["time*memory", (time, memory) => {
                            return {name: "内存使用", value: `${(memory / 100).toFixed(2)} G`};
                        }]}
                    />
                    <Geom type="line" position="time*call" color="#3182bd" size={1} shape="dotSmooth" />
                    <Guide>
                        <Guide.Line
                            top // {boolean} 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
                            start={['start', server.memory.total * 0.8]} // {object} | {function} | {array} 辅助线结束位置，值为原始数据值，支持 callback
                            end={['end', server.memory.total * 0.8]} // 同 start
                            lineStyle={{
                                stroke: 'red', // 线的颜色
                                lineDash: [0, 2, 2], // 虚线的设置
                                lineWidth: 1, // 线的宽度
                            }} // 图形样式配置 https://bizcharts.net/products/bizCharts/api/graphic
                            text={{
                                position: 'start', // 'start' | 'center' | 'end' | '39%' | 0.5 文本的显示位置
                                autoRotate: true, // {boolean} 是否沿线的角度排布，默认为 true
                                style: { fill: 'red' }, // {object}文本图形样式配置
                                offsetX: 20, // {number} x 方向的偏移量
                                offsetY: 20, // {number} y 方向的偏移量
                                content: `预警线 ${(server.memory.total * 0.8 / 100).toFixed(2)}G`,
                            }}
                        />
                        <Guide.Line
                            top // {boolean} 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
                            start={['start', server.memory.total]} // {object} | {function} | {array} 辅助线结束位置，值为原始数据值，支持 callback
                            end={['end', server.memory.total]} // 同 start
                            lineStyle={{
                                stroke: 'green', // 线的颜色
                                lineDash: [0, 2, 2], // 虚线的设置
                                lineWidth: 1, // 线的宽度
                            }} // 图形样式配置 https://bizcharts.net/products/bizCharts/api/graphic
                            text={{
                                position: 'start', // 'start' | 'center' | 'end' | '39%' | 0.5 文本的显示位置
                                autoRotate: true, // {boolean} 是否沿线的角度排布，默认为 true
                                style: { fill: 'green' }, // {object}文本图形样式配置
                                offsetX: 20, // {number} x 方向的偏移量
                                offsetY: -10, // {number} y 方向的偏移量
                                content: `内存容量 ${(server.memory.total / 100).toFixed(2)}G`,
                            }}
                        />
                    </Guide>
                </Chart>
            </QueueAnim>
        </ChartCard>
    }

    // clear handle
    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentDidMount(){
        setTimeout(() => {
            this.monitorInitData();
        }, 500)
        this.timer = setInterval(() => {this.monitorOnTimer()}, this.state.timerInterval);
    }

    render(){
        return <div>
            {this.mkMemory()}
        </div>
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
    zabbixUserInfo: getLoginZabbixInfo(state),
});


export default connect(
    mapState, 
    null
)(MemoryCT);

