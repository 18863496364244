/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim';
import { Menu, Input, Row, Col } from 'antd';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from '@ant-design/colors';
import { dataAccountList, dataAccountNew, dataAccountMod } from '../../api/account';
import { getLoginUserInfo } from '../login/loginRD'

import { 
    HoCard, HoBreadcrumb, HoButton, HoRefreshButton, HoNewButton, HoAlertDialog, HoIconFont,
} from '../../util/hoComponent';
import { HoTable, hoTableInitPaginationInfo, 
    hoTableColumn, hoTableColumnDate, hoTableColumnAction, hoTableColumnTag, hoTableColumnTagButton, hoTableColumnActionButton 
} from '../../util/tableComponent';
import { message } from '../../util/message';
import { GenForm, ResetForm } from './genForm';
import { encrypt, randomNumber, randomString } from '../../util/logic';

import './account.less';

class AccountCT extends Component{
    constructor(props){
        super(props);
        const columns = [
            hoTableColumn('状态', 'IsActive', 'int', false, false, '7%', {
                render: (text, record) => {
                    let tags = []
                    if (record.IsActive) {
                        tags.push({ color: blue[5], tag: '已激活' })
                    } else {
                        tags.push({ color: red[3], tag: '禁用' })
                    }
                    return hoTableColumnTagButton(tags)
                }
            }),
            hoTableColumn('账号', 'Account', 'str', false, false, '15%'),
            hoTableColumn('昵称', 'NickName', 'str', false, false, '15%'),
            hoTableColumn('SIP号', 'SipNum', 'str', false, false, '15%'),
            hoTableColumnDate('创建时间', 'CreateTime', false, '15%'),
            hoTableColumnAction('操作', 'operation', (text, record) => {
                let actions = [];
                if (record.IsActive) {
                    actions.push({ title: '禁用', popconfirm: { title: `是否禁用${record.Account}？` }, onClick: (e, record) => this.tblRowDisableOnClick(e, record)})
                } else {
                    actions.push({ title: '启用', onClick: (e, record) => this.tblRowEnableOnClick(e, record) })
                }
                actions.push({title: '重置密码', onClick: (e, record) => this.tblRowResetOnClick(e, record)})
                actions.push({title: '编辑', onClick: (e, record) => this.tblRowEditOnClick(e, record)})
                return hoTableColumnActionButton(actions, record);
            }, '15%'),
        ];

        this.state = {
            columns: columns,
            data: [],
            paginationInfo: hoTableInitPaginationInfo(),
            search: {
                text: '',
            },
            operation: false,
            opData: undefined,
        }
    }

    dataChanged(dst, src) {
        if (dst && src) {
            for (let key in dst) {
                if (src?.[key]?.value !== dst[key]?.value) {
                    return true;
                }
            }
        }
        return false;
    }

    trans2ApiData(data) {

        function transInt(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = parseInt(d[name]);
            }
        }

        function transSecurity(d, name) {
            if (d.hasOwnProperty(name)) {
                d[name] = encrypt(d[name]);
            }
        }

        let d = {}
        for (let key in data) {
            d[key] = data[key].value
        }

        transInt(d, 'Duration');
        transInt(d, 'Amount');
        transSecurity(d, 'Account');
        transSecurity(d, 'AccountPassword');
        transSecurity(d, 'ConfirmAccountPassword');

        return d;
    }

    searchOnChange(search, pag) {
        let filter1 = [];
        let filter2 = [];
        if (search?.text?.length > 0) {
            filter1.push(['Account', 'like', search.text]);
            filter2.push(['SipNum', 'like', search.text]);
        }
        let paginationInfo = JSON.parse(JSON.stringify(pag))
        paginationInfo.pagination.filters = [
            filter1,
            filter2
        ]

        paginationInfo.pagination.sort = {
            field: 'CreateTime',
            order: 1
        }
        
        this.setState({
            search: search,
            paginationInfo: paginationInfo,
            loading: true,
        }, () => {
            dataAccountList(this.props, paginationInfo,
                (_, rsp, req) => {
                    this.setState({
                        loading: false,
                        paginationInfo: hoTableInitPaginationInfo(req, rsp.TotalNum),
                        data: rsp.RecordList,
                    })
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                }
            )

        })
    }

    // '禁用'按钮被点击
    tblRowDisableOnClick(e, record){
        e.stopPropagation();
        dataAccountMod(this.props, {Id: record.Id, IsActive: 0}, () => {
            this.mainRefreshOnClick()
        });
    }

    // '启用'按钮被点击
    tblRowEnableOnClick(e, record){
        e.stopPropagation();
        dataAccountMod(this.props, {Id: record.Id, IsActive: 1}, () => {
            this.mainRefreshOnClick()
        });
    }

    // '编辑'按钮被点击
    tblRowEditOnClick(e, record) {
        let data = {
            Id: { value: record.Id },
            Account: { value: record.Account },
            AccountPassword: { value: record.AccountPassword },
            NickName: { value: record.NickName },
        }

        this.setState({
            operation: 'edit',
            opData: Object.assign({}, data),
            orgData: Object.assign({}, data),
        })
    }

    tblRowResetOnClick(e, record) {
        let data = {
            Id: { value: record.Id },
            Account: { value: record.Account },
            AccountPassword: { value: "" },
            ConfirmAccountPassword: { value: "" },
            NickName: { value: record.NickName },
        }

        this.setState({
            operation: 'reset',
            opData: Object.assign({}, data),
            orgData: Object.assign({}, data),
        })
    }

    // '新增'按钮被点击
    mainNewOnClick() {
        let account = randomNumber(8);
        let password = randomString(8);
        this.setState({
            operation: 'add',
            opData: {
                Account: { value: account },
                AccountPassword: { value: password },
                ConfirmAccountPassword: { value: password },
                NickName: { value: `NO.${account}` },
            },
            orgData: undefined,
        })
    }

    // '刷新'按钮被点击
    mainRefreshOnClick() {
        let {search, paginationInfo} = this.state;
        this.searchOnChange(search, paginationInfo)
    }

    // 面包屑
    mkBreadcrumb(){
        return <HoBreadcrumb 
            items={[{title: "用户管理", url: "member"}, {title: "账户"}]}
        />
    }

    mkHeaderInfo(){
        const buttons = (<div>
            <HoNewButton onClick={(e) => this.mainNewOnClick(e)}/>
            <HoRefreshButton onClick={(e) => this.mainRefreshOnClick(e)}/>
        </div>);
        return {title: <span>账户</span>, buttons: buttons}; 
    }

    mkNewPage() {
        let { opData, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title="新增"
            visible={operation === 'add'}
            okButtonProps={{ disabled: opData?.ConfirmAccountPassword?.value !== opData?.AccountPassword?.value || !opData?.AccountPassword?.value}}
            onOkClick={() => {
                dataAccountNew(this.props, this.trans2ApiData(opData), () => {
                    message.success("新增账户成功")
                    this.setState({
                        opData: undefined,
                        operation: false,
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                })
            }}
            onCancelClick={() => {
                this.setState({
                    operation: false,
                })
            }}
        >
            <GenForm
                fields={opData}
                operation={operation}
                onChange={(changedFields) => {
                    let { opData } = this.state;
                    this.setState({
                        opData: { ...opData, ...changedFields },
                    });
                }}
            />
        </HoAlertDialog>
    }

    mkModPage() {
        let { opData, orgData, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title="编辑"
            visible={operation === 'edit'}
            okButtonProps={{disabled: operation === 'edit' && !this.dataChanged(opData, orgData)}}
            onOkClick={() => {
                dataAccountMod(this.props, this.trans2ApiData(opData), () => {
                    message.success("编辑账户成功")
                    this.setState({
                        opData: undefined,
                        operation: false,
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                    
                })
            }}
            onCancelClick={() => {
                this.setState({
                    operation: false,
                })
            }}
        >
            <GenForm
                fields={opData}
                operation={operation}
                onChange={(changedFields) => {
                    let { opData } = this.state;
                    this.setState({
                        opData: { ...opData, ...changedFields },
                    });
                }}
            />
        </HoAlertDialog>
    }

    mkResetPage() {
        let { opData, operation } = this.state;

        return <HoAlertDialog
            width="50%"
            title="重置密码"
            visible={operation === 'reset'}
            okButtonProps={{ disabled: opData?.ConfirmAccountPassword?.value !== opData?.AccountPassword?.value || !opData?.AccountPassword?.value}}
            onOkClick={() => {
                dataAccountMod(this.props, this.trans2ApiData(opData), () => {
                    message.success("重置账户密码成功")
                    this.setState({
                        opData: undefined,
                        operation: false,
                    }, () => {
                        this.mainRefreshOnClick()
                    })
                })
            }}
            onCancelClick={() => {
                this.setState({
                    operation: false,
                })
            }}
        >
            <ResetForm
                fields={opData}
                operation={operation}
                onChange={(changedFields) => {
                    let { opData } = this.state;
                    this.setState({
                        opData: { ...opData, ...changedFields },
                    });
                }}
            />
        </HoAlertDialog>
    }

    mkTable(){
        let { columns, data, loading, paginationInfo } = this.state;
        let total = paginationInfo?.pagination?.total || 0;

        return <div className="ho-account-table">
            <QueueAnim type={['left', 'right']}>
                <Row key="search" className="search" type="flex">
                    <Col span={12} style={{display: 'flex'}}>
                        <Input.Search
                            size="large"
                            className="input"
                            placeholder="搜索账号或SIP号"
                            enterButton
                            allowClear={true}
                            onSearch={(text) => {
                                let {search, paginationInfo} = this.state;
                                search.text = text;
                                this.searchOnChange(search, paginationInfo)
                            }}
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    let {search, paginationInfo} = this.state;
                                    search.text = "";
                                    this.searchOnChange(search, paginationInfo)
                                }
                            }}
                            />
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row>
            </QueueAnim>
            <HoTable
                key="table"
                columns={columns}
                data={data}
                rowKey={'Id'}
                loading={loading}
                onColumnChange={(paginationInfo) => this.searchOnChange(this.state.search, paginationInfo)}
                total={total}
            />
        </div>
    }

    // clear handle
    componentWillUnmount() {
        
    }

    componentDidMount() {
        this.mainRefreshOnClick()
    }

    render(){
        // 主页面
        const breadcrumbComponent = this.mkBreadcrumb();
        const { title, buttons } = this.mkHeaderInfo();
        const tableComponent = this.mkTable();
        const newPageComponent = this.mkNewPage();
        const modPageComponent = this.mkModPage();
        const resetPageComponent = this.mkResetPage();

        return (<div>
            {newPageComponent}
            {modPageComponent}
            {resetPageComponent}
            {breadcrumbComponent}
            <HoCard key="card" title={title} buttons={buttons} >
                {tableComponent}
            </HoCard>
        </div>);
    }
}

const mapState = (state) => ({
    reqUserInfo: getLoginUserInfo(state), 
});


export default connect(
    mapState, 
    null
)(AccountCT);

