/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'

import { Button, Layout, Menu, Icon, Dropdown, Tabs, Avatar, Badge, Drawer, Empty, Row, Col, Tooltip} from 'antd';
import HeaderNav from './headerNav';
import Logo from './logo';
import { HoIconFont, HoLinkButton } from '../util/hoComponent';

import { 
    listWarnData, ACT_NET_WARN_LIST, listWarnDataSuccessHd,
} from '../pages/devops/warn/warnRD';
import {dataListProblem, ZABBIX_HOST_IDS} from '../pages/devops/zabbix/zabbixRD'
import { getLoginUserInfo, loginRequestZabbix, getLoginZabbixInfo } from '../pages/login/loginRD'
import { version } from '../util/version';
import { getUrlParam } from '../util/logic';

import './layout.less'

class Header extends Component {
    constructor(props){
        super(props);

        this.noticePageOnClose = this.noticePageOnClose.bind(this);
        this.noticeIconOnClick = this.noticeIconOnClick.bind(this);
        this.userMenuLogoutOnClick = this.userMenuLogoutOnClick.bind(this);

        this.mkHeaderLeftLogo = this.mkHeaderLeftLogo.bind(this);
        this.mkHeaderMidMenu = this.mkHeaderMidMenu.bind(this);
        this.mkHeaderRightMenu = this.mkHeaderRightMenu.bind(this);
        this.mkNoticePage = this.mkNoticePage.bind(this);

        this.state = {
            hideHeader: parseInt(getUrlParam("hideHeader", window.location.search)) || 0,
            noticePageVisible: false,
        };
    }
    
    noticePageOnClose(){
        // console.log("noticePageOnClose");
        this.setState({
            noticePageVisible: false,
        });
    }

    noticeIconOnClick(){
        // console.log("noticeIconOnClick");
        // this.setState({
        //     noticePageVisible: true,
        // });
        window.goToMenu("devops/tool/warn")
        this.setState({handerFlag: true});
    }

    userMenuLogoutOnClick({key, keyPath, item, domEvent}){
        // console.log("userMenuLogoutOnClick", key, keyPath, item, domEvent)
        const {logoutOnClick} = this.props
        window.goToMenu()
        if (logoutOnClick !== undefined){
            logoutOnClick()
        }
    }

    mkHeaderLeftLogo(){
        return <Logo collapsed={true}/>;
    }
    
    mkHeaderMidMenu(){
        return <HeaderNav 
            history={this.props.history}
            match={this.props.match}
            reqUserInfo={this.props.reqUserInfo}
            defaultSelectedKeys={this.props.defaultSelectedKeys}
            >
        </HeaderNav>
    }

    mkHeaderRightMenu(){
        const { handerFlag} = this.state;
        const {reqUserInfo, data} = this.props;
        let warnCount = data ? data.records.length : 0;
        const userMenu = (<Menu selectable={false} className="user">
            <Menu.Item key="person"><Icon type="user"/>个人资料</Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="exit" onClick={this.userMenuLogoutOnClick}><Icon type="logout" />退出登录</Menu.Item>
        </Menu>);
        
        return (
            <div className="layout-header-right">
                <span className="layout-header-right-index-action" onClick={this.noticeIconOnClick}>
                    <Badge count={0} dot className="layout-header-right-alarm">
                        {warnCount ? <div><div className="warn-pulse warn-pulse-fix"/><div className="warn-pulse-big warn-pulse-big-fix"/></div> : undefined}
                        <Tooltip 
                            title={<span>发生报警，请及时处理
                                <HoLinkButton onClick={this.noticeIconOnClick}>查看</HoLinkButton>
                                <HoLinkButton onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                        handerFlag: true
                                    })
                                }}>忽略</HoLinkButton>
                            </span>}
                            placement="bottom" visible={warnCount && !handerFlag ? true : false}>
                            <HoIconFont className={warnCount ? "warn" : "normal"} type={"icon-pathx-white"}/>
                        </Tooltip>
                    </Badge>
                </span>
                <Dropdown
                    overlayClassName="profile-dropdown"
                    placement="bottomRight"
                    overlay={userMenu}>
                    <span className="layout-header-right-index-action">
                        <Avatar size="small" icon="user" style={{marginBottom: '4px'}} />
                        <span className="profile">{reqUserInfo ? reqUserInfo.user.name : "(未设置昵称)"}</span>
                        {/* <span style={{marginLeft: '8px'}}>{reqUserInfo ? reqUserInfo.company.name : "(未关联公司)"}</span> */}
                    </span>
                </Dropdown>
            </div>
        );
    }

    mkNoticePage(){

        const table = (<Tabs defaultActiveKey="undo" className="ho-notice-tabs">
            <Tabs.TabPane tab="待办" key="undo" >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无待办事项"/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="通知" key="notice">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无通知"/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="消息" key="message" >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无消息"/>
            </Tabs.TabPane>
        </Tabs>);

        return (
            <Drawer
                title={null}
                placement="right"
                width={480}
                closable={false}
                bodyStyle={{padding: '0px'}}
                onClose={this.noticePageOnClose}
                visible={this.state.noticePageVisible}
                >
                {table}
            </Drawer>
        );
    }

    warnOnTimer() {
        let {dispatch, zabbixUserInfo} = this.props;
        if (!zabbixUserInfo) {
            dispatch(loginRequestZabbix(undefined, () => dataListProblem(this.props, {hostids: ZABBIX_HOST_IDS}, listWarnDataSuccessHd)))
        } else {
            dataListProblem(this.props, {hostids: ZABBIX_HOST_IDS}, listWarnDataSuccessHd);
        }
    }

    // clear handle
    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentDidMount() {
        if (!version.isInternal()) {
            this.warnOnTimer()
            this.timer = setInterval(() => {this.warnOnTimer()}, 30000);
        }
    }

    render(){

        const headerLeftComponent = this.mkHeaderLeftLogo();
        const headerMidComponent = this.mkHeaderMidMenu();
        const headerRightComponent = this.mkHeaderRightMenu();
        const noticePageComponent = this.mkNoticePage();

        let header = (
            <Fragment>
                <Layout.Header className="layout-header">
                    <Row>
                        <Col xs={2} sm={2} md={2} lg={2} xl={1}>
                            {headerLeftComponent}
                        </Col>
                        <Col xs={14} sm={14} md={16} lg={18} xl={18}>
                            {headerMidComponent}
                        </Col>
                        <Col xs={8} sm={8} md={6} lg={4} xl={5}>
                            {headerRightComponent}   
                        </Col>
                    </Row>
                </Layout.Header>
                <div className="header-fixed"/>
            </Fragment>
        );

        return this.state.hideHeader ? undefined : header;
    }
}

const mapState = (state) => ({
    data: listWarnData(state),
    zabbixUserInfo: getLoginZabbixInfo(state),
});

export default connect(
    mapState, 
    null
)(Header);