/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import { hoTableTransPaginationInfo } from "../../../../../util/tableComponent";
import { netRequestThunk2 } from "../../../../../util/netReqRD";
import {loginNoauthHd} from "../../../../login/loginRD";

export const ACT_NET_LICENCE_LIST = 'listLicense';
export const ACT_NET_LICENCE_MOD = 'modLicense';
export const ACT_NET_LICENCE_NEW = 'newLicense';

export const dataListLicense = (props, paginationInfo, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/license/list'
    const msgBody = {
        ...hoTableTransPaginationInfo(paginationInfo),
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_LICENCE_LIST, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_LICENCE_LIST, successHd, successHd))
    );
}

export const dataModLicense = (props, license, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/license/mod'
    const msgBody = {
        ...license,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_LICENCE_MOD, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_LICENCE_MOD, successHd, successHd))
    );
}

export const dataNewLicense = (props, license, successHd = undefined, errorHd = undefined) => {
    const {reqUserInfo, dispatch, } = props;
    const path = '/cs/v1/mntn/mcu/ms/license/gen'
    const msgBody = {
        ...license,
    };
    const header = {
        Token: reqUserInfo?.auth?.token,
    }
    dispatch(netRequestThunk2(path, msgBody, header, ACT_NET_LICENCE_NEW, successHd, errorHd,
        () => loginNoauthHd(dispatch, path, msgBody, header, reqUserInfo, ACT_NET_LICENCE_NEW, successHd, successHd))
    );
}


