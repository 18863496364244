export let deviceTypeMap = [{
    value: 'ALL',
    title: "所有设备",
    caps: {
        
    },
},{
    value: 'I-5300',
    title: "5300",
    caps: {
        pullLog: true,
        license: true,
    },
}, {
    value: 'I-5200',
    title: "5200",
    caps: {
        pullLog: true,
        license: true,
    },
}, {
    value: 'I-5200-general',
    title: "5200（通用版）",
    caps: {
        pullLog: true,
        license: true,
    },
}, {
    value: 'TeleConference 2600',
    title: "2600",
    caps: {
        pullLog: true,
        license: true,
    },
}, {
    value: 'CAMERA-28181',
    title: "摄像头",
    caps: {
        
    },
}, {
    value: 'telephone',
    title: "手机用户（Android）",
    caps: {
        pullLog: true,
    },
}, {
    value: 'ios-telephone',
    title: "手机用户（ios）",
    caps: {
        pullLog: true,
    },
}, {
    value: 'WEB',
    title: "网页用户",
    caps: {
        pullLog: true,
    },
}, {
    value: 'VMEETING-SDK',
    title: "网页SDK用户",
    caps: {
        
    },
}, {
    value: 'sipp',
    title: "测试用户",
    caps: {
        
    },
}
]

export let stateMap = [
    {
        value: 'ALL',
        title: "所有状态",
    },{
        value: 'online',
        title: "在线",
    }, {
        value: 'offline',
        title: "离线",
    }
]

export let warnStateMap = [
    {
        value: 'ALL',
        title: "所有状态",
    },{
        value: '0',
        title: "正在告警",
    }, {
        value: '1',
        title: "已恢复",
    }
]